import axios from 'axios';

import { FC } from 'react';
import { downloadsAction } from '../../features/downloads';
import { useAppDispatch } from '../../store';
import {Button} from './Button';

// a component for download button

export const DownloadButton: FC<{downloadType:{downloadType:string}}> = (props) => {
const dispatch = useAppDispatch()

  

    return (
      <Button 
      {...props}
      
      onClick={() => dispatch(downloadsAction(props.downloadType))}

      >{props.children ? props.children : "Download"}</Button>
    );
  };
  