import React, { useEffect, useState } from 'react';
import * as d3 from 'd3';

interface IAreaChartProps {
  width: number;
  height: number;
}

export function AreaChart(props: IAreaChartProps) {
  const { width, height } = props;

  const data: { date: Date | null; value: number }[] = [
    {
      date: '2018-03-06',
      value: 11500.1,
    },
    {
      date: '2018-03-07',
      value: 10929.5,
    },
    {
      date: '2018-03-08',
      value: 10147.4,
    },
    {
      date: '2018-03-09',
      value: 9466.35,
    },
    {
      date: '2018-03-10',
      value: 9531.32,
    },
    {
      date: '2018-03-11',
      value: 9711.89,
    },
    {
      date: '2018-03-12',
      value: 9937.5,
    },
    {
      date: '2018-03-13',
      value: 9470.38,
    },
    {
      date: '2018-03-14',
      value: 9355.85,
    },
    {
      date: '2018-03-15',
      value: 8428.35,
    },
    {
      date: '2018-03-16',
      value: 8585.15,
    },
    {
      date: '2018-03-17',
      value: 8346.53,
    },
    {
      date: '2018-03-18',
      value: 8245.51,
    },
    {
      date: '2018-03-19',
      value: 8675.87,
    },
    {
      date: '2018-03-20',
      value: 9051.02,
    },
    {
      date: '2018-03-21',
      value: 9177.37,
    },
    {
      date: '2018-03-22',
      value: 9100.71,
    },
    {
      date: '2018-03-23',
      value: 8879.62,
    },
    {
      date: '2018-03-24',
      value: 8996.18,
    },
    {
      date: '2018-03-25',
      value: 8682.01,
    },
  ].map((d) => ({ date: d3.timeParse('%Y-%m-%d')(d.date), value: d.value }));

  const [areaPath, setAreaPath] = useState<string>('');

  const x = d3
    .scaleTime()
    // @ts-ignore
    .domain(d3.extent(data, (d) => d.date))
    .range([-3, width + 3]);

  const y = d3
    .scaleLinear()
    // @ts-ignore
    .domain([0, d3.max(data, (d) => d.value)])
    .nice()
    .range([height + 3, 0]);


// @ts-ignore
  const area = d3
  // @ts-ignore
    .area()
    // @ts-ignore
    .curve(d3.curveLinear)
    // @ts-ignore
    .x((d) => x(d.date))
    // @ts-ignore
    .y0(y(0))
    // @ts-ignore
    .y1((d) => y(d.value));
  useEffect(() => {
    if (!areaPath.length) {
      // @ts-ignore
      setAreaPath(area(data));
    }
  }, [area, areaPath, data]);

  return (
    <svg width={width} height={height}>
      <g transform={`translate(${0}, ${0})`}>
        {Math.random() > 0.5 ? (
          <>
            <defs>
              <linearGradient id='greenGradient' gradientTransform='rotate(90)'>
                <stop offset='3%' stopColor='rgb(232,69,69, 0.16)' />
                <stop offset='100%' stopColor='rgb(232,69,69, 0.0)' />
              </linearGradient>
            </defs>
            <path d={areaPath} stroke='#e84545' strokeWidth='3' fill='url(#greenGradient)' />
          </>
        ) : (
          <>
            <defs>
              <linearGradient id='redGradient' gradientTransform='rotate(90)'>
                <stop offset='3%' stopColor='rgba(0,175,145,0.16)' />
                <stop offset='100%' stopColor='rgba(0,175,145,0.0)' />
              </linearGradient>
            </defs>
            <path d={areaPath} stroke='#00AF91' strokeWidth='3' fill='url(#redGradient)' />
          </>
        )}

        <line x1='0' y1='75%' x2='100%' y2='75%' stroke='#00587a' opacity='0.16' strokeWidth='2' strokeDasharray='5' />
      </g>
    </svg>
  );
}
