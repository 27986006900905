import { ErrorMessage, Field, Form, Formik, useFormikContext } from "formik";
import { debounce, isEqual, orderBy } from "lodash";
import React, { useState } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import * as Yup from "yup";
import { Button } from "../../common-components";
import CoinsList from "./CoinsList";
// import { ActionMeta, OnChangeValue } from "react-select";

import classNames from "classnames";
import { InformationIcon } from "../../../assets/icons";
import { IWithdrawReqDataModel } from "../../../features/interfaces";
import {
  IWithdrawData,
  TCryptoPairs,
} from "../../../features/interfaces/withdraw/withdraw-interfaces";
import {
  ajaxSuccessAlert,
  currencyFormat,
  findCryptoPairBycoinNetworkArr,
} from "../../../features/utils";
import { submitWithdrawAction } from "../../../features/withdraw/slices/submitWithdrawAction";
import { useAppDispatch, useAppSelector } from "../../../store";
import GAModal from "../../common-components/GAModal";
import WalletDropDown from "./WalletDropdown";
import WithdrawOverview from "./WithdrawOverview";
import WithdrawalHelp from "./WithdrawalHelp";
import { TCoinNetWorkArr } from "../../../features/interfaces/withdraw/TCoinNetWorkArr";
export default function WithdrawForm(props: RouteComponentProps) {
  const [displayHelp, setDisplayHelp] = useState(false);
  const [openGAForm, setOpenGAForm] = useState(false);
  const [GAapproved, setGAapproved] = useState(false);
  const setOpenGAFormDebounced = debounce(setOpenGAForm, 400);
  const history = useHistory();
  const { fees, cryptoPairs, constraints, message } = useAppSelector(
    (state) => state.withdraw.getWithdrawData
  );
  const { withdrawSubmit } = useAppSelector((state) => state.withdraw);
  const { twoFaAuthenticationAt } = useAppSelector(
    (state) => state.user.userProfileDataUtils.data
  );
  const dispatch = useAppDispatch();

  function findCryptoPairId(coinNetworkArr: TCoinNetWorkArr) {
    const cryptoPair = findCryptoPairBycoinNetworkArr(
      coinNetworkArr,
      cryptoPairs
    );
    const cryptoPairId = cryptoPair?.id ?? 0;

    return cryptoPairId;
  }

  const validationSchema = Yup.object().shape({
    amount: Yup.number()
      .min(
        constraints.min,
        `you can't withdraw less than ${constraints.min}
        `
      )
      .max(
        constraints.max,
        `${"you can't withdraw more than " + constraints.max}`
      ),

    walletAddress: Yup.string().required(
      "please enter or select a valid wallet address"
    ),
    //  walletValidationSchema('BTC')
  });
  const ReSubmitForm = ({ setGAapproved }: any) => {
    // Grab values and submitForm from context

    const { values, submitForm } = useFormikContext();

    React.useEffect(() => {
      let tmpValues: any = values;

      if (GAapproved) {
        let data: IWithdrawData = {
          usdAmount: tmpValues.amount,
          walletAddress: tmpValues.walletAddress,
          cryptoPairId: findCryptoPairId(tmpValues.deposit_type), //tmpValues.deposit_type //CoinToNetwork(tmpValues.deposit_type),
        };

        if (GAapproved && twoFaAuthenticationAt) {
          dispatch(submitWithdrawAction(data)).then((res) => {
            if (res.meta.requestStatus === "fulfilled") {
              res.payload &&
                res.payload.message &&
                ajaxSuccessAlert(res.payload.message).then(() =>
                  history.push("/panel/reports")
                );
            }
          });

          setGAapproved(false);
        }
      }
    }, [submitForm]);

    return null;
  };

  const getStaticFee = (feesObj: typeof fees, selectedValue?: number) => {
    const limits = ["lastLimit", "firstLimit", "noLimit"] as const;

    const orderedFees = orderBy(feesObj, ["maxAmount"], ["desc"]);

    const feesMap = new Map(
      orderedFees.map((item, index) => [limits[index], item])
    );
    if (typeof selectedValue === "number" && feesObj) {
      if (selectedValue > feesMap.get("lastLimit")!.maxAmount)
        return feesMap.get("noLimit")!.commissionPercent;

      if (
        selectedValue <= feesMap.get("lastLimit")!.maxAmount &&
        selectedValue > feesMap.get("firstLimit")!.maxAmount
      )
        return feesMap.get("lastLimit")!.commissionPercent;

      if (selectedValue <= feesMap.get("firstLimit")!.maxAmount)
        return feesMap.get("firstLimit")!.commissionPercent;
    }
    return 0;
  };
  const getCoinPairFee = (
    coinNetWorkArr: TCoinNetWorkArr,
    cryptoPairs: TCryptoPairs
  ) => {
    const selectedCoinPair = findCryptoPairBycoinNetworkArr(
      coinNetWorkArr,
      cryptoPairs
    );

    const coinBasedFee = selectedCoinPair?.cryptoFeePercent ?? 0;
    return coinBasedFee;
  };
  const getFee = (
    coinNetWorkArr: TCoinNetWorkArr,
    cryptoPairs: TCryptoPairs,
    selectedValue?: number
  ) => {
    const staticFee = getStaticFee(fees, selectedValue);
    const coinBasedFee = +getCoinPairFee(coinNetWorkArr, cryptoPairs);
    return staticFee + coinBasedFee;
  };

  return (
    <>
      {/* <WithdrawalHelp
        isOpen={displayHelp}
        onClose={() => setDisplayHelp(false)}
      /> */}

      <GAModal
        isOpen={openGAForm}
        onClose={() => setOpenGAForm(false)}
        setGAapproved={setGAapproved}
      />

      <WithdrawOverview />
      <div className="w-full relative">
        <div className={classNames("withdrawFormWrapper")}>
          <Formik
            initialValues={{
              amount: 0,
              deposit_type: [undefined, undefined],
              walletAddress: "",
            }}
            validationSchema={validationSchema}
            validateOnChange={true}
            // enableReinitialize={true}
            onSubmit={(values: IWithdrawReqDataModel) => {
              let data: IWithdrawData = {
                usdAmount: values.amount,
                walletAddress: values.walletAddress,
                cryptoPairId: findCryptoPairId(values.deposit_type),
              };

              if (twoFaAuthenticationAt === null) {
                dispatch(submitWithdrawAction(data)).then((res) => {
                  if (res.meta.requestStatus === "fulfilled") {
                    res.payload &&
                      res.payload.message &&
                      ajaxSuccessAlert(res.payload.message).then(() =>
                        history.push("/panel/reports")
                      );
                  }
                });
              }

              if (twoFaAuthenticationAt) {
                !GAapproved && setOpenGAFormDebounced(true);
              }
            }}
          >
            {({ values, handleReset, handleSubmit, isValid, handleChange }) => (
              <Form
                onReset={handleReset}
                onSubmit={handleSubmit}
                onChange={handleChange}
                className="withdrawFormWrapper__form"
              >
                {GAapproved && <ReSubmitForm setGAapproved={setGAapproved} />}
                <CoinsList name="deposit_type" />
                <div>
                  <div className="relative">
                    {/* sets these two inputs disabled before coin selection */}
                    <div
                      className={classNames({
                        withdrawFormWrapper__inputDisable: isEqual(
                          values.deposit_type,
                          [undefined, undefined]
                        ),
                      })}
                    >
                      <p
                        className={classNames({
                          withdrawFormWrapper__hidden: !isEqual(
                            values.deposit_type,
                            [undefined, undefined]
                          ),
                        })}
                      >
                        Choose your desired coin please...
                      </p>

                      <label htmlFor="walletAddress">
                        Enter your wallet address
                      </label>
                      <WalletDropDown
                        name="walletAddress"
                        cryptoPairId={findCryptoPairId(values.deposit_type)}
                      />
                    </div>
                    <div
                      className={classNames(
                        "withdrawFormWrapper__valueContainer"
                      )}
                    >
                      <label htmlFor="amount">Withdrawal amount (USD)</label>
                      <Field
                        id="amount"
                        name="amount"
                        type="number"
                        placeholder="Enter the amount of withdrawal in US dollars"
                        onChange={handleChange}
                      />

                      <ErrorMessage
                        name="amount"
                        render={(msg) => (
                          <div className="text-red-500">{msg}</div>
                        )}
                      />
                    </div>
                  </div>

                  <div className="withdrawFormWrapper__minMax">
                    <div>
                      <p>Min Withdraw</p>
                      <p>Max Withdraw</p>
                    </div>

                    <div>
                      <p>
                        {/* {values.deposit_type && (min / coinPrice).toFixed(5)}{" "} */}
                        {/* {values.deposit_type} */}
                      </p>
                      <p>
                        {/* {values.deposit_type && (max / coinPrice).toFixed(2)}{" "} */}
                        {/* {values.deposit_type} */}
                      </p>
                    </div>

                    <div />

                    <div>
                      <p>${constraints.min}</p>
                      <p>${constraints.max}</p>
                    </div>
                  </div>

                  <div className="fundsToWithdraw">
                    <p className="fundsToWithdraw__header">Funds to withdraw</p>
                    <div>
                      {/* <div>
                        <p>
                          {values.deposit_type &&
                            (values.amount / coinPrice).toFixed(5)}{" "}
                          {values.deposit_type}
                        </p>
                      </div>
                      <ApproximatelyEqualIcon /> */}

                      <div>
                        <p>
                          <b>$</b>
                          {values.amount}
                        </p>

                        <p>
                          -
                          {
                            Number.isInteger((getFee(
                              values.deposit_type,
                              cryptoPairs,
                              values.amount
                            ) * 100)) ? 
                            (getFee(
                              values.deposit_type,
                              cryptoPairs,
                              values.amount
                            ) * 100) 
                            : 
                            (getFee(
                              values.deposit_type,
                              cryptoPairs,
                              values.amount
                            ) * 100).toFixed(2)
                          
                          }
                          % Fee
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="withdrawFee">
                    {/* <div> */}
                    {/* <p>Withdrawal Fee ({values.deposit_type})</p> */}

                    {/* ////////////////////////////////////// the function below needs a revision */}
                    {/* <p> */}
                    {
                      // usdTocoin(
                      //   (WithdrawFee[values.deposit_type] / 100) *
                      //     values.amount,
                      //5
                      // )
                    }
                    {/* {values.deposit_type} */}
                    {/* </p> */}
                    {/* </div> */}
                    <div>
                      <p>Receiving Amount:</p>
                      <p>
                        {currencyFormat(
                          Math.trunc(
                            values.amount -
                              getFee(
                                values.deposit_type,
                                cryptoPairs,
                                values.amount
                              ) *
                                values.amount
                          )
                        )}
                      </p>
                    </div>
                  </div>

                  <div className="withdrawActions">
                    <div
                      className={classNames("withdrawFormWrapper", {
                        withdrawFormWrapper__pageDisable:
                          !constraints.isWithdrawAllowed,
                        withdrawFormWrapper__pageDisable__hidden:
                          constraints.isWithdrawAllowed,
                      })}
                    >
                      <p>
                        {message ||
                          "You've reached your withdrawal limit. You can withdraw your earnings next month."}
                      </p>
                    </div>
                    <Button
                      type="submit"
                      loading={withdrawSubmit.status === "pending"}
                      disabled={
                        withdrawSubmit.status === "pending" ||
                        !isValid ||
                        !constraints.isWithdrawAllowed
                      }
                    >
                      Withdraw
                    </Button>

                    {/* <InformationIcon
                      onClick={(event: React.MouseEvent<SVGSVGElement>) => {
                        event.preventDefault();
                        setDisplayHelp(true);
                      }}
                    /> */}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}
