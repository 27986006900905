import { memo } from "react";

import { currencyFormat, numberFormatter } from "../../../features/utils";
import { useAppSelector } from "../../../store";
import { SlickSlider } from "../../common-components";
// import { useAppSelector } from '../../../store';

function OverviewCardsList() {
  const { data } = useAppSelector((state) => state.matchingBonusData.treeChart);
  return (
    <div style={{ marginBottom: "1em" }}>
      <SlickSlider
        settings={{
          className: "slider variable-width",
          dots: false,
          infinite: true,
          speed: 500,
          initialSlide: 0,
          slidesToShow: 5,
          slidesToScroll: 0,
          arrows: false,
          centerMode: false,
          autoplay: true,
          autoplaySpeed: 3000,
          pauseOnHover: true,
          responsive: [
            {
              breakpoint: 840,
              settings: {
                speed: 500,
                initialSlide: 2,
                slidesToShow: 2,
                slidesToScroll: 2,
              },
            },
            {
              breakpoint: 760,
              settings: {
                speed: 500,
                initialSlide: 2,
                slidesToShow: 2,
                slidesToScroll: 2,
                centerMode: true,
              },
            },
            {
              breakpoint: 560,
              settings: {
                speed: 500,
                initialSlide: 2,
                slidesToShow: 2,
                slidesToScroll: 1,
                centerMode: true,
              },
            },
            {
              breakpoint: 450,
              settings: {
                speed: 500,
                initialSlide: 1,
                slidesToShow: 1.1,
                slidesToScroll: 1,
                centerMode: true,
              },
            },
          ],
        }}
      >
        <div className="amp__overviewCard">
          <div>
            <p className="bg-blue-600">{currencyFormat(data.totalEarnings)}</p>
            <h3>Total Earned</h3>
          </div>
        </div>

        <div className="amp__overviewCard">
          <div>
            <p className="bg-brand">{currencyFormat(data.totalPendings)}</p>
            <h3>Pending Earnings</h3>
          </div>
        </div>

        <div className="amp__overviewCard">
          <div>
            <p className="bg-green-600">
              {numberFormatter(data.investors.total)}
            </p>
            <h3>Total Investors</h3>
          </div>
        </div>

        <div className="amp__overviewCard">
          <div>
            <p className="bg-pink-700">
              {numberFormatter(data.directReferrals.total ?? 0)}
            </p>
            <h3>Direct Referrals</h3>
          </div>
        </div>

        <div className="amp__overviewCard">
          <div>
            <p className="bg-orange-600">
              {currencyFormat(data.burnedAmount ?? 0)}
            </p>
            <h3>Burned Amount</h3>
          </div>
        </div>

      </SlickSlider>
    </div>
  );
}

export default memo(OverviewCardsList);
