import { createSlice } from "@reduxjs/toolkit";

import { ITransactionReports, IValidationErrors } from "../interfaces";
import { getTransactionAction } from "./slices/get-transaction-Action";

const initialData: {
  status: "idle" | "rejected" | "pending";
  error: IValidationErrors;
  transactions: ITransactionReports[];
  dataFetched: boolean;
} = {
  status: "idle",
  error: {},
  transactions: [],
  dataFetched: false,
};

export const { reducer, actions } = createSlice({
  name: "transactions",
  initialState: initialData,
  reducers: {},
  extraReducers: (builder) => {
    // GET USER SUBMITTED TRANSACTIONS
    builder
      .addCase(getTransactionAction.fulfilled, (state, action) => {
        state.status = "idle";
        state.dataFetched = true;
        if (action.payload) {
          state.transactions = action.payload;
        }
      })
      .addCase(getTransactionAction.pending, (state) => {
        state.status = "pending";
        state.dataFetched = false;
      })
      .addCase(getTransactionAction.rejected, (state, action) => {
        state.status = "rejected";
        state.dataFetched = true;
        if (action.payload) state.error = action.payload;
      });
  },
});

export default reducer;
