import classNames from "classnames";
import React, { useState } from "react";
import { NavLink, RouteComponentProps } from "react-router-dom";
import {
  AffiliateBinaryIcon,
  AffiliateMultiLevelIcon,
  DashboardIcon,
  DepositIcon,
  LogoutIcon,
  PortfolioIcon,
  ReportsIcon,
  SettingsIcon,
  WithdrawIcon,
} from "../../assets/icons";
import { signoutAction } from "../../features/authentication/slices/signout";
import { useAppDispatch, useAppSelector } from "../../store";
import { SideNav } from "../common-components";
import { ReloadingNavLink } from "../common-components/ReloadingNavLink";

export default function SideBar(props: RouteComponentProps) {
  const [openMenu, setOpenMenu] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const { initialInvestmentAt, isMatchingEnabled, isWithdrawAvailable } =
    useAppSelector((state) => state.user.userProfileDataUtils.data);
  const sideNavChildren = (
    <>
      <NavLink
        aria-label="Dashboard"
        exact={true}
        className={classNames("sideBar__link", {
          sideBar__link_disable: !initialInvestmentAt,
        })}
        activeClassName="sideBar__link_active"
        onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
          if (!initialInvestmentAt) {
            event.preventDefault();
            return;
          } else {
            setOpenMenu(false);
          }
        }}
        to={`${props.match.url}`}
      >
        <DashboardIcon />
        <span>Dashboard</span>
      </NavLink>

      <NavLink
        aria-label="Portfolio"
        className={classNames("sideBar__link", {
          // sideBar__link_disable: false,
          sideBar__link_disable: !initialInvestmentAt,
        })}
        activeClassName="sideBar__link_active"
        exact={true}
        onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
          // setOpenMenu(false);
          if (!initialInvestmentAt) {
            event.preventDefault();
            return;
          } else {
            setOpenMenu(false);
          }
        }}
        to={`${props.match.url}/portfolio`}
      >
        <PortfolioIcon />
        <span>Portfolio</span>
      </NavLink>

      <ReloadingNavLink
        aria-label="Affiliate Multi Level Plan"
        isActiveNow={props.location.pathname.includes("multi")}
        isDisable={!initialInvestmentAt}
        redirectToPath={`${props.match.url}/affiliate-multi-level-plan`}
        setOpenMenu={setOpenMenu}
      >
        <AffiliateMultiLevelIcon />
        <span>Affiliate Multi Level Plan</span>
      </ReloadingNavLink>

      <NavLink
        aria-label="Affiliate Matching Bonus Plan"
        exact={true}
        className={classNames("sideBar__link", {
          sideBar__link_disable: !isMatchingEnabled,
        })}
        activeClassName="sideBar__link_active"
        onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
          if (!isMatchingEnabled) {
            event.preventDefault();
            return;
          } else {
            setOpenMenu(false);
          }
        }}
        to={`${props.match.url}/affiliate-matching-bonus-plan`}
      >
        <AffiliateBinaryIcon />
        <span>Affiliate Matching Bonus Plan</span>
      </NavLink>
      <NavLink
        aria-label="Deposit"
        exact={true}
        className={classNames("sideBar__link")}
        activeClassName="sideBar__link_active"
        onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
          setOpenMenu(false);
        }}
        to={`${props.match.url}/deposit`}
      >
        <DepositIcon />
        <span>Deposit</span>
      </NavLink>
      <NavLink
        aria-label="Withdraw"
        exact={true}
        className={classNames("sideBar__link ", {
          //sideBar__link_disable: true,
          sideBar__link_disable: !isWithdrawAvailable,
        })}
        activeClassName="sideBar__link_active"
        onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
          if (!isWithdrawAvailable) {
            event.preventDefault();
            return;
          } else {
            setOpenMenu(false);
          }
        }}
        to={`${props.match.url}/withdraw`}
      >
        <WithdrawIcon />
        <span>Withdraw</span>
      </NavLink>
      <NavLink
        aria-label="Reports"
        // exact={true}
        className={classNames("sideBar__link", {
          sideBar__link_disable: !initialInvestmentAt,
          //  || !triedToTransferFund,
        })}
        activeClassName="sideBar__link_active"
        onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
          if (
            !initialInvestmentAt
            // || !triedToTransferFund
          ) {
            event.preventDefault();
            return;
          } else {
            setOpenMenu(false);
          }
        }}
        to={`${props.match.url}/reports`}
      >
        <ReportsIcon />
        <span>Reports</span>
      </NavLink>
      <NavLink
        aria-label="Settings"
        exact={true}
        className={classNames("sideBar__link")}
        activeClassName="sideBar__link_active"
        onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
          setOpenMenu(false);
        }}
        to={`${props.match.url}/settings`}
      >
        <SettingsIcon />
        <span>Settings</span>
      </NavLink>
      <a
        aria-label="Logout"
        className={classNames({})}
        onClick={() => dispatch(signoutAction())}
      >
        <LogoutIcon />
        <span>Signout</span>
      </a>
    </>
  );
  return (
    <SideNav isOpen={openMenu} onSetOpen={() => setOpenMenu(!openMenu)}>
      {sideNavChildren}
    </SideNav>
  );
}
