import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import { IMatchingBonusCardModel, IValidationErrors } from "../../interfaces";

export const getMatchingById = createAsyncThunk<
  IMatchingBonusCardModel[] | string,
  number,
  {
    rejectValue: IValidationErrors;
  }
>("user/get-matching-data-id", async (id, { rejectWithValue }) => {
  try {
    return axios
      .get(`${process.env.REACT_APP_API_ROOT_NEW}/matching/matching/${id}`)
      .then((response) => {
        if (response.data.message) return response.data.message;
        return response.data.data.refereeDirectTeamMembers;
      });
  } catch (error: any) {
    let message = "";
    console.error(error);
    return rejectWithValue({
      message: message,
      code: error.response.status,
      name: "user/get-matching-data-id",
    });
  }
});
