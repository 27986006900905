import { createSlice } from "@reduxjs/toolkit";

import { downloadsAction } from "./slices/downloadThunk";

const initialData: {
  error: any | undefined;
  status: "idle" | "rejected" | "pending";
  dataFetched: boolean;
} = {
  error: {},
  status: "idle",
  dataFetched: false,
};

export const { actions, reducer } = createSlice({
  name: "downloads",
  initialState: initialData,
  reducers: {
    setErrors(state, action) {},
    clearErrors(state) {
      state.error = {};
    },
  },
  extraReducers: (builder) => {
    // FETCH USER WALLETS
    builder
      .addCase(downloadsAction.fulfilled, (state, action) => {
        state.status = "idle";
        if (action.payload) {
          state.dataFetched = true;
          state.error = {};
        }
      })
      .addCase(downloadsAction.pending, (state) => {
        state.status = "pending";
        state.dataFetched = false;
      })
      .addCase(downloadsAction.rejected, (state, action) => {
        state.status = "rejected";
        state.dataFetched = false;
        state.error = action.payload;
      });
  },
});

export default reducer;
