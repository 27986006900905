import classNames from "classnames";
import { Field, Form, Formik } from "formik";
import { KeyboardEvent, memo, useEffect, useState } from "react";
import * as Yup from "yup";

import {
  actions,
  changeEmailAction,
} from "../../../../features/authentication";
import {
  ajaxErrorAlert,
  ajaxSuccessAlert,
  ajaxWarningAlert,
  logout,
} from "../../../../features/utils";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { Button, CustomModal } from "../../../common-components";

const ValidationSchema = Yup.object().shape({
  email: Yup.string()
    .required("Please enter a valid email address")
    .email("Please enter a valid email address"),
  field: Yup.number().min(1).max(1),
});

export default memo(function EmailForm({
  onClose,
  isOpen,
}: {
  isOpen: boolean;
  onClose(): void;
}) {
  const [pinCode, setPinCode] = useState<string[]>(["", "", "", "", "", ""]);

  const dispatch = useAppDispatch();
  const { changeEmailData, error } = useAppSelector((state) => state.user);
  const changeEmailStatus = changeEmailData.status;
  useEffect(() => {
    let status = error?.code;
    let message = error?.message;
    if (status === 400) {
      ajaxErrorAlert(message).then((res) => {
        if (res.value) dispatch(actions.clearErrors());
      });
    }
  }, [error, dispatch]);

  const setPinValue = (
    event: KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    if (
      (event.keyCode >= 48 && event.keyCode <= 57) ||
      (event.keyCode >= 96 && event.keyCode <= 105) ||
      event.keyCode === 8
    ) {
      let newPinCode = [...pinCode];
      newPinCode[index] = event.key.toString();

      if (index < 6) {
        document.getElementById(`PIN_${index + 1}`)?.focus();
      }

      if (index === 6) {
        document.getElementById("password")?.focus();
      }

      setPinCode((prevState) => newPinCode);
    }
    if (event.keyCode === 8) {
      let newPinCode = [...pinCode];
      newPinCode[index] = event.key.toString();
      if (index <= 6) {
        document.getElementById(`PIN_${index - 1}`)?.focus();
      }
    }
  };

  useEffect(() => {
    document.getElementById("PIN_0")?.focus();
    document.addEventListener("paste", pasteFromClipboard);
  }, []);

  const pasteFromClipboard = (event: ClipboardEvent) => {
    if (event.clipboardData?.getData("text").length === 6) {
      let code = event.clipboardData.getData("text").toString();

      for (let index = 0; index < 6; index++) {
        if (code.charCodeAt(index) < 47 && code.charCodeAt(index) > 57) {
          ajaxErrorAlert(
            "Please paste a valid five digit code to verify your email"
          );

          return;
        }
      }

      setPinCode((prevState) => event.clipboardData!.getData("text").split(""));
    }
  };

  return (
    <CustomModal isOpen={isOpen} onClose={onClose} className ="Modal">
      <Formik
        initialValues={{ email: "", field: "" }}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={ValidationSchema}
        onSubmit={(values, actions) => {
          actions.setSubmitting(true);
          ajaxWarningAlert(
            `Are you sure you want to change your email to ${values.email}?`
          ).then(async (res) => {
            if (res.isConfirmed) {
              try {
                await dispatch(
                  changeEmailAction({
                    newEmail: values.email,
                    otpCode: pinCode.join(""),
                  })
                );

                const message = error?.message;
                if (message && error?.name === "user/change-email") {
                  ajaxErrorAlert(message).then((res) => {
                    if (res) {
                      onClose();
                    }
                  });
                }
                ajaxSuccessAlert(
                  "Your email changed successfully! you need to signin again"
                ).then((res) => {
                  if (res) {
                    logout();
                    onClose();
                  }
                });
              } catch (error) {
                ajaxErrorAlert(
                  "We can't process you request right now!",
                  "Oops!"
                );
              }
            }
          });

          actions.setSubmitting(false);
        }}
      >
        {({
          values,
          handleReset,
          handleSubmit,
          handleBlur,
          handleChange,
          errors,
          isSubmitting,
        }) => (
          <Form
            onReset={handleReset}
            onSubmit={handleSubmit}
            className="changeEmailForm"
          >
            <h1>Setting New Email Address</h1>

            <div>
              <label htmlFor="email">Please enter your new email address</label>
              <Field
                name="email"
                id="email"
                placeholder="email@example.com"
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.email && <p className="formError">{errors.email}</p>}
            </div>

            <div>
              <label>Verification Code:</label>

              <div>
                {pinCode.map((code: string, index: number) => (
                  <Field
                    key={`PIN_${index}`}
                    id={`PIN_${index}`}
                    value={code}
                    min={0}
                    max={9}
                    maxLength={1}
                    onKeyDown={(event: KeyboardEvent<HTMLInputElement>) => {
                      setPinValue(event, index);
                    }}
                    onBlur={handleBlur}
                    type="number"
                    className={classNames({ inputError: errors.field })}
                  />
                ))}
              </div>
              {errors.field && (
                <p className="formPinError ma ">{errors.field}</p>
              )}
            </div>

            <Button type="submit" loading={changeEmailStatus === "pending"}>
              Set New Email Address
            </Button>
          </Form>
        )}
      </Formik>
    </CustomModal>
  );
});
