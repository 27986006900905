import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { IValidationErrors } from "../../interfaces";
export const googleAuthenticatorAction = createAsyncThunk<
  | {
      secretKey: string;
    }
  | any,
  undefined,
  {
    rejectValue: IValidationErrors;
  }
>("user/GA", async (_, { rejectWithValue }) => {
  try {
    return await axios
      .get(`${process.env.REACT_APP_API_ROOT_NEW}/auth/2fa`)
      .then((response) => {
        return {
          secretKey: response.data.data.url,
        };
      })
      .catch((error) => {
        switch (error.response.error) {
          case 500:
            return rejectWithValue({
              message: error.message,
              code: error.response.status,
              name: "user/GA",
            });
          case 401:
            return rejectWithValue({
              message: error.message,
              code: error.response.status,
              name: "user/GA",
            });
          case 403:
            return rejectWithValue({
              message: error.message,
              code: error.response.status,
              name: "user/GA",
            });
        }
      });
  } catch (error: any) {
    let message = "";
    return rejectWithValue({
      message: message,
      code: error.response.status,
      name: "user/GA",
    });
  }
});
