import { useRef, useEffect, useCallback, useMemo, RefObject } from 'react';
import * as d3 from 'd3';
import { currencyFormat } from '../../../features/utils';

interface ProgressChartProps {
  width: number;
  color: string;
  value: number;
  floatingPointCount?: number;
  totalValue: number;
}

export function ProgressChart({
  width,
  value,
  totalValue,
  color,
  floatingPointCount,
}: ProgressChartProps) {
  const _progressRef = useRef() as RefObject<SVGSVGElement>;

  let numberOfFloatingPointDigits = 1;

  if (totalValue > 0) {
    numberOfFloatingPointDigits = 5;
  } else if (totalValue > 9) {
    numberOfFloatingPointDigits = 4;
  } else if (totalValue > 99) {
    numberOfFloatingPointDigits = 3;
  } else if (totalValue > 999) {
    numberOfFloatingPointDigits = 2;
  } else if (totalValue > 9999) {
    numberOfFloatingPointDigits = 1;
  }

  const dataset = useMemo(
    () => [
      { name: 'Success', count: value },
      { name: 'Error', count: totalValue - value },
    ],
    [value, totalValue]
  );

  const renderChart = useCallback(() => {
    if (_progressRef) {
      const ratio = dataset[0].count / totalValue;

      const pie = d3
        .pie()
        // @ts-ignore
        .value((d) => d.count)
        .sort(null);

      const outerRadius = width / 2 - 10;
      const innerRadius = width / 2 - 22;
      const colorGenerator = d3.scaleOrdinal().range([color, '#dee3e2']);
      const arc = d3.arc().innerRadius(innerRadius).outerRadius(outerRadius);
      const arcLine = d3.arc().startAngle(0);

      const svg = d3
        .select(_progressRef.current)
        .attr('width', width)
        .attr('height', width)
        .append('g')
        .attr('transform', 'translate(' + width / 2 + ',' + width / 2 + ')');

      svg
        .selectAll('path')
        // @ts-ignore
        .data(pie(dataset))
        .enter()
        .append('path')
        // @ts-ignore
        .attr('d', arc)
        // @ts-ignore
        .attr('fill', function (d, i) {
          // @ts-ignore
          return colorGenerator(d.data.name);
        })
        .on('mouseenter', (d) => {
          let endAngle = d.endAngle;
          let startAngle = d.startAngle;

          let arcOver = d3
            .arc()
            .outerRadius(outerRadius + 9)
            .endAngle(endAngle)
            .startAngle(startAngle);
          // @ts-ignore
          d3.select(this)
            .attr('stroke', 'white')
            // @ts-ignore
            .transition()
            .duration(1000)
            // @ts-ignore
            .attr('d', arcOver)
            .attr('stroke-width', 6);
        });

      let pathLine = svg
        .append('path')
        .datum({ endAngle: 0 })
        // @ts-ignore
        .attr('d', arcLine)
        .attr('fill', color);

      let middleCount = svg
        .append('text')
        .datum(
          value.toFixed(
            floatingPointCount ? floatingPointCount - 1 : numberOfFloatingPointDigits - 1
          )
        )
        .text(function (d) {
          return currencyFormat(
            Number(d),
            floatingPointCount ? floatingPointCount - 1 : numberOfFloatingPointDigits - 1
          );
        })
        .attr('class', 'middleText')
        .attr('text-anchor', 'middle')
        .attr('dy', outerRadius / 6)
        .attr('fill', color);

      let arcTween = (transition: any, newAngle: any) => {
        transition.attrTween('d', function (d: any) {
          let interpolate = d3.interpolate(d.endAngle, newAngle);
          let interpolateCount = d3.interpolate(0, dataset[0].count);
          return function (t: any) {
            d.endAngle = interpolate(t);

            middleCount.text(
              currencyFormat(
                Number(interpolateCount(t)),
                floatingPointCount ? floatingPointCount - 1 : numberOfFloatingPointDigits - 1
              )

              // `$${Number(interpolateCount(t)).toFixed(
              //   floatingPointCount ?? numberOfFloatingPointDigits
              // )}`
            );

            return arcLine(d);
          };
        });
      };

      let animate = function () {
        pathLine
          .transition()
          .duration(750)
          .call(arcTween, 2 * Math.PI * ratio);
      };

      setTimeout(animate, 750);
    }
  }, [
    dataset,
    _progressRef,
    width,
    color,
    value,
    floatingPointCount,
    totalValue,
    numberOfFloatingPointDigits,
  ]);

  useEffect(() => {
    renderChart();

    return () => {
      while (_progressRef?.current?.firstChild) {
        // eslint-disable-next-line
        _progressRef.current.removeChild(_progressRef.current.lastChild!);
      }
    };
  }, [renderChart, _progressRef]);

  return <svg ref={_progressRef} className='progressChart' />;
}
