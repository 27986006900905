import { memo, useCallback, useEffect, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import PanelRoutes from "../../routes/PanelRoutes";
import SideBar from "./SideBar";
import PanelHeader from "./panel-header/PanelHeader";
import Cookie from "js-cookie";
import { useAppDispatch, useAppSelector } from "../../store";
import { userProfileUtilsAction } from "../../features/authentication/slices/userProfileUtilsAction";



function UserPanel(props: RouteComponentProps) {
  const [displayPriceList, setDisplayPriceList] = useState(false); 
  return (
    <>
      <SideBar {...props} />
      <div className="userPanelWrapper">
        <PanelHeader
          priceListActive={displayPriceList}
          onDisplayPriceList={() =>
            setDisplayPriceList((prevState) => !prevState)
          }
          {...props}
        />

        <div className="mainWrapper">
          <PanelRoutes {...props} />
        </div>
      </div>
    </>
  );
}
export default memo(UserPanel);
