import CreatableSelect from "react-select/creatable";
import { Field, useField } from "formik";
import { useEffect, useState } from "react";
import { ActionMeta } from "react-select";
import * as Yup from "yup";
import { IOption, IWalletDropDownProps } from "../../../features/interfaces";
import { walletValidationSchema } from "../../../features/utils";
import { useAppSelector } from "../../../store";
import { ECryptoDefaultNetwork } from "../../../enums/ECryptoDefaultNetwork";
import walletValidator from "multicoin-address-validator";
import { validate } from 'multicoin-address-validator'

export default function WalletDropDown(props: IWalletDropDownProps) {
  const { cryptoPairs, wallets } = useAppSelector(
    (state) => state.withdraw.getWithdrawData
  );

  const cryptoNetwork = cryptoPairs.find(
    ({ id }) => id === props.cryptoPairId
  )?.networkName;
  const [field, meta, helpers] = useField(props.name);

  // const [dropDownValue, setDropDownValue] = useState({ label: "", value: "" });
  const [dropDownError, setDropDownError] = useState("");
  const [dropDownValue, setDropDownValue] = useState("");

  useEffect(() => {
    // helpers.setValue(dropDownValue.value);
    helpers.setValue(dropDownValue)
  }, [dropDownValue, meta.value.deposit_type]);

  const Wallet = wallets.filter(
    ({ cryptoPairId }) => cryptoPairId === props.cryptoPairId
  );
  // get options from store (right now we use mockdata)
  const optionsCreator = Wallet?.map((wallet: any) => ({
    label: wallet.address,
    value: wallet.address,
  }));

  const handleCreate = (inputValue: string): void => {
    const YupObj = Yup.object().shape({
      walletAddress: walletValidationSchema(
        (() => {
          return cryptoNetwork ? ECryptoDefaultNetwork[cryptoNetwork] : "";
        })()
      ),
    });
    const walletAddressObj = { walletAddress: inputValue };

    YupObj.validate(walletAddressObj)

      .then(function (value) {
        // setDropDownValue({ label: inputValue, value: inputValue });
        setDropDownValue(inputValue);
        setDropDownError("");
        helpers.setValue(inputValue);
      })
      .catch(function (err) {
        setDropDownError(err.message);
      });
  };

  // const handleChange = (newValue: any) => {
  //   let value = newValue === null ? { label: "", value: "" } : newValue;
  //   setDropDownValue(newValue);
  //   setDropDownError("");
  // };

  const handleChange = (e: any) => {
    setDropDownValue(e.target.value);
    setDropDownError("");

    const YupObj = Yup.object().shape({
      walletAddress: walletValidationSchema(
        (() => {
          return cryptoNetwork ? ECryptoDefaultNetwork[cryptoNetwork] : "";
        })()
      ),
    });
    
    const walletAddressObj = { walletAddress: e.target.value };
    YupObj.validate(walletAddressObj)
      .then(function (value) {
        // setDropDownValue({ label: inputValue, value: inputValue });
        setDropDownValue(e.target.value);
        setDropDownError("");
        helpers.setValue(e.target.value);
      })
      .catch(function (err) {
        setDropDownError(err.message);
      });
    
  };
  const styles = {
    control: (style: any, { isFocused }: any) => {
      return {
        ...style,
        backgroundColor: isFocused ? "white" : "#f9fafb",
        borderColor: "#e5e7eb",
      };
    },
  };
  return (
    <>
      {/* <CreatableSelect
        {...field}
        isClearable={true}
        onChange={handleChange}
        onCreateOption={handleCreate}
        options={optionsCreator}
        value={dropDownValue}
        styles={styles}
        placeholder={<div>"Wallet Address"</div>}
      /> */}
      <Field
        onChange={handleChange}
        value={dropDownValue}
        placeholder="Wallet Address"
      />
      <div>
        <p className="text-red-600">{dropDownError}</p>
      </div>
    </>
  );
}
