import { memo, useCallback, useEffect } from "react";

import { earningReportAction } from "../../../../features/payments/slices/earningReportAction";
import { usePagination } from "../../../../features/usePagination";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { Loading } from "../../../common-components";
import Pagination from "../../../common-components/Pagination";
import EarningCard from "./EarningCard";

function EarningsReport({ width }: { width: number }) {
  const dispatch = useAppDispatch();

  const { status, dataFetched, earningReport } = useAppSelector(
    (state) => state.payments
  );

  const fetchInvests = useCallback(async () => {
    await dispatch(earningReportAction());
  }, [dispatch]);

  useEffect(() => {
    if (!dataFetched) fetchInvests();
  }, [fetchInvests, dataFetched]);
  const { limitedItems, pageIndex, totalPages, setPageSize, setPageIndex } =
    usePagination(earningReport);


    // console.log("earningReport" , earningReport)

  return status === "pending" && !dataFetched ? (
    <Loading />
  ) : (
    <>
      {limitedItems.map((earningReport, index) => (
        <EarningCard
          key={`${earningReport.dueDate}_${index}`}
          earningReport={earningReport}
          width={width}
        />
      ))}

      <Pagination
        setPageIndex={setPageIndex}
        setPageSize={setPageSize}
        pageIndex={pageIndex}
        totalPages={totalPages}
      />
    </>
  );
}

export default memo(EarningsReport);
