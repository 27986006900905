import { useCallback, useEffect } from "react";

import { getTransactionAction } from "../../../../features/transactions/slices/get-transaction-Action";
import { usePagination } from "../../../../features/usePagination";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { Loading } from "../../../common-components";
import Pagination from "../../../common-components/Pagination";
import TransactionCard from "./TransactionCard";
import { ITransactionReports } from "../../../../features/interfaces";

interface ITransactionsReportProps {
  width: number;
  transactions: ITransactionReports[];
  limitedItems: ITransactionReports[];
}

export default function TransactionsReport(props: ITransactionsReportProps) {
  const { width } = props;

  const dispatch = useAppDispatch();

  const { error, status, transactions, dataFetched } = useAppSelector(
    (state) => state.transactions
  );
  

  // const { pageIndex, totalPages, setPageSize, setPageIndex } =
  //   usePagination(props.transactions);
  

  return status === "pending" ? (
    <div className="w-full h-full bg-white relative rounded-lg">
      <Loading />
    </div>
  ) : (
    <>
      {

        props.limitedItems.length > 0 ?
          props.limitedItems.map((transaction: any) => (
            <TransactionCard
              key={transaction.coinType}
              transaction={transaction}
              width={width}
            />

          )) : null

      }


      {/* {props.limitedItems.length > 0 ? (
        <Pagination
          setPageIndex={setPageIndex}
          setPageSize={setPageSize}
          pageIndex={pageIndex}
          totalPages={totalPages}
        />
      ) : null} */}

    </>
  );
}
