import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { IValidationErrors } from "../../interfaces";

export const Active2FAAction = createAsyncThunk<
  {} | undefined,
  { twoFactorToken: string },
  {
    rejectValue: IValidationErrors;
  }
>("user/active2FA", async (code2fa, thunkAPI) => {
  try {
    const response = axios.post(
      `${process.env.REACT_APP_API_ROOT_NEW}/auth/2fa`,
      code2fa
    );
    return response;
  } catch (error: any) {
    return thunkAPI.rejectWithValue({
      message: error.interceptedMessage,
      code: error.response.status,
      name: "user/active2FA",
    });
  }
});
