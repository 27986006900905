import React, { useEffect, useRef } from 'react';

export function NotFoundPage() {
  const _canvas = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const canvas = _canvas.current!;

    let context = canvas.getContext('2d')!;
    let width = 1920;
    let height = 1080;
    let particles: any = [];

    let Particle = {
      x: 0,
      y: 0,
      dx: 0,
      dy: 0,
    };

    const reset = function (particle: any) {
      particle.y = Math.random() * height;
      particle.x = Math.random() * width;
      particle.dx = Math.random() * 1 - 0.5;
      particle.dy = Math.random() * 0.5 + 0.5;
    };

    function createParticles(count: number) {
      if (count !== particles.length) {
        particles = [];
        for (let i = 0; i < count; i++) {
          // @ts-ignore
          particles.push(Particle);
        }
      }
    }

    function onResize() {
      width = window.innerWidth;
      height = window.innerHeight;
      canvas.width = width;
      canvas.height = height;

      createParticles((width * height) / 10000);
    }

    function updateParticles() {
      context.clearRect(0, 0, width, height);
      context.fillStyle = '#f6f9fa';

      particles.forEach(function (particle: any) {
        particle.y += particle.dy;
        particle.x += particle.dx;

        if (particle.y > height) {
          particle.y = 0;
        }

        if (particle.x > width) {
          reset(particle);
          particle.y = 0;
        }

        context.beginPath();
        context.arc(particle.x, particle.y, 5, 0, Math.PI * 2, false);
        context.fill();
      });

      // window.requestAnimationFrame(updateParticles);
    }

    onResize();
    updateParticles();

    window.addEventListener('resize', onResize);

    return () => canvas.remove();
  }, [_canvas]);

  return (
    <div className='content'>
      <canvas ref={_canvas} />
      <div className='main-text'>
        <h1>
          Aw jeez.
          <br />
          That page has gone missing.
        </h1>
      </div>
      <div className='ground'>
        <div className='mound'>
          <div className='mound_text'>404</div>
          <div className='mound_spade'></div>
        </div>
      </div>
    </div>
  );
}
