import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { IValidationErrors } from "../../interfaces";
import { IUserProfileData } from "../../interfaces/userProfile/IUserProfile";

export const userProfileAction = createAsyncThunk<
  {
    userProfileData: IUserProfileData;
  },
  undefined,
  {
    rejectValue: IValidationErrors;
  }
>("user/user-profile", async (_, thunkAPI) => {
  try {
    const response = await axios
      .get(`${process.env.REACT_APP_API_ROOT_NEW}/user/profile`)
      .then((res) => ({
        userProfileData: res.data.data,
      }));
    return response;
  } catch (error: any) {
    return thunkAPI.rejectWithValue({
      message: error.response.data.message,
      code: error.response.status,
      name: "user/user-profile",
    });
  }
});
