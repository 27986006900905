import classNames from "classnames";
import { useEffect, useState } from "react";
import { Transition } from "react-transition-group";
import socket from "socket.io-client";

import { acceptableCoins } from "../../../features/utils";
import { CoinPriceType } from "../../../types";
import { SlickSlider } from "../SlickSlider";
import { PriceWatcherCard } from "./Card";

export function CryptoPriceWatcher(props: { visible: boolean }) {
  const duration = 400;
  const [cryptoList, setCryptoList] = useState<CoinPriceType[]>([]);

  const priceSocket = socket("https://posterity.biz", {
    transports: ["websocket"],
    path: "/socket",
    rejectUnauthorized: false,
  });

  useEffect(() => {
    priceSocket.on("error", (error) => console.error(error.message));
    priceSocket.on("price", (data) => {
      let coins: CoinPriceType[] = data.filter(
        (d: CoinPriceType) => acceptableCoins.indexOf(d.originalSymbol) !== -1
      );
      let coinsWithHistory = coins.map((c) => ({
        ...c,
        history: [{ timeStamp: c.lastUpdate, value: c.price }],
      }));

      if (cryptoList.length === 0) {
        setCryptoList(coinsWithHistory);
      } else {
        let updatedCoinsPrice = coinsWithHistory.map((cwh) => {
          if (cwh.history.length > 20) {
            if (cwh.price === cwh.history[cwh.history.length - 1].value) {
              return cwh;
            } else {
              return {
                ...cwh,
                history: [
                  ...cwh.history,
                  { value: cwh.price, timeStamp: cwh.lastUpdate },
                ],
              };
            }
          } else if (cwh.history.length === 20) {
            cwh.history.shift();
            cwh.history.push({ value: cwh.price, timeStamp: cwh.lastUpdate });

            return cwh;
          }

          return cwh;
        });

        setCryptoList(updatedCoinsPrice);
      }
    });

    return () => {
      priceSocket.close();
    };
  }, [cryptoList, priceSocket]);

  const defaultStyle = {
    transition: `opacity ${duration}ms ease-in-out, bottom ${duration}ms ease-in-out`,
    opacity: 0,
    position: "relative",
    bottom: "-15px",
  };

  const transitionStyles = {
    entering: { opacity: 1, bottom: "0px" },
    entered: { opacity: 1, bottom: "0px" },
    exiting: { opacity: 0 },
    exited: { opacity: 0 },
  };

  return (
    <Transition in={props.visible} timeout={duration} unmountOnExit={true}>
      {(state) => (
        <div
          style={{
            ...defaultStyle,
            // @ts-ignore
            ...transitionStyles[state],
          }}
        >
          <div
            className={classNames("cryptoPriceContainer", {
              cryptoPriceContainer_hide: !props.visible,
            })}
          >
            <SlickSlider
              settings={{
                className: "slider variable-width",
                dots: false,
                infinite: true,
                speed: 500,
                initialSlide: 0,
                slidesToShow: 4.3,
                slidesToScroll: 1,
                arrows: false,
                centerMode: true,
                autoplay: true,
                autoplaySpeed: 3000,
                pauseOnHover: true,
                responsive: [
                  {
                    breakpoint: 1600,
                    settings: {
                      initialSlide: 1,
                      slidesToShow: 4,
                      slidesToScroll: 1,
                    },
                  },
                  {
                    breakpoint: 1280,
                    settings: {
                      initialSlide: 1,
                      slidesToShow: 3,
                      slidesToScroll: 1,
                    },
                  },
                  {
                    breakpoint: 980,
                    settings: {
                      initialSlide: 1,
                      slidesToShow: 3,
                      slidesToScroll: 1,
                    },
                  },
                  {
                    breakpoint: 880,
                    settings: {
                      initialSlide: 1,
                      slidesToShow: 2.1,
                      slidesToScroll: 1,
                      centerMode: true,
                    },
                  },
                  {
                    breakpoint: 700,
                    settings: {
                      initialSlide: 1,
                      slidesToShow: 1,
                      slidesToScroll: 1,
                      centerMode: true,
                    },
                  },
                  {
                    breakpoint: 600,
                    settings: {
                      variableWidth: true,
                      initialSlide: 1,
                      slidesToShow: 1,
                      slidesToScroll: 1,
                      centerMode: true,
                    },
                  },
                  {
                    breakpoint: 500,
                    settings: {
                      variableWidth: true,
                      initialSlide: 0,
                      slidesToShow: 1,
                      slidesToScroll: 1,
                    },
                  },
                ],
              }}
            >
              {cryptoList.map((crypto, index) => (
                <PriceWatcherCard
                  key={`${crypto.name}__${index}`}
                  icon={`data:${crypto.logo.mimeType};base64, ${crypto.logo.imageData}`}
                  name={crypto.name}
                  symbol={crypto.slug}
                  price={crypto.price}
                  changes={crypto.change}
                />
              ))}
            </SlickSlider>
          </div>
        </div>
      )}
    </Transition>
  );
}
