import React, { FunctionComponent, useEffect, useRef } from "react";
import classNames from "classnames";

interface ISideNavProps {
  isOpen: boolean;
  onSetOpen(): void;
}

export const SideNav: FunctionComponent<ISideNavProps> = (props) => {
  return (
    <>
      <div
        className={classNames("burgerButton", { burgerOpen: props.isOpen })}
        onClick={(event: React.MouseEvent<HTMLDivElement>) => {
          event.preventDefault();
          props.onSetOpen();
        }}
      >
        <span></span>
        <span></span>
        <span></span>
      </div>
      <span
        onClick={() => props.onSetOpen()}
        className={classNames({ blur: props.isOpen })}
      ></span>
      <nav className={classNames("sideBar", { sideBar__open: props.isOpen })}>
        {props.children}
      </nav>
    </>
  );
};
