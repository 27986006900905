import React from "react";

function Setting() {
  return (
    <div>
      <div className="guide-title">Setting</div>
      <div className="dashboard-sections">
        <div className="dashboard-sections-header">FEATURES</div>
        <div>
          This page allows you to access and modify your account settings. You
          can update your nickname, password, email address and two-factor
          authentication (2FA) preferences. Please follow the instructions on
          the screen to make any changes to your account.
        </div>
      </div>

      <div className="dashboard-sections">
        <div className="dashboard-sections-header">SECURITY</div>
        <div>
          To ensure the security of your account, please follow these steps:
        </div>
        <div>
          When you created your account, we sent you a temporary password to
          your email address. This password is not secure and should be replaced
          as soon as possible. To change your password, go to your account
          settings and click on the "Change Password" option. You will need to
          enter your current password and then create a new one. Your new
          password should be at least eight characters long and include a mix of
          letters, numbers, and symbols. Once you have entered your new
          password, confirm it and save the changes. You will receive a
          confirmation email that your password has been changed successfully.
        </div>
      </div>

      <div className="dashboard-sections">
        <div className="dashboard-sections-header">
          TWO FACTOR AUTHENTICATION (2FA)
        </div>
        <p className="deposit-paragraph">
          One of the most important steps to protect your account from
          unauthorized access is to enable two-factor authentication (2FA). This
          is a security feature that requires you to enter a code from your
          phone or another device when you try to change your email address or
          password, or when you want to withdraw money from your account.
          Without 2FA, you cannot complete these actions. 2FA helps prevent
          hackers from accessing your account even if they know your password.
        </p>
        <p className="deposit-paragraph">
          It is essential that you keep a backup of your 2FA private key. This
          key is a unique code that you can scan with your mobile device to set
          up 2FA. If you want to make a backup of your 2FA private key in the
          Google Authenticator App on your phone or mobile device, you can
          follow these steps:
        </p>
        <p className="deposit-paragraph">
          First, open the menu panel and tap on the “Transfer account” option.
          Then, tap continue and pass the validation check point. Next, select
          the Posterity token by placing a check-mark beside it. Finally, tap
          export and you will get a file containing your private key. You should
          store this file in a different location than your current phone or
          mobile device. This way, you can still access your private key from
          the file if you lose your device.
        </p>
        <p className="deposit-paragraph">
          ⚠️ If you lose access to your mobile device and you don't have a
          backup of your 2FA private key, you will not be able to withdraw your
          funds from Posterity anymore. Posterity support center <p id="bold">cannot help you</p> 
          regarding this issue as it’s your sole responsibility to keep your 2FA
          safe and have a backup plan. ⚠️
        </p>
      </div>
    </div>
  );
}

export default Setting;
