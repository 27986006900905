import { createSlice } from "@reduxjs/toolkit";

import { IStatus, IValidationErrors } from "../interfaces";
import { IDashboardData } from "../interfaces/dashboard-Portfolio";
import { getDashboardData } from "./slices/getDashboardData";

const initialData: {
  dashboard: {
    status: IStatus;
    data: IDashboardData;
    error?: IValidationErrors;
    dataFetched: boolean;
  };
} = {
  dashboard: {
    status: "idle",
    error: {},
    dataFetched: false,
    data: {
      investmentChart: {
        paidTillToday: 0,
        thisMonthTotalProfitToGet: 0,
      },
      totalInfo: {
        walletBalance: 0,
        pendingEarnings: 0,
        totalInvestments: 0,
        portfolioEarnings: 0,
        affiliateEarnings: 0,
        pendingPortfolioEarnings: 0,
        burnedAffiliate : 0
      },
      profile: {
        spotWalletAddress: "",
        referralCode: "",
        activationDate: "",
        mlmActivationDate: "",
        matchingActivationDate: "",
      },
      recentActivities: [],
      pieChart: {
        investEarning: 0,
        affiliateEarning: 0,
      },
      stackedChart: [],
      lineChart: [],
    },
  },
};

export const { reducer, actions } = createSlice({
  name: "dashboardPortfolio",
  initialState: initialData,
  reducers: {
    setMinMaxInvest(state) {},
  },

  extraReducers: (builder) => {
    builder
      .addCase(getDashboardData.fulfilled, (state, { payload }) => {
        state.dashboard.status = "idle";

        if (payload) {
          state.dashboard.data = payload;
        }
        state.dashboard.dataFetched = true;
      })
      .addCase(getDashboardData.pending, (state) => {
        state.dashboard.status = "pending";
      })
      .addCase(getDashboardData.rejected, (state, action) => {
        state.dashboard.status = "rejected";
        if (action.payload) state.dashboard.error = action.payload;
        state.dashboard.dataFetched = false;
      });
  },
});

export default reducer;
