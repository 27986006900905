import { useCallback, useEffect } from "react";
import useDimensions from "react-cool-dimensions";
import { fetchMultiLevelDataAction } from "../../../features/multi-level-plan";
import { useAppDispatch, useAppSelector } from "../../../store";
import { Loading } from "../../common-components";
import ResponsiveSunburst from "../../common-components/charts/ResponsiveSunburst";
import Earnings from "./Earnings";
import OverviewCardsList from "./OverViewCardsList";
import Summary from "./Summary";
import { userProfileUtilsAction } from "../../../features/authentication/slices/userProfileUtilsAction";


export default function AffiliateMultiLevelPlan() {
  const { dataFetched, data, status } = useAppSelector(
    (state) => state.multiLevelData
  );
  const dispatch = useAppDispatch();
  const referralData = useAppSelector((state) => state.multiLevelData.data);
  const fetchData = useCallback(async () => {
    await dispatch(fetchMultiLevelDataAction());
  }, [dispatch]);

  useEffect(() => {
    if (!dataFetched) fetchData();
  }, [dataFetched, fetchData]);

  const { observe, width } = useDimensions<HTMLDivElement>({
    onResize: ({ observe, unobserve, width, height, entry }) => {
      unobserve();
      observe();
    },
  });

  useEffect(() =>{
    dispatch(userProfileUtilsAction());
  }, [])

  return (
    <div className="amlWrapper" ref={observe}>
      {!data && status === "pending" ? (
        <Loading />
      ) : (
        data && (
          <>
            {data.mlmChart[0] && (
              <ResponsiveSunburst width={width} data={data.mlmChart} />
            )}
            <OverviewCardsList />
            <div className="amlWrapper__rowWrapper">
              <Summary referralData={data?.mlmSummary} />
              <Earnings earnings={data?.mlmEarning} />
            </div>
          </>
        )
      )}
    </div>
  );
}
