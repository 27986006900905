import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Cookie from "js-cookie";

import { IPaymentModel, IValidationErrors } from "../../interfaces";

// Get Invest Fee By Asset Name
export const fetchPaymentsAction = createAsyncThunk<
  IPaymentModel[],
  undefined,
  {
    rejectValue: IValidationErrors;
  }
>("user/fetch-user-payments", async (_, thunkAPI) => {
  try {
    let response = await axios.post(
      `${process.env.REACT_APP_API_ROOT}/user-payments`,
      {
        token: Cookie.get("token"),
      }
    );

    return response.data.userPayments;
  } catch (error: any) {
    thunkAPI.rejectWithValue({
      message: error.response.data.message,
      code: error.response.status,
      name: "user/fetch-user-payments",
    });
  }
});
