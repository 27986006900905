import classNames from "classnames";
import useDimensions from "react-cool-dimensions";
import { NavLink, RouteComponentProps, Switch } from "react-router-dom";
import { PrivateRoute } from "../../common-components";
import EarningReport from "./earnings";
import TransactionReport from "./transactions";
import WithdrawalReport from "./withdrawals";
import { useCallback, useEffect , useState } from "react";
import { getTransactionAction } from "../../../features/transactions/slices/get-transaction-Action";
import { useAppDispatch, useAppSelector } from "../../../store";
import { usePagination } from "../../../features/usePagination";
import Pagination from "../../common-components/Pagination";
import { userProfileUtilsAction } from "../../../features/authentication/slices/userProfileUtilsAction";

export default function Reports(props: RouteComponentProps) {
  // const [render , setRender] = useState(false)
  const { observe, width } = useDimensions<HTMLDivElement>({
    onResize: ({ observe, unobserve, width, height, entry }) => {
      unobserve();
      observe();
    },
  });
  const dispatch = useAppDispatch();
  const fetchInvests = useCallback(async () => {
    await dispatch(getTransactionAction());
  }, [dispatch]);

  useEffect(() => {
    fetchInvests();
  }, [fetchInvests]);

  const { error, status, transactions, dataFetched } = useAppSelector(
    (state) => state.transactions
  );
  const { limitedItems, pageIndex, totalPages, setPageSize, setPageIndex } =
    usePagination(transactions);


    useEffect(() =>{
      dispatch(userProfileUtilsAction());
    }, [])

  return (
    <div className="reportsWrapper" ref={observe}>
      <div className="tabComponent">
        <div className="tabComponent__tabs">
          <NavLink
            aria-label="Transactions"
            exact={true}
            className={classNames({ disableTab: false })}
            activeClassName="activeTab"
            to={`${props.match.url}`}
          >
            <span>Transactions</span>
          </NavLink>

          <NavLink
            aria-label="Earnings"
            exact={true}
            className={classNames({ disableTab: false })}
            activeClassName="activeTab"
            to={`${props.match.url}/earnings`}
          >
            <span>Earnings</span>
          </NavLink>

          <NavLink
            aria-label="Withdrawals"
            exact={true}
            className={classNames({ disableTab: false })}
            activeClassName="activeTab"
            to={`${props.match.url}/withdraw`}
          >
            <span>Withdrawals</span>
          </NavLink>
        </div>
        <div className="tabComponent__tabPanels">
          <Switch>
            <PrivateRoute
              exact
              render={(args) => {
                return (
                  <>
                    <TransactionReport limitedItems={limitedItems} transactions={transactions} width={width} {...args} />
                    {transactions?.length > 0 ? <Pagination
                      setPageIndex={setPageIndex}
                      setPageSize={setPageSize}
                      pageIndex={pageIndex}
                      totalPages={totalPages}
                    /> : null
                  }
                   
                  </>
                )
              }}
              path={`${props.match.url}`}
            />

            <PrivateRoute
              exact
              render={(args) => {
                return <WithdrawalReport width={width} {...args} />;
              }}
              path={`${props.match.url}/withdraw`}
            />

            <PrivateRoute
              exact
              render={(args) => {
                return <EarningReport width={width} {...args} />;
              }}
              path={`${props.match.url}/earnings`}
            />
          </Switch>
        </div>
      </div>
    </div>
  );
}
