import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { IValidationErrors } from "../../interfaces";
export const signoutAction = createAsyncThunk<
  | {
      statusCode: number;
    }
  | undefined,
  undefined,
  {
    rejectValue: IValidationErrors;
  }
>("user/signout", async (_, { rejectWithValue }) => {
  try {
    return await axios
      .get(`${process.env.REACT_APP_API_ROOT_NEW}/auth/sign-out`)
      .then((response) => {
        return {
          statusCode: response.data["statusCode"],
        };
      })
      .catch((error) => {
        return rejectWithValue({
          message: error.message,
          code: error.response.status,
          name: "user/signout",
        });
      });
  } catch (error: any) {
    return rejectWithValue({
      message: error.message,
      code: error.response.status,
      name: "user/signout",
    });
  }
});
