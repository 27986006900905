import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import Cookie from 'js-cookie';

import { IValidationErrors, IWithdrawReqDataModel } from '../../interfaces';

export const submitWithdrawRequestAction = createAsyncThunk<
any,
IWithdrawReqDataModel,
  {
    rejectValue: IValidationErrors;
  }
>('withdraw/submitRequest', async (withdrawReqData, thunkAPI) => {

  try {
    let token = Cookie.get('token');
    if (token?.length) {
    const response= await axios.post(`${process.env.REACT_APP_API_ROOT}/Withdraw-request`, {
      ...withdrawReqData,
      token: token,
        
      });
      return response.data;
    }

  } catch (error: any) {
    return thunkAPI.rejectWithValue({
      message: error.response.data.message,
      code: error.response.data.status,
      name: 'sendWithdrawRequest',
    });
  }
});
