import { IMatchingTree } from "../../../features/interfaces/matching-bonus";
import { IReferral } from "../../../features/interfaces/user";

export const data: IReferral[] = [
  {
    referredBy: "string",
    userWalletAddress: "string",
    nickname: "string",
    referralCode: "string",
    referredDate: "string",
    referredTimestamp: 10,
    totalInvestments: 400,
    activated: true,
    activationData: "string",
    activationTimestamp: 8,
  },
  {
    referredBy: "string",
    userWalletAddress: "string",
    nickname: "string",
    referralCode: "string",
    referredDate: "string",
    referredTimestamp: 70,
    totalInvestments: 480,
    activated: true,
    activationData: "string",
    activationTimestamp: 3,
  },
  {
    referredBy: "string",
    userWalletAddress: "string",
    nickname: "string",
    referralCode: "string",
    referredDate: "string",
    referredTimestamp: 10,
    totalInvestments: 430,
    activated: true,
    activationData: "string",
    activationTimestamp: 8,
  },
  {
    referredBy: "string",
    userWalletAddress: "string",
    nickname: "string",
    referralCode: "string",
    referredDate: "string",
    referredTimestamp: 10,
    totalInvestments: 410,
    activated: true,
    activationData: "string",
    activationTimestamp: 8,
  },
  {
    referredBy: "string",
    userWalletAddress: "string",
    nickname: "string",
    referralCode: "string",
    referredDate: "string",
    referredTimestamp: 10,
    totalInvestments: 500,
    activated: true,
    activationData: "string",
    activationTimestamp: 8,
  },
  {
    referredBy: "string",
    userWalletAddress: "string",
    nickname: "string",
    referralCode: "string",
    referredDate: "string",
    referredTimestamp: 10,
    totalInvestments: 900,
    activated: true,
    activationData: "string",
    activationTimestamp: 8,
  },
];

export const matchingTree: IMatchingTree = {
  team_a: [
    {
      referredBy: "string",
      userWalletAddress: "string",
      nickname: "string",
      referralCode: "string",
      referredDate: "string",
      referredTimestamp: 10,
      totalInvestments: 400,
      activated: true,
      activationData: "string",
      activationTimestamp: 8,
    },
  ],
  team_b: [
    {
      referredBy: "string",
      userWalletAddress: "string",
      nickname: "string",
      referralCode: "string",
      referredDate: "string",
      referredTimestamp: 10,
      totalInvestments: 400,
      activated: true,
      activationData: "string",
      activationTimestamp: 8,
    },
  ],
};
