import { ResponsiveLine, Serie } from "@nivo/line";
import classNames from "classnames";
import { memo } from "react";
import { months } from "../../../features/utils";
import { useAppSelector } from "../../../store";

const investors: Serie[] = [
  {
    id: "Team B",
    data: [
      {
        y: 324,
        x: months[2],
      },
      {
        y: 735,
        x: months[3],
      },
      {
        y: 876,
        x: months[4],
      },
      {
        y: 596,
        x: months[5],
      },
      {
        y: 547,
        x: months[6],
      },
      {
        y: 736,
        x: months[7],
      },
      {
        y: 536,
        x: months[8],
      },
      {
        y: 472,
        x: months[9],
      },
      {
        y: 357,
        x: months[10],
      },
      {
        y: 312,
        x: months[11],
      },
      {
        y: 435,
        x: months[0],
      },
      {
        y: 317,
        x: months[1],
      },
    ],
  },
  {
    id: "Team A",
    data: [
      {
        y: 341,
        x: months[2],
      },
      {
        y: 312,
        x: months[3],
      },
      {
        y: 412,
        x: months[4],
      },
      {
        y: 412,
        x: months[5],
      },
      {
        y: 434,
        x: months[6],
      },
      {
        y: 512,
        x: months[7],
      },
      {
        y: 412,
        x: months[8],
      },
      {
        y: 512,
        x: months[9],
      },
      {
        y: 412,
        x: months[10],
      },
      {
        y: 416,
        x: months[11],
      },
      {
        y: 512,
        x: months[0],
      },
      {
        y: 341,
        x: months[1],
      },
    ],
  },
];

const Investors = () => {
  const { data } = useAppSelector((state) => state.matchingBonusData.treeChart);
  const monthData = data.investorsChart.map((e) => e.month);
  const dataB = data.investorsChart.map((e) => ({
    y: e.investorsTeamB,
    x: e.month,
  }));
  const dataA = data.investorsChart.map((e) => ({
    y: e.investorsTeamA,
    x: e.month,
  }));
  const lineChartData: Serie[] = [
    {
      id: "Team B",
      data: dataB,
    },
    {
      id: "Team A",
      data: dataA,
    },
  ];

  let BdataValue = dataB.map((e) => e.y);
  let largestB = BdataValue[0];

  for (var i = 0; i < BdataValue.length; i++) {
    if (largestB < BdataValue[i]) {
      largestB = BdataValue[i];
    }
  }
  let AdataValue = dataA.map((e) => e.y);
  let largestA = AdataValue[0];

  for (var i = 0; i < AdataValue.length; i++) {
    if (largestA < AdataValue[i]) {
      largestA = AdataValue[i];
    }
  }

  let tickValuesData = [largestA, largestB];
  let largestTickValue = tickValuesData[0];
  for (var i = 0; i < tickValuesData.length; i++) {
    if (largestTickValue < tickValuesData[i]) {
      largestTickValue = tickValuesData[i];
    }
  }
  let highestInvestor = largestTickValue;

  const disableCard = highestInvestor === 0;

  return (
    <div
      className={classNames("card  ", {
        card__disable: disableCard,
      })}
    >
      <p className="card__header">Investors</p>
      <p className="card__subHeader">of last 12 months</p>
      <div className="ampInvestors__lineChart">
        <ResponsiveLine
          data={disableCard ? investors : lineChartData}
          margin={{ top: 30, right: 20, bottom: 80, left: 60 }}
          xScale={{ type: "point" }}
          yScale={{
            type: "linear",
            min: "auto",
            max: "auto",
            stacked: false,
            reverse: false,
          }}
          // curve="natural"
          axisTop={null}
          axisRight={null}
          colors={["#A9418C", "#5B9FB2"]}
          pointSize={5}
          pointBorderWidth={2}
          pointBorderColor={{ from: "serieColor" }}
          pointLabel="y"
          pointLabelYOffset={-12}
          useMesh={true}
          enableSlices={"x"}
          axisBottom={{
            orient: "bottom",
            tickSize: 8,
            tickPadding: 10,
            tickRotation: -45,
            legendOffset: 100,
            legendPosition: "middle",
          }}
          axisLeft={{
            orient: "left",
            tickSize: 5,
            tickPadding: 0,
            tickRotation: 0,
            legendOffset: -45,
            format: (d) => `${Number(d)}`,
            legendPosition: "middle",
            tickValues: largestTickValue > 5 ? 5 : largestTickValue,
          }}
          legends={[
            {
              anchor: "bottom-left",
              direction: "row",
              translateX: -20,
              translateY: 80,
              itemsSpacing: -10,
              itemDirection: "left-to-right",
              itemWidth: 120,
              itemHeight: 30,
              symbolSize: 13,
              symbolShape: "circle",
              symbolBorderColor: "rgba(0, 0, 0, .8)",
            },
          ]}
        />
      </div>
    </div>
  );
};

export default memo(Investors);
