import { useCallback, useEffect } from "react";
import { usePagination } from "../../../../features/usePagination";
import { withdrawReportAction } from "../../../../features/withdraw/slices/getWithdrawReportAction";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { Loading } from "../../../common-components";
import Pagination from "../../../common-components/Pagination";
import WithdrawCard from "./WithdrawCard";

interface IWithdrawalReportProps {
  width: number;
}

export default function WithdrawalReport(props: IWithdrawalReportProps) {
  const dispatch = useAppDispatch();

  const { withdrawalList, status, error, dataFetched } = useAppSelector(
    (state) => state.withdraw.userWithdrawalsListData
  );
  const { withdrawReport } = useAppSelector((state) => state.withdraw);

  const fetchWithdrawalList = useCallback(async () => {
    await dispatch(withdrawReportAction());
  }, [dispatch]);

  useEffect(() => {
    // if (!dataFetched)
    fetchWithdrawalList();
  }, [fetchWithdrawalList, dataFetched]);

  const { limitedItems, pageIndex, totalPages, setPageSize, setPageIndex } =
    usePagination(withdrawReport.data);

  return withdrawReport.withdrawStatus === "pending" &&
    !withdrawReport.dataFetched ? (
    <Loading />
  ) : (
    <>
      {limitedItems.map((withdraw, index) => (
        <WithdrawCard
          key={`${withdraw.requestDate}_${index}`}
          withdraw={withdraw}
          width={props.width}
        />
      ))}
      {limitedItems.length > 0 ? (
        <Pagination
          setPageIndex={setPageIndex}
          setPageSize={setPageSize}
          pageIndex={pageIndex}
          totalPages={totalPages}
        />
      ) : null}
    </>
  );
}
