import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { RouteComponentProps } from "react-router-dom";

interface CircleTimerProps extends RouteComponentProps {
  duration: number;
}

export function CircleTimer(props: CircleTimerProps) {
  const { duration } = props;
  const [remainingTime, setRemainingTime] = useState(duration);
  const _canvas = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const canvas = _canvas.current!;
    const context = canvas.getContext("2d")!;
    let pointToFill = 4.72; //Point from where you want to fill the circle
    let canvasWidth = canvas.width; //Return canvas width
    let canvasHeight = canvas.height; //Return canvas height
    let diff; // find the different between current value (no) and trageted value (100)

    const draw = () => {
      diff = (remainingTime / duration) * Math.PI * 2 * 10;
      context.clearRect(0, 0, canvasWidth, canvasHeight); // Clear canvas every time when function is call

      var timeFormat = moment.duration(remainingTime * 1000);

      context.fillStyle = "#000";
      context.strokeStyle = "#003247";
      context.textAlign = "center";
      context.font = "14px Nunito";
      context.lineCap = "round";
      context.fillText(
        `${timeFormat.hours()}:${timeFormat.minutes()}:${timeFormat.seconds()}`,
        canvasWidth / 2 - 5,
        canvasHeight / 2
      );

      context.beginPath();
      context.lineWidth = 2;
      context.arc(
        (canvasWidth - 10) / 2,
        (canvasHeight - 10) / 2,
        (canvasWidth - 20) / 2,
        0,
        Math.PI * 2
      );
      context.stroke();

      context.beginPath();
      context.lineWidth = 5; // size of stroke
      context.arc(
        (canvasWidth - 10) / 2,
        (canvasHeight - 10) / 2,
        (canvasWidth - 20) / 2,
        pointToFill,
        diff / 10 + pointToFill
      );
      context.stroke();
    };

    const interval = setInterval(() => {
      if (remainingTime >= 0) {
        setRemainingTime((prevState) => {
          return prevState - 1;
        });
        draw();
      }
    }, 1000);

    if (remainingTime <= 0) {
      localStorage.setItem("waitingForPayment", "false");
      props.history.push("/panel/deposit");
    }

    return () => {
      clearInterval(interval);
      canvas.remove();
    };
  }, [remainingTime, duration, props.history]);

  return <canvas height="80" width="80" ref={_canvas} />;
}
