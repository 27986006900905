const Notification = () => {
  return (
    <div>
        <div className="guide-title">Notification Center</div>
        <div>
      
        Stay updated with the latest happenings! Notifications keep you in the loop about new investments, important updates of your portfolio , and upcoming events. Tap to never miss a beat!
    
        </div>
    </div>
  )
}

export default Notification