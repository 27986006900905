import classNames from "classnames";
import { Field, Form, Formik } from "formik";
import React, { MouseEvent, useEffect, useState } from "react";
import * as Yup from "yup";
import walletValidator from "multicoin-address-validator";
import { InvoiceIcon, OptionsIcon, SelectIcon } from "../../../assets/icons";
import { getWalletAction, actions as investActions } from "../../../features/investment";
import { createDepositAction } from "../../../features/investment/slices/createDepositAction";
import { getAdminWalletAction } from "../../../features/investment/slices/getAdminWalletAction";
import {
  ajaxErrorAlert,
  ajaxSuccessAlert,
  getImg,
  networkedCoins,
} from "../../../features/utils";
import { useAppDispatch, useAppSelector } from "../../../store";
import { Button, CopyToClipboard, CustomModal } from "../../common-components";
import { ECryptoDefaultNetwork } from "../../../enums/ECryptoDefaultNetwork";
import { TCoinNetworkNames } from "../../../features/interfaces/withdraw/TCoinNetWorkArr";
interface ISubmitDepositFormProps {
  isOpen: boolean;
  onClose(): void;
}

export default function SubmitDepositForm({
  isOpen,
  onClose,
}: ISubmitDepositFormProps) {
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedWallet, setSelectedWallet] = useState("");

  const dispatch = useAppDispatch();
  const { status } = useAppSelector(
    (state) => state.investment.lastTransaction
  );
  const { error } = useAppSelector((state) => state.investment.adminWalletInfo);
  const { coinsInfo } = useAppSelector((state) => state.investment);
  const activeCoins = coinsInfo.data.filter((coin) => coin.isDepositAvailable);

  const { adminWalletInfo } = useAppSelector((state) => state.investment);
  const adminWallet = adminWalletInfo.data.address;

  useEffect(() => {
    if (error?.message) {
      ajaxErrorAlert(error.message).then((res) => {
        if (res.value) {
          dispatch(investActions.clearErrors());
          dispatch(investActions.clearTransaction());
          setCurrentStep(1);
          onClose();
        }
      });
    }
  }, [error, onClose, dispatch]);


  // const {recentDeposits} = useAppSelector((state => state.investment.getwallet.getWalletData))
  // console.log("recentDeposits" , recentDeposits)


  return (
    <Formik
      initialValues={{
        assetId: 0,
        assetNetwork: "",
        userWalletAddress: "",
        adminWalletAddress: "",
      }}
      validateOnMount={false}
      validateOnBlur={false}
      validationSchema={Yup.object().shape({
        assetNetwork: Yup.string().required(),
        userWalletAddress: Yup.string()
          .required()
          .test(
            "user_wallet_address",
            "Please enter a valid wallet address to proceed.",
            function (value) {
              if (value)
                return walletValidator.validate(
                  value,
                  ECryptoDefaultNetwork[
                    this.parent.assetNetwork.toUpperCase() as TCoinNetworkNames
                  ]
                );

              return false;
            }
          ),

        adminWalletAddress: Yup.string(),
        assetId: Yup.number(),
      })}
      onSubmit={async (values, actions) => {
        actions.setSubmitting(true);

        onClose();
        dispatch(
          createDepositAction({
            destinationAddress: adminWallet,
            sourceAddress: values.userWalletAddress,
            cryptoPairId: values.assetId,
          })
        )
          .then((res) => {
            if(res.meta.requestStatus === "fulfilled"){
              ajaxSuccessAlert(
                "To deposit funds, transfer them from your wallet address to the Posterity wallet address. It is important that you use the exact sender/receiver wallet addresses, otherwise you may lose your funds. Ensure that the transaction fee is set to standard or high, and avoid using the low fee option as it may result in losing your funds. Once your transaction is confirmed, enter the transaction ID in the next step. If you fail to submit the transaction within 3 hours, your transaction window will be closed. If you try to submit it again after this period, your funds may be lost forever because each time you try to make a deposit, the Posterity wallet address changes automatically. Please do not submit any support tickets regarding deposit issues as the process is completely autonomous and if you make a mistake in the process, there is nothing we can do to correct it."
              );
            }
             
             
          })
          .finally(() => {
            onClose();
            setCurrentStep(1);
          });
        actions.resetForm();
        actions.setSubmitting(false);
      }}
    >
      {({
        values,
        handleReset,
        handleSubmit,
        errors,
        handleChange,
        handleBlur,
        setValues,
        setFieldValue,
        isSubmitting,
        resetForm,
        isValid,
      }) => (
        <CustomModal
          isOpen={isOpen}
          closeOnClickOutside={false}
          className="Modal"
          onClose={() => {
            if (isSubmitting || status === "pending") return;
            resetForm();
            handleReset();
            setCurrentStep(1);
            onClose();
          }}
        >
          <Form
            onReset={handleReset}
            onSubmit={handleSubmit}
            className="submitDepositForm"
          >
            <div className="submitDepositForm_header">
              <ul>
                <li
                  className={classNames({
                    submitDepositForm_header_activeStep: currentStep === 1,
                    submitDepositForm_header_completedStep: currentStep > 1,
                  })}
                >
                  <OptionsIcon />
                  <p>
                    Step 1 / 3
                    <br />
                    <b>Crypto-currency</b>
                  </p>
                </li>
                <li
                  className={classNames({
                    submitDepositForm_header_activeStep: currentStep === 2,
                    submitDepositForm_header_completedStep: currentStep > 2,
                  })}
                >
                  <SelectIcon />
                  <p>
                    Step 2 / 3
                    <br />
                    <b>Your source wallet address</b>
                  </p>
                </li>

                <li
                  className={classNames({
                    submitDepositForm_header_activeStep: currentStep === 3,
                  })}
                >
                  <InvoiceIcon />
                  <p>
                    Step 3 / 3
                    <br />
                    <b>Posterity Wallet Address</b>
                  </p>
                </li>
              </ul>
            </div>

            <div
              className={classNames("submitDepositForm_step", {
                submitDepositForm_step_active: currentStep === 1,
              })}
            >
              <label>Choose your desired crypto-currency:</label>
              {
                coinsInfo.data.map((coin) =>{
                  return (
                    coin.id === values.assetId && <div className="text-sm text-gray-600">{coin.description}</div>
                  )
                })
              }
              <ul className="submitDepositForm_coinsList">
                {coinsInfo.data.map((coin) => {
                  const coinData = activeCoins.find((activeCoin) => {
                    return (
                      activeCoin.networkName === coin.networkName &&
                      activeCoin.cryptoSymbol === coin.cryptoSymbol
                      
                    );
                  });
                  const coinNotActive = !coinData;
          
                  
                  return (
                
                   <div className="flex flex-col">
                    <li
                      key={coin.cryptoSymbol! + coin.networkName}
                      id={"" + coinData?.id}
                      className={classNames({
                        disableCoinCard: coinNotActive,
                        selectedCoinCard: values.assetId === coinData?.id,
                      })}
                      onClick={(event: MouseEvent<HTMLLIElement>) => {
                        event.preventDefault();

                        if (coinData) {
                          setValues(
                            {
                              ...values,
                              assetNetwork: coinData.networkName ?? "",
                              assetId: coinData.id,
                            },
                            false
                          );
                          
                        }
                      }}
                    >
                      
                     
                      <img
                        // src={coin.cryptoSymbol! + coin.networkName}
                        // src={`../../../assets/img/networks/${coin.cryptoSymbol! + coin.networkName}.png`}
                        src= {getImg(coin.cryptoSymbol!, coin.networkName!)}
                        alt={coin.cryptoSymbol! + coin.networkName}
                        crossOrigin="anonymous"
                      />
                    </li>
                   </div>
              
                  );
                })}
              </ul>
            </div>

            {/* <img src={bnb}/> */}

            <div
              className={classNames("submitDepositForm_step", {
                submitDepositForm_step_active: currentStep === 2,
              })}
            >
              <label htmlFor="userWalletAddress">
                Enter your source wallet address from which you are transferring
                funds:
              </label>

              <Field
                id="userWalletAddress"
                name="userWalletAddress"
                value={values.userWalletAddress}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="You need to enter your wallet address"
              />

              {errors.userWalletAddress && (
                <p className="formError">{errors.userWalletAddress}</p>
              )}
            </div>

            <div
              className={classNames("submitDepositForm_step", {
                submitDepositForm_step_active: currentStep === 3,
              })}
            >
              <label>
                Please transfer your crypto-currency from your entered wallet
                address to the following wallet address:
              </label>

              <div className="w-full relative">
                <Field
                  name="adminWalletAddress"
                  disabled
                  className="w-full text-black font-bold"
                />

                <div className="fixedCopyButton">
                  <CopyToClipboard valueToCopy={selectedWallet} />
                </div>
              </div>
            </div>
            <Button
              loading={isSubmitting || status === "pending"}
              disabled={
                (currentStep === 1 && values.assetNetwork.length === 0) ||
                (currentStep === 2 &&
                  (errors.userWalletAddress ||
                    values.userWalletAddress.length === 0)) ||
                status === "pending"
                  ? true
                  : false
              }
              type="submit"
              onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                event.preventDefault();

                if (currentStep === 1)
                  dispatch(getAdminWalletAction(values.assetId));
                if (currentStep === 2) {
                  setSelectedWallet(adminWallet);

                  setFieldValue("adminWalletAddress", adminWallet); // bug if user selects an active coin and gets it's admin wallet and then selects a coin without a wallet the prev coins admin wallet will be shown to the user instead of null
                }
                if (currentStep >= 3) {
                  handleSubmit();
                  return;
                }

                if (currentStep === 2) {
                  dispatch(() => createDepositAction);
                }

                setCurrentStep(() => currentStep + 1);
              }}
            >
              {currentStep === 1 || currentStep === 2 ? "Continue" : "Submit"}
            </Button>
          </Form>
        </CustomModal>
      )}
    </Formik>
  );
}
