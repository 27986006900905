import React from "react";
import numbro from "numbro";
import { useAppSelector } from "../../../store";

export default function WithdrawOverview() {
  const { constraints } = useAppSelector(
    (state) => state.withdraw.getWithdrawData
  );

  return (
    <div className="withdrawOverview">
      <div className="withdrawOverview__card">
        <p style={{ backgroundColor: "#19aacf" }}>
          {numbro(constraints.walletBalance)
            .formatCurrency({
              mantissa: 2,
              average: true,
              optionalMantissa: true,
            })
            .toUpperCase()}
        </p>
        <h3>Total Wallet Balance</h3>
      </div>
      <div className="withdrawOverview__card">
        <p style={{ backgroundColor: "#737373" }}>
          {numbro(constraints.withdrawableAmount)
            .formatCurrency({
              mantissa: 2,
              average: true,
              optionalMantissa: true,
            })
            .toUpperCase()}
        </p>
        <h3>Withdrawable Amount</h3>
      </div>
    </div>
  );
}
