import React from "react";

function Matching() {
  return (
    <div>
      <div className="guide-title">Affiliate Matching Bonus Plan</div>
      <div>
        This help is designed to assist you in activating the Affiliate Matching
        Bonus Plan, which is a feature of the Posterity platform. This plan
        allows you to earn rewards from the investments of your referrals, with
        an unlimited number of levels deep. However, before you use this page,
        you should read the Posterity whitepaper carefully. The whitepaper
        explains the details and benefits of the plan, as well as the
        requirements and conditions for participation. This help page will only
        focus on how to use the page, not how the plan works.
        <div className="dashboard-sections">
          <div>
            To activate this plan, you need to assign members to both{" "}
            <div id="teamA">team A</div> and <p id="teamB">team B</p>. This will
            allow you to earn commissions from the Matching Bonus plan, which is
            a type of employee share incentive arrangement. Here are the steps
            to follow:
          </div>

          <ul className="guide-lists" id="matching">
            <li className="list-item">
              <div>
                {" "}
                Click on the <div id="teamA">“Add Team A”</div> option and
                select one of your active downline investors from the drop-down
                list. This will assign them to your left side (Team A). You can
                only see the nicknames of your downline network, so make sure
                you confirm with them before assigning them.
              </div>
            </li>

            <li className="list-item">
              Click on the <p id="teamB">“Add Team B”</p> option and select one
              of your active downline investors from the drop-down list. This
              will assign them to your right side (Team B). Again, double-check
              with them before confirming.
            </li>

            <li className="list-item">
              Once you have assigned members to both teams, your Matching Bonus
              plan will be activated and you will be eligible to receive
              commissions from the new investments of your downline network.
              Note that any investments made by your downline members before
              activating this plan will not count towards your commissions.
            </li>
          </ul>
        </div>
        <p className="deposit-paragraph">
          Please be careful when assigning members to your teams, as you cannot
          undo this action in the future.
        </p>
        <p className="deposit-paragraph">
          The Affiliate Matching Bonus Plan is a way to earn more money by
          referring other people to join our online business platform. It uses a
          Hybrid-Binary system, which means you can have unlimited direct
          referrals on each team. You can choose which team to place your
          referrals on by clicking on the options that are described above. When
          you place a referral on a team, their entire downline network will
          also move to that team, following the same structure as in the
          Multi-Level Plan.
        </p>
        <p className="deposit-paragraph">
          You can earn up to 2,500 USD per day from this plan alone, in addition
          to your regular investment profits and Affiliate Multi-Level Plan
          commissions.
        </p>
      </div>
    </div>
  );
}

export default Matching;
