import React from "react";

function Deposit() {
  return (
    <div>
      <div className="guide-title">Deposit</div>
      <p className="deposit-paragraph">
        This tutorial will guide you step by step on how to deposit money and
        make an investment in Posterity. It is important that you follow the
        instructions carefully and do not skip any steps. This tutorial covers
        everything you need to know, so you only must learn it once and you can
        use it again in the future.
      </p>
      <p className="deposit-paragraph">
        First, send your funds to Posterity via your desired cryptocurrency.
        Then, you can make an investment with your available funds. You may use
        your earnings to make a new investment as well. Your earnings and
        deposited funds are shown together under the “Available Balance” in your
        Deposit page:
      </p>

      <p className="deposit-paragraph">
        The first step is to move your crypto assets to the platform. You can do
        this by clicking the Deposit button. You will see a page with various
        coins and tokens that are supported. Select the cryptocurrency you want
        and click Continue.
      </p>
      <p className="deposit-paragraph">
        To proceed with the Deposit process, you must provide the wallet address
        where you store your cryptocurrency assets.<b id="deposit-bold-text"> This wallet address should
        not have been used previously on our platform for any fund transfers by
        another client. Each wallet address is linked to a single account only.
        Therefore, please enter the correct wallet address with your funds and
        click Continue.
        </b>
      </p>
      <p className="deposit-paragraph">
        The next step is to send your funds to the Posterity wallet address. You
        should transfer your funds from the wallet address you have provided in
        the previous page, to this wallet address. To copy the destination
        wallet address, use the "Copy to clipboard" next to it and then press
        the “Submit” button.
      </p>
      <p className="deposit-paragraph">
        To complete the deposit, you need to send your funds from the wallet
        address you entered in step 2 to Posterity's wallet address within three
        hours. After that, you need to enter your transaction hash (TXID) in the
        corresponding tab. Your deposit will be converted to USD and credited to
        your account automatically once your transaction is confirmed on the
        blockchain. If it is not confirmed, you can retry by entering the
        correct TXID. This process is fully automated and we cannot access or
        assist you via support center with the deposit section.
      </p>
      <p className="deposit-paragraph">
        Now that you have enough funds in your account to make an investment,
        click on the “Make an investment” option. If it’s your first investment,
        then your investment amount should be greater than 20 USD to activate
        your account. Remember that your investment amount must be a multiple of
        20. After entering the desired amount, follow the procedure to make an
        investment in your Portfolio. If it’s your first investment, then your
        account is activated and you can enjoy using and exploring various
        options in your account. You can even refer your friends to your
        Affiliate Network by sending them your referral link.
      </p>
      <p className="deposit-paragraph">
        Please note that if you already have earnings (Investment or Referral)
        in your Available balance plus deposited funds, when you try to make an
        investment, the platform will first deduct all the requisite money from
        your deposited funds. If it does not cover the whole investment amount,
        then it will deduct the remaining amount from your earnings.
      </p>
    </div>
  );
}

export default Deposit;
