import { reducer } from "./authentication";

export { actions } from "./authentication";
//export { activate2FAAction } from "./slices/activate-2fa";
export { changeEmailAction } from "./slices/change-email";
export { changePasswordAction } from "./slices/change-password";
export { fetchUserDataAction } from "./slices/fetch-user-data";
export { getReferralNameAction } from "./slices/getReferralNameAction";
export { googleAuthenticatorAction } from "./slices/googleAuthenticator";
export { registerAction } from "./slices/registerAction";
export { request2FaAction } from "./slices/request-2FA";
export { requestOTPAction } from "./slices/request-otp";
export { requestPinAction } from "./slices/request-pincode";
export { signInAction } from "./slices/signin";
// export { loginAction } from "./slices/login";
export { signoutAction } from "./slices/signout";
export { userProfileAction } from "./slices/userProfile";

export default reducer;
