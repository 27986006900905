// @ts-nocheck
import { useEffect } from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import "sweetalert2/src/sweetalert2.scss";
import "./assets/scss/index.scss";
import { TokenClient } from "./features/axiosInterceptor";
import { removeCookie } from "./features/utils";
import MainRoutes from "./routes/MainRoutes";
import { store } from "./store";

// axios config //
TokenClient();

export default function App() {

  // const removeCookie = () => Cookies.remove("token");
  useEffect(() => {

    window.addEventListener("onbeforeunload", () => {
      removeCookie();
    });
    return () => window.removeEventListener("onbeforeunload", removeCookie);
  }, []);

  return (
    <Provider store={store}>
      <Router basename={process.env.PUBLIC_URL}>
        <MainRoutes />
      </Router>
    </Provider>
  );
}
