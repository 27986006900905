import React, { useRef, useEffect } from "react";

import { RightArrow2Icon } from "../../assets/icons";

interface Props {
  buttons: React.ReactNode;
}

export default function IntroducingPosterity(props: Props) {
  const _canvas = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const canvas = _canvas.current!;
    const context = canvas.getContext("2d")!;
    context.canvas.width = document.body.clientWidth;
    context.canvas.height = document.body.clientHeight;
    context.lineWidth = 6;
    context.strokeStyle = "#ff4040";

    context.beginPath();
    context.moveTo(0, context.canvas.height - context.canvas.height / 3);
    context.lineTo(
      context.canvas.width / 2,
      context.canvas.height - context.canvas.height / 3
    );

    context.stroke();
    context.closePath();
    context.beginPath();
    context.moveTo(context.canvas.width / 2, context.canvas.height / 2);
    context.lineTo(
      context.canvas.width / 2,
      context.canvas.height - context.canvas.height / 10
    );
    context.lineTo(
      context.canvas.width / 2 - context.canvas.width / 5,
      context.canvas.height - context.canvas.height / 10
    );
    context.lineTo(
      context.canvas.width / 2 - context.canvas.width / 5,
      context.canvas.height
    );
    context.stroke();
    context.closePath();
    context.beginPath();
    context.moveTo(context.canvas.width / 2, context.canvas.height / 2);
    context.lineTo(
      context.canvas.width / 2 - context.canvas.width / 10,
      context.canvas.height / 2
    );
    context.lineTo(
      context.canvas.width / 2 - context.canvas.width / 10,
      context.canvas.height / 2 - context.canvas.height / 8
    );
    context.lineTo(
      context.canvas.width,
      context.canvas.height / 2 - context.canvas.height / 8
    );
    context.stroke();
    context.closePath();

    return () => canvas.remove();
  }, [_canvas]);

  return (
    <div className="landingWrapper__wrapper lIntroducingPosterity">
      <canvas ref={_canvas} />
      <div>
        <h1>
          Posterity IS A DEFI PLATFORM BASED ON HYPERLEDGER FABRIC AND BINANCE
          SMART CHAIN
        </h1>
        <p>
          The state-of-the-art autonomous trading smart contracts with the help
          of new proprietary A.I algorithms, execute low-risk trading strategies
          based on market data and trends, ensuring steady and reasonable
          monthly returns for investors. Don't worry about volatility or market
          crashes no more, as the A.I adapts to any situation and protects your
          funds.
        </p>

        <a
          href=" https://youtu.be/pA2SZmqZeRc "
          target="_blank"
          rel="noopener noreferrer"
        >
          MORE ABOUT BSC
          <RightArrow2Icon />
        </a>
      </div>

      {props.buttons}
    </div>
  );
}
