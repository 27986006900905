import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import Cookie from 'js-cookie';

import { IValidationErrors, IWithdrawalsListDataModel } from '../../interfaces';

export const fetchWithdrawalsListAction = createAsyncThunk<
IWithdrawalsListDataModel[] | undefined,
  undefined,
  {
    rejectValue: IValidationErrors;
  }
>('withdraw/report', async (_, thunkAPI) => {
  try {
    let token = Cookie.get('token');
    if (token?.length) {

      // const response = await axios.post(`${process.env.REACT_APP_API_ROOT}/fetch-withdraw-data`, {
      const response = await axios.post(`${process.env.REACT_APP_API_ROOT}/fetch-Withdrawals-list`,{
        token: token,
      }
      );

      return response.data ;//may return some data as IWithdrawDataModel
    }
  } catch (error: any) {
    return thunkAPI.rejectWithValue({
      message: error.response.data.message,
      code: error.response.status,
      name: 'user/fetch-withdrawals-list-data',
    });
  }
});
