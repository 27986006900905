import React from "react";

function Withdraw() {
  return (
    <div>
      <div className="guide-title">Withdraw</div>
      <div className="dashboard-sections">
        <div className="dashboard-sections-header">UPPER TABS</div>
        To request a withdrawal from your wallet balance, please follow these
        regulations:
        <ul className="guide-lists" id="withdraw">
          <li className="list-item">
            You can only withdraw funds that derive from your investment
            earnings and referral commissions. We call this the Withdraw-able
            amount. If you have deposited funds but have not invested them yet,
            you cannot withdraw them. You need to invest them first.
          </li>
          <li className="list-item">
            You also need to respect the minimum and maximum limits of a
            withdrawal request. The minimum amount is 16 USD. The maximum amount
            is your portfolio value plus half of your last investment amount.
            For example, if your portfolio is worth 1000 USD and your last
            investment was 500 USD, you can withdraw up to 1,250 USD.
          </li>
          <li className="list-item">
            You can only make one withdrawal request per month. So, plan your
            finances wisely.
          </li>
        </ul>
      </div>

      <div className="dashboard-sections">
        <div className="dashboard-sections-header">Expenses & Fees</div>
        To process your withdrawal request, we have a Fee calculation system
        that applies reasonable fees to cover the expenses and blockchain fees.
        The fees for your withdrawal request are composed of two parts:
        <ul className="guide-lists" id="withdraw">
          <li className="list-item">Static Fee</li>
          <li className="list-item">
            This fee varies according to your withdrawal amount:
            <ul id="withdraw-subList">
              <li>
                For withdrawal amounts between 16 and 4,999 US dollars, the
                static fee is 1 percent of the withdrawal amount.
              </li>
              <li>
                For withdrawal amounts between 5,000 and 49,999 US dollars, the
                static fee is 2 percent of the withdrawal amount.
              </li>
              <li>
                For withdrawal amounts above 50,000 US dollars, the static fee
                is 3 percent of the withdrawal amount.
              </li>
            </ul>
          </li>

          <li className="list-item">Floating Fee</li>
          <li className="list-item">
            This fee depends on the coin or token you choose to withdraw your
            funds in.
          </li>

          <li className="list-item">
            The current rates are as follows:
            <ul id="withdraw-subList">
              <li>Ethereum: 1.3%</li>
              <li>Binance Coin: 1.2%</li>
              <li>Tether: 1%</li>
            </ul>
          </li>
        </ul>
        <div className="dashboard-sections">
          <div>
            To illustrate how our withdrawal system works, let us use an
            example:
          </div>
          <p className="deposit-paragraph">
            Imagine you want to withdraw 2000 USD from your account. According
            to our policy, you will have to pay a 20 USD static fee and a 10 USD
            floating fee, which adds up to a total fee of 30 USD for a 2000 USD
            withdrawal request.
          </p>

          <p className="deposit-paragraph">
            * Please note that the blockchain fee is a separate charge that will
            be automatically deducted from your withdrawal amount based on the
            current network and blockchain fees. It has nothing to do with
            Posterity.
          </p>
          <p className="deposit-paragraph"> 
            * The withdrawal process is carefully regulated and verified by the
            latest security checks and they will go under very strict policies.
            Therefore, it may take up to four working days to process your
            request.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Withdraw;
