import classNames from "classnames";
import { memo, useEffect, useState } from "react";
import ReactToolTip from "react-tooltip";

import {
  currencyFormat,
  numberFormatter,
  remainingTimeCalc,
  timeFormat,
} from "../../../features/utils";
import { useAppSelector } from "../../../store";
import CountDown from "../deposit/CountDown";
import moment from "moment";

const Summary = () => {
  const { data } = useAppSelector((state) => state.matchingBonusData.treeChart);

  // console.log("data.currentLifecycleStartedAt" , data.currentLifecycleStartedAt , data.currentLifecycleEndsAt)
  // const currentEndTimeStamp =
  //   new Date(data.currentLifecycleEndsAt!).getTime() / 1000;

  //  const currentStartTimeStamp = new Date(data.currentLifecycleStartedAt!).getTime() / 1000
  //  const countTimeSTamp = currentEndTimeStamp - currentStartTimeStamp
  // console.log("count matching", remainingTimeCalc(currentEndTimeStamp));
  const [matchingCount, setMatchingCount] = useState(remainingTimeCalc());
  console.log("day", Math.floor(matchingCount / 86400));
  // const daysLeft = parseInt(String(matchingCount / 86400));
  // const hoursLeft = Math.floor((matchingCount % 86400) / 3600);
  // const minutesLeft = Math.floor((matchingCount % 3600) / 60);
  const [daysLeft, setDaysLeft] = useState<number | null>(null);
  const [hoursLeft, setHoursLeft] = useState<number | null>(null);
  const [minutesLeft, setMinutesLeft] = useState<number | null>(null);

  useEffect(() => {
    const currentEndTimeStamp = moment(data.currentLifecycleEndsAt!).utc();

    const updateTimeDifference = () => {
      const now = moment().utc();
      // const duration = (currentEndTimeStamp - now) / 1000;
      var duration = moment.duration(currentEndTimeStamp.diff(now));
      console.log("currentEndTimeStamp ", currentEndTimeStamp, now);
      console.log("duration ", duration);
      // const days = duration / 86400;
      // const hours = duration % 86400 / 3600;
      // const minutes = moment.now(duration)
      setDaysLeft(Math.floor(duration.as("days")));
      setHoursLeft(duration.hours());
      setMinutesLeft(duration.minutes());
    };

    updateTimeDifference();
    const interval = setInterval(updateTimeDifference, 30000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div
      className={classNames("card", {
        // card__disable: !isActivated,
      })}
    >
      <p className="card__header">Summary</p>
      <ul className="ambpSummary">
        <li>
          <p>First Day of your First Life Cycle:</p>
          <p>
            {data.firstDayLifecycle ? timeFormat(data.firstDayLifecycle) : "-"}
          </p>
        </li>

        <li>
          <p>Current Life Cycle Started at:</p>
          <p>
            {data.firstDayLifecycle
              ? timeFormat(data.currentLifecycleStartedAt)
              : "-"}
          </p>
        </li>
        <li>
          <p>Your Current Life Cycle Ends at:</p>
          <p className="1">
            {data.firstDayLifecycle
              ? timeFormat(data.currentLifecycleEndsAt)
              : "-"}

            {/* {data.firstDayLifecycle ? (
              daysLeft! >= 0 || daysLeft === -0 ? (
                <>
                  {daysLeft} Days - {hoursLeft} Hours
                </>
              ) : (
                "The lifecycle is restarting. Please wait a few hours"
              )
            ) : (
              "-"
            )} */}
          </p>
        </li>
        <li className="flex justify-center items-center">
          <p className="flex justify-center items-center w-full">
            {daysLeft !== null && minutesLeft !== null && hoursLeft !== null ? (
              <CountDown days={daysLeft} minutes={minutesLeft} hour={hoursLeft} />
            ) : null}
          </p>
        </li>
        <li className="bg-green-600 bg-opacity-10">
          <p>Matched Bonuses(Current Month):</p>
          <p className="flex items-center">
            <b>
              {`${numberFormatter(+data.matchedBonuses)}  ${
                +data.matchedBonuses === 1 ? "Matched Bonus" : "Matched Bonuses"
              } (${numberFormatter(+data.matchedBonuses * 50)}  USD)`}
            </b>
            {/* - {isActivated ? totalMatch : 31} */}
            {/* <RefreshIcon className="mr-1 w-4 h-4 ml-1" /> */}
          </p>
        </li>
        <li className="bg-green-600 bg-opacity-10">
          <p>Total Investors In Team A (Left Investors):</p>
          <p style={{ color: "#5B9FB2" }}>
            {numberFormatter(data.investors.teamA)}
          </p>
        </li>
        <li className="bg-green-600 bg-opacity-10">
          <p>Total Investors In Team B (Right Investors):</p>
          <p style={{ color: "#A9418C" }}>
            {numberFormatter(data.investors.teamB)}
          </p>
        </li>
      </ul>

      <div className="ampTreeSummary">
        <div>
          <div>
            <p data-tip data-for="leftTree">
              Total A.M.B.I:
            </p>
            <p> {numberFormatter(data.totalAmbi.teamA)}</p>
            <ReactToolTip id="leftTree" type="dark" effect="solid">
              <p>Total Investors with active Matching Bonus Plans</p>
            </ReactToolTip>
          </div>

          <div>
            <p>Direct Referrals:</p>
            <p>{data.directReferrals.teamA}</p>
          </div>

          <div>
            <p>Unmatched Investment:</p>
            <p>{currencyFormat(data.unmatchedInvestmetsA)}</p>
          </div>
        </div>

        <div>
          <div>
            <p data-tip data-for="rightTree">
              Total A.M.B.I:
            </p>
            <p>{numberFormatter(data.totalAmbi.teamB)}</p>
            <ReactToolTip id="rightTree" type="dark" effect="solid">
              <p>Total Investors with active Matching Bonus Plans</p>
            </ReactToolTip>
          </div>
          <div>
            <p>Direct Referrals:</p>
            <p>{data.directReferrals.teamB}</p>
          </div>
          <div>
            <p>Unmatched Investment:</p>
            <p>{currencyFormat(data.unmatchedInvestmetsB)}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(Summary);
