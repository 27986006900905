import Cookie from "js-cookie";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { NoActivityLimiter } from "../features/NoActivityLimiter";

interface PrivateRouterProps extends RouteProps {}

export const PrivateRoute = (props: PrivateRouterProps) => {
  return Cookie.get("token")?.length ? (
    <NoActivityLimiter>
      <Route {...props} component={props.component} />
      
    </NoActivityLimiter>
  ) : (
    <Redirect to="/auth" />
  );
};
