import React, { useRef, useEffect } from "react";
import { Link } from "react-router-dom";

import { RightArrow2Icon } from "../../assets/icons";
import Photo from "../../assets/img/howPosterityWorks.jpg";
import { downloadWhitPaperModal } from "../../features/utils";

interface Props {
  buttons: React.ReactNode;
}

export default function HowPosterityWorks(props: Props) {
  const _canvas = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const canvas = _canvas.current!;
    const context = canvas.getContext("2d")!;
    context.canvas.width = document.body.clientWidth;
    context.canvas.height = document.body.clientHeight;
    context.lineWidth = 6;
    context.strokeStyle = "#ff4040";

    context.beginPath();
    context.moveTo(0, context.canvas.height / 2 - context.canvas.height / 8);
    context.lineTo(
      context.canvas.width / 10,
      context.canvas.height / 2 - context.canvas.height / 8
    );
    context.lineTo(context.canvas.width / 10, context.canvas.height / 2);
    context.lineTo(context.canvas.width / 2, context.canvas.height / 2);
    context.stroke();
    context.closePath();

    context.beginPath();
    context.moveTo(context.canvas.width / 2, context.canvas.height / 2);
    context.lineTo(
      context.canvas.width / 2 + context.canvas.width / 6,
      context.canvas.height / 2
    );
    context.lineTo(
      context.canvas.width / 2 + context.canvas.width / 6,
      context.canvas.height
    );
    context.stroke();
    context.closePath();

    return () => canvas.remove();
  }, [_canvas]);

  return (
    <div className="landingWrapper__wrapper lHowPosterityWorks">
      <canvas ref={_canvas} />
      <div>
        <img src={Photo} alt="how posterity works" />
        <div>
          <h1>How Posterity Works</h1>
          <p>
            Posterity blockchain platform is developed on top of Hyperledger
            Fabric and Binance Smart Chain as a second layer blockchain.
            Posterity invented cutting-edge A.I Portfolio Manager and Trading
            Smart Contracts to make steady, reasonable, and safe returns for its
            clients. It's genuinely a trustless architecture out of companies
            and government control. To make it more secure, Posterity decided to
            complete an already unique platform by creating its own native token
            called Spot. You can add it to any wallet application you want.
            <br />
          </p>
          <span>1 Spot = 1 USD</span>

          <div>
            <Link to="/auth/register">
              Start Earning Now <RightArrow2Icon />
            </Link>
            <button
              onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                downloadWhitPaperModal();
              }}
            >
              Learn More
              <RightArrow2Icon />
            </button>
          </div>
        </div>
      </div>
      {props.buttons}
    </div>
  );
}
