import { Field, Form, Formik } from "formik";
import { useCallback, useEffect, useState } from "react";
import * as Yup from "yup";
import { getWalletAction, actions as investmentActions } from "../../../features/investment";
import { getAdminWalletAction } from "../../../features/investment/slices/getAdminWalletAction";
import { patchTransactionHashAction } from "../../../features/investment/slices/patchTransactionHashAction";
import {
  NetworkToCoin,
  ajaxConfirmAlert,
  ajaxSuccessAlert,
  ajaxSuccessAlertCancelDeposit,
  ajaxWarningAlert,
  noConfirmAjaxWarningAlert,
  remainingTimeCalc,
} from "../../../features/utils";
import { useAppDispatch, useAppSelector } from "../../../store";
import { Button, CopyToClipboard } from "../../common-components";
import CountDown from "./CountDown";
import { useHistory } from "react-router-dom";
import {actions as investActions} from "../../../features/investment/investment"
import Swal from "sweetalert2";
import { userProfileUtilsAction } from "../../../features/authentication/slices/userProfileUtilsAction";

const SubmitTransactionHash = ({destinationAddress} : any) => {
  const { data, status } = useAppSelector(
    (state) => state.investment.patchtxidInfo
  );
  const {
    data: { address: adminWalletAddress },
  } = useAppSelector((state) => state.investment.adminWalletInfo);

  const [timeRemaining, setTimeRemaining] = useState(
    remainingTimeCalc(data.expiredAt)
  );
  
  const {recentDeposits} = useAppSelector(state => state.investment.getwallet.getWalletData)
  console.log("recentDeposits" , recentDeposits)

  const dispatch = useAppDispatch();

  useEffect(() => {
    const interval = setInterval(() => {
      timeRemaining >= 0
        ? setTimeRemaining((pre) => pre - 1)
        : dispatch(investmentActions.closetxidPanel());
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [data.expiredAt]);

  let seconds = Math.floor(timeRemaining % 60);
  let totlsMinutes = Math.floor(timeRemaining / 60);
  let hour = Math.floor(timeRemaining / 3600 )
  const newMin = (hour * 60)
  let test = totlsMinutes - newMin



// const timeConvert =(timestampInSeconds : any)=>{
//   const utc = new Date().getDate()
//   const date = new Date().getHours()
//   console.log("utc" , utc , date)
//   const time = timestampInSeconds - utc
//   return time
// }

// console.log("hours2" , data.expiredAt)
  // let hour = Math.floor()

  // const fetchAdminWalletData = useCallback(async () => {
  //   await dispatch(getAdminWalletAction());
  // }, [dispatch]);

  // useEffect(() => {
  //   adminWalletAddress || fetchAdminWalletData();
  // }, [fetchAdminWalletData]);

  // useEffect(() => {
  //   if (error?.message) {
  //     ajaxErrorAlert(error.message).then((res) => {
  //       if (res.value) {
  //         dispatch(investActions.clearErrors());
  //       }
  //     });
  //   }
  // }, [error, dispatch]);
  

  // const fetchData = useCallback(async () => {
  //   await dispatch(getWalletAction());
  // }, [dispatch]);

  // useEffect(() => {
  //    fetchData();
  // }, [fetchData]);


  const { getWalletData, dataFetched } = useAppSelector(
    (state) => state.investment.getwallet
  );
  const {adminWalletAdress} = useAppSelector((state) => state.investment)
  const adminWalletData = getWalletData.recentDeposits.find((e: any)=> e.status === "pending" && e.destinationAddress)
  // console.log("adminWalletData?.destinationAddress" , adminWalletData?.destinationAddress)

  const handleCancel = () => {
    // Custom logic for the "Save" button
    const cancelPatchData = {
      txid: null,
      isCanceled : true
    }

    ajaxConfirmAlert(`You are about to cancel your transaction request. Please ensure that you have not transferred any funds to Posterity. If you have already made a transaction and it is in pending status, we strongly advise you not to cancel your request. Instead, submit your transaction hash in the appropriate field. If you cancel your request after making a transaction, there is a high probability that you will lose your funds because Posterity's wallet addresses are changing periodically.`)
    .then((res) =>{
      if(res.isConfirmed){
        dispatch(investActions.setCanceledDeposit(true))
        dispatch(
          patchTransactionHashAction(cancelPatchData)
        ).then((res) => {
         if(res.meta.requestStatus === "fulfilled")  {
          ajaxSuccessAlertCancelDeposit("Your transaction request has been canceled successfully." , undefined , undefined , "Return to the Deposit pge")
          // dispatch(getWalletAction())
         }
        });
      }
    })
     
  };

  return true ? (
    // return true ? (
    <div className="card">
      <h1 className="card__header">Submit Transaction Hash:</h1>
      <div className="submitTransactionHashCard">
        <Formik
          initialValues={{ transactionHash: "" }}
          validateOnMount={false}
          validateOnBlur={false}
          validationSchema={Yup.object().shape({
            transactionHash: Yup.string()
              .required("Please enter the Transaction Hash in a valid format.")
              .matches(
                /(^[0-9a-fA-F]{64}$)|(^0x([A-Fa-f0-9]{64})$)/,
                "Please enter the Transaction Hash in a valid format."
              ),
          })}
          onSubmit={async (values, actions) => {
            actions.setSubmitting(true);
            const patchData = {
              txid : values.transactionHash ,
              isCanceled : false
            }

            await dispatch(
              patchTransactionHashAction(patchData)
            ).then((res) => {
              res.meta.requestStatus === "fulfilled" &&
                ajaxSuccessAlert(
                  "Please wait while Posterity verifies your transaction hash. As soon as Posterity detects your transaction is confirmed in the corresponding blockchain, your Available Balance tops up automatically. Please do not contact Posterity regarding the transfer. The whole process is autonomous."
                ).then(() =>{
                  dispatch(userProfileUtilsAction());
                })
            });
            // if(cancel){
            //   const cancelPatchData = {
            //     txid : values.transactionHash ,
            //     isCancelled : values.isCancelled
            //   }
            //   await dispatch(
            //     patchTransactionHashAction(cancelPatchData)
            //   ).then((res) => {
            //     res.meta.requestStatus === "fulfilled" &&
            //       ajaxSuccessAlert(
            //         "Deposit proccess canceled!"
            //       );
            //   });
            // }

            actions.setSubmitting(false);
          }}
        >
          {({
            values,
            handleReset,
            handleSubmit,
            errors,
            handleChange,
            handleBlur,
            setValues,
            isSubmitting,
            resetForm
            
          }) => (
            <Form
              onReset={handleReset}
              onSubmit={handleSubmit}
              className="submitTransactionHashForm"
            >
              <div>
                <label>Your Wallet Address:</label>
                <Field disabled value={data.sourceAddress} className="w-full" />
              </div>

              <div>
                <label>Posterity Wallet Address:</label>
                <div className="w-full relative">
                  <Field
                    disabled
                    value={adminWalletAdress}
                    className="w-full"
                  />
                  <div className="fixedCopyButton">
                    <CopyToClipboard valueToCopy={adminWalletAdress} />
                  </div>
                </div>
              </div>

              <div>
                <label htmlFor="transactionHash">
                  {NetworkToCoin(data.network).toUpperCase()} Transaction Hash:
                </label>
                <Field
                  name="transactionHash"
                  id="transactionHash"
                  className="w-full"
                  value={values.transactionHash}
                  onChange={handleChange}
                  placeholder="enter transaction hash here..."
                />
                {errors.transactionHash && (
                  <p className="formError">{errors.transactionHash}</p>
                )}
              </div>

            <div className=" flex flex-row-reverse gap-2 justify-end">
            
              <button
                // disabled={values.transactionHash ? true : false}
                onClick={() =>{
                  resetForm()
                  handleCancel()
                }}
                // className={`${values.transactionHash ? "bg-red-400" : "bg-red-700"}`}
                className="bg-red-700"
              >
                Cancel
              </button>

              <Button
                disabled={status === "pending" || isSubmitting}
                loading={isSubmitting}
                type="submit"
              >
                Submit Transaction Hash
              </Button>
            </div>
            </Form>
          )}
        </Formik>

        <CountDown  minutes={test} hour={hour}/>
      </div>
    </div>
  ) : null;
};

export default SubmitTransactionHash;
