import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { IValidationErrors } from "../../interfaces";

export const patchTransactionHashAction = createAsyncThunk<
  any,
  {txid ?: string | null , isCanceled : boolean},
  {
    rejectValue: IValidationErrors;
  }
>("user/patch-transaction-hash", async (transactionData, thunkAPI) => {
  try {
    const response = await axios
      .patch(`${process.env.REACT_APP_API_ROOT_NEW}/crypto/deposit`, transactionData)
      .then((res) => {
        return res.data.data;
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue({
          message: error.interceptedMessage,
          code: error.response.status,
          name: "user/patch-transaction-hash",
        });
      });

    return response;
  } catch (error: any) {
    console.error("patchTransactionHashAction Error", error);
  }
});
