import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { IValidationErrors } from "../../interfaces";
import { IEarningReport } from "../../interfaces/payment";

export const earningReportAction = createAsyncThunk<
  IEarningReport[],
  void,
  {
    rejectValue: IValidationErrors;
  }
>("report/earning", async (_, thunkAPI) => {
  try {
    return axios
      .get(`${process.env.REACT_APP_API_ROOT_NEW}/report/earning`)
      .then((res) => {
        return res.data.data;
      })
      .catch((error) => {
        return error;
      });
  } catch (error: any) {
    return thunkAPI.rejectWithValue({
      message: error.response.message,
      code: error.response.status,
      name: "report/earning",
    });
  }
});
