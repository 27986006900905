import { memo, useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store";
import { Loading } from "../../common-components";
import FundInfo from "./FundInfo";
import NewInvestForm from "./NewInvestForm";
import SubmitDepositForm from "./SubmitDepositForm";
import SubmitTransactionHash from "./SubmitTransactionHash";
import TransactionsHistory from "./TransactionsHistory";
import { getWalletAction } from "../../../features/investment/slices/getWalletAction";
import InvestChoice from "./InvestChoice";
import { userProfileUtilsAction } from "../../../features/authentication/slices/userProfileUtilsAction";

function Deposit() {
  const [displayDepositForm, setDisplayDepositForm] = useState(false);
  const [displayInvestForm, setDisplayInvestForm] = useState(false);
  const [displayChoiceInvestForm, setDisplayChoiceInvestForm] = useState(false);
  const [render, setRender] = useState(false);
  const { userProfileDataUtils } = useAppSelector((state) => state.user);

  const { getWalletData, dataFetched } = useAppSelector(
    (state) => state.investment.getwallet
  );
  const { txidPanelOpen } = useAppSelector(
    (state) => state.investment.patchtxidInfo
  );
  const dispatch = useAppDispatch();

  const fetchData = useCallback(async () => {
    await dispatch(getWalletAction());
  }, [dataFetched, dispatch, getWalletAction]);

  useEffect(() => {
    if (!dataFetched) fetchData();
  }, []);

  // console.log("getWalletData status" , getWalletData.recentDeposits.filter((e: any)=> e.status === "pending"))
  // console.log("getWalletData" , getWalletData.recentDeposits.filter((e: any)=> e.status === "pending"))
  let adminWalletData;
  try {
    if (getWalletData.recentDeposits) {
      adminWalletData = getWalletData.recentDeposits.find(
        (e: any) => e.status === "pending" && e.destinationAddress
      );
    }
  } catch (error) {
    console.log(error);
  }
  //  adminWalletData.map((e) => e.destinationAddress)

  useEffect(() => {
    dispatch(userProfileUtilsAction());
  }, []);

  return dataFetched ? (
    <>
      <SubmitDepositForm
        isOpen={displayDepositForm}
        onClose={() => {
          return setDisplayDepositForm(false);
        }}
      />
      <InvestChoice
        // hasInvestedAt={userProfileDataUtils.data.initialInvestmentAt}
        isOpen={displayChoiceInvestForm}
        setInvestFormVisible={() => setDisplayInvestForm(true)}
        setInvestFormInvisible={() => setDisplayInvestForm(false)}
        depositBalance={getWalletData.depositBalance}
        withdrawableBalance={getWalletData.withdrawableBalance}
        // maxSupply={+getWalletData.balance}
        onClose={() => {
          return setDisplayChoiceInvestForm(false);
        }}
      />
      <NewInvestForm
        hasInvestedAt={userProfileDataUtils.data.initialInvestmentAt}
        isOpen={displayInvestForm}
        maxSupply={+getWalletData.balance}
        onClose={() => {
          return setDisplayInvestForm(false);
        }}
      />
      <div className="dDeposit">
        <div className="flex flex-col w-full gap-4">
          <FundInfo
            transactionInProcess={false}
            hasInvestedAt={userProfileDataUtils.data.initialInvestmentAt}
            setDepositFormVisible={() => setDisplayDepositForm(true)}
            setInvestFormVisible={() => setDisplayInvestForm(true)}
            setInvestChoiceFormVisible={() => setDisplayChoiceInvestForm(true)}
            balance={getWalletData.balance}
          />

          {txidPanelOpen && (
            <SubmitTransactionHash
              destinationAddress={adminWalletData?.destinationAddress}
            />
          )}
        </div>

        <TransactionsHistory history={getWalletData.recentDeposits} />
      </div>
    </>
  ) : (
    <Loading />
  );
}
export default memo(Deposit);
