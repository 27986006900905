import { createSlice } from "@reduxjs/toolkit";
import {
  IAction,
  IAdminWallet,
  IAdminWalletInfoDataType,
  ICoinsinfoDataType,
  ICreateDepositDataType,
  IInvestsSummary,
  IStatus,
  ITransactionModel,
  IUserInvests,
  IValidationErrors,
  IpatchTXIDInfoData,
} from "../interfaces";
import { IGetWallet } from "../interfaces/investments/investments";
import { createDepositAction } from "./slices/createDepositAction";
import { fetchAdminWalletsAndRecentTransactionAction } from "./slices/fetch-admin-wallets-and-recent-transactions";
import { getAdminWalletAction } from "./slices/getAdminWalletAction";
import { getCoinsInfoAction } from "./slices/getCoinsInfoAction";
import { getWalletAction } from "./slices/getWalletAction";
import { submitNewInvestmentAction } from "./slices/submit-new-investment";

import { remainingTimeCalc } from "../utils";
import { getInvestAction } from "./slices/getInvestAction";
import { patchTransactionHashAction } from "./slices/patchTransactionHashAction";


const initialData: {
  newInvestment: IAction;
  lastTransaction: {
    status: IStatus;
    error?: IValidationErrors;
    transaction?: ITransactionModel | null;
    // transaction?: ITransactionModel | null;
  };
  walletType : string;
  submitTransactionHash: {
    status: IStatus;
    error?: IValidationErrors;
  };
  userInvests: IUserInvests;
  userInvestsInfo: {
    status: IStatus;
    details: IInvestsSummary;
    error?: IValidationErrors;
  };
  adminWalletsAndRecentTransactions: {
    status: IStatus;
    wallets: IAdminWallet[];
    recentTransactions: ITransactionModel[];
    error?: IValidationErrors;
    dataFetched: boolean;
  };
  coinsInfo: {
    status: IStatus;
    data: ICoinsinfoDataType[];
    error?: IValidationErrors;
  };
  getwallet: {
    status: IStatus;
    getWalletData: IGetWallet;
    dataFetched: boolean;
    error?: IValidationErrors;
  };
  adminWalletInfo: {
    status: IStatus;
    data: IAdminWalletInfoDataType;
    error?: IValidationErrors;
  };
  createDeposit: {
    status: IStatus;
    data: ICreateDepositDataType;
    // | undefined
    error?: IValidationErrors;
  };
  patchtxidInfo: {
    status: IStatus;
    txidPanelOpen: boolean;
    data: IpatchTXIDInfoData;
    error?: IValidationErrors;
  };

  pendingDepositStatus: boolean;
  triedToTransferFund: boolean;
  adminWalletAdress : string | undefined;
  canceledDeposit : boolean;
} = {
  newInvestment: {
    status: "idle",
    error: {},
    //amount : 0
  },
  lastTransaction: {
    status: "idle",
    error: {},
    transaction: null,
  },
  submitTransactionHash: {
    status: "idle",
    error: {},
  },
  userInvests: {
    status: "idle",
    invests: [],
    summary: {
      totalPaid: 0,
      toBePaid: 0,
      totalInvestments: 0,
      numberOfInvestments: 0,
    },
    error: {},
    dataFetched: false,
  },
  userInvestsInfo: {
    status: "idle",
    details: {
      activeInvests: 0,
      totalInvestments: 0,
      totalInvestsProfit: 0,
      totalPaid: 0,
      totalToBePaid: 0,
    },
    error: {},
  },
  adminWalletsAndRecentTransactions: {
    dataFetched: false,
    status: "idle",
    wallets: [],
    recentTransactions: [],
    error: {},
  },
  coinsInfo: {
    status: "idle",
    data: [
      {
        id: 0,
        cryptoName: "",
        cryptoSymbol: undefined,
        networkName: undefined,
        isDepositAvailable: true,
        description : ""
      },
    ],
    error: {},
  },
  getwallet: {
    status: "idle",
    getWalletData: {
      message: "",
      balance: "",
      recentDeposits: [],
      statusCode: 0,
      error: [""],
      depositBalance : 0,
      withdrawableBalance : 0
    },

    dataFetched: false,
    error: {},
  },
  adminWalletInfo: {
    status: "idle",
    data: {
      address: "",
      balance: "",
      createdAt: "",
      id: 0,
      maxLimit: "",
      status: false,
      updatedAt: "",
    },
    error: {},
  },
  createDeposit: {
    status: "idle",
    error: {},
    data: {
      id: 0,
      sourceAddress: "",
      status: "",
      expiredAt: 0,
      createdAt: "",
      network: 0,
    },
  },
  patchtxidInfo: {
    status: "idle",
    error: {},
    txidPanelOpen: false,
    data: {
      id: 0,
      sourceAddress: "",
      status: "",
      expiredAt: 0,
      createdAt: "",
      network: 0,
    },
  },
  walletType : "",
  pendingDepositStatus: false,
  triedToTransferFund: false,
  adminWalletAdress : "" ,
  canceledDeposit : false
};

export const { reducer, actions } = createSlice({
  name: "user",
  initialState: initialData,
  reducers: {
    setTriedToTransferFund(state) {
      state.triedToTransferFund = true;
    },
    setMinMaxInvest(state) {},

    clearTransaction(state) {
      state.lastTransaction.error = {};
      state.lastTransaction.transaction = null;
      delete state.lastTransaction.transaction;
    },
    closetxidPanel(state) {
      state.patchtxidInfo.txidPanelOpen = false;
    },
    clearErrors(state) {
      state.newInvestment.error = {};
      state.adminWalletInfo.error = {};
      state.userInvestsInfo.error = {};
      state.userInvests.error = {};
    },

    setRecentTransactions(state, action) {
      state.adminWalletsAndRecentTransactions.recentTransactions =
        action.payload;
    },
    setWalletType (state , action) {
      state.walletType = action.payload
    } , 
    setCanceledDeposit (state , action){
  
      state.canceledDeposit = action.payload
    }
  },
  extraReducers: (builder) => {
    // REQUEST NEW INVEST
    builder
      .addCase(submitNewInvestmentAction.fulfilled, (state, action) => {
        // authenticationActions.setUserData(action.payload);
        state.newInvestment.status = "idle";
        state.getwallet.getWalletData.balance = action.payload.newBalance;
        // window.location.reload();
      })
      .addCase(submitNewInvestmentAction.pending, (state) => {
        state.newInvestment.status = "pending";
      })
      .addCase(submitNewInvestmentAction.rejected, (state, action) => {
        state.newInvestment.status = "rejected";
        if (action.payload) {
          state.newInvestment.error = action.payload;
        }
      });
    // old api, should be deleted
    builder
      .addCase(
        fetchAdminWalletsAndRecentTransactionAction.fulfilled,
        (state, { payload }) => {
          state.adminWalletsAndRecentTransactions.status = "idle";
          state.adminWalletsAndRecentTransactions.recentTransactions =
            payload.recentTransactions;
          state.adminWalletsAndRecentTransactions.wallets = payload.wallets;
          state.lastTransaction.transaction = payload.lastTransaction;
          state.adminWalletsAndRecentTransactions.dataFetched = true;
        }
      )
      .addCase(fetchAdminWalletsAndRecentTransactionAction.pending, (state) => {
        state.adminWalletsAndRecentTransactions.status = "pending";
      })
      .addCase(
        fetchAdminWalletsAndRecentTransactionAction.rejected,
        (state, { payload }) => {
          state.adminWalletsAndRecentTransactions.status = "rejected";
          if (payload) state.adminWalletsAndRecentTransactions.error = payload;
        }
      );
    //getCoinsInfoAction
    builder
      .addCase(getCoinsInfoAction.fulfilled, (state, { payload }) => {
        state.coinsInfo.status = "idle";
        state.coinsInfo.data = payload.data;
      })
      .addCase(getCoinsInfoAction.pending, (state) => {
        state.coinsInfo.status = "pending";
      })
      .addCase(getCoinsInfoAction.rejected, (state, { payload }) => {
        state.coinsInfo.status = "rejected";
        if (payload) state.coinsInfo.error = payload;
      });
    // get wallet action

    builder
      .addCase(getWalletAction.fulfilled, (state, action) => {
        state.getwallet.status = "idle";
        state.getwallet = action.payload;
        state.getwallet.dataFetched = true;
        state.adminWalletAdress = state.getwallet.getWalletData.recentDeposits.find((e: any)=> e.status === "pending")?.destinationAddress
        // console.log("action.payload" , state.getwallet.getWalletData.recentDeposits.find((e: any)=> e.status === "pending")?.destinationAddress)
        // should be this when status is correctly updated by backend
        // state.getwallet.getWalletData.recentDeposits[0]?.status === "pending"
        if (
          remainingTimeCalc(
            state.getwallet.getWalletData.recentDeposits[0]?.expiredAt
          ) >= 0 &&
          state.getwallet.getWalletData.recentDeposits[0]?.status === "pending"
        ) {
          state.patchtxidInfo.txidPanelOpen = true;
        }

        state.patchtxidInfo.data =
          action.payload.getWalletData.recentDeposits[0];
      })
      .addCase(getWalletAction.pending, (state) => {
        state.getwallet.status = "pending";
      })
      .addCase(getWalletAction.rejected, (state, action) => {
        state.getwallet.status = "rejected";
        if (action.payload) {
          state.getwallet.error = action.payload;
        }
      });
    // get admin wallet
    builder
      .addCase(getAdminWalletAction.fulfilled, (state, { payload }) => {
        state.adminWalletInfo.status = "idle";
        state.adminWalletInfo.data = payload.data;
        state.adminWalletAdress = payload.data.address
      })
      .addCase(getAdminWalletAction.pending, (state) => {
        state.adminWalletInfo.status = "pending";
      })
      .addCase(getAdminWalletAction.rejected, (state, { payload }) => {
        state.adminWalletInfo.status = "rejected";
        if (payload) {
          state.adminWalletInfo.error = payload;
        }
      });

    // create deposit (transfer fund)
    builder
      .addCase(createDepositAction.fulfilled, (state, { payload }) => {
        state.createDeposit.status = "idle";
        state.createDeposit.data = payload.data;
        state.patchtxidInfo.txidPanelOpen = true;
        state.patchtxidInfo.data = { ...payload.data };
        state.getwallet.getWalletData.recentDeposits.unshift({
          ...payload.data,
          updatedAt: "",
          cryptoAmount: null,
          usdAmount: null,
          txId: null,
          destinationAddress : ""
        });
      })
      .addCase(createDepositAction.pending, (state) => {
        state.createDeposit.status = "pending";
      })
      .addCase(createDepositAction.rejected, (state, { payload }) => {
        state.createDeposit.status = "rejected";
        state.createDeposit.error = payload;
      });
    // patch deposit (send txid)
    builder
      .addCase(patchTransactionHashAction.fulfilled, (state, { payload }) => {

        state.patchtxidInfo.status = "idle";
        state.patchtxidInfo.txidPanelOpen = false;
        if(!state.canceledDeposit){
          state.getwallet.getWalletData.balance = payload.walletBalance;
        } 
        state.getwallet.getWalletData.recentDeposits = payload.recentDeposits;
        
      })
      .addCase(patchTransactionHashAction.pending, (state) => {
        state.patchtxidInfo.status = "pending";
      })
      .addCase(patchTransactionHashAction.rejected, (state, { payload }) => {
        state.patchtxidInfo.status = "rejected";
        state.triedToTransferFund = true;
        state.patchtxidInfo.error = payload;
      });

    // GET USER INVESTS
    builder
      .addCase(getInvestAction.fulfilled, (state, { payload }) => {
        state.userInvests.status = "idle";
        state.userInvests.dataFetched = true;
        if (payload) {
          state.userInvests.summary = payload.summary;
          state.userInvests.invests = payload.invests;
        }
      })
      .addCase(getInvestAction.pending, (state) => {
        state.userInvests.status = "pending";
        state.userInvests.dataFetched = false;
      })
      .addCase(getInvestAction.rejected, (state, action) => {
        state.userInvests.status = "rejected";
        if (action.payload) state.userInvests.error = action.payload;
        state.userInvests.dataFetched = false;
      });
  },
});

export default reducer;
