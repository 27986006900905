import React from 'react'
import { Button, CustomModal } from "../common-components";
import * as Yup from "yup";
import { Active2FAAction } from "../../features/authentication/slices/Active2FAAction";
import { verify2FAAction } from "../../features/authentication/slices/Verify2FAAction";
import { Formik, Form, Field } from "formik";
import classNames from "classnames";
import { useAppDispatch, useAppSelector } from "../../store";
import {
    ajaxErrorAlert,
    ajaxSuccessAlert,
    ajaxWarningAlert,
  } from "../../features/utils";
const validationSchema = Yup.object().shape({
    code: Yup.string()
      .required("You need to enter the 2fa code to activate this feature.")
      .min(6)
      .max(6),
});

interface IActive2FAFormProps {
    isOpen: boolean;
    onClose(): void;
    setGAapproved? : any
  }

function GAModal(props: IActive2FAFormProps) {
    const { isOpen, onClose , setGAapproved } = props;
    const dispatch = useAppDispatch();
    const { verify2Fa } = useAppSelector((state) => state.user);
  return (
    <CustomModal isOpen={isOpen} onClose={onClose} className ="Modal">
        <Formik
        initialValues={{ code: "", password: "" }}
        validationSchema={validationSchema}
        onSubmit={async (values, actions) => {
          actions.setSubmitting(true);
          const data = {
            code: values.code,
          };
          await dispatch(verify2FAAction(data))
          .then((res) => {
            res.meta.requestStatus === "fulfilled" &&
              ajaxSuccessAlert(
                "Verification 2FA Done"
              ).then((res) => {
                onClose();
               setGAapproved(true)
              });
          });

          actions.setSubmitting(true);
        }}
      >
        {({
          values,
          handleReset,
          handleSubmit,
          isSubmitting,
          handleBlur,
          handleChange,
          errors,
        }) => (
          <Form
            onReset={handleReset}
            onSubmit={handleSubmit}
            className="GaForm"
          >
            <h1 className="GaFormTitle">Two Factor Authentication</h1>
            <br />
            
            <div>
              <div>
                <label htmlFor="code">
                  <span>
                    Enter 6 digit code from the application <br />
                  </span>
                </label>
                <Field
                  id="code"
                  //type="number"
                  placeholder="Example: 435 837"
                  value={values.code}
                  onChange={handleChange}
                  className={classNames({ inputError: errors.code })}
                  maxLength={6}
                />
                {errors.code && <p className="formError">{errors.code}</p>}
                <div className='GaForm-button'>
                  <Button
                    loading={verify2Fa.status === "pending"}
                    disabled={verify2Fa.status === "pending"}
                    type="submit"
                    className="mr-6"
                  >
                    Verify
                  </Button>
                  <Button type="button" onClick={() => onClose()}>
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>

    </CustomModal>
  )
}

export default GAModal