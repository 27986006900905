export { ReactComponent as AffiliateBinaryIcon } from './AffiliateBinary.svg';
export { ReactComponent as AffiliateControlPanelIcon } from './AffiliateControlPanel.svg';
export { ReactComponent as AffiliateMultiLevelIcon } from './AffiliateMultiLevel.svg';
export { ReactComponent as DashboardIcon } from './Dashboard.svg';
export { ReactComponent as PortfolioIcon } from './Portfolio.svg';
export { ReactComponent as ReportsIcon } from './Reports.svg';
export { ReactComponent as SettingsIcon } from './Settings.svg';
export { ReactComponent as WithdrawIcon } from './Withdraw.svg';
export { ReactComponent as OfficialRatesIcon } from './OfficialRates.svg';

export { ReactComponent as LogoutIcon } from './Logout.svg';
export { ReactComponent as Help } from './Help.svg';
export { ReactComponent as NotificationIcon } from './Notification.svg';
export { ReactComponent as ClockIcon } from './Clock.svg';
export { ReactComponent as Cancel } from './Cancel.svg';
export { ReactComponent as ActiveIcon } from './Active.svg';
export { ReactComponent as OptionsIcon } from './Options.svg';
export { ReactComponent as InvoiceIcon } from './Invoice.svg';
export { ReactComponent as SelectIcon } from './Select.svg';
export { ReactComponent as DeactivateIcon } from './Deactivate.svg';
export { ReactComponent as RefreshIcon } from './Refresh.svg';
export { ReactComponent as InformationIcon } from './Information.svg';
export { ReactComponent as MonthlyIncomeIcon } from './MonthlyIncome.svg';
export { ReactComponent as DollarIcon } from './Dollar.svg';
export { ReactComponent as LeftArrowIcon } from './LeftArrow.svg';
export { ReactComponent as RightArrowIcon } from './RightArrow.svg';
export { ReactComponent as RightArrow2Icon } from './RightArrow2.svg';
export { ReactComponent as TickIcon } from './Tick.svg';
export { ReactComponent as MaintenanceIcon } from './Maintenance.svg';
export { ReactComponent as UsersIcon } from './Users.svg';
export { ReactComponent as RankingIcon } from './Ranking.svg';
export { ReactComponent as TransactionIcon } from './Transaction.svg';
export { ReactComponent as ExclamationIcon } from './Exclamation.svg';
export { ReactComponent as ApproximatelyEqualIcon } from './ApproximatelyEqual.svg';
export { ReactComponent as DepositIcon } from './Deposit.svg';
export { ReactComponent as PlayIcon } from './Play.svg';
export { ReactComponent as PendingIcon } from './Pending.svg';
export { ReactComponent as RejectedIcon } from './Rejected.svg';
export { ReactComponent as ApprovedIcon } from './Approved.svg';
export { ReactComponent as LoginIcon } from './Login.svg';
export { ReactComponent as RegisterIcon } from './Approved.svg';
export { ReactComponent as CloseIcon } from './Close.svg';
export { ReactComponent as EyeIcon } from './Eye.svg';
export { ReactComponent as IDCardIcon } from './IDCard.svg';
export { ReactComponent as CycleIcon } from './Cycle.svg';
export { ReactComponent as PlusIcon } from './Plus.svg';
export { ReactComponent as UpArrowAngleIcon } from './UpArrowAngle.svg';
export { ReactComponent as PriceListIcon } from './PriceList.svg';
export { ReactComponent as LockIcon } from './Lock.svg';
export { ReactComponent as WalletIcon } from './Wallet.svg';
export { ReactComponent as Search } from './Search.svg';
export { ReactComponent as Arrow } from './Arrow.svg';
export { ReactComponent as CloseEye } from './CloseEye.svg';

// Coin Icons
export { ReactComponent as TetherIcon } from './Tether.svg';
export { ReactComponent as BitcoinIcon } from './Bitcoin.svg';
export { ReactComponent as LitecoinIcon } from './Litecoin.svg';
export { ReactComponent as EthereumIcon } from './Ethereum.svg';
export { ReactComponent as BNBIcon } from './binance-coin-seeklogo.com.svg';

// Technologies Icons
export { ReactComponent as LogoIcon } from './Logo.svg';
