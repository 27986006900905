// @ts-nocheck
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { RouteComponentProps, Link } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import Swal from "sweetalert2";
import classNames from "classnames";
// import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import ReCAPTCHA from "react-google-recaptcha";

import { Button } from "../common-components";
import {
  EMAIL_REGEX,
  ajaxErrorAlert,
  passwordStrongChecker,
  ajaxSuccessAlert,
  redirectTo,
} from "../../features/utils";
import { useAppDispatch, useAppSelector } from "../../store";
import { changePasswordAction, actions } from "../../features/authentication";
import { forgotPasswordAction } from "../../features/authentication/slices/forgotPassword-Action";

const validationSchema = Yup.object().shape({
  field: Yup.number().min(1).max(1),
  password: Yup.string()
    // .test('len', 'Must be at least 8 characters', (value) => value?.length === 5)
    .required("You need to chose a password.")
    .oneOf(
      [Yup.ref("password_confirm")],
      "Please enter exactly the same password in both cards."
    )
    .test(
      "password_strong",
      "Your password must contain at least 8 characters and at least one upper case letter and one number.",
      function (value) {
        if (value) {
          if (passwordStrongChecker.test(value)) {
            return true;
          }
        }

        return false;
      }
    ),
    email: Yup.string()
    .required("Please enter your Posterity email address in correct format to continue.")
    .test(
      "test-for-email",
      "Please enter your Posterity email address in correct format to continue.",
      function (value) {
        if (value) {
          if (value.search(EMAIL_REGEX) !== -1) {
            return true;
          }
        }
        return false;
      }
    ),
  password_confirm: Yup.string()
    .required("Please re-enter your password.")
    .oneOf(
      [Yup.ref("password")],
      "Please enter exactly the same password in both cards."
    ),
});

interface Props extends RouteComponentProps<{}, {}, { username: string }> {}

export default function ChangePassword(props: Props) {
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [recaptcha , setRecaptcha] = useState("")
  const [pinCode, setPinCode] = useState<string[]>(["", "", "", "", "", ""]);

  const dispatch = useAppDispatch();
  const { changePasswordStatus, error, forgotPassword } = useAppSelector(
    (state) => state.user
  );

  // useEffect(() => {
  //   let message = error?.message;

  //   if (message) {
  //     ajaxErrorAlert(message).then((res) => {
  //       if (res) dispatch(actions.clearErrors());
  //     });
  //   }
  // }, [error, dispatch, props.history]);

  useEffect(() => {
    document.getElementById("PIN_0")?.focus();
    document.addEventListener("paste", pastFromClipboard);
  }, [props.history]);

  const pastFromClipboard = (event: ClipboardEvent) => {
    if (event.clipboardData?.getData("text").length === 6) {
      let code = event.clipboardData.getData("text").toString();

      for (let index = 0; index < 6; index++) {
        if (code.charCodeAt(index) < 47 && code.charCodeAt(index) > 57) {
          ajaxErrorAlert(
            "Please paste a valid five digit code to verify your email"
          );

          Swal.close();

          return;
        }
      }

      setPinCode((prevState) => event.clipboardData!.getData("text").split(""));
    }
  };

  const setPinValue = (
    event: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    if (
      (event.keyCode >= 48 && event.keyCode <= 57) ||
      (event.keyCode >= 96 && event.keyCode <= 105) ||
      event.keyCode === 8
    ) {
      let newPinCode = [...pinCode];
      newPinCode[index] = event.key.toString();

      if (index < 6) {
        document.getElementById(`PIN_${index + 1}`)?.focus();
      }

      if (index === 6) {
        document.getElementById("password")!.focus();
      }

      setPinCode((prevState) => newPinCode);
    }
    if (event.keyCode === 8) {
      let newPinCode = [...pinCode];
      newPinCode[index] = event.key.toString();
      if (index <= 6) {
        document.getElementById(`PIN_${index - 1}`)?.focus();
      }
    }
  };

  return (
    <Formik
      initialValues={{
        field: "",
        password: "",
        password_confirm: "",
        email: "",
      }}
      validateOnBlur={false}
      // validateOnChange={false}
      validationSchema={validationSchema}
      onSubmit={async (values, actions) => {
        actions.setSubmitting(true);
        // const username = localStorage.getItem("username")!;

        const data = {
          otpCode: pinCode.join(""),
          newPassword: values.password,
          email: values.email,
          //newPassword : values.password_confirm
          //   email: username.search(EMAIL_REGEX) !== -1 ? username : "",
          //   userWalletAddress:
          //     username.search(EMAIL_REGEX) !== -1 ? "" : username,
          //   recoveryPin: pinCode.join(""),
          //   password: values.password,
        };

        await dispatch(forgotPasswordAction(data)).then((res) => {
          if (res.meta.requestStatus === "fulfilled") {
            ajaxSuccessAlert(res.payload.data.message);
            redirectTo("/auth");
          }
        });

        actions.setSubmitting(false);
      }}
    >
      {({
        values,
        handleReset,
        handleSubmit,
        handleBlur,
        handleChange,
        errors,
        isSubmitting,
      }) => (
        <Form
          onReset={handleReset}
          onSubmit={handleSubmit}
          className="changePasswordForm"
        >
          <div>
            <label htmlFor="email">Enter Your Recovery PIN</label>
            <div>
              {pinCode.map((code: string, index: number) => (
                <Field
                  key={`PIN_${index}`}
                  id={`PIN_${index}`}
                  value={code}
                  min={0}
                  max={9}
                  maxLength={1}
                  onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                    setPinValue(event, index);
                  }}
                  onBlur={handleBlur}
                  type="number"
                  className={classNames({ inputError: errors.field })}
                />
              ))}
            </div>
          </div>

          <div>
            <label htmlFor="username">Enter Your Email</label>
            <Field
              id="email"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              type="text"
              placeholder="Enter a valid email"
              className={classNames({ inputError: errors.email })}
            />
            {errors.email && <p className="formError">{errors.email}</p>}
          </div>

          <div className="passwordField">
            <label htmlFor="password">Enter Your New Password</label>
            <Field
              id="password"
              name="password"
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              type={showPassword ? "text" : "password"}
              placeholder="Enter a new password"
              className={classNames({ inputError: errors.password })}
            />
            <span
              onClick={(event: React.MouseEvent<HTMLSpanElement>) => {
                event.preventDefault();
                setShowPassword(!showPassword);
              }}
            >
              {showPassword ? "Hide" : "Show"}
            </span>
            {errors.password && <p className="formError">{errors.password}</p>}
          </div>

          <div className="passwordField">
            <label htmlFor="password_confirm">Confirm Your New Password</label>
            <Field
              id="password_confirm"
              name="password_confirm"
              value={values.password_confirm}
              onChange={handleChange}
              onBlur={handleBlur}
              type={showPasswordConfirm ? "text" : "password"}
              placeholder="Re-enter your new password"
              className={classNames({ inputError: errors.password_confirm })}
            />
            <span
              onClick={(event: React.MouseEvent<HTMLSpanElement>) => {
                event.preventDefault();
                setShowPasswordConfirm(!showPasswordConfirm);
              }}
            >
              {showPasswordConfirm ? "Hide" : "Show"}
            </span>
            {errors.password_confirm && (
              <p className="formError">{errors.password_confirm}</p>
            )}
          </div>
          <ReCAPTCHA sitekey={process.env.REACT_APP_SITE_KEY} onChange={(e) =>{setRecaptcha(e)}}/>
          <div className="mt-4 gap-18">
            <Button
              loading={forgotPassword.status === "pending"}
              disabled={forgotPassword.status === "pending" || recaptcha === "" }
              type="submit"
            >
              Change My Password
            </Button>
            <Link className="ml-2 mt-2" to={`/auth`}>
              Back To Sign-In
            </Link>
          </div>
        </Form>
      )}
    </Formik>
  );
}
