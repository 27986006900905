import { createSlice, current } from "@reduxjs/toolkit";
import { IStatus } from "./../interfaces/util-interfaces/IStatus";

import {
  IuserWithdrawData,
  IuserWithdrawalsListData,
  IwithdrawSubmitData,
} from "../interfaces";
import {
  IReportWithdraw,
  IgetWithdrawData,
} from "../interfaces/withdraw/withdraw-interfaces";
import { fetchUserwithdrawDataAction } from "./slices/fetch-withdraw-data";
import { fetchWithdrawalsListAction } from "./slices/fetch-withdrawals-list-Action";
import { getWithdrawAction } from "./slices/getWithdraw-Action";
import { withdrawReportAction } from "./slices/getWithdrawReportAction";
import { submitWithdrawAction } from "./slices/submitWithdrawAction";
import { submitWithdrawRequestAction } from "./slices/submitWithdrawRequest";
import { EStatus } from "../../enums/EStatus";
const initialData: {
  userWithdrawData: IuserWithdrawData;
  withdrawSubmitData: IwithdrawSubmitData;
  userWithdrawalsListData: IuserWithdrawalsListData;
  getWithdrawData: IgetWithdrawData;
  withdrawSubmit: {
    status: IStatus;
  };
  withdrawReport: IReportWithdraw;
} = {
  userWithdrawData: {
    status: "idle",
    error: {},
    dataFetched: false,
    withdrawData: {
      TotalWalletBalance: 0,
      maxWithdrawableAmount: 10000,
      WithdrawFee: { BTC: 1, ETH: 2 },
      minWithdrawableAmount: 10,
      is2FAActive: false,
      iswithdrawallowed: true,
      coinPrice: 20000,
    },
  },
  withdrawSubmitData: {
    status: "idle",
    error: {},
  },
  userWithdrawalsListData: {
    status: "idle",
    error: {},
    dataFetched: false,
    withdrawalList: [
      {
        USDAmount: 0,
        coinAmount: 0,
        walletAddress: "wallet address",
        transaction_Id: "txid",
        coin_type: "BTC",
        paidDate: "2022-07-30 06:55:55+0000",
        paidTimestamp: 0,
        creationDate: "2022-07-30 06:55:55+0000",
        creationTimestamp: 0,
        isApproved: true,
        isPaid: false,
      },
    ],
  },
  getWithdrawData: {
    status: EStatus.idle,
    wallets: [],
    cryptoPairs: [],
    constraints: {
      isWithdrawAllowed: false,
      withdrawableAmount: 0,
      walletBalance: 0,
      min: 0,
      max: 0,
    },
  },
  withdrawSubmit: {
    status: "idle",
  },
  withdrawReport: {
    data: [
      {
        id: 0,
        coinType: "",
        cryptoAmount: 0,
        usdAmount: "",
        walletAddress: "",
        status: "",
        requestDate: "",
        txid: "",
      },
    ],
    dataFetched: false,
    withdrawStatus: "idle",
    error: {},
  },
};

export const { reducer, actions } = createSlice({
  name: "withdraw",
  initialState: initialData,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getWithdrawAction.fulfilled, (state, action) => {
        state.getWithdrawData.status = "idle";
        state.getWithdrawData = action.payload;
        state.getWithdrawData.dataFetched = true;
      })
      .addCase(getWithdrawAction.pending, (state) => {
        state.getWithdrawData.status = "pending";
        state.getWithdrawData.dataFetched = false;
      })
      .addCase(getWithdrawAction.rejected, (state, action) => {
        state.getWithdrawData.status = "rejected";
        state.getWithdrawData.error = action.payload;
      });

    builder
      .addCase(submitWithdrawAction.fulfilled, (state, { payload }) => {
        state.withdrawSubmit.status = "idle";
        // state.getWithdrawData.constraints.walletBalance -= payload.usdAmount;
        // state.getWithdrawData.constraints.withdrawableAmount -=
        //   payload.usdAmount;
        state.getWithdrawData.constraints.isWithdrawAllowed = false;
      })
      .addCase(submitWithdrawAction.pending, (state) => {
        state.withdrawSubmit.status = "pending";
      })
      .addCase(submitWithdrawAction.rejected, (state, action) => {
        state.withdrawSubmit.status = "rejected";
      });

    // report withdraw
    builder
      .addCase(withdrawReportAction.fulfilled, (state, action) => {
        state.withdrawReport.withdrawStatus = "idle";
        state.withdrawReport.dataFetched = true;
        state.withdrawReport.data = action.payload;
      })
      .addCase(withdrawReportAction.pending, (state) => {
        state.withdrawReport.withdrawStatus = "pending";
        state.withdrawReport.dataFetched = false;
      })
      .addCase(withdrawReportAction.rejected, (state, action) => {
        state.withdrawReport.withdrawStatus = "rejected";
        state.withdrawReport.error = action.payload;
      });

    // GET USER withdraw data
    builder
      .addCase(fetchUserwithdrawDataAction.fulfilled, (state, action) => {
        state.userWithdrawData.status = "idle";
        state.userWithdrawData.dataFetched = true;
        if (action.payload) {
          state.userWithdrawData.withdrawData = action.payload;
        }
      })
      .addCase(fetchUserwithdrawDataAction.pending, (state) => {
        state.userWithdrawData.status = "pending";
        state.userWithdrawData.dataFetched = false;
      })
      .addCase(fetchUserwithdrawDataAction.rejected, (state, action) => {
        state.userWithdrawData.status = "rejected";
        state.userWithdrawData.dataFetched = true;
        if (action.payload) state.userWithdrawData.error = action.payload;
      });

    // Submit a new withdraw request
    builder
      .addCase(submitWithdrawRequestAction.fulfilled, (state, action) => {
        state.withdrawSubmitData.status = "idle";
      })
      .addCase(submitWithdrawRequestAction.pending, (state) => {
        state.withdrawSubmitData.status = "pending";
      })
      .addCase(submitWithdrawRequestAction.rejected, (state, action) => {
        state.withdrawSubmitData.status = "rejected";

        if (action.payload) state.withdrawSubmitData.error = action.payload;
      });

    // Fetching withdrawals list
    builder
      .addCase(fetchWithdrawalsListAction.fulfilled, (state, action) => {
        state.userWithdrawalsListData.status = "idle";
        state.userWithdrawalsListData.dataFetched = true;
        if (action.payload) {
          state.userWithdrawalsListData.withdrawalList = action.payload;
        }
      })
      .addCase(fetchWithdrawalsListAction.pending, (state) => {
        state.userWithdrawalsListData.status = "pending";
        state.userWithdrawalsListData.dataFetched = false;
      })
      .addCase(fetchWithdrawalsListAction.rejected, (state, action) => {
        state.userWithdrawalsListData.status = "rejected";
        state.userWithdrawalsListData.dataFetched = true;
        if (action.payload)
          state.userWithdrawalsListData.error = action.payload;
      });
  },
});

export default reducer;
