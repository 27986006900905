// @ts-ignore
import { useEffect, useState } from "react";
import { NavLink, RouteComponentProps } from "react-router-dom";
import ReactToolTip from "react-tooltip";
import Withdraw from "../../text-guide/Withdraw";
import Dashboard from "../../text-guide/Dashboard";
import Portfolio from "../../text-guide/Portfolio";
import MultiLevel from "../../text-guide/MultiLevel";
import Matching from "../../text-guide/Matching";
import { CustomModal } from "../../common-components";
import { signoutAction } from "../../../features/authentication/slices/signout";
import { capitalize } from "../../../features/utils";
import { LogoutIcon, Help, NotificationIcon } from "../../../assets/icons";
import { useAppDispatch, useAppSelector } from "../../../store";
import Deposit from "../../text-guide/Deposit";
import Reports from "../../text-guide/Reports";
import Setting from "../../text-guide/Setting";
import Notification from "../../text-guide/Notification";



interface IPanelHeaderProps extends RouteComponentProps {
  onDisplayPriceList(): void;
  priceListActive: boolean;
}

const textGuide = {
  withdrawText: Withdraw,
  dashboardText: Dashboard,
  portfolioText: Portfolio,
  multiLevel: MultiLevel,
  matchingBonus: Matching,
  depositText: Deposit,
  reportsText: Reports,
  settingText: Setting,
  notificationText : Notification
};

export default function PanelHeader(props: IPanelHeaderProps) {
  const { pathname } = props.location;
  const [displayDescription, setDisplayDescription] = useState(false);
  const [help, setHelp] = useState(textGuide.dashboardText);
  // const [badge, setBadge] = useState(0);
  const dispatch = useAppDispatch();


  // useEffect(() => {
  //   setBadge(11);
  // }, [badge]);
  const {notificationBadge} = useAppSelector((state) => state.user.userProfileDataUtils.data)
  

  const helpHandler = () => {
    if (pathname === "/panel") {
      setHelp(textGuide.dashboardText);
    } else if (pathname === "/panel/withdraw") {
      setHelp(textGuide.withdrawText);
    } else if (pathname === "/panel/portfolio") {
      setHelp(textGuide.portfolioText);
    } else if (pathname === "/panel/affiliate-multi-level-plan") {
      setHelp(textGuide.multiLevel);
    } else if (pathname === "/panel/affiliate-matching-bonus-plan") {
      setHelp(textGuide.matchingBonus);
    } else if (pathname === "/panel/deposit") {
      setHelp(textGuide.depositText);
    } else if (pathname === "/panel/reports") {
      setHelp(textGuide.reportsText);
    } else if (pathname === "/panel/settings") {
      setHelp(textGuide.settingText);
    } else if(pathname === "/panel/notification-center"){
      setHelp(textGuide.notificationText)
    }
  };

  return (
    <div className="panelHeader">
      <CustomModal
        isOpen={displayDescription}
        closeOnClickOutside={true}
        className="ModalTextguid"
        onClose={() => {
          setDisplayDescription(false);
        }}
      >
        <p className="fundsInfo_description">
          {help}
          {/* <textGuide.withdrawText/> */}
        </p>
      </CustomModal>

      <div className="panelHeader__currentRoute">
        <span>
          {pathname === "/panel"
            ? capitalize("Dashboard")
            : capitalize(pathname.split("/").slice(2)[0])}
        </span>

        <div onClick={() => setDisplayDescription(true)}>
          <Help onClick={() => helpHandler()} className="panelHeader__help" />
        </div>
      </div>

      <div className="panelHeader__actions">



        {/* <div className="panelHeader__notifications"> */}
          <NavLink
            to={`${props.match.url}/notification-center`}
            exact={true}
            className="panelHeader__Badgewrapper"
          >
             {/* <p className="panelHeader__badge">{notificationBadge && notificationBadge }</p>  */}
             {
              notificationBadge! > 0 ?  <p className="panelHeader__badge">{notificationBadge && notificationBadge }</p>  : null

             }
            <NotificationIcon className="panelHeader__notification" />
          </NavLink>
        {/* </div> */}

        {/* <span className={classNames({ priceListActive: priceListActive })}>
          <PriceListIcon
            data-tip
            data-for='priceListIcon'
            onClick={(event: React.MouseEvent<SVGElement>) => {
              event.preventDefault();
              onDisplayPriceList();
            }}
          />
          <ReactToolTip id='priceListIcon' type='dark' effect='solid'>
            <p>Display cryptocurrencies price list</p>
          </ReactToolTip>
        </span> */}

        <span onClick={() => dispatch(signoutAction())}>
          <LogoutIcon data-tip data-for="logOutIcon" />

          <ReactToolTip id="logOutIcon" type="dark" effect="solid">
            <p>Sign out from your account</p>
          </ReactToolTip>
        </span>
      </div>
    </div>
  );
}
