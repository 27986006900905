export enum notification {
    "WELCOME" = "Welcome" ,
    "DEPOSIT" = "Deposit" , 
    "INVESTMENT" = "Investment" ,
    "INVESTMENT_PROFIT" = "Investment_Profit" , 
    "AFFILIATE_PROFIT" = "Affiliate_Profit" ,
    "DIRECT_REFERRAL" = "Direct_Referral" , 
    "MATCHING_ACTIVATED" = "Matching_Activated" ,
    "PASSWORD_CHANGE" = "Password_Change",
    "MFA_ACTIVATED" = "Mfa_Activated" ,
    "WITHDRAW_REQUEST" = "Withdraw_Request" ,
    "WITHDRAW_APPROVED" = "Withdraw_Approved" ,
    "WITHDRAW_REJECTED" = "Withdraw_Rejected" , 
    "WITHDRAW_PAID" = "Withdraw_Paid" , 
    "NICKNAME_CHANGED" = "Nickname_Change"
}

