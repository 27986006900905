// import React from 'react';
// import { store } from 'react-notifications-component';

// import { TickIcon, CloseIcon, ExclamationIcon } from '../../assets/icons';

export const addNotification = (
  title: string,
  message: string,
  type:
    | "info"
    | "success"
    | "danger"
    | "default"
    | "warning"
    | undefined = "info",
  position?: "",
  width: number | undefined = undefined
) => {
  // store.addNotification({
  //   title: title,
  //   message: message,
  //   type: type,
  //   insert: 'top',
  //   container: 'top-right',
  //   animationIn: ['animate__animated', 'animate__fadeInRight'],
  //   animationOut: ['animate__animated', 'animate__fadeOutRight'],
  //   content: (
  //     <div className={`notification__custom--${type}`}>
  //       <div>
  //         {type === 'danger' ? (
  //           <CloseIcon />
  //         ) : type === 'success' ? (
  //           <TickIcon />
  //         ) : type === 'warning' ? (
  //           <ExclamationIcon />
  //         ) : null}
  //       </div>
  //       <div>
  //         <h1>{title}</h1>
  //         <p>{message}</p>
  //       </div>
  //     </div>
  //   ),
  //   width: width,
  //   dismiss: {
  //     duration: 5000,
  //     pauseOnHover: true,
  //     waitForAnimation: true,
  //   },
  // });
};
