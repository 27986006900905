// @ts-nocheck
import axios from "axios";
import Cookie from "js-cookie";

export const TokenClient = () => {
  axios.interceptors.request.use(
    (config) => {
      const token = Cookie.get("token");
      config.headers.authorization = token ? `Bearer ${token}` : "";
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};
