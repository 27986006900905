import { useState, useEffect, memo } from "react";
import { arrayToTree } from "performant-array-to-tree";

import { useAppSelector } from "../../../store";
import {
  IMultiLevelSubsetDataModel,
  ISunburstChartData,
  ITreeDataModel,
  MLMChartDataType,
} from "../../../features/interfaces";

import Sunburst from "./Sunburst";
import SunWrapper from "./SunWrapper";

interface IResponsiveSunburstProps {
  width: number;
  data?: MLMChartDataType;
}

function ResponsiveSunburst(props: IResponsiveSunburstProps) {
  const { width, data } = props;
  const [sunburstData, setSunburstData] = useState<ISunburstChartData>({
    name: "",
    children: [],
  });

  const makePropertiesStandard = (array: any[]) => {
    for (let i = array.length; i > 0; i--) {
      let levelZeroElem = array[i - 1];

      delete levelZeroElem.id;
      delete levelZeroElem.parentId;
      levelZeroElem.name = levelZeroElem.nickname;
      delete levelZeroElem.nickname;

      let childrenArr = levelZeroElem.children;
      console.log("childrenArr" , childrenArr)

      if (childrenArr[0]) {
        makePropertiesStandard(childrenArr);
      } else if (!childrenArr[0]) {
        levelZeroElem.size = 1;
        delete levelZeroElem.children;
      }
    }
  };

  useEffect(() => {
    if (data) {
      let tmpArrData = JSON.parse(JSON.stringify(data));
      makePropertiesStandard(tmpArrData);
      console.log("tmpArrData" , tmpArrData)

      setSunburstData(tmpArrData[0]);
    }
  }, [data]);

  return sunburstData.name ? (
    <SunWrapper
      data={sunburstData}
      width={width > 1400 ? width / 3 : width < 700 ? width : (width / 3) * 2}
    />
  ) : null;
}

export default memo(ResponsiveSunburst);
