import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const requestOTPAction = createAsyncThunk<any, { type: string }, {}>(
  "user/request-otp",
  async (params, thunkAPI) => {
    try {
      await axios
        .get(`${process.env.REACT_APP_API_ROOT_NEW}/auth/otp`, { params })
        .then((res) => res);
    } catch (error: any) {
      return thunkAPI.rejectWithValue({
        message: error.interceptedMessage
          ? error.interceptedMessage
          : "Please make sure you are using the correct email address (format)!",

        name: "user/request-otp",
        code: error.response.data.status,
      });
    }
  }
);
