import { useEffect, useState } from "react";
import { IusePaginationProps, PageSizeType } from "./interfaces";

export const usePagination = <Type extends unknown[]>(
  noPaginationArr: Type
) => {
  const [limitedItems, setLimitedItems] = useState<Type>(noPaginationArr);
  const [pageSize, setPageSize] = useState<PageSizeType>(10);
  const [pageIndex, setPageIndex] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const totalPagesCounter = (arr: unknown[], pageSize: number) =>
    Math.ceil(arr.length / pageSize);
    

  const itemsLimiter = <K,>(itemsArr: K[]): K[] => {
    
    let start =  (pageIndex)*pageSize;
    let end = Math.min(((pageIndex)*pageSize)+(pageSize-1) , noPaginationArr.length);

    return  itemsArr.slice(start, end +1);
  }


  useEffect(() => {
    setTotalPages(totalPagesCounter(noPaginationArr, pageSize));
    let tmpLimitedItems = itemsLimiter(noPaginationArr);
    //@ts-expect-error
    setLimitedItems(tmpLimitedItems);
    console.log("pageIndex:" , pageIndex)
  }, [noPaginationArr, pageIndex, pageSize]);

  return {
    limitedItems,
    setLimitedItems,
    pageSize,
    pageIndex,
    totalPages,
    setPageSize,
    setPageIndex,
  };
};
