import classNames from "classnames";
import React, { FC, useState } from "react";

import moment from "moment";
import { DeactivateIcon, TickIcon } from "../../../assets/icons";
import { IInvestDataType } from "../../../features/interfaces/investments/IInvestDataType";
import { currencyFormat, numericTimeFormat } from "../../../features/utils";

export enum AccordionSizes {
  normal,
  large,
  medium,
  small,
}

const PortfolioItem: FC<{
  size: AccordionSizes;
  invest: IInvestDataType;
}> = ({ invest, size }) => {
  const [accordionOpen, setAccordionOpen] = useState(false);

  const expirationDate = moment
    .duration(moment(invest.expiresAt).diff(moment.now()))
    .humanize(true)
    .slice(3);

  const renderHeader = () => {
    switch (size) {
      case AccordionSizes.normal:
      case AccordionSizes.large:
        return (
          <>
            <div className="portfolioItem__item">
              <p>Life Span </p>
              {/* <p>{invest?.expiresAt}</p> */}
              <p>{expirationDate}</p>
            </div>
            <div className="portfolioItem__item transactionFee">
              <p>Invested</p>

              <p>
                {
                  //@ts-ignore
                  currencyFormat(+invest?.amount)
                }
              </p>
            </div>

            <div className="portfolioItem__item">
              <p>Invested source</p>

              <p>
                {
                  //@ts-ignore
                invest?.walletType
                }
              </p>
            </div>

            <div className="portfolioItem__item cryptoAmount">
              <p>Paid</p>
              <p>{currencyFormat(+invest.paid)}</p>
            </div>
            <div className="portfolioItem__item usdAmount">
              <p>To Be Paid</p>
              <p>{currencyFormat(+invest.toBePaid)}</p>
            </div>
            <div className="portfolioItem__item">
              <p>Invested Date</p>
              <p>{numericTimeFormat(invest?.createdAt)}</p>
            </div>
            <div className="portfolioItem__item">
              <p>Expiration Date</p>
              <p>{numericTimeFormat(invest?.expiresAt)}</p>
            </div>
            <div
              className={classNames("portfolioItem__item status", {
                status__onHold: invest.status !== "active",
              })}
            >
              <p>Status</p>

              <p>
                {invest.status === "active" ? (
                  <>
                    <TickIcon />
                    Active
                  </>
                ) : (
                  <>
                    <DeactivateIcon /> On Hold
                  </>
                )}
              </p>
            </div>
          </>
        );

      case AccordionSizes.medium:
        return (
          <>
            <div className="portfolioItem__item">
              <p>Life Span </p>
              <p>{expirationDate}</p>
            </div>
            <div className="portfolioItem__item transactionFee">
              <p>Invested</p>

              <p>
                {
                  //@ts-ignore
                  currencyFormat(+invest?.amount)
                }
              </p>
            </div>
            <div className="portfolioItem__item cryptoAmount">
              <p>Paid</p>

              <p>{currencyFormat(+invest.paid)}</p>
            </div>
            <div className="portfolioItem__item usdAmount">
              <p>To Be Paid</p>

              <p>{currencyFormat(+invest.toBePaid)}</p>
            </div>
            <div
              className={classNames("portfolioItem__item status", {
                status__onHold: invest.status !== "active",
              })}
            >
              <p>Status</p>
              <p></p>
              <p>
                {invest.status === "active" ? (
                  <>
                    <TickIcon />
                    Active
                  </>
                ) : (
                  <>
                    <DeactivateIcon /> On Hold
                  </>
                )}
              </p>
            </div>
          </>
        );

      case AccordionSizes.small:
        return (
          <>
            <div className="portfolioItem__item">
              <p>Life Span </p>
              <p>{expirationDate}</p>
            </div>
            <div className="portfolioItem__item transactionFee">
              <p>Invested</p>
              <p>
                {
                  //@ts-ignore
                  currencyFormat(+invest?.amount)
                }
              </p>
            </div>
            <div className="portfolioItem__item cryptoAmount">
              <p>Paid</p>
              <p>{currencyFormat(+invest.paid)}</p>
            </div>
            <div className="portfolioItem__item usdAmount">
              <p>To Be Paid</p>
              <p>{currencyFormat(+invest.toBePaid)}</p>
            </div>
          </>
        );
    }
  };

  const renderContent = () => {
    switch (size) {
      case AccordionSizes.medium:
        return (
          <div className="portfolioItem__itemsCol">
            <div className="portfolioItem__item">
              <p>Invested Date</p>
              <p>{numericTimeFormat(invest?.createdAt)}</p>
            </div>
          </div>
        );

      case AccordionSizes.small:
        return (
          <div className="portfolioItem__itemsCol">
            <div
              className={classNames("portfolioItem__item status", {
                status__onHold: invest.status !== "active",
              })}
            >
              <p>Status</p>
              <p>
                {invest.status === "active" ? (
                  <>
                    <TickIcon />
                    Active
                  </>
                ) : (
                  <>
                    <DeactivateIcon /> On Hold
                  </>
                )}
              </p>
            </div>
            <div className="portfolioItem__item">
              <p>Invested Date</p>
              <p>{numericTimeFormat(invest?.createdAt)}</p>
            </div>
          </div>
        );
    }
  };

  return (
    <div
      className={classNames("portfolioItem", {
        portfolioItem__opened: accordionOpen,
      })}
    >
      <div
        className="portfolioItem__line"
        onClick={(event: React.MouseEvent) => {
          event.preventDefault();

          if (size === AccordionSizes.medium || size === AccordionSizes.small) {
            setAccordionOpen(() => !accordionOpen);
          }

          return;
        }}
      >
        <div className="portfolioItem__items">{renderHeader()}</div>
        {(size === AccordionSizes.medium || size === AccordionSizes.small) && (
          <span className="portfolioItem__icon" />
        )}
      </div>

      <div className="portfolioItem__inner">
        <div className="portfolioItem__content">
          <div className={classNames("portfolioItem__items bordered")}>
            {renderContent()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(PortfolioItem);
