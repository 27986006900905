import { useCallback, useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import { getWithdrawAction } from "../../../features/withdraw/slices/getWithdraw-Action";
import { useAppDispatch } from "../../../store";
import WithdrawForm from "./WithdrawForm";
import { userProfileUtilsAction } from "../../../features/authentication/slices/userProfileUtilsAction";

// import WithdrawInvoice from "./WithdrawInvoice";

interface Props extends RouteComponentProps {}

export default function Withdraw(props: Props) {
  const dispatch = useAppDispatch();

  const fetchWithdrawData = useCallback(async () => {
    await dispatch(getWithdrawAction());
  }, [dispatch]);

  useEffect(() => {
    fetchWithdrawData();
  }, [fetchWithdrawData]);

  useEffect(() =>{
    dispatch(userProfileUtilsAction());
  }, [])

  return (
    <div className="withdrawWrapper">
      <WithdrawForm {...props} />
    </div>
  );
}
