import { useCallback, useEffect } from "react";
import useDimensions from "react-cool-dimensions";
import { getInvestAction } from "../../../features/investment/slices/getInvestAction";
import { usePagination } from "../../../features/usePagination";
import { useAppDispatch, useAppSelector } from "../../../store";
import { Loading } from "../../common-components";
import Pagination from "../../common-components/Pagination";
import PortfolioItem, { AccordionSizes } from "./PortfolioItem";
import PortfolioOverview from "./PortfolioOverview";
import { userProfileUtilsAction } from "../../../features/authentication/slices/userProfileUtilsAction";

export default function Portfolio() {
  const dispatch = useAppDispatch();
  const {
    invests,
    status,
    dataFetched,
    summary: { totalPaid, toBePaid, totalInvestments, numberOfInvestments },
  } = useAppSelector((state) => state.investment.userInvests);

  const fetchInvests = useCallback(async () => {
    // showLoading();
    await dispatch(getInvestAction());
    // Swal.close();
  }, [dispatch]);

  useEffect(() => {
    // if (!dataFetched)
    fetchInvests();
  }, [fetchInvests]);
  const { limitedItems, pageIndex, totalPages, setPageSize, setPageIndex } =
    usePagination(invests);

    // console.log("totalPages" , totalPages)
    // console.log("pageIndex" , pageIndex)

  const { observe, width } = useDimensions<HTMLDivElement>({
    onResize: ({ observe, unobserve, width, height, entry }) => {
      unobserve();
      observe();
    },
  });

  let size =
    width <= 1000 && width > 800
      ? AccordionSizes.large
      : width <= 800 && width > 600
      ? AccordionSizes.medium
      : width <= 600
      ? AccordionSizes.small
      : AccordionSizes.normal;

  const portfolioItemCreator = () => {
    return limitedItems.map((invest: any) => (
      <PortfolioItem key={invest.id} invest={invest} size={size} />
    ));
  };


  useEffect(() =>{
    dispatch(userProfileUtilsAction());
  }, [])

  
  return status === "pending" && !dataFetched ? (
    <Loading />
  ) : (
    <div ref={observe}>
      <PortfolioOverview
        totalInvest={+totalInvestments}
        investsCount={+numberOfInvestments}
        toBePaid={+toBePaid}
        totalPaid={+totalPaid}
      />

      <div className="card">
        <p className="card__header">Investments</p>

        {portfolioItemCreator()}
        <Pagination
          setPageIndex={setPageIndex}
          setPageSize={setPageSize}
          pageIndex={pageIndex}
          totalPages={totalPages}
        />
      </div>
    </div>
  );
}
