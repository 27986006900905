import React, { useState, useRef, useEffect } from "react";
import YouTube, { Options } from "react-youtube";

import { PlayIcon } from "../../assets/icons";
interface Props {
  buttons: React.ReactNode;
}

export default function DEFIPlatform(props: Props) {
  const [playVideo, setPlayVideo] = useState<boolean>(false);

  const _ref = useRef(null);

  const options: Options = {
    playerVars: {
      showinfo: 0,
      controls: 0,
      autoplay: 0,
      color: "white",
    },
  };

  const setPlay = (event: any) => {
    event.target.pauseVideo();

    if (playVideo) {
      event.target.playVideo();
    }
  };

  const _canvas = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const canvas = _canvas.current!;
    const context = canvas.getContext("2d")!;
    context.canvas.width = document.body.clientWidth;
    context.canvas.height = document.body.clientHeight;
    context.lineWidth = 6;
    context.strokeStyle = "#ff4040";

    context.beginPath();
    context.moveTo(context.canvas.width, context.canvas.height / 4);
    context.lineTo(context.canvas.width / 2, context.canvas.height / 4);
    context.lineTo(context.canvas.width / 2, context.canvas.height / 2);
    context.stroke();
    context.closePath();

    context.beginPath();
    context.moveTo(context.canvas.width / 2, context.canvas.height / 2);
    context.lineTo(
      context.canvas.width / 2,
      context.canvas.height / 2 + context.canvas.height / 4
    );
    context.lineTo(
      context.canvas.width / 2 + context.canvas.width / 3,
      context.canvas.height / 2 + context.canvas.height / 4
    );
    context.lineTo(
      context.canvas.width / 2 + context.canvas.width / 3,
      context.canvas.height
    );
    context.stroke();
    context.closePath();

    return () => canvas.remove();
  }, [_canvas]);

  return (
    <div className="landingWrapper__wrapper lDEFIPlatform">
      <canvas ref={_canvas} />
      <div>
        <div>
          {!playVideo ? (
            <div className="playButton">
              <p onClick={(event: React.MouseEvent) => setPlayVideo(true)}>
                <PlayIcon />
                Play Video
              </p>
            </div>
          ) : (
            <YouTube
              ref={_ref}
              videoId="Fl2OUpVj6DM"
              opts={options}
              onReady={setPlay}
            />
          )}
        </div>
        <div>
          <h1>DEFI Platform</h1>
          <p>
            Defi is a revolutionary movement that aims to create a more
            inclusive and transparent financial system that does not depend on
            centralized intermediaries. By leveraging the power of <b>Blockchain</b>,
            <b>Cryptography</b>, and <b>Smart Contracts</b>, Defi enables users to control
            their own assets and interact with various financial services in a
            trustless and permissionless way. Posterity is a Defi platform that
            offers you the opportunity to grow your funds by participating in a
            decentralized and autonomous community. Posterity is not controlled
            by any entity or institution, but by the collective actions of its
            users. Posterity is a truly decentralized service that empowers you
            to take charge of your financial future.
          </p>
        </div>
      </div>
      {props.buttons}
    </div>
  );
}
