import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import { IMLMData, IValidationErrors } from "../../interfaces";

export const fetchMultiLevelDataAction = createAsyncThunk<
  IMLMData,
  undefined,
  {
    rejectValue: IValidationErrors;
  }
>("user/fetch-multi-level-data", async (_, thunkAPI) => {
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_API_ROOT_NEW}/mlm/mlm`
    );

    return response.data.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue({
      message: error.response.data.message,
      code: error.response.status,
      name: "user/fetch-multi-level-data",
    });
  }
});
