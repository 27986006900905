import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { IDashboardData, IValidationErrors } from "../../interfaces";
export const getDashboardData = createAsyncThunk<
  IDashboardData,
  undefined,
  {
    rejectValue: IValidationErrors;
  }
>("user/dashboard", async (_, thunkAPI) => {
  try {
    const response = await axios
      .get(`${process.env.REACT_APP_API_ROOT_NEW}/user/portfolio`)
      .then((res) => res.data.data);
    return response;
  } catch (error: any) {
    return thunkAPI.rejectWithValue({
      message: error.response.data.message,
      code: error.response.status,
      name: "user/dashboard",
    });
  }
});
