import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import { IValidationErrors } from "../../interfaces";

export const changeNicknameAction = createAsyncThunk<
  any,
  string,
  {
    rejectValue: IValidationErrors;
  }
>("user/change-nickname", async (newNickname, thunkAPI) => {
  let response = await axios
    .patch(`${process.env.REACT_APP_API_ROOT_NEW}/user/nickname`, {
      newNickname,
    })
    .then((res) => {
      return res;
    })
    .catch((error) =>
      thunkAPI.rejectWithValue({
        message: error.interceptedMessage,
        code: error.response.data.status,
        name: "user/change-nickname",
      })
    );

  return response;
});
