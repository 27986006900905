import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Cookie from "js-cookie";

import { IChangePasswordDataModel, IValidationErrors } from "../../interfaces";

export const changePasswordAction = createAsyncThunk<
  void,
  IChangePasswordDataModel,
  { rejectValue: IValidationErrors }
>("user/change-password", async (credential, thunkAPI) => {
  try {
    const response = await axios.patch(
      `${process.env.REACT_APP_API_ROOT_NEW}/user/password`,
      {
        ...credential,
      }
    );

    Cookie.set("token", response.data.token);
  } catch (error: any) {
    if (error.response.data.status === 403) {
      return thunkAPI.rejectWithValue({
        message: error.interceptedMessage,
        name: "user/request-pin",
        code: error.response.data.status,
      });
    }
    return thunkAPI.rejectWithValue({
      message: error.interceptedMessage,
      name: "user/request-pin",
      code: error.response.data.status,
    });
  }
});
