import {
  useCallback,
  useEffect,
  useRef,
  useState,
  MouseEvent,
  FC,
} from "react";
import { arrayToTree } from "performant-array-to-tree";
import * as d3 from "d3";
import Swal from "sweetalert2";
import { EyeIcon , CloseEye } from "../../../assets/icons";
import moment from "moment";
import { matchingTree } from "./mockData";
import dataFlattened from "../../common-components/charts/treeData.json";
import secondLevelData from "../../common-components/charts/treeDataSecondLevel.json";
import { Button, Loading, TreeChart } from "../../common-components";
import AddNodeToTree from "./AddNodeToTree";
import { getReferralAction } from "../../../features/matching-bonus-plan/slices/getReferralsAction";
import {
  ajaxSuccessAlert,
  noConfirmAjaxWarningAlert,
  numberFormatter,
  useInterval,
} from "../../../features/utils";
import { ICONS } from "../../../assets/icons-base64";
import { useAppSelector, useAppDispatch } from "../../../store";
import { IMatchingTreeDataModel } from "../../../features/interfaces";
import { getMatchingById } from "../../../features/matching-bonus-plan/slices/getMatchingById";
import { currencyFormat } from "../../../features/utils";
const TreeConfigAndView: FC<{
  width: number;
  height: number;
  directReferrals: (
    | {
      name: string;
      totalInvestments: number;
      matchingStatus: string;
      id: string;
      parentId: null;
      team?: undefined;
    }
    | {
      name: string;
      totalInvestments: number;
      matchingStatus: string;
      id: string;
      parentId: string;
      team: string;
    }
  )[];
  matchingActivated: boolean;
}> = ({ width, height, directReferrals, matchingActivated }) => {
  const chartHeight = width < 600 ? (height / 3) * 2 : height;
  const nodeWidth = width < 600 ? 150 : 300;
  const [eyeToggle, setEyeToggle] = useState(true)

  const teamACreated = true;
  const teamBCreated = true;
  const cycleEndsAtTimestamp = 1500222222;
  const dispatch = useAppDispatch();
  const [timeStamp, setTimeStamp] = useState(
    (cycleEndsAtTimestamp - moment.now()) / 1000
  );

  useInterval(() => {
    if (cycleEndsAtTimestamp > 0) {
      if (timeStamp > 0) setTimeStamp((prev) => prev - 60);
    }
  }, 60000);

  let minutes = Math.floor((timeStamp / 60) % 60);
  let hours = Math.floor((timeStamp / (60 * 60)) % 24);
  let days = Math.floor((timeStamp / (60 * 60 * 24)) % 124);

  const { nickname, referralCode, spotWalletAddress, initialInvestmentAt } =
    useAppSelector((state: any) => state.user.userProfileDataUtils.data);
  const { data: newLevelData, status } = useAppSelector(
    (state) => state.matchingBonusData.getMatchingById
  );
  const { data: oldChartData } = useAppSelector(
    (state) => state.matchingBonusData.treeChart
  );

  const { treeChart } = useAppSelector((state) => state.matchingBonusData);
  let teamName =
    treeChart.data.matchingTeamInfo.map((_) => _.teamName).length === 2;

  let userNickname = nickname,
    userReferralCode = referralCode,
    userWalletAddress = spotWalletAddress,
    userTotalInvestments = 1500;

  const treeContainer = useRef<HTMLDivElement>(null);
  let firstLevel = dataFlattened;
  let secondLevel = secondLevelData;
  const [levelData, setLevelData] = useState(firstLevel);
  const [newdataAdded, setNewdataAdded] = useState(false);

  const [chartState, setChartState] = useState({
    displayChart: matchingActivated,
    displayAddLeftNode: false,
    displayAddRightNode: false,
  });
  useEffect(() => {
    setChartState({ ...chartState, displayChart: matchingActivated });
  }, [matchingActivated]);

  const normalizeData = useCallback(
    (rawData: IMatchingTreeDataModel[] = []) => {
      let temp: IMatchingTreeDataModel[] = [
        {
          children: [],
          nickname: userNickname,
          referralCode: userReferralCode,
          referredBy: "",
          referredDate: "",
          referredTimestamp: 0,
          userWalletAddress: "0xDB10B7963EeA1a33fd7D1804FBE7Bb2e9F686a86",
          totalInvestments: userTotalInvestments,
          team: "root",
          activated: true,
          activationData: "2023-01-15T07:24:03.146Z",
          activationTimestamp: 2023,
        },
        ...rawData,
      ];

      const normalizedData = arrayToTree(temp, {
        parentId: "referredBy",
        dataField: null,
        id: "referralCode",
      })[0];

      let testTemp = d3
        .hierarchy(normalizedData)
        .descendants()
        .map((d, i) =>
          Object.assign(d, { id: d.data.referralCode, team: d.data.team })
        )
        .map((d) =>
          Object.assign(d.data, {
            id: d.id,
            parentId: d.parent && d.parent.id,
            team: d.team,
          })
        );

      return testTemp;
    },
    [
      userNickname,
      userReferralCode,
      userWalletAddress,
      userTotalInvestments,
      initialInvestmentAt,
    ]
  );
  const eyeClickHandler = () => {
    setEyeToggle(prev => !prev)
    // console.log("eye:" , eyeToggle)
  }


  const renderChart = useCallback(
    (treeHolder: HTMLDivElement | null) => {
      if (treeHolder) {
        // chart blurred
        let data: any;
        if (
          matchingTree === undefined ||
          (matchingTree.team_a?.length === 0 &&
            matchingTree.team_b?.length === 0)
        ) {
          data = levelData.map((d) => {
            const cardWidth = Math.round(Math.random() * 50 + nodeWidth);

            const cardHeight = Math.round(Math.random() * 20 + 130);

            const expanded = false;

            return {
              nodeId: d.id,
              parentNodeId: d.parentId,
              width: cardWidth,
              height: cardHeight,
              borderWidth: 1,
              borderRadius: 15,
              borderColor: {
                red: 15,
                green: 140,
                blue: 121,
                alpha: 1,
              },
              backgroundColor: {
                red: 0,
                green: 81,
                blue: 90,
                alpha: 1,
              },
              template: `<div class="treeCard" >
                      <div>
                        Nickname <span>${d.name}</span>
                      </div>
                      <div>
                        Investment <span>${currencyFormat(12020)}</span>
                        </div>
                      <div>
                        Matching Status <span>${d.name.length > 5 ? "Active" : "Not Active"
                }</span>
                      </div>
                      <div>
                        Total Investors <span>${123}</span>
                      </div>
                 </div>
              </div>`,
              connectorLineColor: {
                red: 11,
                green: 123,
                blue: 108,
                alpha: 1,
              },
              connectorLineWidth: 5,
              dashArray: "",
              expanded: expanded,
            };
          });
        } else {
          // activated
          const brandColor = {
            red: 91,
            green: 159,
            blue: 178,
            alpha: 1,
          };
          const backColor = {
            red: 169,
            green: 65,
            blue: 140,
            alpha: 1,
          };

          let customData: IMatchingTreeDataModel[] = [];
          customData = customData.concat(
            matchingTree.team_a.map((item) => ({
              ...item,
              children: [],
              team: "a",
            }))
          );
          customData = customData.concat(
            matchingTree.team_b.map((item) => ({
              ...item,
              children: [],
              team: "b",
            }))
          );

          let normalizedData = normalizeData(customData);

          // data = normalizedData.map((d) => {
          //   const width = Math.round(Math.random() * 50 + 300);
          //   const height = Math.round(Math.random() * 20 + 130);
          //   const expanded = true;

          data = directReferrals.map((d) => {
            const cardWidth = Math.round(Math.random() * 50 + nodeWidth);
            const cardHeight = Math.round(
              Math.random() * 20 + width < 600 ? 90 : 130
            );
            const expanded = true;

            return {
              nodeId: d.id,
              parentNodeId: d.parentId,
              width: cardWidth,
              height: cardHeight,
              borderWidth: 1,
              borderRadius: 15,
              borderColor:
                (d.team === "a" && brandColor) || (d.team === "b" && backColor),
              backgroundColor:
                (d.team === "a" && brandColor) || (d.team === "b" && backColor),
              template: `
              <div class="treeCard" >
             
                  <div>
                    Nickname <span>${d.name.toUpperCase()}</span>
                  </div>
                  <div>
                  Investment <span>${currencyFormat(+d.totalInvestments)}</span>
                  </div>
                <div>
                  Matching Status <span>${d.matchingStatus}</span>
                </div>
                ${d.team
                  ? `<div>
                      Team <span>${d.team.toUpperCase()}</span>
                    </div>`
                  : ""
                }
                </div>`,
              connectorLineColor: brandColor,
              connectorLineWidth: 3,
              dashArray: "",
              expanded: expanded,
            };
          });
        }

        let chart = new TreeChart(chartHeight, width);

        const showLevel = async (e: any) => {
          const rootId = directReferrals.find(
            (directReferral) => !directReferral.parentId
          )?.id;
          // to avoid calling api when root is clicked
          if (e === rootId) return;

          let res = await dispatch(getMatchingById(+e));
          if (res.meta.requestStatus === "fulfilled") {
            if (typeof res.payload === "string")
              noConfirmAjaxWarningAlert(res.payload);
          }
        };
        chart
          // @ts-ignore
          .container(treeHolder)
          .data(data)
          .svgWidth(width)
          .onNodeClick(showLevel)
          .render();
      }
    },
    [
      height,
      width,
      matchingTree,
      levelData,
      directReferrals,
      dispatch,
      newdataAdded,
    ]
  );

  useEffect(() => {
    const treeHolder = treeContainer?.current;
    if (treeHolder) renderChart(treeHolder);

    // return () => {
    //   d3.select(treeHolder).remove();
    // };
  }, [
    treeContainer,
    renderChart,
    levelData,
    directReferrals,
    dispatch,
    newdataAdded,
  ]);

  return (
    <>
      <AddNodeToTree
        isOpen={chartState.displayAddLeftNode}
        side="left"
        onClose={() => {
          setChartState((prevState) => ({
            ...prevState,
            displayAddLeftNode: false,
          }));
          Swal.close();
        }}
      />
      <AddNodeToTree
        isOpen={chartState.displayAddRightNode}
        side="right"
        onClose={() => {
          setChartState((prevState) => ({
            ...prevState,
            displayAddRightNode: false,
          }));
          Swal.close();
        }}
      />

      <div
        className="treeConfigAndView"
        style={{
          height: chartHeight,
          width: width,
        }}
      >
        {status === "pending" ? <Loading /> : null}
        {!chartState.displayChart ? (
          <div className="treeConfigAndView__overlay" />
        ) : null}

        {/* <div className="treeConfigAndView__config">
          <Button
            disabled={
              treeChart.data.matchingTeamInfo.length === 2 ? true : false
              // : treeChart.data.map((_) => _.teamName).length === 2 && true
            }
            onClick={(event: MouseEvent<HTMLButtonElement>) => {
              setChartState((prevState) => ({
                ...prevState,
                displayAddLeftNode: true,
              }));
              dispatch(getReferralAction());
            }}
          >
            Add Team A
            <img
              src={`data:${ICONS.leftNode.mimeType};base64, ${ICONS.leftNode.imageData}`}
              alt="posterity"
            />
          </Button>
          <Button
            disabled={
              treeChart.data.matchingTeamInfo.length >= 2 ? false : true
            }
            onClick={(event: MouseEvent<HTMLButtonElement>) => {
              setChartState((prevState) => ({
                ...prevState,
                displayAddRightNode: true,
              }));
              dispatch(getReferralAction());
            }}
          >
            <img
              src={`data:${ICONS.rightNode.mimeType};base64, ${ICONS.rightNode.imageData}`}
              alt="posterity"
            />
            Add Team B
          </Button>
        </div> */}





        <div className="treeConfigAndView__config">
          <Button
            disabled={
              treeChart.data.matchingTeamInfo.length === 2 ? true : false
              // : treeChart.data.map((_) => _.teamName).length === 2 && true
            }
            onClick={(event: MouseEvent<HTMLButtonElement>) => {
              setChartState((prevState) => ({
                ...prevState,
                displayAddLeftNode: true,
              }));
              dispatch(getReferralAction());
            }}
          >
            Add Team A
            <img
              src={`data:${ICONS.leftNode.mimeType};base64, ${ICONS.leftNode.imageData}`}
              alt="posterity"
            />
          </Button>
          <Button
            disabled={
              treeChart.data.matchingTeamInfo.length >= 2 ? false : true
            }
            onClick={(event: MouseEvent<HTMLButtonElement>) => {
              setChartState((prevState) => ({
                ...prevState,
                displayAddRightNode: true,
              }));
              dispatch(getReferralAction());
            }}
          >
            <img
              src={`data:${ICONS.rightNode.mimeType};base64, ${ICONS.rightNode.imageData}`}
              alt="posterity"
            />
            Add Team B
          </Button>
        </div>


        <div className="absolute top-1 right-6 w-8 cursor-pointer z-30" onClick={eyeClickHandler} >
          {
            eyeToggle ? <CloseEye /> : <EyeIcon />
          }
        </div>




        <div
          className="treeChart"
          style={{
            height: chartHeight,
            width: width,
          }}
          ref={treeContainer}
        />


        <div className={`${eyeToggle && "eye-icon-blur absolute w-full h-full inset-0 z-20 "}`}> 
        </div>

      </div>
    </>
  );
};

export default TreeConfigAndView;
