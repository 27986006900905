export const sassBreakpoints= {mobileSmall: 320 ,
mobile: 375 ,
mobileBigger: 414 ,
tabletSmaller: 680 ,
tablet: 768 ,
tabletBigger: 834 ,
desktop: 980 ,
wide: 1024 ,
wideBigger: 1280 ,
laptopHd: 1366 ,
laptopHdBigger: 1440 ,
hd: 1600 ,
fullHd: 1920 } 