import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Cookie from "js-cookie";

import { IValidationErrors } from "../../interfaces";
import {
  ISignInData,
  ISignInResposeModel,
} from "../../interfaces/auth/ISignInData";
export const signInAction = createAsyncThunk<
  | {
      signInData: ISignInResposeModel;
      token: string;
      statusCode: number;
      hasInvestedAt: string | null;
    }
  | any,
  ISignInData,
  {
    rejectValue: IValidationErrors;
  }
>("user/signin", async (credential, { rejectWithValue }) => {
  try {
    return await axios
      .post(`${process.env.REACT_APP_API_ROOT_NEW}/auth/sign-in`, credential)
      .then((res) => {
        const token = res.data.data.token;
        Cookie.set("token", token, { expires: 1 });
        if (credential.rememberCredentials) {
          Cookie.set("token", token, { expires: 30 });
        }
        return {
          signInData: res.data["signinData"],
          statusCode: res.data["statusCode"],
          token: res.data.data["token"],
          hasInvestedAt: res.data.data["initialInvestmentAt"],
        };
      })
      .catch((error) => {
        return rejectWithValue({
          message: error.response.data.message,
          name: "user/signin",
          code: error.response.data.status,
        });
      });
  } catch (error: any) {
    console.error("signIn error", error); 
  }
});
