import React from "react";

function Reports() {
  return (
    <div>
      <div className="guide-title">Reports</div>
      <p className="deposit-paragraph">
        To have a full history of all your account activities, we did our best
        to provide you with detailed information on every single transaction
        that occurred on your account in three major categories: 
        <p id="report-type">Transactions ,Earnings, Withdrawals</p>
      </p>
      <p className="deposit-paragraph">
        Transactions are the actions you take on our platform, such as deposits,
        investment. You can see the date, time, amount, and status of each
        transaction.
      </p>
      <p className="deposit-paragraph">
        Earnings are the income you generate from your investments or affiliate
        activities on our platform. You can see the total amount of earnings you
        have accumulated over time.
      </p>
      <p className="deposit-paragraph">
        Withdrawals are the transfers of funds from your account to your private
        wallet address or your exchange account. You can see the date, time,
        amount, and status of each withdrawal, as well as the fees and exchange
        rates applied.
      </p>
    </div>
  );
}

export default Reports;
