import React from 'react';
import classNames from 'classnames';
import { truncate } from 'lodash';

import { UpArrowAngleIcon } from '../../../assets/icons';
import { numberFormatter } from '../../../features/utils';
import { AreaChart } from '../charts/AreaChart';

interface IPriceWatcherCard {
  name: string;
  symbol: string;
  price: number;
  changes: number;
  icon: string;
}

export function PriceWatcherCard(props: IPriceWatcherCard) {
  const { name, symbol, price, changes, icon } = props;

  return (
    <div className='priceWatcherCard'>
      <div>
        <div>
          <img src={icon} alt={symbol} />
          <h3>
            {truncate(name, { length: 20 })}
            <span> {truncate(symbol, { length: 20 })}</span>
          </h3>
        </div>

        <AreaChart width={150} height={60} />
      </div>

      <div>
        <div>
          <span>${numberFormatter(price)}</span>
          <span className={classNames({ priceDown: Math.random() > 0.5 })}>
            <UpArrowAngleIcon />
            {changes.toFixed(4)}%
          </span>
        </div>
        <div>
          <span>Price</span>
          <span>Price Change (24h)</span>
        </div>
      </div>
    </div>
  );
}
