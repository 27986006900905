import { memo, useCallback, useEffect, useState } from "react";
import { Redirect, Route, RouteComponentProps, Switch, useHistory } from "react-router-dom";
import { Loading } from "../components/common-components";
import AffiliateBinaryPlan from "../components/user-panel/affiliate-matching-bonus-plan";
import AffiliateMultiLevelPlan from "../components/user-panel/affiliate-multi-level-plan";
import Dashboard from "../components/user-panel/dashboard";
import Deposit from "../components/user-panel/deposit";
import Maintenance from "../components/user-panel/maintenance/Maintenance";
import { NotificationsCenter } from "../components/user-panel/notifications-center";
import Portfolio from "../components/user-panel/portfolio";
import Reports from "../components/user-panel/reports";
import PanelSettings from "../components/user-panel/settings";
import Withdraw from "../components/user-panel/withdraw";
import { userProfileAction } from "../features/authentication";
import { MaintenanceRoute } from "../routes/MaintenanceRoute";
import { useAppDispatch, useAppSelector } from "../store";
import { userProfileUtilsAction } from "../features/authentication/slices/userProfileUtilsAction";
import Cookie from "js-cookie";


interface Props extends RouteComponentProps {}

function PanelWrapper(props: Props) {
  const { url } = props.match;
  // const [status , setStatus] = useState(false)
  const dispatch = useAppDispatch();

  const { dataFetched } = useAppSelector((state) => state.user);
  const { triedToTransferFund } = useAppSelector((state) => state.investment);
  const { initialInvestmentAt, isMatchingEnabled } = useAppSelector(
    (state) => state.user.userProfileDataUtils.data
  );

  const fetchData = useCallback( () => {
    if (!dataFetched) {
      dispatch(userProfileUtilsAction())
    }
  }, [dataFetched]);

  useEffect(() => {
    if (!dataFetched) {
      fetchData();
    }
  }, [dataFetched]);


  // useEffect(() => {
  //   initialInvestmentAt ?  history.push("/panel")
  //   : history.push("/panel/deposit")
  // }, [initialInvestmentAt]);


  const routeCreator = () => {
    // debugger
  return (
      <>
        { initialInvestmentAt ? (
          <Switch>
            {isMatchingEnabled && (
                <Route
                  exact={true}
                  path={`${url}/affiliate-matching-bonus-plan`}
                  component={AffiliateBinaryPlan}
                />
            )}
            <Route
              exact={true}
              path={`${url}/maintenance`}
              component={Maintenance}
            />
            <Route exact={true} path={`${url}`} component={Dashboard} />
            <Route
              exact={true}
              path={`${url}/portfolio`}
              component={Portfolio}
            />
            <Route
              exact={true}
              path={`${url}/affiliate-multi-level-plan`}
              component={AffiliateMultiLevelPlan}
            />
            
            <Route path={`${url}/reports`} component={Reports} />
            <Route exact={true} path={`${url}/deposit`} component={Deposit} />
            <Route exact={true} path={`${url}/withdraw`} component={Withdraw} />
            <Route
              exact={true}
              path={`${url}/settings`}
              component={PanelSettings}
            />

            <Route
              exact={true}
              path={`${url}/notification-center`}
              component={NotificationsCenter}
            />

            

            <Redirect from={`${url}/*`} to="/panel" />

          </Switch>
        ) : (
          <Switch>
            {triedToTransferFund && (
              <Route path={`${url}/reports`} component={Reports} />
            )}

            <Route exact={true} path={`${url}/deposit`} component={Deposit} />

            <Route
              exact={true}
              path={`${url}/settings`}
              component={PanelSettings}
            />

            <Route
              exact={true}
              path={`${url}/notification-center`}
              component={NotificationsCenter}
            />

            {/* should change it to go under hasinvest done condition  */}

            <Redirect from={`${url}/*`} to={`${url}/deposit`} />

          </Switch>
        )}
      </>
    );
  };

  
  return dataFetched ? <>{routeCreator()}</> : <Loading />;
}
export default memo(PanelWrapper);
