import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ITransactionReports, IValidationErrors } from "../../interfaces";

export const getTransactionAction = createAsyncThunk<
  ITransactionReports[],
  undefined,
  {
    rejectValue: IValidationErrors;
  }
>("user/report/get-transaction", async (_, thunkAPI) => {
  try {
    return await axios
      .get(`${process.env.REACT_APP_API_ROOT_NEW}/report/transaction`)
      .then((res) => res.data.data )
      .catch((error)=>{
       return thunkAPI.rejectWithValue({
          message: error.response.data.message,
          code: error.response.status,
          name: "user/report/get-transaction",
        });
      })
  } catch (error: any) {
   return thunkAPI.rejectWithValue({
      message: error.response.data.message,
      code: error.response.status,
      name: "user/report/get-transaction",
    });
  }
});
