import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { IValidationErrors } from "../../interfaces";
import { IGetWallet } from "../../interfaces/investments/investments";
export const getWalletAction = createAsyncThunk<
  | {
      getWalletData: IGetWallet[];
    }
  | any,
  undefined,
  {
    rejectValue: IValidationErrors;
  }
>("user/getWallet", async (_, { rejectWithValue }) => {
  try {
    return axios
      .get(`${process.env.REACT_APP_API_ROOT_NEW}/wallet/wallet`)
      .then((response) => {

        return {
          getWalletData: response.data.data,
        };
      });
  } catch (error: any) {

    let message = "";
    // console.error(error);
    return rejectWithValue({
      message: message,
      code: error.response.status,
      name: "user/getWallet",
    });
  }
});
