import classNames from "classnames";
import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";

import { changePasswordAction } from "../../../../features/authentication";
import {
  ajaxErrorAlert,
  ajaxSuccessAlert,
  logout,
  passwordStrongChecker,
  redirectTo,
} from "../../../../features/utils";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { Button, CustomModal } from "../../../common-components";

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .required("You need to chose a password.")
    .oneOf(
      [Yup.ref("password_confirm")],
      "Password and confirmation must match"
    )
    .test(
      "password_strong",
      "Your password must contain at least 8 characters and at least one upper case letter and one number.",
      function (value) {
        if (value) {
          if (passwordStrongChecker.test(value)) {
            return true;
          }
        }

        return false;
      }
    ),
  password_confirm: Yup.string()
    .required("Please re-enter your password.")
    .oneOf([Yup.ref("password")], "Please re-enter to match your password."),
});

interface IPasswordFormProps {
  isOpen: boolean;
  onClose(): void;
}

export default function PasswordForm(props: IPasswordFormProps) {
  const { isOpen, onClose } = props;
  const [pinCode, setPinCode] = useState<string[]>(["", "", "", "", "", ""]);

  const dispatch = useAppDispatch();
  const { changePasswordStatus } = useAppSelector((state) => state.user);
  const { email } = useAppSelector((state) => state.user.userData.data);

  const setPinValue = (
    event: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    if (
      (event.keyCode >= 48 && event.keyCode <= 57) ||
      (event.keyCode >= 96 && event.keyCode <= 105) ||
      event.keyCode === 8
    ) {
      let newPinCode = [...pinCode];
      newPinCode[index] = event.key.toString();

      if (index < 6) {
        document.getElementById(`PIN_${index + 1}`)?.focus();
      }

      if (index === 6) {
        document.getElementById("password")?.focus();
      }

      setPinCode((prevState) => newPinCode);
    }
    if (event.keyCode === 8) {
      let newPinCode = [...pinCode];
      newPinCode[index] = event.key.toString();
      if (index <= 6) {
        document.getElementById(`PIN_${index - 1}`)?.focus();
      }
    }
  };

  const pastFromClipboard = (event: ClipboardEvent) => {
    if (event.clipboardData?.getData("text").length === 6) {
      let code = event.clipboardData.getData("text").toString();

      for (let index = 0; index < 6; index++) {
        if (code.charCodeAt(index) < 47 && code.charCodeAt(index) > 57) {
          ajaxErrorAlert(
            "Please paste a valid five digit code to verify your email"
          );

          return;
        }
      }

      setPinCode((prevState) => event.clipboardData!.getData("text").split(""));
    }
  };

  useEffect(() => {
    document.getElementById("PIN_0")?.focus();
    document.addEventListener("paste", pastFromClipboard);
  }, []);

  useEffect(() => {}, []);

  return (
    <CustomModal isOpen={isOpen} onClose={onClose} className ="Modal">
      <Formik
        validationSchema={validationSchema}
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={{ password: "", password_confirm: "", field: "" }}
        onSubmit={async (values, actions) => {
          const data = {
            otpCode: pinCode.join(""),
            newPassword: values.password,
            // email: email,
            // userWalletAddress: '',
            // captcha: 'just a string',
            // recoveryPin: pinCode.join(''),
            // password: values.password,
          };

          await dispatch(changePasswordAction(data)).then((res) => {
            const alertOnClose = () => {
              props.onClose();
              logout();
              redirectTo("/auth");
            };
            res.meta.requestStatus === "fulfilled" &&
              ajaxSuccessAlert(
                "Your password has changed successfully. You need to sign in again.",
                "Changed!",
                { onClose: alertOnClose }
              );
          });

          actions.setSubmitting(true);
        }}
      >
        {({
          values,
          handleReset,
          handleSubmit,
          isSubmitting,
          handleBlur,
          handleChange,
          errors,
        }) => (
          <Form
            onReset={handleReset}
            onSubmit={handleSubmit}
            className="panelChangePasswordForm"
          >
            <h1>Setting New Password</h1>

            <div>
              <div>
                <label htmlFor="password">New Password:</label>
                <Field
                  id="password"
                  name="password"
                  type="password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="******************"
                  value={values.password}
                />
                {errors.password && (
                  <p className="formError">{errors.password}</p>
                )}
              </div>

              <div>
                <label htmlFor="password_confirm">Confirm New Password:</label>
                <Field
                  id="password_confirm"
                  name="password_confirm"
                  type="password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="******************"
                  value={values.password_confirm}
                />
                {errors.password_confirm && (
                  <p className="formError">{errors.password_confirm}</p>
                )}
              </div>

              <div>
                <label>Verification Code:</label>
                <div>
                  {pinCode.map((code: string, index: number) => (
                    <Field
                      key={`PIN_${index}`}
                      id={`PIN_${index}`}
                      value={code}
                      min={0}
                      max={9}
                      maxLength={1}
                      onKeyDown={(
                        event: React.KeyboardEvent<HTMLInputElement>
                      ) => {
                        setPinValue(event, index);
                      }}
                      onBlur={handleBlur}
                      type="number"
                      className={classNames({ inputError: errors.field })}
                    />
                  ))}
                </div>
              </div>
            </div>

            <div>
              <h3>Password Hints:</h3>
              <ul>
                <li>At least 8 characters--the more characters, the better.</li>
                <li>A mixture of both uppercase and lowercase letters.</li>
                <li>A mixture of letters and numbers.</li>
                <li>Maximum password length is 25 characters.</li>
              </ul>
            </div>

            <Button
              type="submit"
              disabled={changePasswordStatus === "pending"}
              loading={changePasswordStatus === "pending" ? true : undefined}
            >
              Change My Password
            </Button>
          </Form>
        )}
      </Formik>
    </CustomModal>
  );
}
