import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { IValidationErrors } from "../../interfaces";
import {
  IPostWithdraw,
  IWithdrawData,
} from "../../interfaces/withdraw/withdraw-interfaces";
export const submitWithdrawAction = createAsyncThunk<
  IPostWithdraw,
  IWithdrawData,
  {
    rejectValue: IValidationErrors;
  }
>("user/submitwithdraw", async (withdrawData, thunkAPI) => {
  try {
    return await axios
      .post(
        `${process.env.REACT_APP_API_ROOT_NEW}/withdraw/withdraw`,
        withdrawData
      )
      .then((res) => {
        const messageInjectedRes = {
          ...res.data.data,
          withdrawStatus: res.data.data.status,
          message: res.data.message,
        };
        return messageInjectedRes;
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue({
          message: error.response.data.message,
          code: error.response.status,
          name: "user/submitwithdraw",
        });
      });
  } catch (error: any) {
    return thunkAPI.rejectWithValue({
      message: error.response.data.message,
      code: error.response.status,
      name: "user/submitwithdraw",
    });
  }
});
