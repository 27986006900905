import { createListenerMiddleware } from "@reduxjs/toolkit";
import Swal from "sweetalert2";
import { showLoading } from "./utils";
export const apiPendingMiddleware = createListenerMiddleware();

apiPendingMiddleware.startListening({
  predicate: (action) => {
    if (/pending|rejected|fulfilled/i.test(action.type)) return true;
    return false;
  },
  effect: async (action, listenerApi) => {
    if (action.type.toLowerCase().includes("pending")) {
      showLoading();
    }

    if (/fulfilled/i.test(action.type)) {
      Swal.close();
    }
  },
});
