import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  ICreateDepositResponseModel,
  IValidationErrors,
} from "../../interfaces";
import { IResponseModel } from "../../interfaces/IResponseModel";
import { ICreateDeposit } from "../../interfaces/investments/investments";

export const createDepositAction = createAsyncThunk<
  ICreateDepositResponseModel,
  ICreateDeposit,
  {
    rejectValue: IValidationErrors;
  }
>("user/create-deposit", async (wallettsAndNetwork, { rejectWithValue }) => {
  try {
    return await axios
      .post(
        `${process.env.REACT_APP_API_ROOT_NEW}/crypto/deposit`,
        wallettsAndNetwork
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue({
          message: error.response.data.message,
          name: "user/create-deposit",
          code: error.response.data.status,
        });
      });
  } catch (error: any) {
    rejectWithValue({
      message: error.response.data.message,
      name: "user/create-deposit",
      code: error.response.data.status,
    });
  }
});
