import { Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import Select from "react-select";
import { Arrow, RightArrow2Icon } from "../../assets/icons";
import { IPaginationProps, PageSizeType } from "../../features/interfaces";
import { ajaxErrorAlert } from "../../features/utils";
import * as Yup from "yup";
import classNames from "classnames";

function Pagination({
  pageIndex,
  totalPages,
  setPageIndex,
  setPageSize,
}: IPaginationProps) {
  const [selectedValue, setSelectedValue] = useState<{
    label: string;
    value: PageSizeType;
  }>({ label: "10", value: 10 });
  // let pageIndex: number, totalPages: number, pageSize;
  // pageIndex = totalPages = pageSize = 1;
  // pageIndex = 1;
  // totalPages = 3;

  const pageSizeOptions: {
    label: string;
    value: PageSizeType;
  }[] = [
    { label: "5", value: 5 },
    { label: "10", value: 10 },
    { label: "20", value: 20 },
    { label: "30", value: 30 },
    { label: "40", value: 40 },
    { label: "50", value: 50 },
  ];
  const selectStyle = {
    control: (provided: any, state: any) => {
      return {
        ...provided,
        borderColor: "rgb(229 231 235)",
        backgroundColor: "#F9FAFB",
        minHeight: "27px",
        height: "27px",
      };
    },
    valueContainer: (provided: any) => {
      return {
        ...provided,
        height: "27px",
        padding: "0 6px",
      };
    },
    singleValue: (provided: any) => {
      return {
        ...provided,
        top: "40%",
      };
    },
    menu: (provided: any, state: any) => {
      return {
        ...provided,

        zIndex: 100,
      };
    },
    dropdownIndicator: (provided: any, state: any) => {
      return {
        ...provided,
        paddingTop: "2px",
      };
    },
    indicatorSeparator: (provided: any, state: any) => { 

      return {
        ...provided,
        marginTop: "4px",
      };
    },
  };
  const prevPageFunc = () => {
    setPageIndex(pageIndex - 1);
  };
  const nextPageFunc = () => {
    setPageIndex(pageIndex + 1);
  };
  const goThirdPage = () => {
    setPageIndex(pageIndex + 2);
  };
  useEffect(() => {
    if (pageIndex !== 0 && pageIndex >= totalPages)
      setPageIndex(totalPages);
  }, [selectedValue, pageIndex]);

  const pageSquares = (
    <>
      {totalPages > 1 && pageIndex !== 0 && (
        <button
          onClick={prevPageFunc}
          className="bg-white text-gray-900 border-2 rounded w-8 h-8 text-center hover:border-brand hover:text-brand cursor-pointer"
        >
          {pageIndex}
        </button>
      )}
      <span className="bg-white text-gray-900 border-2 rounded w-11 h-8 text-center  mx-1 border-brand hover:text-brand hover:translate-x-0 cursor-default">
        {pageIndex + 1}
      </span>
      {totalPages > pageIndex + 1 && (
        <button
          onClick={nextPageFunc}
          className="bg-white text-gray-900 border-2 rounded w-8 h-8 text-center hover:border-brand hover:text-brand cursor-pointer"
        >
          {pageIndex + 2}
        </button>
      )}
      {totalPages > 2 && pageIndex === 0 && (
        <button
          onClick={goThirdPage}
          className="bg-white text-gray-900 border-2 rounded w-8 h-8 text-center hover:border-brand hover:text-brand cursor-pointer"
        >
          {pageIndex + 3}
        </button>
      )}
    </>
  );

  const validationSchema = Yup.object().shape({
    goToPage: Yup.number()
      .min(1, `Least page is 1.`)
      .max(totalPages, `There are only ${totalPages} pages!`),
  });
  return (
    <div className="paginationWrapper">
      <div className="left-item-wrapper">
        <div className="page-item">
          <span className="mr-2">Page </span>
          <strong>
            {pageIndex + 1} of {totalPages}
          </strong>
        </div>

        <div className="flex items-center">
          <span className="inline-block mr-2 ">show: </span>
          <Select
            className="inline-block w-24 pt-px mr-4"
            name="pageSize"
            menuPlacement="top"
            options={pageSizeOptions}
            defaultValue={pageSizeOptions[0]}
            value={selectedValue}
            styles={selectStyle}
            onChange={(value) => {
              if (value) {
                setSelectedValue(value);
                setPageSize(value.value);
              }
            }}
          />
        </div>
        <div className="flex items-center ">
          <span className="min-w-fit">Go to page: </span>

          <Formik
            initialValues={{ goToPage: pageIndex + 1 }}
            onSubmit={(values) => {
              validationSchema
                .validate(values)
                .then(() => {
                  return setPageIndex(values.goToPage - 1);
                })
                .catch(({ errors }) => {
                  ajaxErrorAlert(errors[0]);
                });
            }}
          >
            <Form className="flex flex-row flex-nowrap ">
              <Field
                className="page-input mr-2"
                type="number"
                id="goToPage"
                name="goToPage"
              />

              <button type="submit">
                <Arrow className="w-4 h-4" />
              </button>
            </Form>
          </Formik>
        </div>
      </div>

      <div className="flex items-center justify-between flex-wrap gap-y-2.5 ">
        <div className="btnsWrapper">
          <button
            onClick={prevPageFunc}
            disabled={pageIndex === 0}
            className={classNames("mr-2", { buttonDisabled: pageIndex === 0 })}
          >
            <RightArrow2Icon />
          </button>
          {pageSquares}
          <button
            onClick={nextPageFunc}
            disabled={pageIndex + 1 === totalPages}
            className={classNames("ml-2", {
              buttonDisabled: pageIndex + 1 === totalPages,
            })}
          >
            <RightArrow2Icon className="w-4 h-4" />
          </button>
        </div>
      </div>
    </div>
  );
}
export default Pagination;
