import React from "react";

function MultiLevel() {
  return (
    <div>
      <div className="guide-title">Affiliate Multi Level Plan</div>
      <div>
        This page has a lot of information about Multi Level Plan. You might
        feel overwhelmed at first. But don't worry, this help section will
        explain everything you need to know. Just read it carefully and you'll
        understand how Multi Level Plan works.
        <div className="dashboard-sections">
          <div className="dashboard-sections-header">The Diagram Chart</div>
          <p>
            The <p id="bold">diagram chart</p> displays your affiliates in a visual way. You can
            see your downline network level by level. Each affiliate has a
            nickname that they chose to protect their privacy. You can click on
            any affiliate to see their downlines, if they have any.
          </p>
        </div>
        <div className="dashboard-sections">
          <div className="dashboard-sections-header">The Summary Chart</div>
          <p>
            The <p id="bold">Summary chart</p> gives you a detailed overview of your Bonus
            percentage, current month new investments from your downline clients
            on each level, new investors, earnings from each referral level, and
            projected income for the current payment cycle. To get paid from a
            referral level, you must meet some conditions. Otherwise, you won't
            see any data for that level. The conditions are explained at the end
            of this help section.
          </p>
        </div>
        <div className="dashboard-sections">
          <div className="dashboard-sections-header">The Earnings Chart</div>
          <p>
            The <p id="bold">Earnings chart</p> shows you how much money you have made from the
            Affiliate Multi Level Plan every month for a year. It is a useful
            tool to track your income and see how it changes over time. These
            are the terms and conditions of the Affiliate Multi-Level plan. By
            participating in this plan, you agree to learn and follow them:
          </p>
          <ol className="guide-lists">
            <li className="list-item">
              <div className="deposit-paragraph">
                1. You earn a <p id="percent">4%</p> referral commission on your direct referral
                investment amounts. This means that whenever someone you refer
                invests in the platform, you get <p id="percent">4%</p> of their investment as a
                bonus.
              </div>
            </li>

            <li className="list-item">
              <div className="deposit-paragraph">
                2. You'll earn a <p id="percent">1%</p> referral commission on your second referral
                level. You should know that you need to have two active direct
                investors to receive level two referral commissions.
              </div>
            </li>

            <li className="list-item">
              <div className="deposit-paragraph">
                3. You'll earn a <p id="percent">0.5%</p> referral commission on your third referral
                level on the condition that you have referred three active
                direct investors, and your minimum portfolio amount is not less
                than 300 USD. This means that you need to have three referrals
                who have invested in the platform, and you also need to invest
                at least 300 USD yourself to qualify for this level.
              </div>
            </li>

            <li className="list-item">
              <div className="deposit-paragraph">
                4. You'll earn a <p id="percent">0.5%</p> referral commission on your fourth
                referral level on the condition that you have referred four
                active direct investors, and your minimum portfolio amount is
                not less than 400 USD. This means that you need to have four
                referrals who have invested in the platform, and you also need
                to invest at least 400 USD yourself to qualify for this level.
              </div>
            </li>

            <li className="list-item">
              <div className="deposit-paragraph">
                5. You'll earn a <p id="percent">3%</p> referral commission on your fifth referral
                level on the condition that you have referred five active direct
                investors, and your minimum portfolio amount is not less than
                1,000 USD. This means that you need to have five direct
                referrals and you need to invest at least 1,000 USD yourself to
                qualify for this level. This is a <p id="bold">significant jump</p> in the {" "}
                 <p id="bold">commission rate</p> from the previous levels, so make sure you don't
                miss this opportunity.
              </div>
            </li>
            <li className="list-item">
              <div className="deposit-paragraph">
                6. You'll earn a <p id="percent">0.5%</p> referral commission on your sixth referral
                level on the condition that you have referred six active direct
                investors, and your minimum portfolio amount is not less than
                1,200 USD. This means that you need to have one more referral
                than the previous level, and you also need to invest at least
                1,200 USD yourself to qualify for this level.
              </div>
            </li>
            <li className="list-item">
              <div className="deposit-paragraph">
                7. You'll earn a <p id="percent">0.5%</p> referral commission on your seventh
                referral level on the condition that you have referred seven
                active direct investors, and your minimum portfolio amount is
                not less than 1,400 USD. This means that you need to have one
                more referral than the previous level, and you also need to
                invest at least 1,400 USD yourself to qualify for this level.
              </div>
            </li>
            <li className="list-item">
              <div className="deposit-paragraph">
                8. You'll earn a <p id="percent">0.5%</p> referral commission on your eighth
                referral level on the condition that you have referred eight
                active direct investors, and your minimum portfolio amount is
                not less than 1,800 USD. This means that you need to have one
                more referral than the previous level, and you also need to
                invest at least 1,800 USD yourself to qualify for this level.
              </div>
            </li>
            <li className="list-item">
              <div className="deposit-paragraph">
                9. You'll earn a <p id="percent">0.5%</p> referral commission on your ninth referral
                level on the condition that you have referred nine active direct
                investors, and your minimum portfolio amount is not less than
                2,000 USD. This means that you need to have one more referral
                than the previous level, and you also need to invest at least
                2,000 USD yourself to qualify for this level.
              </div>
            </li>
            <li className="list-item">
              <div className="deposit-paragraph">
                10. You'll earn a <p id="percent">1%</p> referral commission on your tenth referral
                level on the condition that you have referred ten active direct
                investors, and your minimum portfolio amount is not less than
                4,000 USD. This means that you need to have ten direct referrals
                and you need to invest at least 4,000 USD yourself to qualify
                for this level. This is a <p id="bold">significant jump</p> in the {" "}
               <p id="bold">commission rate</p> from the previous levels, so make sure you don't miss this
                opportunity.
              </div>
            </li>
            <li className="list-item">
              <div className="deposit-paragraph">
                11. You'll earn a <p id="percent">0.25%</p> referral commission on your eleventh
                referral level on the condition that you have referred eleven
                active direct investors, and your minimum portfolio amount is
                not less than 4,500 USD. This means that you need to have one
                more referral than the previous level, and you also need to
                invest at least 4,500 USD yourself to qualify for this level.
              </div>
            </li>
            <li className="list-item">
              <div className="deposit-paragraph">
                12. You'll earn a <p id="percent">0.25%</p> referral commission on your twelfth
                referral level on the condition that you have referred twelve
                active direct investors, and your minimum portfolio amount is
                not less than 5,000 USD. This means that you need to have one
                more referral than the previous level, and you also need to
                invest at least 5,000 USD yourself to qualify for this level.
              </div>
            </li>
            <li className="list-item">
              <div className="deposit-paragraph">
                13. You'll earn a <p id="percent">0.25%</p> referral commission on your thirteenth
                referral level on the condition that you have referred thirteen
                active direct investors, and your minimum portfolio amount is
                not less than 5,500 USD. This means that you need to have one
                more referral than the previous level, and you also need to
                invest at least 5,500 USD yourself to qualify for this level.
              </div>
            </li>
            <li className="list-item">
              <div className="deposit-paragraph">
                14. You'll earn a <p id="percent">0.25%</p> referral commission on your fourteenth
                referral level on the condition that you have referred fourteen
                active direct investors, and your minimum portfolio amount is
                not less than 6,000 USD. This means that you need to have one
                more referral than the previous level, and you also need to
                invest at least 6,000 USD yourself to qualify for this level.
              </div>
            </li>
            <li className="list-item">
              <div className="deposit-paragraph">
                15. You'll earn an eye popping <p id="percent">1%</p> referral commission on your
                fifteenth referral level on the condition that you have referred
                fifteen active direct investors, and your minimum portfolio
                amount is not less than 10,000 USD. This means that you need to
                have fifteen direct referrals and you need to invest at least
                10,000 USD yourself to qualify for this level. This is a
                significant jump in the commission rate from the previous
                levels, so make sure you don't miss this opportunity.
              </div>
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
}

export default MultiLevel;
