import { lazy, Suspense, useCallback, useEffect } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { getDashboardData } from "../../../features/Dashboard-Portfolio/slices/getDashboardData";
import { useAppDispatch, useAppSelector } from "../../../store";
import { Loading } from "../../common-components";
import { userProfileUtilsAction } from "../../../features/authentication/slices/userProfileUtilsAction";

const Earnings = lazy(() => import("./Earnings"));
const EarningSummary = lazy(() => import("./EarningSummary"));
const Investments = lazy(() => import("./Investments"));
const WalletInputs = lazy(() => import("./WalletInputs"));
const RecentActivities = lazy(() => import("./RecentActivities"));
const NewInvestors = lazy(() => import("./NewInvestors"));
const Profile = lazy(() => import("./Profile"));

interface IDashboardProps extends RouteComponentProps {}

export default function Dashboard(props: IDashboardProps) {
  const { status, dataFetched } = useAppSelector(
    (state) => state.dashboardPortfolio.dashboard
  );
  
  
  const dispatch = useAppDispatch();
  

  const fetchData = useCallback(async () => {
    await dispatch(getDashboardData());
  }, [dispatch]);

  useEffect(() => {
    fetchData();
  }, []);


  useEffect(() =>{
    dispatch(userProfileUtilsAction());
  }, [])


 

  return status === "pending" ? null : (
    <Suspense fallback={<Loading />}>
      <div className="dWrapper">
        <Earnings />
        <div className="dRowWrapper">
          <Investments {...props} />
          <NewInvestors />
          <WalletInputs />
          <EarningSummary />
          <RecentActivities />
          <Profile />
        </div>
      </div>
    </Suspense>
  );
}
