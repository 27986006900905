import React, { useState, useRef, useEffect } from "react"; // , { useState }
import classNames from "classnames";

import {
  AffiliateControlPanelIcon,
  AffiliateMultiLevelIcon,
  AffiliateBinaryIcon,
} from "../../assets/icons";

interface Props {
  buttons: React.ReactNode;
}

export default function HybridAffiliatePlan(props: Props) {
  const [activeSlide, setActiveSlide] = useState(0);

  const contents = [
    <div
      onClick={(event: React.MouseEvent) => setActiveSlide(0)}
      className={classNames("options__option", { active: 0 === activeSlide })}
    >
      {0 === activeSlide ? (
        <>
          <h1>Affiliate Hybrid Plan</h1>
          <blockquote>
            Opportunity is missed by most people because it's dressed in
            overalls and looks like work.
            <span>Thomas Edison</span>
          </blockquote>
          <p>
            If you are looking for an opportunity to earn more than fixed
            monthly returns, consider becoming a POSTERITY AFFILIATE. You can
            help the platform to increase the liquidity of the "Profit Pool",
            which distributes profits among investors. You can enjoy virtually{" "}
            <b>UNLIMITED AFFILIATE COMMISSIONS</b>.
          </p>
        </>
      ) : (
        <div className="option_label">
          <h1 style={{ color: "#19aacf" }}>Affiliate Plan</h1>
          <AffiliateControlPanelIcon fill="#19aacf" />
        </div>
      )}
    </div>,
    <div
      onClick={(event: React.MouseEvent) => setActiveSlide(1)}
      className={classNames("options__option", { active: 1 === activeSlide })}
    >
      {1 === activeSlide ? (
        <>
          <h1>Multi-Level Plan</h1>
          <p>
            A Unique level-based plan that allows you to earn as much as your
            account looks like a phone number. It’s an unseen Multi Level plan
            because of the staggering level 5,10,15 commissions to empower team
            leaders as much as possible. Posterity gives you a vast source of
            income <b>as deep as 15 referral levels</b>. You can earn virtually
            unlimited referral commissions because it is a level-based plan, you
            can introduce as many investors as you want and help them grow their
            business in Posterity and earn enormous referral commissions. Please
            download the Posterity Whitepaper to learn more about Affiliate
            Multi Level Plan.
          </p>
        </>
      ) : (
        <div className="option_label">
          <h1 style={{ color: "#6153a6" }}>Multi-Level Plan</h1>
          <AffiliateMultiLevelIcon fill="#6153a6" />
        </div>
      )}
    </div>,
    <div
      onClick={(event: React.MouseEvent) => setActiveSlide(2)}
      className={classNames("options__option", { active: 2 === activeSlide })}
    >
      {2 === activeSlide ? (
        <>
          <h1>Matching Bonus Plan</h1>
          <p>
            On top of the Affiliate Multi-Level Plan, we designed a tailored
            unrivaled “Affiliate Matching Bonus Plan” that allows you to earn a
            maximum of 2,500 USD daily commission. It is more than <b>75,000 USD
            per month</b>. You can position your referrals in two Teams. You’ll earn
            50 USD every time both teams reach a total of 500 USD investment, as
            a Matching Bonus Plan commission. This is a unique and lucrative
            opportunity for you to increase your income and network with other
            affiliates.
          </p>
        </>
      ) : (
        <div className="option_label">
          <h1 style={{ color: "#003247" }}>Matching Bonus Plan</h1>
          <AffiliateBinaryIcon fill="#003247" />
        </div>
      )}
    </div>,
  ];

  const _canvas = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const canvas = _canvas.current!;
    const context = canvas.getContext("2d")!;
    context.canvas.width = document.body.clientWidth;
    context.canvas.height = document.body.clientHeight;
    context.lineWidth = 6;
    context.strokeStyle = "#ff4040";

    context.beginPath();
    context.moveTo(context.canvas.width / 2, context.canvas.height / 2);
    context.lineTo(
      context.canvas.width / 2,
      context.canvas.height / 2 + context.canvas.height / 3
    );
    context.lineTo(
      context.canvas.width / 13,
      context.canvas.height / 2 + context.canvas.height / 3
    );
    context.lineTo(
      context.canvas.width / 13,
      context.canvas.height - context.canvas.height / 3
    );
    context.lineTo(0, context.canvas.height - context.canvas.height / 3);
    context.stroke();
    context.closePath();
  }, []);

  return (
    <div className="landingWrapper__wrapper lHybridAffiliatePlan">
      <canvas ref={_canvas} />
      <div>
        <div className="options">{[...contents]}</div>
      </div>

      {props.buttons}
    </div>
  );
}
