import React, { useEffect } from "react";
import { RouteComponentProps, Route, Switch } from "react-router-dom";
import Cookie from "js-cookie";

import Login from "./Login";
import Register from "./Register";
import ForgotPassword from "./ForgotPassword";
import AuthWrapper from "./AuthWrapper";
import ChangePassword from "./ChangePassword";
import { useAppSelector } from "../../store";

interface Props extends RouteComponentProps {}

export default function Authentication(props: Props) {
  const { url } = props.match;
  //const { data } = useAppSelector((state) => state.user.userData);
  const { data } = useAppSelector((state) => state.user.signInData);
  useEffect(() => {
    if (Cookie.get("token")?.length) {
      if (data.hasInvestedAt) {
        props.history.push("/panel");
      } else {
        props.history.push("/panel/deposit");
        // props.history.push("/panel");
      }
    }
  }, [props.history, data]);

  return (
    <AuthWrapper>
      <Switch>
        <Route
          path={`${url}/register/:referralCode?`}
          exact={true}
          component={Register}
        />
        <Route
          path={`${url}/forgot-password`}
          exact={true}
          component={ForgotPassword}
        />
        <Route
          path={`${url}/change-password`}
          exact={true}
          component={ChangePassword}
        />
        <Route path={`${url}/`} exact={true} component={Login} />
      </Switch>
    </AuthWrapper>
  );
}
