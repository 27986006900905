import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Cookie from "js-cookie";

import {
  IInvestBound,
  IReferral,
  IUserModel,
  IValidationErrors,
} from "../../interfaces";
import { IRecentActivity } from "../../interfaces/user";

export const fetchUserDataAction = createAsyncThunk<
  | {
      userData: IUserModel;
      token: string;
      investBound: IInvestBound;
      recentActivities: IRecentActivity[];
      referrals: IReferral[];
      totalDownlinked: IReferral[];
    }
  | undefined,
  undefined,
  {
    rejectValue: IValidationErrors;
  }
>("user/fetch-data", async (_, thunkAPI) => {
  try {
    let token = Cookie.get("token");
    if (token?.length) {
      const response = await axios.post(
        `${process.env.REACT_APP_API_ROOT}/get-user-data`,
        {
          token: token,
        }
      );

      return {
        userData: response.data["userData"],
        token: response.data["userData"].token,
        recentActivities: response.data["recentActivities"],
        investBound: response.data["investBound"],
        referrals: response.data["referrals"],
        totalDownlinked: response.data["totalDownlinked"],
      };
    }
  } catch (error: any) {
    return thunkAPI.rejectWithValue({
      message: "No an authorized user",
      code: error.response.status,
      name: "user/fetch-data",
    });
  }
});
