import { FC } from "react";
const CountDown: FC<{ minutes: number; hour: number; days?: number }> = ({
  minutes,
  hour,
  days,
}) => (
  <div className="flex flex-col items-center justify-center w-128">
    <h3 className="text-xl font-bold mb-2">Remaining Time</h3>
    <div className="flex items-center justify-center w-full font-nunito px-8">
      {days ? (
        <>
          <div className="flex flex-col items-center justify-center bg-brand text-white rounded-lg h-20 w-20 sm:h-32 sm:w-32">
            <span className="text-2xl sm:text-4xl font-bold leading-5">
              {days}
            </span>
            <span className="text-lg font-semibold">Days</span>
          </div>

          <svg
            x="0px"
            y="0px"
            width="43.957px"
            height="43.957px"
            viewBox="0 0 43.957 43.957"
            className="transform scale-50"
          >
            <g>
              <path
                fill="rgb(0, 50, 71)"
                d="M32.198,33.736c0,5.637-4.585,10.221-10.219,10.221c-5.635,0-10.219-4.584-10.219-10.221
		c0-5.635,4.584-10.219,10.219-10.219C27.613,23.518,32.198,28.102,32.198,33.736z M21.979,20.439
		c5.634,0,10.219-4.585,10.219-10.22C32.198,4.584,27.613,0,21.979,0c-5.635,0-10.219,4.584-10.219,10.219
		C11.759,15.854,16.344,20.439,21.979,20.439z"
              />
            </g>
          </svg>
        </>
      ) : null}

      <div className="flex flex-col items-center justify-center bg-brand text-white rounded-lg h-20 w-20 sm:h-32 sm:w-32">
        <span className="text-2xl sm:text-4xl font-bold leading-5">
          {hour > 9 ? hour : `0${hour}`}
          {/* {hour} */}
        </span>
        <span className="text-lg font-semibold">Hour</span>
      </div>

      <svg
        x="0px"
        y="0px"
        width="43.957px"
        height="43.957px"
        viewBox="0 0 43.957 43.957"
        className="transform scale-50"
      >
        <g>
          <path
            fill="rgb(0, 50, 71)"
            d="M32.198,33.736c0,5.637-4.585,10.221-10.219,10.221c-5.635,0-10.219-4.584-10.219-10.221
		c0-5.635,4.584-10.219,10.219-10.219C27.613,23.518,32.198,28.102,32.198,33.736z M21.979,20.439
		c5.634,0,10.219-4.585,10.219-10.22C32.198,4.584,27.613,0,21.979,0c-5.635,0-10.219,4.584-10.219,10.219
		C11.759,15.854,16.344,20.439,21.979,20.439z"
          />
        </g>
      </svg>

      <div className="flex flex-col items-center justify-center bg-brand text-white rounded-lg h-20 w-20 sm:h-32 sm:w-32">
        <span className="text-2xl sm:text-4xl font-bold leading-5">
          {minutes > 9 ? minutes : `0${minutes}`}
          {/* {minutes} */}
        </span>
        <span className="text-lg font-semibold">Minutes</span>
      </div>
    </div>
  </div>
);

export default CountDown;
