import { Component } from "react";
import Select, { OptionsType } from "react-select";

interface Props {
  name: string;
  handleChange: (name: string, value: any) => void;
  isMulti: boolean;
  options: OptionsType<{
    label: string;
    value: string;
    key: number;
    disabled: boolean;
  }>;
  defaultOption?: {
    label: string;
    value: string;
    key: number;
    disabled: boolean;
  };
}

interface State {
  selectedOption: OptionsType<{
    label: string;
    value: string;
    key: number;
    disabled: boolean;
  }> | null;
}

export class ReactSelect extends Component<Props, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      selectedOption: null,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(selectedOption: any) {
    this.setState({ selectedOption });
    this.props.handleChange(this.props.name, selectedOption);
  }

  render() {
    const { options } = this.props;

    return (
      <Select
        className="react-select"
        classNamePrefix="react-select"
        name="react-select"
        value={
          this.props.defaultOption
            ? this.props.defaultOption
            : this.state.selectedOption
        }
        onChange={this.handleChange}
        options={options}
        isMulti={this.props.isMulti}
        isOptionDisabled={(option) => option.disabled}
      />
    );
  }
}
