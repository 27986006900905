import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { IInvestsSummaryDataType, IValidationErrors } from "../../interfaces";
import { IInvestDataType } from "../../interfaces/investments/IInvestDataType";
export const getInvestAction = createAsyncThunk<
  | {
      invests: IInvestDataType[];
      summary: IInvestsSummaryDataType;
    }
  | undefined,
  { status?: string; limit: number } | void,
  {
    rejectValue: IValidationErrors;
  }
>("invest/getInvest", async (params, { rejectWithValue }) => {
  try {
    return axios
      .get(`${process.env.REACT_APP_API_ROOT_NEW}/investment/invest`, {
        params: params,
      })
      .then((response) => {
        return {
          invests: response.data.data.invests,
          summary: response.data.data.summary,
        };
      })
      .catch((error) => {
        return rejectWithValue({
          message: error.interceptedMessage,
          code: error.response.status,
          name: "invest/getInvest",
        });
      });
  } catch (error: any) {}
});
