import { Form, Formik } from "formik";
import { memo } from "react";
import { getMatchingBonusData } from "../../../features/matching-bonus-plan/slices/getMatchingBonusData";
import { ajaxSuccessAlert, ajaxWarningAlert } from "../../../features/utils";
import { useAppDispatch, useAppSelector } from "../../../store";
import { Button, CustomModal, ReactSelect } from "../../common-components";

import { setTeamAction } from "../../../features/matching-bonus-plan/slices/setTeamAction";

const AddNodeToTree = ({
  isOpen,
  onClose,
  side,
}: {
  isOpen: boolean;
  onClose(): void;
  side: "left" | "right";
}) => {
  const dispatch = useAppDispatch();
  const { status } = useAppSelector(
    (state: any) => state.matchingBonusData.referrals
  );
  const { getReferrals } = useAppSelector((state) => state.matchingBonusData);

  return (
    <Formik
      initialValues={{ value: { id: "", nickname: "" } }}
      onSubmit={async (values, actions) => {
        actions.setSubmitting(true);

        if (values.value.id) {
          await ajaxWarningAlert(
            undefined,
            undefined,
            `You are placing <b>${values.value.nickname}</b> under your Team  ${
              side === "left" ? "A (Left side)" : "B (Right side)"
            } in your Matching Bonus plan. After confirming this positioning, you will never be able to change it again. Are you sure you want to continue?`
          ).then((res) => {
            if (res.dismiss || res.isDismissed) {
              onClose();
            }
          });

          const dataA = {
            teamName: "a",
            refereeId: Number(values.value.id),
          };

          const dataB = {
            teamName: "b",
            refereeId: Number(values.value.id),
          };

          if (side === "left") {
            await dispatch(setTeamAction(dataA)).then(async (res) => {
              if (res.meta.requestStatus === "fulfilled") {
                await ajaxSuccessAlert(
                  `You've placed ${values.value.nickname} under your Team  ${
                    side === "left" ? "A (Left side)" : "B (Right side)"
                  } in your Matching Bonus plan!`,
                  "Congrats!"
                ).then((res) => {
                  if (res.isConfirmed) {
                    onClose();
                  }
                });
                dispatch(getMatchingBonusData());
              }
            });
          }

          if (side === "right") {
            await dispatch(setTeamAction(dataB)).then(async (res) => {
              if (res.meta.requestStatus === "fulfilled") {
                await ajaxSuccessAlert(
                  `You've placed ${values.value.nickname} under your Team  ${
                    side === "right" ? "B (Right side)" : "A (Left side)"
                  } in your Matching Bonus plan!`,
                  "Congrats!"
                ).then((res) => {
                  if (res.isConfirmed) {
                    onClose();
                  }
                });
                dispatch(getMatchingBonusData());
              }
            });
          }
        }

        actions.setSubmitting(false);
      }}
    >
      {({
        values,
        handleReset,
        setFieldValue,
        handleSubmit,
        handleBlur,
        handleChange,
        errors,
        isSubmitting,
      }) => (
        <CustomModal isOpen={isOpen} onClose={onClose} className ="Modal">
          <div className="addNodeToMatchingTree">
            <h1>
              Please select one of your direct referrals to be placed under your
              Team {side === "left" ? "A (Left side)" : "B (Right side)"} of
              your Matching bonus plan.
            </h1>

            <Form onReset={handleReset} onSubmit={handleSubmit}>
              <ReactSelect
                handleChange={(name, value) => {
                  setFieldValue("value", {
                    nickname: value.label,
                    id: value.value,
                  });
                }}
                isMulti={false}
                name="addLeftNode"
                options={getReferrals.map((option, index) => ({
                  label: option.nickname,
                  value: option.id.toString(),
                  key: index,
                  disabled: option.isActive ? false : true,
                }))}
              />

              <div className="addNodeToMatchingTree__actions">
                <Button
                  onClick={onClose}
                  disabled={isSubmitting || status === "pending"}
                  type="reset"
                >
                  Cancel
                </Button>

                <Button
                  disabled={
                    isSubmitting ||
                    status === "pending" ||
                    values.value.id === null
                  }
                  loading={isSubmitting || status === "pending"}
                  type="submit"
                >
                  Apply Changes
                </Button>
              </div>
            </Form>
          </div>
        </CustomModal>
      )}
    </Formik>
  );
};

export default memo(AddNodeToTree);
