import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { IValidationErrors } from "../../interfaces";
import { ISetTeam, ISetTeamRes } from "../../interfaces/matching-bonus";

export const setTeamAction = createAsyncThunk<
  ISetTeamRes,
  ISetTeam,
  {
    rejectValue: IValidationErrors;
  }
>("user/setTeam", async (data, thunkAPI) => {
  try {
    return await axios
      .post(`${process.env.REACT_APP_API_ROOT_NEW}/matching/matching`, data)
      .then((response) => {
        return response.data.data;
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue({
          message: error.response.message,
          code: error.response.status,
          name: "user/setTeam",
        });
      });
  } catch (error: any) {
    return thunkAPI.rejectWithValue({
      message: error.response.message,
      code: error.response.status,
      name: "user/setTeam",
    });
  }
});
