import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import {
  IValidationErrors,
  IRegisterData,
  IResponseModel,
} from "../../interfaces";

export const registerAction = createAsyncThunk<
  IResponseModel | any,
  IRegisterData,
  {
    rejectValue: IValidationErrors;
  }
>("user/register", async (credential, thunkAPI) => {
  try {
    return await axios.post(`${process.env.REACT_APP_API_ROOT_NEW}/auth/sign-up` , credential)
  } catch (error: any) {
    let message = "";
    return thunkAPI.rejectWithValue({
      message: error.interceptedMessage ? error.interceptedMessage : message,
      code: error.response.status,
      name: "user/register",
    });
  }
});
