import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

//import { GoogleReCaptchaProvider } from "react-google-recaptcha";

import { LogoIcon } from "../../assets/icons";
// import Logo from '../../assets/img/icon.gif';

interface AuthWrapperProps {
  children: React.ReactNode;
}

export default function AuthWrapper(props: AuthWrapperProps) {
  const { children } = props;
  const [path, setPath] = useState("");
  let location = useLocation();

  useEffect(() => {
    setPath(location.pathname);
  }, [location]);

  let loginSideText = (
    <p>
      Don't miss any investment update on your digital capital. Sign in to stay
      updated on your financial world.
    </p>
  );

  let forgotSideText = (
    <p>
      If you don't remember your password, please enter your email address you
      used to sign in to your Posterity account and we will send you a link to
      reset your password.
    </p>
  );

  let registerSideText = (
    <p>
      The only thing worse than starting something and failing...is not starting
      something.
      <br /> Seth Godin
    </p>
  );

  return (
    //  <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}>
    <div className="authenticationWrapper">
      <div>
        <div className="authenticationWrapper_sideBar">
          <p>
            <Link to="/">
              <span className="logoIcon">
                <LogoIcon />
              </span>
              {/* <img src={LogoIcon} alt='as' /> */}
              {/* <LogoIcon /> */}
              <span>Posterity</span>
            </Link>
          </p>
          <p>
            {path === "/auth/forgot-password"
              ? null
              : path === "/auth/register"
              ? "Welcome"
              : "Welcome Back"}
          </p>
          {path === "/auth/forgot-password"
            ? forgotSideText
            : path === "/auth/register"
            ? registerSideText
            : loginSideText}

          <p className="mt-6">Posterity Team</p>
        </div>
        <div className="authenticationWrapper_wrapper">
          <div>{children}</div>
          {/* <OAuth /> */}
        </div>
      </div>
    </div>
    //  </GoogleReCaptchaProvider>
  );
}
