import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { IValidationErrors } from "../../interfaces";
import { IGetReferral } from "../../interfaces/user";

export const getReferralAction = createAsyncThunk<
  IGetReferral[],
  void,
  {
    rejectValue: IValidationErrors;
  }
>("user/referrals", async (_, thunkAPI) => {
  try {
    return await axios
      .get(`${process.env.REACT_APP_API_ROOT_NEW}/user/referee`)
      .then((response) => {
        return response.data.data;
      })
      .catch((error) => {
        return error;
      });
  } catch (error: any) {
    return thunkAPI.rejectWithValue({
      message: error.response.message,
      code: error.response.status,
      name: "user/referrals",
    });
  }
});
