import { useCallback, useEffect } from "react";
import useDimensions from "react-cool-dimensions";
import Investors from "./Investors";
import OverviewCardList from "./OverviewCardList";
import Summary from "./Summary";
import TreeConfigAndView from "./TreeConfigAndView";
import { useAppDispatch, useAppSelector } from "../../../store";
import { Loading } from "../../common-components";
import dataFlattened from "../../common-components/charts/treeData.json";
import { getMatchingBonusData } from "../../../features/matching-bonus-plan/slices/getMatchingBonusData";
import { userProfileUtilsAction } from "../../../features/authentication/slices/userProfileUtilsAction";
export default function AffiliateMatchingBonus() {
  const dispatch = useAppDispatch();

  const { referrals } = useAppSelector((state: any) => state.matchingBonusData);
  const { dataFetched, status, data } = useAppSelector(
    (state) => state.matchingBonusData.treeChart
  );

  const fetchDirectReferrals = useCallback(() => {
    dispatch(getMatchingBonusData());
  }, [dispatch]);

  useEffect(() => {
    if (!dataFetched) fetchDirectReferrals();
  }, [referrals, fetchDirectReferrals]);



  useEffect(() =>{
    dispatch(userProfileUtilsAction());
  }, [])

  let standardData: (
    | {
        name: string;
        totalInvestments: number;
        matchingStatus: string;
        id: string;
        parentId: null;
        team?: undefined;
      }
    | {
        name: string;
        totalInvestments: number;
        matchingStatus: string;
        id: string;
        parentId: string;
        team: string;
      }
  )[] = data.matchingTeamInfo.map((referral) => {
    if (!referral.parentId)
      return {
        name: referral.nickname,
        totalInvestments: referral.totalInvestments,
        matchingStatus: referral.matchingStatus,
        id: "" + referral.id,
        parentId: null,
      };

    return {
      name: referral.nickname,
      totalInvestments: referral.totalInvestments,
      matchingStatus: referral.matchingStatus,
      id: "" + referral.id,
      parentId: "" + referral.parentId,
      team: referral.teamName === "a" ? "a" : "b",
    };
  });

  const { observe, width, height } = useDimensions<HTMLDivElement>({
    onResize: ({ observe, unobserve, width, height, entry }) => {
      unobserve();
      observe();
    },
  });

  return status === "pending" ? (
    <Loading />
  ) : (
    <div className="abpWrapper">
      <OverviewCardList />

      <div className="abpWrapper__rowGrid">
        <TreeConfigAndView
          width={width}
          height={height}
          directReferrals={
            standardData.length >= 2 ? standardData : dataFlattened
            // dataFlattened
          }
          matchingActivated={standardData.length >= 3 ? true : false}
        />

        {/* <OrganizationalChart data={data}/> */}
        <div className="abpWrapper__rowWrapper" ref={observe}>
          <Summary />
          <Investors />
        </div>
      </div>
    </div>
  );
}
