import React, { useRef, useEffect } from "react";

import Sec from "../../assets/img/Security.png";

interface Props {
  buttons: React.ReactNode;
}

export default function Security(props: Props) {
  const _canvas = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const canvas = _canvas.current!;
    const context = canvas.getContext("2d")!;
    context.canvas.width = document.body.clientWidth;
    context.canvas.height = document.body.clientHeight;
    context.lineWidth = 6;
    context.strokeStyle = "#ff4040";

    context.beginPath();
    context.moveTo(context.canvas.width / 2 - context.canvas.width / 5, 0);
    context.lineTo(
      context.canvas.width / 2 - context.canvas.width / 5,
      context.canvas.height / 4
    );
    context.lineTo(
      context.canvas.width / 2 - context.canvas.width / 5,
      context.canvas.height / 4
    );
    context.lineTo(
      context.canvas.width / 4 - context.canvas.width / 5,
      context.canvas.height / 4
    );
    context.lineTo(
      context.canvas.width / 4 - context.canvas.width / 5,
      context.canvas.height / 2
    );
    context.lineTo(context.canvas.width / 2, context.canvas.height / 2);
    context.stroke();
    context.closePath();
    context.beginPath();
    context.moveTo(context.canvas.width / 2, context.canvas.height / 2);
    context.lineTo(
      context.canvas.width / 2,
      context.canvas.height - context.canvas.height / 5
    );
    context.lineTo(
      context.canvas.width / 2 - context.canvas.width / 4,
      context.canvas.height - context.canvas.height / 5
    );
    context.lineTo(
      context.canvas.width / 2 - context.canvas.width / 4,
      context.canvas.height
    );
    context.stroke();
    context.closePath();

    return () => canvas.remove();
  }, [_canvas]);

  return (
    <div className="landingWrapper__wrapper lSecurity">
      <canvas ref={_canvas} />
      <div>
        <img src={Sec} alt="security" />
        <div>
          <h1>THE MOST SECURE PLATFORM TO KEEP YOUR FUNDS</h1>
          <p>
            {/* A truly <b>Decentralized Posterity network</b> protects you against hacks, human
            failures, government impositions, and censorship or blocking your assets. Binance Smart
            Chain is considered as one of the most secure platforms that ever existed. But the
            Posterity team tried to find a way to bulletproof the client's funds as safe as we
            could. In order to achieve this goal, we chose to go beyond any regular Defi system and
            integrate Binance Smart Chain and the <b>advanced Hyper-Ledger Fabric blockchain</b>{' '}
            technologies in the backbone of the Posterity platform.
            <br /> We overcame many obstacles to finally bring Posterity clients one of the most
            secure and sophisticated Defi platforms. */}


            Security is a paramount concern for any Defi platform that aims to
            provide reliable and trustworthy services to its clients. Posterity
            understands this challenge and has designed a unique solution that
            combines the best of both worlds: <b>Binance Smart Chain</b> and {' '}
           <b>Hyper-Ledger Fabric</b> . BSC is a blockchain network that supports smart
            contracts and offers high performance and low fees. It is widely
            regarded as one of the most secure platforms in the crypto space,
            thanks to its robust consensus mechanism, anti-fraud system, and
            decentralized governance. However, BSC alone is not enough to
            guarantee the ultimate security of Posterity. That is why Posterity
            also integrates Hyper-Ledger Fabric, a state-of-the-art blockchain
            technology that offers advanced features such as permissioned
            access, data privacy, scalability, and modularity. Hyper-Ledger
            Fabric allows Posterity to create a customized and secure
            environment for its clients.
          </p>
        </div>
      </div>

      {props.buttons}
    </div>
  );
}
