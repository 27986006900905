import { Field, Form, Formik } from "formik";
import { useEffect } from "react";
import * as Yup from "yup";
import { actions as authenticationActions } from "../../../features/authentication";
import {
  getWalletAction,
  actions as investActions,
} from "../../../features/investment";
import { submitNewInvestmentAction } from "../../../features/investment/slices/submit-new-investment";
import {
  ajaxErrorAlert,
  ajaxSuccessAlert,
  ajaxWarningAlert,
  currencyFormat,
  numberFormatter,
} from "../../../features/utils";
import { useAppDispatch, useAppSelector } from "../../../store";
import { Button, CustomModal } from "../../common-components";
import { userProfileUtilsAction } from "../../../features/authentication/slices/userProfileUtilsAction";
import { useHistory } from "react-router-dom";

export default function NewInvestForm({
  isOpen,
  onClose,
  hasInvestedAt,
  maxSupply,
}: {
  isOpen: boolean;
  onClose(): void;
  hasInvestedAt: string | undefined | null;
  maxSupply: number;
}) {
  const dispatch = useAppDispatch();
  const { error, status } = useAppSelector(
    (state) => state.investment.newInvestment
  );
  const { walletType, getwallet } = useAppSelector((state) => state.investment);

  useEffect(() => {
    if (error?.message)
      ajaxErrorAlert(error.message, "Error!").then((res) => {
        if (res) {
          dispatch(investActions.clearErrors());
        }
      });
  }, [error, dispatch]);

  const history = useHistory();

  return (
    <Formik
      initialValues={{ investAmount: 0 }}
      validateOnChange
      validateOnBlur
      validateOnMount={false}
      validationSchema={Yup.object().shape({
        investAmount: Yup.number()
          .required("You need to enter desired invest amount.")
          .min(
            hasInvestedAt !== null ? 20 : 20,
            hasInvestedAt !== null
              ? `Minimum invest must be ${currencyFormat(
                  hasInvestedAt !== null ? 20 : 20
                )}`
              : `‏To activate your account, you need to deposit more than $20. The entered amount must be a multiple of $20`
          )
          .max(50000, `Maximum invest must be ${currencyFormat(50000)}`)
          .test(
            "user_invest_amount",
            "Your available balance is not enough to make such a deposit. Please use “Deposit” on the current page to top up your balance.",
            function (value) {
              if (Number(value) > maxSupply) return false;
              if (Number(value) <= maxSupply) return true;

              return false;
            }
          )
          .test(
            "user_invest_multiple_20",
            "Deposit amount must be a multiple of $20.",
            function (value) {
              if (Number(value) % 20 === 0) return true;

              return false;
            }
          ),
      })}
      onSubmit={async (values, actions) => {
        actions.setSubmitting(true);
        ajaxWarningAlert(
          `You are going to deposit $${numberFormatter(
            values.investAmount
          )} to your investment portfolio. You will receive ${numberFormatter(
            values.investAmount
          )} Spot tokens in your Posterity wallet address. Please take note that all deposits are non-refundable. Your deposited amount lives as long as 8 years and during that period, you will earn 380% in total. Do you want to continue?`
        ).then(async (res) => {
          if (res.value) {
            await dispatch(
              submitNewInvestmentAction({
                amount: values.investAmount,
                walletType: walletType,
              })
            ).then((res) => {
              if (res.meta.requestStatus === "fulfilled") {
                // console.log("res.meta.requestStatus" , res.meta.requestStatus)
                dispatch(authenticationActions.fillInitialInvestmentDate());
                ajaxSuccessAlert(
                  "Congratulations. Your deposit is final. Please refer to your dashboard to view all required information regarding your portfolio.",
                  "Congratulation!"
                ).then((res) => {
                  onClose();
                  dispatch(getWalletAction());
                  if (!hasInvestedAt) {
                    history.push("/panel");
                  }
                  dispatch(userProfileUtilsAction());
                });
                onClose();
              }
            });
          }
        });

        actions.setSubmitting(false);
      }}
    >
      {({
        values,
        handleReset,
        handleSubmit,
        errors,
        handleChange,
        handleBlur,
        isSubmitting,
      }) => (
        <CustomModal
          isOpen={isOpen}
          closeOnClickOutside={false}
          className="Modal"
          onClose={() => {
            if (isSubmitting || status === "pending") return;
            handleReset();
            onClose();
          }}
        >
          <Form
            onReset={handleReset}
            onSubmit={handleSubmit}
            className="newInvestmentForm"
          >
            <div className="relative">
              <label htmlFor="investAmount">
                Please enter the desired amount to make an investment:
              </label>
              <Field
                type="number"
                id="investAmount"
                name="investAmount"
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={isSubmitting || status === "pending"}
                placeholder="Multiple of $20"
                value={
                  values.investAmount === 0 ? undefined : values.investAmount
                }
              />
              {errors.investAmount && (
                <p className="formError">{errors.investAmount}</p>
              )}
            </div>

            <Button
              loading={isSubmitting || status === "pending" ? true : undefined}
              disabled={
                isSubmitting ||
                status === "pending" ||
                (values.investAmount > getwallet.getWalletData.depositBalance &&
                  values.investAmount >=
                    getwallet.getWalletData.withdrawableBalance) ||
                Boolean(errors.investAmount?.length)
              }
              type="submit"
            >
              Confirm
            </Button>
          </Form>
        </CustomModal>
      )}
    </Formik>
  );
}
