import BG from "../../../assets/img/bg.jpg";
const Maintenance = () => {
  return (
    <div className="maintenance_container">
      <img src={BG} alt="posterity" />
      <div className="maintenance_wrapper">
        <h1>Maintenance Mode</h1>
        <p>
          We're undergoing a bit of scheduled maintenance. Sorry for the
          inconvenience. We'll be back up and running as soon as possible.
          Please check back in a short while.
        </p>
        <span>Posterity Maintenance Team</span>
      </div>
    </div>
  );
};

export default Maintenance;
