import {
  IInvestBound,
  IReferral,
  IStatus,
  IUserModel,
  IValidationErrors,
} from "../interfaces";
import { IGAData } from "../interfaces/auth/IGA";
import { ISignInResposeModel } from "../interfaces/auth/ISignInData";
import { IRecentActivity } from "../interfaces/user";
import { IUserProfileData } from "../interfaces/userProfile/IUserProfile";

export const initialData: {
  userData: {
    status: IStatus;
    data: IUserModel;
    recentActivities: IRecentActivity[];
    referrals: IReferral[];
    totalDownlinked: IReferral[];
  };
  signInData: {
    status: IStatus;
    data: ISignInResposeModel;
  };
  userProfileData: {
    status: IStatus;
    data: IUserProfileData;
    error?: IValidationErrors;
  };
  userProfileDataUtils: {
    status: IStatus;
    data: IUserProfileData;
    error?: IValidationErrors;
  };
  signOutData: {
    status: IStatus;
    statusCode: number;
  };
  changeEmailData: {
    status: IStatus;
    openEmailForm: boolean;
  };
  GAData: {
    status: IStatus;
    //secretKey: string;
    data: IGAData;
  };
  verify2Fa: {
    status: IStatus;
  };
  getForgotPass: {
    status: IStatus;
    email: string;
  };
  forgotPassword: {
    status: IStatus;
  };
  error: IValidationErrors | undefined;
  investBound: IInvestBound;
  referralName: string;
  dataFetched: boolean;
  changingNicknameStatus: IStatus;
  changePasswordStatus: IStatus;
  requestPinStatus: IStatus;
  request2FAStatus: IStatus;
  activate2FAStaus: IStatus;
  // logOutStatus: IStatus;/
  OTPstatus: IStatus | "";

  //signInStatus : IStatus;
} = {
  userData: {
    status: "idle",
    data: {
      email: "",
      nickname: "",
      username: "",
      registerDate: "",
      lastLogin: "",
      registerTimestamp: 0,
      twoFAIsSet: false,
      timeStamp: 0,
      userActivatedDate: "",
      userActivatedTimestamp: 0,
      hasDoneInvest: false,
      totalInvestment: 0,
      internalWallet: 0,
      internalWalletHistory: {},
      userActiveInvestUsd: 0,
      userSpotWallet: {
        address: "",
        balance: 0,
      },
      userBalance: 0,
      withdrawableBalance: 0,
      portfolioEarnings: 0,
      userWallets: [],
      totalInvestEarned: 0,
      // what is this? are they different?
      totalMonthlyInvestEarning: 0,
      totalInvestEarningThisMonth: 0,
      // Referrals Related Fields
      affiliateEarnings: 0,
      totalReferralEarned: 0,
      referralCode: "",
      totalReferralsPending: 0,
      isMultiActivated: false,
      hasAccessToMatching: false,
      multiLevelActivationDate: "",
      isMatchingActivated: false,
      hasAccessToMulti: false,
      matchingActivationDate: "",
    },
    recentActivities: [],
    referrals: [],
    totalDownlinked: [],
  },

  signInData: {
    status: "idle",
    data: {
      nickname: "",
      hasInvestedAt: null,
      error: [],
      message: "",
      statusCode: 0,
    },
  },
  signOutData: {
    status: "idle",
    statusCode: 0,
  },
  GAData: {
    status: "idle",
    data: {
      secretKey: "",
      statusCode: 0,
      error: [""],
      message: "",
    },
  },

  userProfileData: {
    status: "idle",
    data: {
      email: "",
      nickname: "",
      referralCode: "",
      initialInvestmentAt: null,
      twoFaAuthenticationAt: "",
      spotWalletAddress: "",
      isMatchingEnabled: false,
      isWithdrawAvailable: false,
      notificationBadge : null
    },
    error: {},
  },
  userProfileDataUtils: {
    status: "idle",
    data: {
      email: "",
      nickname: "",
      referralCode: "",
      initialInvestmentAt: null,
      twoFaAuthenticationAt: "",
      spotWalletAddress: "",
      isMatchingEnabled: false,
      isWithdrawAvailable: false,
      notificationBadge : null
    },
    error: {},
  },
  changeEmailData: {
    status: "idle",
    openEmailForm: false,
  },
  verify2Fa: {
    status: "idle",
  },
  getForgotPass: {
    status: "idle",
    email: "",
  },
  forgotPassword: {
    status: "idle",
  },
  error: {},
  investBound: { min: 0, max: 0 },
  referralName: "",
  dataFetched: false,
  changingNicknameStatus: "idle",
  requestPinStatus: "idle",
  request2FAStatus: "idle",
  changePasswordStatus: "idle",
  activate2FAStaus: "idle",
  OTPstatus: "idle",
};
