import { createSlice } from "@reduxjs/toolkit";
import { initialData } from "./initialData";
import { generalAppMaintenanceAction } from "./slices/generalMaintenanceAction";

export const { actions, reducer } = createSlice({
  name: "maintenance",
  initialState: initialData,
  reducers: {
    underMaintenance: (state) => {
      state.maintenance.value = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(generalAppMaintenanceAction.fulfilled, (state, action) => {
        state.appMaintenance = action.payload;
        state.isAppUnderMaintenance = false;
        state.status = "idle";
      })
      .addCase(generalAppMaintenanceAction.pending, (state) => {
        state.status = "pending";
      })
      .addCase(generalAppMaintenanceAction.rejected, (state, action) => {
        state.status = "rejected";
        state.isAppUnderMaintenance = true;
        state.error = action.payload;
      });
  },
});

export default reducer;
