import React, { Component } from 'react';
import Slider, { Settings } from 'react-slick';

// import { LeftIcon } from "../../assets/icons";

interface Props {
  settings: Settings;
}

interface State {}

// @ts-ignore
const NextArrow = ({ onClick }) => {
  return (
    <div
      className='absolute align-middle bottom-auto cursor-pointer h-8 border border-gray-300 w-8 bg-white shadow-md rounded-lg flex items-center justify-center z-50'
      style={{ top: '50%', right: '-1em' }}
      onClick={onClick}>
      {/* <LeftIcon
        className="mr-1"
        style={{
          transform: "rotate(-180deg)",
        }}
      /> */}
    </div>
  );
};

// @ts-ignore
const PrevArrow = ({ onClick }) => {
  return (
    <div
      className='absolute align-middle bottom-auto cursor-pointer h-8 border border-gray-300 w-8 bg-white shadow-md rounded-lg flex items-center justify-center z-50'
      style={{ top: '50%', left: '-1em' }}
      onClick={onClick}>
      {/* <LeftIcon className="ml-1" /> */}
    </div>
  );
};

// @ts-ignore
// const NextButton = ({ onClick }) => {
//   return (
//     <div
//       className="slick-button flex justify-center items-center next-button"
//       onClick={onClick}
//     >
//       <LeftIcon style={{ transform: "rotate(-180deg)" }} />
//     </div>
//   );
// };

// @ts-ignore
// const PrevButton = ({ onClick }) => {
//   return (
//     <div
//       className="slick-button flex justify-center items-center prev-button"
//       onClick={onClick}
//     >
//       <LeftIcon />
//     </div>
//   );
// };

export class SlickSlider extends Component<Props, State> {
  private _slider: React.RefObject<any>;

  constructor(props: any) {
    super(props);

    this._slider = React.createRef();

    this.state = {};
  }

  render() {
    const { settings, children } = this.props;

    // @ts-ignore
    settings['nextArrow'] = <NextArrow />;
    // @ts-ignore
    settings['prevArrow'] = <PrevArrow />;

    return (
      <div>
        <Slider
          {...settings}
          // @ts-ignore
          ref={(node) => (this._slider = node)}>
          {children}
        </Slider>
      </div>
    );
  }
}
