import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import { IChangeEmailDataModel, IValidationErrors } from "../../interfaces";

export const changeEmailAction = createAsyncThunk<
  { email: string },
  IChangeEmailDataModel,
  {
    rejectValue: IValidationErrors;
  }
>("user/change-email", async (credential, thunkAPI) => {
  try {
    let response = await axios.patch(
      `${process.env.REACT_APP_API_ROOT_NEW}/user/email`,
      {
        ...credential,
      }
    );

    return {
      email: credential.newEmail,
    };
  } catch (error: any) {
    if (error.response.status === 400) {
      return thunkAPI.rejectWithValue({
        message: error.interceptedMessage,
        code: error.response.status,
        name: "user/change-email",
      });
    }
    return thunkAPI.rejectWithValue({
      message:
        "There is some problem with changing your email. Please try again.",
      code: error.response.status,
      name: "user/change-email",
    });
  }
});
