import React, { useState } from "react";
import Select from 'react-select'
import { notification } from "../../../enums/notification.enum";
import { useAppDispatch } from "../../../store";
import { fetchNotificationAction } from "../../../features/notification/slices/fetchNotification-action";


export function NotificationFilter() {
  const dispatch = useAppDispatch()

  const options = [
    { value: notification.WELCOME.toLocaleUpperCase(), label: "Welcome to Posterity" },
    { value: notification.DEPOSIT.toLocaleUpperCase(), label: "New Deposit" },
    { value: notification.INVESTMENT.toLocaleUpperCase() , label: "New Investment" } ,
    { value: notification.INVESTMENT_PROFIT.toLocaleUpperCase() , label: "Portfolio Earnings" } , 
    { value: notification.AFFILIATE_PROFIT.toLocaleUpperCase() ,  label: "Affiliate Earnings"} ,
    { value: notification.DIRECT_REFERRAL.toLocaleUpperCase() ,  label: "New Direct Referral" } ,
    { value: notification.MATCHING_ACTIVATED.toLocaleUpperCase() ,  label: "Affiliate Matching Plan Activated"} ,
    { value: notification.PASSWORD_CHANGE.toLocaleUpperCase() ,  label: "Changed Password" } ,
    { value: notification.MFA_ACTIVATED.toLocaleUpperCase() ,  label: "2 Factor Authentication Activated" } ,
    { value: notification.WITHDRAW_REQUEST.toLocaleUpperCase() ,  label: "Withdrawal Requests"} ,
    { value: notification.WITHDRAW_APPROVED.toLocaleUpperCase() ,  label: "Approved Withdrawals" } ,
    { value: notification.WITHDRAW_REJECTED.toLocaleUpperCase() ,  label: "Rejected Withdrawals"} ,
    { value: notification.WITHDRAW_PAID.toLocaleUpperCase() ,  label: "Successful Withdrawal Requests"} ,
    { value: notification.NICKNAME_CHANGED.toLocaleUpperCase() ,  label: "Changed Nickname"} ,


  ]
 
  const handleChange =(option :any)=>{
    const type : any[] =[]
    option.map((e : any) => type.push(e.value))
    dispatch(fetchNotificationAction({type , limit : 1000 , offset : 0}))
  }


  return (
    <form className="flex flex-col p-8 mb-6 bg-white rounded-r-lg w-full">
       <div className="mb-5">
             <h1 className="text-xl text-gray-700 font-bold">Choose your notifications</h1>
           </div>
           <div className="w-3/4">
            <Select className="z-50" options={options} isMulti onChange={handleChange} name="selectValue"/>
          </div>
          {/* <div className=" flex justify-end w-full mt-7">
           <button type="submit" >Search</button>
          </div> */}
    </form>

  );
}
