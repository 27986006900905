import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import { IRequestPinData, IValidationErrors } from "../../interfaces";

export const requestPinAction = createAsyncThunk<
  void,
  IRequestPinData,
  { rejectValue: IValidationErrors }
>("user/request-pin", async (credential, thunkAPI) => {
  try {
    await axios.post(`${process.env.REACT_APP_API_ROOT}/request-recovery-pin`, {
      ...credential,
    });
  } catch (error: any) {
    return thunkAPI.rejectWithValue({
      message:
        "Please make sure you are using the correct email address (format)!",
      name: "user/request-pin",
      code: error.response.data.status,
    });
  }
});
