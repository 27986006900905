import { useState } from 'react';

import { useInterval } from '../../../features/utils';

interface ProgressRingProps {
  width: number;
  dailyIncome: number;
  offset: number;
}

export function ProgressRing({ width, dailyIncome, offset }: ProgressRingProps) {
  const [ringProgress, setRingProgress] = useState(dailyIncome);
  const radius = width / 2;

  const stroke = 15;
  const normalizedRadius = radius - stroke;
  const circumference = normalizedRadius * 2 * Math.PI;

  const strokeDashoffset = circumference - (offset / 360) * circumference;

  useInterval(
    () =>
      setRingProgress((prevState) => (prevState < 100 ? prevState + dailyIncome / 360 : prevState)),
    240000
  );

  return (
    <svg height={radius * 2} width={radius * 2} className='progressRing'>
      <circle
        stroke={'#e3e3e3'}
        fill='transparent'
        style={{
          strokeDashoffset,
          transition: 'stroke-dashoffset 0.35s',
          strokeWidth: stroke,
          transform: 'rotate(-90deg)',
          transformOrigin: '50% 50%',
        }}
        r={normalizedRadius}
        cx={radius}
        cy={radius}
      />
      <circle
        stroke='#116979'
        fill='transparent'
        strokeWidth={stroke}
        strokeDasharray={circumference + ' ' + circumference}
        style={{
          strokeDashoffset,
          transition: 'stroke-dashoffset 0.35s',
          strokeWidth: stroke,
          transform: 'rotate(-90deg)',
          transformOrigin: '50% 50%',
        }}
        r={normalizedRadius}
        cx={radius}
        cy={radius}
      />
      <text y='50%' x='50%' textAnchor='middle' dominantBaseline='middle' fill='#116979'>
        ${ringProgress.toFixed(2)}
      </text>
    </svg>
  );
}
