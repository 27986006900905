import { createSlice } from "@reduxjs/toolkit";

import { IValidationErrors, IWalletInfo } from "../interfaces";
import { addUserWalletAction } from "./slices/add-user-wallet";
import { fetchUserWalletsAction } from "./slices/fetch-user-wallets";

const initialData: {
  error: IValidationErrors | undefined;
  userWallets: IWalletInfo[];
  status: "idle" | "rejected" | "pending";
  dataFetched: boolean;
} = {
  error: {},
  userWallets: [],
  status: "idle",
  dataFetched: false,
};

export const { actions, reducer } = createSlice({
  name: "user",
  initialState: initialData,
  reducers: {
    setErrors(state, action) {},
    clearErrors(state) {
      state.error = {};
    },
  },
  extraReducers: (builder) => {
    // FETCH USER WALLETS
    builder
      .addCase(fetchUserWalletsAction.fulfilled, (state, action) => {
        state.status = "idle";
        if (action.payload) {
          state.userWallets = action.payload.userWallets;
          state.dataFetched = true;
          state.error = {};
        }
      })
      .addCase(fetchUserWalletsAction.pending, (state) => {
        state.status = "pending";
        state.dataFetched = false;
      })
      .addCase(fetchUserWalletsAction.rejected, (state, action) => {
        state.status = "rejected";
        state.dataFetched = false;
        state.error = action.payload;
      });

    // adding a wallet adress
    builder
      .addCase(addUserWalletAction.fulfilled, (state, action) => {
        state.status = "idle";
        if (action.payload) {
          state.userWallets = action.payload.userWallets;
          state.dataFetched = true;
          state.error = {};
        }
      })
      .addCase(addUserWalletAction.pending, (state, action) => {
        state.status = "pending";
        state.dataFetched = false;
      })
      .addCase(addUserWalletAction.rejected, (state, action) => {
        state.status = "rejected";
        state.dataFetched = false;
        state.error = action.payload;
      });
  },
});

export default reducer;
