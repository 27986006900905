import React, { useRef, useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Scrollbars } from "react-custom-scrollbars-2";

import KnowledgeBaseItem from "./KnowledgeBaseItem";

interface Props {
  buttons: React.ReactNode;
}

export default function KnowledgeBase(props: Props) {
  const _canvas = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const canvas = _canvas.current!;
    const context = canvas.getContext("2d")!;
    context.canvas.width = document.body.clientWidth;
    context.canvas.height = document.body.clientHeight;
    context.lineWidth = 6;
    context.strokeStyle = "#ff4040";

    context.beginPath();
    context.moveTo(context.canvas.width / 2 + context.canvas.width / 3, 0);
    context.lineTo(
      context.canvas.width / 2 + context.canvas.width / 3,
      context.canvas.height / 8
    );
    context.lineTo(
      context.canvas.width / 2 - context.canvas.width / 3,
      context.canvas.height / 8
    );
    context.lineTo(
      context.canvas.width / 2 - context.canvas.width / 3,
      context.canvas.height / 2
    );
    context.lineTo(context.canvas.width / 2, context.canvas.height / 2);
    context.stroke();
    context.closePath();

    context.beginPath();
    context.moveTo(context.canvas.width / 2, context.canvas.height / 2);
    context.lineTo(
      context.canvas.width / 2,
      context.canvas.height - context.canvas.height / 8
    );
    context.lineTo(
      context.canvas.width - context.canvas.width / 8,
      context.canvas.height - context.canvas.height / 8
    );
    context.lineTo(
      context.canvas.width - context.canvas.width / 8,
      context.canvas.height - context.canvas.height / 3
    );
    context.lineTo(
      context.canvas.width,
      context.canvas.height - context.canvas.height / 3
    );
    context.stroke();
    context.closePath();

    return () => canvas.remove();
  }, [_canvas]);

  return (
    <div className="landingWrapper__wrapper lKnowledgeBase">
      <canvas ref={_canvas} />
      <div>
        <div className="lKnowledgeBase__header">
          <h3>F.A.Q</h3>
          <p>Knowledge Base</p>
        </div>
        <Tabs>
          <TabList>
            <Tab>Posterity</Tab>
            <Tab>Account</Tab>
            <Tab>Funding</Tab>
            <Tab>Withdraw</Tab>
            <Tab>Affiliate</Tab>
          </TabList>

          <TabPanel>
            <div className="lKnowledgeBase__list">
              <Scrollbars>
                <KnowledgeBaseItem
                  header="WHAT IS POSTERITY.BIZ?"
                  passage="Posterity is a platform that offers its clients a unique opportunity to earn passive income from cryptocurrency trading. It uses a revolutionary A.I Portfolio Manager that leverages the power of decentralized finance (DeFi) to offer you a secure and profitable way to invest in cryptocurrencies. With Posterity, you can deposit crypto assets and enjoy a fixed 4% monthly return on your investment for eight years. Posterity uses advanced algorithms and smart contracts to optimize your portfolio performance and minimize risks. You can also withdraw your returns once a month. Posterity is the ultimate solution for crypto investors who want to grow their wealth and achieve financial freedom."
                />
                <KnowledgeBaseItem
                  header="WHY IS POSTERITY A DEFI PLATFORM, AND WHAT IS THE PURPOSE OF USING A.I?"
                  passage="Unlike traditional platforms that require you to share your personal information, follow government regulations, and deal with human errors, Posterity harnesses the power of DeFi to offer you a free, easy, safe, and unrestricted way to grow your wealth. Posterity uses cutting-edge A.I algorithms to analyze the market trends and optimize your portfolio according to low risk and high returns patterns."
                />
                <KnowledgeBaseItem
                  header="WHY SHOULD I PREFER POSTERITY OVER OTHER INVESTMENT OPTIONS? IS POSTERITY BETTER THAN OTHER LONG-TERM INVESTMENT OPTIONS?"
                  passage="Posterity is based on a decentralized platform that allows you to invest in cryptocurrencies without the need for a third party like organizations or governments. The funds are safe on blockchains, and no other individual can touch them. Unlike other investment options, Posterity returns are not uncertain or unsteady because of its proprietary A.I Portfolio Manager; therefore, you can plan your financial goals based on fixed returns, and you don’t need to worry about market situations and volatilities. The platform uses advanced algorithms and smart contracts to optimize your portfolio performance and minimize risks."
                />
                <KnowledgeBaseItem
                  header="HOW POSTERITY WORKS AND AFFORDS TO PAY SUCH RETURNS?"
                  passage="Posterity uses a combination of smart contracts and artificial intelligence to execute profitable trades in various crypto markets. Here is how it works:<br>
                  Posterity clients deposit their funds in the Posterity smart contract, which acts as a secure and transparent escrow.<br>
                  The Posterity A.I Portfolio Manager, which is a sophisticated algorithm that analyzes the market trends and signals and decides the best trading strategies for each smart contract, transfers the funds to Posterity trading smart contracts, which are specialized programs that run on the blockchain and interact with different crypto exchanges. The Posterity A.I Portfolio Manager allocates the funds among the different trading smart contracts according to their performance and risk profile.<br>
                  Trading smart contracts can take either long-term or short-term positions, depending on the daily investment amount and the market conditions. The long-term positions are more conservative and aim for steady growth over time, while the short-term positions are more aggressive and seek to capitalize on short-term fluctuations in the market.<br>
                  The Posterity trading smart contracts execute the trades on the crypto exchanges and send the profits back to the A.I Portfolio Manager, which accumulates them in the Profit Pool. The Profit Pool is a reserve fund that ensures the stability and sustainability of Posterity's returns. The Profit Pool also acts as a buffer against potential losses, as the Posterity trading smart contracts have a strict stop loss mechanism that prevents them from losing more than a certain percentage of their funds. If a trade goes wrong, the remaining funds are returned to the Profit Pool and used for the next trade.<br>
                  At the end of each month, the A.I Portfolio Manager distributes a fixed return to each client, based on their initial investment amount. The fixed return is guaranteed by Posterity's proprietary A.I algorithm. The rest of the profits remain in the Profit Pool and are reinvested in future trade. As the Profit Pool grows, so does the security and profitability of Posterity's platform.<br>
                  Posterity is a revolutionary way to earn passive income from cryptocurrency trading, without having to worry about market volatility, technical skills, or security issues. Posterity leverages the power of smart contracts and artificial intelligence to create a reliable and transparent platform that delivers consistent returns to its clients. Posterity is a legitimate and innovative business model that aims to democratize access to cryptocurrency trading and wealth creation."
                />
                <KnowledgeBaseItem
                  header="IS POSTERITY.BIZ REGISTERED?"
                  passage="Posterity is a DeFi platform that aims to reach the decentralization level where it entirely works on its own with no human interference. Posterity does not need to obtain any license or approval from any government or country as all of them oppose their restrictions and jeopardize user confidentiality. Posterity is not a company, and it is not based in a country to operate. It is a global network of nodes that run on smart contracts and blockchain technology. Posterity allows users to earn interest on their crypto assets without intermediaries or censorship. Posterity is designed to be transparent, fair and secure for all participants."
                />
                <KnowledgeBaseItem
                  header="WHAT IF YOU WANT TO DEPOSIT MONEY WITH YOUR DESIRED CRYPTOCURRENCY?"
                  passage="If you want to deposit money with your desired cryptocurrency, you can send an email from your registered email in your Posterity account to Crypto@Posterity.‌iz and include all requisite information about your desired cryptocurrency (make sure you have an active Posterity account). When Posterity receives at least 1000 requests for a cryptocurrency, the verification process begins. After successful verification, the requested cryptocurrency will be added to the Deposit/Withdrawal pages."
                />
                <KnowledgeBaseItem
                  header="WHICH CRYPTOCURRENCIES DOES POSTERITY ACCEPT AS DEPOSIT/WITHDRAWAL?"
                  passage="Currently, Posterity accepts Bitcoin, Ethereum, Tether, BNB, Tron but there will be a comprehensive list of cryptocurrencies added to Posterity soon."
                />
                <KnowledgeBaseItem
                  header="HOW CAN SOMEONE CONTACT POSTERITY IF THEY HAVE A QUESTION?"
                  passage="If you have a question about Posterity, you can visit the Knowledge Base page on Posterity.biz or read Posterity whitepaper. If you have an issue or a question that is not answered there, please send an email to Support@Posterity.Biz and wait for the Posterity support team to answer your question and address your issue. Please keep in mind that if your question is already answered on the Posterity Knowledge base page or whitepaper, you will receive no answer from the support team."
                />
              </Scrollbars>
            </div>
          </TabPanel>

          <TabPanel>
            <div className="lKnowledgeBase__list">
              <Scrollbars>
                <KnowledgeBaseItem
                  header="HOW CAN A CLIENT OPEN A POSTERITY ACCOUNT?"
                  passage="To open a Posterity account, all you need is a valid email address. Go to the ‘Sign Up’ page on Posterity.biz, choose a nickname and enter your email address. You will receive an email containing a Posterity wallet address, private key, and password."
                />
                <KnowledgeBaseItem
                  header="HOW CAN A CLIENT ACTIVATE A POSTERITY ACCOUNT?"
                  passage="To activate your Posterity account, you need to deposit at least 20 USD worth of cryptocurrency and invest at least 20 USD in your investment portfolio. To do so, first sign into the Posterity account, and you are redirected to the 'Deposit' page. You can choose a cryptocurrency to deposit at least 20 USD to your account. Upon receiving the funds, you need to invest at least 20 USD in your investment portfolio. After confirmation, your Posterity account will be activated, and you can use Posterity’s advanced dashboard."
                />
                <KnowledgeBaseItem
                  header="DOES A POSTERITY ACCOUNT HOLDER HAVE THE OPTION TO SHOW THE INVESTMENT PORTFOLIO AMOUNT IN AN INDEPENDENT WALLET APPLICATION?"
                  passage="To get your investment balance in a wallet application like Trust Wallet, MetaMask, or any other wallet app that you can add a BEP-20 token manually, you can use the Spot wallet address, private key, and Spot smart contract address that you receive in an email after signing up with Posterity. The welcome email Posterity sends to its clients contains links to this information."
                />
                <KnowledgeBaseItem
                  header="WHY ARE SOME COUNTRIES NOT ALLOWED TO ACCESS THE POSTERITY WEBSITE?"
                  passage="Some countries have limited or no access to Posterity.biz due to security measures. These countries have a reputation for hacking financial websites, and Posterity restricts access to those countries. The countries that have limited or no access to Posterity.biz are China, Crimea, Cuba, Iran, Myanmar, North Korea, Sudan, and Syria. However, clients from those countries can still use Posterity services using a trusted VPN application."
                />
                <KnowledgeBaseItem
                  header="WHAT IS POSTERITY POLICY REGARDING LIMITING, FREEZING, BLOCKING CLIENT'S ACCOUNTS?"
                  passage="Posterity is a decentralized platform that empowers users to control their own data and identity. Unlike centralized services, Posterity does not have any authority or mechanism to interfere with users' accounts. Users are the sole owners and managers of their accounts, and they can choose how to use and share their data. Posterity respects users' privacy and autonomy and does not impose any limits or restrictions on their accounts."
                />
              </Scrollbars>
            </div>
          </TabPanel>

          <TabPanel>
            <div className="lKnowledgeBase__list">
              <Scrollbars>
                <KnowledgeBaseItem
                  header="HOW MUCH RETURN CAN BE EXPECTED IN POSTERITY?"
                  passage="Posterity is an autonomous platform that delivers its clients a 4% monthly fixed return on their deposited funds for 8 years. Clients don’t need to worry about cryptocurrency market volatility. You are here to receive a reasonable yet exciting fixed return on your digital capital."
                />
                <KnowledgeBaseItem
                  header="WHAT EXACTLY HAPPENS AFTER A DEPOSIT IS MADE?"
                  passage="It's simple and easy! Posterity converts your crypto to USD and sends Spot tokens to your Posterity wallet. Each Spot token is worth one dollar, so you always know how much you have. Plus, you get 4% monthly returns on your deposit, which is awesome! Posterity is the best way to grow your money with crypto! This means that you can use Posterity to secure your crypto assets and benefit from Spot tokens as a stablecoin that earns interest over time."
                />
                <KnowledgeBaseItem
                  header="CAN CLIENTS QUICKLY INCREASE OR REDUCE THEIR REGULAR INVESTMENTS?"
                  passage="One of the benefits of our investment plan is that it allows clients to adjust their contributions according to their financial goals and circumstances. Clients cannot reduce their investments, but they can increase their investments at any time they desire. This gives them more flexibility and control over their money and helps them achieve their desired returns faster."
                />
                <KnowledgeBaseItem
                  header="DOES POSTERITY HAVE A MINIMUM INVESTMENT AMOUNT?"
                  passage="Yes, the minimum investment amount to activate your account is 20 USD. Every individual deposit must be a multiple of 20 USD. This ensures that your funds are allocated efficiently and securely."
                />
                <KnowledgeBaseItem
                  header="IF A CLIENT INVESTS THROUGH POSTERITY, IS HE/SHE TIED INTO A LONG-TERM CONTRACT?"
                  passage="Posterity is a unique investment platform that offers fixed monthly returns to its clients. However, this also means that clients have to commit to a long-term contract with Posterity. Once a client makes a deposit, it will be locked for eight years and cannot be refunded. The client will receive monthly payments that include a portion of the principal and the monthly profit over the eight-year period. After the contract ends, the client will no longer have any balance in their portfolio regarding that particular investment with Posterity.
                  "
                />

                <KnowledgeBaseItem
                  header="HOW AND WHEN CAN A CLIENT SUBMIT A REFUND ON HIS/HER INVESTMENT?"
                  passage="Posterity is a cryptocurrency investment platform that uses blockchain technology and smart contracts to trade your funds 24/7. Due to the nature of this system, Posterity requires a long-term commitment from its investors for eight years. This means that once you invest in Posterity, you cannot request a refund on your investment. Posterity appreciates your trust and cooperation in this matter.
                  "
                />
                {/* <KnowledgeBaseItem
                  header="HOW AND WHEN CAN A CLIENT REDEEM FUNDS?"
                  passage="Posterity is a cryptocurrency investment platform that uses blockchain technology and smart contracts to trade your funds 24/7. Due to the nature of this system, Posterity requires a long-term commitment from its investors for eight years. This means that once you invest in Posterity, you cannot request a refund on your investment. Posterity appreciates your trust and cooperation in this matter."
                /> */}
                <KnowledgeBaseItem
                  header="WHAT ARE THE TOTAL CHARGES TO MANAGE THE CLIENT'S INVESTMENTS?"
                  passage="The platform does not charge any management commissions for the investment services. The only fee that clients have to pay is the withdrawal fee, which is applied when they want to withdraw their funds from the platform. The withdrawal fee consists of a fixed and a floating component, depending on the amount of withdrawal request and the cryptocurrency that the client chooses to withdraw. The fixed fee covers the market conditions and the liquidity of the cryptocurrency, while the floating fee covers the network transaction costs and administrators' fee. The exact withdrawal fees for each cryptocurrency are listed in the Posterity whitepaper and on the 'Withdraw' page of the platform. We believe that this structure is fair and transparent, and it allows us to provide high-quality investment services to our clients.
                  "
                />
                {/* <KnowledgeBaseItem
                  header="WHAT IS A MONTHLY CYCLE?"
                  passage="Posterity is operating in the blockchain world. Blockchain technology does not recognize regular months, so we decided to establish a new monthly definition near identical to ordinary calendars. Each monthly cycle is equal to 31 days."
                />
                <KnowledgeBaseItem
                  header="WHAT DOES A MAIN MONTHLY CYCLE DO?"
                  passage="When a client deposits the first investment and activates his/her account, the account's MAIN Monthly Cycle starts. This cycle lasts forever, and it reaches an end every 31 days and starts over again. When the end of the cycle comes, all pending investment profits and pending referrals will be paid to the client's Posterity balance and are withdrawable afterward."
                /> */}
                <KnowledgeBaseItem
                  header="WHEN DOES POSTERITY PAY INVESTMENT PROFITS?"
                  passage="Each deposit you make creates a new investment with a 4% monthly return. You can deposit as many times as you want, and each investment will be treated separately. Posterity pays the investment profits every other month, on the first day of the odd-numbered months. For example, if you deposit $1000 on the first day of February, you will receive $40 in March, $80 in May, and so on. This way, you can enjoy a steady income from your investments with Posterity."
                />
                <KnowledgeBaseItem
                  header="WHAT IF THERE ARE SEVERAL INVESTMENTS IN A CLIENT'S PORTFOLIO! HOW ARE THE RETURNS PAID?"
                  passage="Posterity treats each investment as a separate entity and pays the returns according to the payment timeframe of each one. The returns are calculated and put in a pending status until the first day of the next payment month (January, March, May, July, September, November). On the first day of these months, all the pending returns are added to your Posterity balance, and you can withdraw them once a month. For more information on how posterity payments work, please read the Posterity whitepaper."
                />
              </Scrollbars>
            </div>
          </TabPanel>

          <TabPanel>
            <div className="lKnowledgeBase__list">
              <Scrollbars>
                <KnowledgeBaseItem
                  header="IS THERE A MINIMUM FOR WITHDRAWAL REQUESTS?"
                  passage="If you want to withdraw funds from your account, you need to meet a certain threshold. The minimum amount that you can request for a withdrawal is 16 USD. This means that you cannot withdraw less than 16 USD at a time."
                />
                <KnowledgeBaseItem
                  header="IS THERE A WITHDRAWAL LIMIT?"
                  passage="Yes. There are some filters and restrictions applied to every withdrawal request. If you are just an investor and don't mind about affiliate plans, you can request your funds without limits. But if you are an active affiliate, you need to meet some conditions (listed in the whitepaper and your withdraw page) before submitting a withdrawal request.

                  This is done to ensure the security and stability of our platform and to prevent any fraudulent or abusive behavior. We appreciate your cooperation and understanding in this matter."
                />
                <KnowledgeBaseItem
                  header="CAN I SUBMIT MULTIPLE WITHDRAWAL REQUESTS?"
                  passage="If you want to withdraw your funds from your account, you need to be aware of the withdrawal policy. Posterity only allows one withdrawal request per month for each client. This means that you cannot submit multiple requests in the same month. If you do, your requests will be rejected, and you will have to wait until next month to withdraw your funds. This policy is in place to ensure the security and stability of Posterity and its holders."
                />
                <KnowledgeBaseItem
                  header="HOW LONG DOES A WITHDRAWAL TAKE?"
                  passage="If you want to withdraw your funds, you need to be patient and follow some steps. Here is a brief explanation of how long a withdrawal takes and why:<br><br>

                  First, you need to submit a withdrawal request through the Posterity website. You can choose the amount and the cryptocurrency you want to receive. This request will be processed within one hour by our system.<br><br>
                  
                  Second, your request will be verified by our security division. It is a totally autonomous background check which will check your account balance, and your transaction history. It will also make sure that the request is not fraudulent or suspicious. This verification can take up to 24 hours.<br><br>
                  
                  Third, your request will be executed by our exchange partner who will convert your invested cryptocurrencies to the one you requested based on the current market rate. They will also deduct a small fee for their service. This exchange can take up to 72 hours (about 3 days). Please note that the exchange partners are connected to Posterity’s platform via automated APIs and there is no human interference in the exchange process.<br><br>
                  
                  Finally, your request will be completed, and you will receive your money in your chosen cryptocurrency wallet. The total time for a withdrawal can vary from one hour to four working days depending on the availability and speed of the exchange partner. We hope this information helps you understand how long a withdrawal takes and why. We appreciate your trust and patience in Posterity."
                />
              </Scrollbars>
            </div>
          </TabPanel>

          <TabPanel>
            <div className="lKnowledgeBase__list">
              <Scrollbars>
                <KnowledgeBaseItem
                  header="WHAT IS POSTERITY AFFILIATE HYBRID PLAN?"
                  passage="If you are looking for a way to earn passive income from your referrals, you should consider the Posterity Affiliate Hybrid Plan. This affiliate plan is a unique and innovative solution that combines two different affiliate plans to maximize your earnings. The Multi-Level plan pays you commissions up to 15 levels deep, based on your referrals' investments. You can earn from 4% to 0.25% commissions depending on the level. To activate this plan, you need to have at least 200 USD in your portfolio. On the other hand, the Matching Bonus plan pays you commissions based on a matched amount on both your A & B referral teams. You can earn 50 USD commissions for every matched team. To activate this plan, you need to have at least 500 USD in your portfolio and two active direct referrals. You can benefit from both plans and increase your earnings exponentially."
                />
                {/* <KnowledgeBaseItem
                  header="HOW DOES POSTERITY AFFILIATE HYBRID PLAN WORK?"
                  passage="It consists of two individual referral plans where they work and pay you simultaneously. The first one is a Multi-Level based referral plan, which activates upon your account activation and gives you a vast source of income as deep as 15 referral levels. You can earn virtually unlimited referral commissions in Posterity Affiliate Multi-Level Plan. The second one is the Matching Bonus plan. You can activate it by referring two active direct investors and having a minimum of 600 USD in your investment portfolio."
                /> */}
                <KnowledgeBaseItem
                  header="HOW MUCH CAN AN AFFILIATE EARN IN POSTERITY AFFILIATE MULTI LEVEL AND MATCHING BONUS PLANS?"
                  passage="Posterity Affiliate Multi Level and Matching Bonus Plans are two of the most lucrative ways to earn passive income online. With Posterity Affiliate Multi Level Plan, you can recruit unlimited referrals and earn unlimited commissions from their investments in Posterity. With Matching Bonus Affiliate Plan, you can earn up to 2,500 USD per day by matching your referrals' investments. Both plans are designed to reward you for your efforts and help you achieve financial freedom with Posterity."
                />
                <KnowledgeBaseItem
                  header="HOW POSTERITY AFFILIATE MULTI LEVEL PLAN WORKS?"
                  passage="Posterity is a platform that rewards you for sharing its services with others. You can join Posterity as an affiliate and earn commissions from your referrals. Here is how the affiliate multi-Level plan works:<br><br>

                  You get 4% commission from your direct referrals (level 1).<br><br>
                  You get 1% commission from your second level referrals (level 2).<br><br>
                  You get 0.5% commission from your third and fourth level referrals (level 3 and 4).<br><br>
                  You get a staggering 3% commission from your fifth level referrals (level 5).<br><br>
                  You get 0.5% commission from your sixth to ninth level referrals (level 6 to 9).<br><br>
                  You get an outstanding 1% commission from your tenth level referrals (level 10).<br><br>
                  You get 0.25% commission from your eleventh to fourteenth level referrals (level 11 to 14).<br><br>
                  You get an eye popping 1% commission from your fifteenth level referrals (level 15).<br><br>
                  To qualify for commissions from level 2 to 15, you need to meet certain requirements. Please read the Posterity Whitepaper for more details."
                />
                <KnowledgeBaseItem
                  header="HOW POSTERITY MATCHING BONUS PLAN WORKS?"
                  passage="Posterity Matching Bonus Plan is a way to reward our loyal investors who have at least two active direct referrals and more than 500 USD in total investment. By assigning your referrals including their downline to either Team A or Team B (Left and Right side), you can earn 50 USD every time both teams reach 500 USD in investments. This bonus is capped at 2,500 USD per day. To learn more about the details and conditions of this plan, please read our Posterity Whitepaper."
                />
                <KnowledgeBaseItem
                  header="IS THERE A LIMIT ON EARNING AFFILIATE COMMISSIONS?"
                  passage="Posterity offers two types of affiliate commissions: Multi-Level and Matching Bonus. The Multi-Level plan rewards you for building a strong and active team of affiliates who invest in Posterity. The Matching Bonus plan rewards you for helping your direct referrals earn more commissions from their own teams. Both plans have different conditions and limitations, which are detailed in the Posterity whitepaper. In summary, the multi-Level plan has no limit on the amount of commissions you can earn, as long as you and your team meet the required criteria. The Matching Bonus plan has a monthly cap of 75,000 USD, which means you cannot earn more than that amount from this plan in a single month."
                />

                <KnowledgeBaseItem
                  header="I ASSIGNED A DIREC REFERRAL TO MATCHING BONUS PLAN, BUT I DID NOT RECEIVE ANY COMMISSIONS ON THEIR INVESTMENTS!"
                  passage="When you assign a direct referral to any of your A or B teams, the module that calculates referrals for that direct referral and all his/her down-line investors, considers their investments as zero. This means that any investments made by your down-line investors before they join the matching bonus plan will not be counted towards your matching bonus. Therefore, it is advisable to assign your direct referrals to the matching bonus plan as soon as possible to maximize your earnings."
                />

                <KnowledgeBaseItem
                  header="WHEN DO CLIENTS RECEIVE AFFILIATE COMMISSIONS?"
                  passage="If you are an affiliate of Posterity, you may be wondering when you will receive your commissions. Posterity uses a hybrid plan that pays commissions on a bi-monthly basis. This means that the commissions you earn in one month will be paid to your account balance on the first day of the next even month (February, April, June, August, October, December). For example, if you earn commissions in January, you will receive them on February 1st. However, before paying the commissions, Posterity applies some filters to ensure the quality and validity of the referrals (for more details, please read our whitepaper). Therefore, your commissions will be marked as pending until they are paid."
                />
              </Scrollbars>
            </div>
          </TabPanel>
        </Tabs>
      </div>

      {props.buttons}
    </div>
  );
}
