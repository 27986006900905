import { ResponsiveBar } from "@nivo/bar";
import classNames from "classnames";
import { memo, useEffect, useState } from "react";
import { MLMEarningType } from "../../../features/interfaces";
import { months } from "../../../features/utils";
import { useAppDispatch, useAppSelector } from "../../../store";
const summary = [
  {
    month: months[0],
    "Affiliate Multi Level Earnings": 51,
  },
  {
    month: months[1],
    "Affiliate Multi Level Earnings": 103,
  },
  {
    month: months[2],
    "Affiliate Multi Level Earnings": 31,
  },
  {
    month: months[3],
    "Affiliate Multi Level Earnings": 139,
  },
  {
    month: months[4],
    "Affiliate Multi Level Earnings": 77,
  },
  {
    month: months[5],
    "Affiliate Multi Level Earnings": 165,
  },
  {
    month: months[6],
    "Affiliate Multi Level Earnings": 198,
  },
  {
    month: months[7],
    "Multi-Level Plan": 51,
  },
  {
    month: months[8],
    "Affiliate Multi Level Earnings": 103,
  },
  {
    month: months[9],
    "Affiliate Multi Level Earnings": 31,
  },
  {
    month: months[10],
    "Affiliate Multi Level Earnings": 139,
  },
  {
    month: months[11],
    "Affiliate Multi Level Earnings": 77,
  },
];

function Earnings({ earnings }: { earnings?: MLMEarningType }) {
  const { userSpotWallet } = useAppSelector(
    (state: any) => state.user.userData.data
  );
  const dispatch = useAppDispatch();
  const { payments, dataFetched } = useAppSelector((state) => state.payments);
  const format = (value: any) => `$ ${value}`;

  const [normalizedEarnings, setNormalizedEarnings] = useState<
    { month: string; "Affiliate Multi Level Earnings": number }[]
  >([{ month: "jan", "Affiliate Multi Level Earnings": 0 }]);
  const [chartIsDisable, setChartIsDisable] = useState(false);

  // const fetchInvests = useCallback(async () => {
  //   await dispatch(fetchPaymentsAction());
  // }, [dispatch]);

  useEffect(() => {
    if (earnings) {
      setNormalizedEarnings(
        earnings.map((e: any) => ({
          month: months[e.month],
          "Affiliate Multi Level Earnings": e.earnings,
        }))
      );
      //
    }
  }, [earnings]);

  useEffect(() => {
    if (earnings) {
      normalizedEarnings.every((e) => e["Affiliate Multi Level Earnings"] === 0)
        ? setChartIsDisable(true)
        : setChartIsDisable(false);
    }
  }, [normalizedEarnings]);

  return (
    <div
      className={classNames("card", {
        card__disable: normalizedEarnings.every((e) => e["Affiliate Multi Level Earnings"] === 0),
      })}
    >
      <p className="card__header">Earnings</p>
      <p className="card__subHeader" style={{ fontSize: "1em" }}>
        of last 12 months
      </p>

      <div className="ampEarnings__chart">
        <ResponsiveBar
          data={chartIsDisable ? summary : normalizedEarnings}
          keys={chartIsDisable ? ["Affiliate Multi Level Earnings"] : ["Affiliate Multi Level Earnings"]}
          indexBy={"month"}
          margin={{ top: 20, right: 10, bottom: 60, left: 40 }}
          padding={0.5}
          groupMode="stacked"
          enableGridY
          colors={["#5B9FB2"]}
          defs={[
            {
              id: "dots",
              type: "patternDots",
              background: "inherit",
              color: "#38bcb2",
              size: 4,
              padding: 1,
              stagger: true,
            },
            {
              id: "lines",
              type: "patternLines",
              background: "inherit",
              color: "#eed312",
              rotation: -45,
              lineWidth: 6,
              spacing: 10,
            },
          ]}
          labelFormat={format}
          tooltipFormat={format}
          borderColor={{ from: "color", modifiers: [["darker", 1.7]] }}
          axisTop={null}
          axisRight={null}
          borderRadius={5}
          axisBottom={{
            tickSize: 8,
            tickPadding: 5,
            tickRotation: -45,
            legendPosition: "middle",
            legendOffset: 32,
          }}
          axisLeft={{
            format: format,
            tickSize: 5,
            tickPadding: 1,
            // tickRotation: 0,
            legendPosition: "middle",
            legendOffset: -40,
            tickRotation: -45,
            tickValues: 5,
          }}
          enableLabel={false}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
          legends={[
            {
              dataFrom: "keys",
              anchor: "bottom-left",
              direction: "row",
              symbolShape: "circle",
              justify: false,
              translateX: -20,
              translateY: 60,
              itemsSpacing: 50,
              itemWidth: 100,
              itemHeight: 20,
              itemDirection: "left-to-right",
              itemOpacity: 1,
              symbolSize: 13,
            },
          ]}
          animate={true}
          motionStiffness={65}
          motionDamping={15}
        />
      </div>
    </div>
  );
}

export default memo(Earnings);
