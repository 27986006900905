import { uniqueId } from "lodash";
import { NotificationFilter } from "./NotificationFilter";
import { NotificationTable } from "./NotificationTable";
import NotificationPagination from "../../common-components/Pagination";
import { notifTableMock } from "./notifMock.data";
import Pagination from "../../common-components/Pagination";
import { usePagination } from "../../../features/usePagination";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../store";
import { fetchNotificationAction } from "../../../features/notification/slices/fetchNotification-action";
import { INotificationData } from "../../../features/interfaces/notifications/INotificationData";
import { userProfileUtilsAction } from "../../../features/authentication/slices/userProfileUtilsAction";
type Props = {};

export const NotificationsCenter = (props: Props) => {
  


  const dispatch = useAppDispatch();
  const {notifications} = useAppSelector((state) => state.notification.notification.data)

  const { limitedItems, pageIndex, totalPages, setPageSize, setPageIndex } =
    usePagination(notifications);


  useEffect(()=>{
    dispatch(fetchNotificationAction({offset : 0 , limit : 1000}))
  }, [])

  useEffect(() =>{
    dispatch(userProfileUtilsAction());
  }, [])
  
  return (
    <div>
      <NotificationFilter />
      <div className="notificationWrapper">
        <div className="notification-header">
          <h1>Notifications</h1>
        </div>
        {limitedItems.map((notifications) => (
          <NotificationTable notifications={notifications} key={notifications.id} />
        ))}


      <Pagination
        setPageIndex={setPageIndex}
        setPageSize={setPageSize}
        pageIndex={pageIndex}
        totalPages={totalPages}
      />
      </div>
    </div>
  );
};
