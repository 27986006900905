import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { IValidationErrors } from "../../interfaces";
import { IResponseModel } from "../../interfaces/IResponseModel";

export const generalAppMaintenanceAction = createAsyncThunk<
  IResponseModel,
  void,
  {
    rejectValue: IValidationErrors;
  }
>("base-info/maintenance", async (_, thunkAPI) => {
  try {
    return await axios
      .get(`${process.env.REACT_APP_API_ROOT_NEW}/base-info/maintenance`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue({
          message: error.response.data.message,
          code: error.response.status,
          name: "base-info/maintenance",
        });
      });
  } catch (error: any) {
    return thunkAPI.rejectWithValue({
      message: error.response.data.message,
      code: error.response.status,
      name: "base-info/maintenance",
    });
  }
});
