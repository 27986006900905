interface ICheckBoxProps {
  id: string;
  name: string;
  label: string;
  value: boolean;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export function CheckBox({
  handleChange,
  label,
  name,
  id,
  value,
}: ICheckBoxProps) {
  return (
    <label htmlFor={id} className="checkBox">
      <input
        id={id}
        type="checkbox"
        name={name}
        checked={value}
        onChange={handleChange}
        // defaultChecked={value}
        className="invisible"
      />
      <div className="checkbox">
        <svg width="20px" height="20px" viewBox="0 0 20 20">
          {/* <path d="M3,1 L17,1 L17,1 C18.1045695,1 19,1.8954305 19,3 L19,17 L19,17 C19,18.1045695 18.1045695,19 17,19 L3,19 L3,19 C1.8954305,19 1,18.1045695 1,17 L1,3 L1,3 C1,1.8954305 1.8954305,1 3,1 Z" /> */}
          <polyline points="4 11 8 15 16 6"></polyline>
        </svg>
      </div>
      <span>{label}</span>
    </label>
  );
}
