import React, { useEffect, useCallback, useState } from "react";
import { RouteComponentProps, Link } from "react-router-dom";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import classNames from "classnames";
import ReCAPTCHA from "react-google-recaptcha";
import Cookie from "js-cookie";

import { Button } from "../common-components";
import { useAppDispatch, useAppSelector } from "../../store";
import {
  getReferralNameAction,
  registerAction,
  actions,
} from "../../features/authentication";
import {
  ajaxErrorAlert,
  ajaxSuccessAlert,
  EMAIL_REGEX,
} from "../../features/utils";
import { IRegisterData } from "../../features/interfaces";

const validationSchema = Yup.object().shape({
  nickname: Yup.string()
    // .max(
    //   16,
    //   "Maximum characters are 16, and a mixture of English letters and numbers."
    // )
    .matches(
      /^[a-zA-Z0-9]{3,16}$/,
      "Nickname Must be between 3 and 16 characters and a mixture of English letters and numbers."
    )
    .required("You need to pick a nickname to proceed registration."),
  email: Yup.string()
    .email()
    .required("Please enter a valid email address.")
    .test(
      "test-for-email",
      "Please enter the correct email (wallet) address or password to continue.",
      function (value) {
        if (value) {
          if (value.search(EMAIL_REGEX) !== -1) {
            return true;
          }
        }
        return false;
      }
    ),
  captcha: Yup.boolean()
    .required("Please answer the captcha.")
    .oneOf([true], "Please answer the captcha."),
});

interface IRegisterProps
  extends RouteComponentProps<{ referralCode?: string }> {}

export default function Register(props: IRegisterProps) {
  const { status } = useAppSelector((state) => state.user.userData);
  const { error } = useAppSelector((state) => state.user);

  const { referralName } = useAppSelector((state) => state.user);

  const dispatch = useAppDispatch();

  const getReferralNickname = useCallback(
    async (referralCode) => {
      await dispatch(getReferralNameAction(referralCode));
    },
    [dispatch]
  );

  useEffect(() => {
    if (props.match.params.referralCode) {
      getReferralNickname(props.match.params.referralCode);
    } 
  }, [props.match.params.referralCode, getReferralNickname, referralName]);

  useEffect(() => {
    if (Cookie.get("token")?.length) {
      props.history.push("/panel");
    }

    let message = error?.message;

    // if (message) {
    //   ajaxErrorAlert(message).then((res) => {
    //     if (res) {
    //       dispatch(actions.clearErrors());
    //     }
    //   });
    // }
  }, [props.history, error, dispatch]);

  return (
    <Formik
      initialValues={{ email: "", nickname: "", captcha: false }}
      validationSchema={validationSchema}
      validateOnBlur={false}
      onSubmit={async (values, actions) => { 
        actions.setSubmitting(true);
        const credential: IRegisterData = {
          email: values.email,
          nickname: values.nickname,
          referralCode: props.match.params.referralCode,
        };
        props.match.params.referralCode ?? delete credential.referralCode;



        try {
          await dispatch(registerAction(credential)).then((response) =>{
            if(response.meta.requestStatus === "fulfilled"){
              ajaxSuccessAlert(
                "To continue, please check your email address for credentials.",
                "Welcome on board!"
              ).then((res) => {
                if (res.value) props.history.push("/auth");
              });
            }
          })
          actions.setSubmitting(false);
        } catch (error) {
          actions.setSubmitting(false);
        }
      }}
    >
      {({
        values,
        handleReset,
        handleSubmit,
        handleBlur,
        handleChange,
        errors,
        isSubmitting,
        isValid,
        dirty,
        touched,
        validateField,
        setFieldValue,
      }) => (
        <Form
          onReset={handleReset}
          onSubmit={handleSubmit}
          className="registerForm"
        >
          <div>
            {referralName ? (
              <h1>
                <b>Referred by:</b>{" "}
                <span>{referralName}</span>
              </h1>
            ) : (
              <h1>
                <b>Referred by:</b> No one, I've found Posterity by searching
                online.
              </h1>
            )}
          </div>
          <div>
            <label htmlFor="nickname">Nickname</label>
            <Field
              id="nickname"
              name="nickname"
              type="text"
              placeholder="Enter your name or a nickname"
              className={classNames({
                inputError: errors.nickname && touched.nickname,
              })}
            />
            {errors.nickname && touched.nickname && (
              <p className="formError">{errors.nickname}</p>
            )}
          </div>
          <div>
            <label htmlFor="email">Email</label>
            <Field
              id="email"
              name="email"
              type="text"
              placeholder="Enter your email address"
              className={classNames({
                inputError: errors.email && touched.email,
              })}
            />
            {errors.email && touched.email && (
              <p className="formError">{errors.email}</p>
            )}
          </div>

          <ReCAPTCHA
            //@ts-ignore
            sitekey={process.env.REACT_APP_SITE_KEY}
            onChange={() => setFieldValue("captcha", true)}
            onExpired={() => {
              setFieldValue("captcha", false);
            }}
          />
          {/* {errors.captcha && touched.captcha && (
            <p className="formError">{errors.captcha}</p>
          )} */}

          <div>
            <Button
              loading={status === "pending"}
              disabled={status === "pending" || !(isValid && dirty)}
              type="submit"
            >
              Create Account and Continue
            </Button>
          </div>
          <div>
            <Link to={`/auth`}> Already on Posterity? Sign in</Link>
          </div>
        </Form>
      )}
    </Formik>
  );
}
