import classNames from "classnames";
import React, { useState } from "react";
import { CycleIcon, DeactivateIcon, TickIcon } from "../../../../assets/icons";
import { IWithdrawCardProps } from "../../../../features/interfaces";
import { currencyFormat, timeFormat } from "../../../../features/utils";
import { AccordionSizes } from "../../../common-components";

function WithdrawCard(props: IWithdrawCardProps) {
  const { width, withdraw } = props;

  let size =
    width <= 1000 && width > 800
      ? AccordionSizes.large
      : width <= 800 && width > 600
      ? AccordionSizes.medium
      : width <= 600
      ? AccordionSizes.small
      : AccordionSizes.normal;
  const [accordionOpen, setAccordionOpen] = useState(false);

  function camelToTitle(camelCase: string): string {
    return camelCase.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase());
  }

  const statusGenerator =
    withdraw.status === "approved" ? (
      <p className="flex items-center text-green-600">
        <TickIcon className="w-4 h-4 mr-2 fill-current" />
        Paid
      </p>
    ) : withdraw.status === "pending" ? (
      <p className="flex items-center text-gray-600">
        <CycleIcon className="w-4 h-4 mr-2 fill-current" />
        Request Pending
      </p>
    ) : (
      <p className="flex items-center text-gray-600">
        <DeactivateIcon className="w-4 h-4 mr-2 fill-current" />
        Request Denied
      </p>
    );
  const sharedAccordionHeader = (
    <>
      <div className="accordionItem__item">
        <p>Payment Amount (USD)</p>
        <p>{currencyFormat(+withdraw.usdAmount)}</p>
      </div>

      <div className="accordionItem__item">
        <p>Coin Type</p>
        <p>{withdraw.coinType}</p>
      </div>
    </>
  );

  const sharedDropdownContent = (
    <>
      <div className="accordionItem__item">
        <p>Wallet Address</p>
        <p>{withdraw.walletAddress}</p>
      </div>
      <div className="accordionItem__item">
        <p>Transaction ID</p>
        <p>{withdraw.txid ? withdraw.txid : "-"}</p>
      </div>
    </>
  );

  const renderHeader = () => {
    switch (size) {
      case AccordionSizes.normal:
      case AccordionSizes.large:
      case AccordionSizes.medium:
        return (
          <>
            {sharedAccordionHeader}
            <div className="accordionItem__item">
              <p>Payment Amount (Crypto)</p>
              {/* <p>{currencyFormat(withdraw.cryptoAmount)}</p> */}
              <p>{withdraw.cryptoAmount ? withdraw.cryptoAmount : "-"}</p>
            </div>
            <div className="accordionItem__item">
              <p>Request Date</p>
              <p className="text-red-600">{timeFormat(withdraw.requestDate)}</p>
            </div>
            {withdraw.status === "approved" && (
              <div className="accordionItem__item">
                <p>Paid Date</p>
                <p className="text-red-600">
                  {timeFormat(withdraw.requestDate)}
                </p>
              </div>
            )}
            <div className="accordionItem__item">
              <p>Status</p>
              {/* {statusGenerator} */}
              {camelToTitle(withdraw.status)}
            </div>
          </>
        );

      case AccordionSizes.small:
        return (
          <>
            {sharedAccordionHeader}
            <div className="accordionItem__item">
              <p>Status</p>
              {camelToTitle(withdraw.status)}
            </div>
          </>
        );
    }
  };

  const renderContent = () => {
    switch (size) {
      case AccordionSizes.normal:
        return (
          <div className="accordionItem__itemsCol">{sharedDropdownContent}</div>
        );
      case AccordionSizes.large:
      case AccordionSizes.medium:
        return (
          <div className="accordionItem__itemsCol">{sharedDropdownContent}</div>
        );

      case AccordionSizes.small:
        return (
          <div className="accordionItem__itemsCol">
            <div className="accordionItem__item">
              <p>Payment Amount (Crypto)</p>
              <p>{withdraw.cryptoAmount ? withdraw.cryptoAmount : "-"}</p>
            </div>
            <div className="accordionItem__item">
              <p>Request Date</p>
              <p className="text-red-600">{timeFormat(withdraw.requestDate)}</p>
            </div>
            {withdraw.status === "approved" && (
              <div className="accordionItem__item">
                <p>paid Date</p>
                <p className="text-red-600">
                  {timeFormat(withdraw.requestDate)}
                </p>
              </div>
            )}
            {sharedDropdownContent}
          </div>
        );

      default:
        break;
    }
  };

  return (
    <div
      className={classNames("accordionItem", {
        accordionItem__opened: accordionOpen,
      })}
    >
      <div
        className="accordionItem__line"
        onClick={(event: React.MouseEvent) => setAccordionOpen(!accordionOpen)}
      >
        <div className="accordionItem__items">{renderHeader()}</div>
        <span className="accordionItem__icon" />
      </div>

      <div className="accordionItem__inner">
        <div className="accordionItem__content">
          <div className={classNames("accordionItem__items bordered")}>
            {renderContent()}
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(WithdrawCard);
