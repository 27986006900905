import classNames from "classnames";
import React, { FC } from "react";

interface IButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  loading?: boolean;
  disableReason?: string;
  locked?: boolean;
}

export const Button: FC<IButtonProps> = (props) => {
  const { loading, ...loadingLessProps } = props;
  return (
    <button
      {...loadingLessProps}
      className={classNames({
        buttonDisabled: props.disabled,
        buttonLocked: props.locked,
      })}
    >
      {props.loading ? (
        <div className="spinnerLoading">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      ) : (
        props.children
      )}
    </button>
  );
};
