import React from "react";

function Dashboard() {
  return (
    <div>
      <div className="guide-title">Dashboard</div>
      <div>
        On the Dashboard page, you will find six informational blocks that aim
        to provide an overview of your Posterity account without the need to
        navigate through all the pages. These blocks offer quick and valuable
        information briefly and are therefore essential for users who want to
        understand their Posterity account better. To ensure that you understand
        each block and its containing elements, we have provided detailed
        explanations below. This will help you make the most of the Dashboard
        and get the insights you need to make informed decisions about your
        Posterity account. On the left, you’ll see your account pages. At first,
        withdraw & Matching Bonus pages are locked. When you have earnings in
        your Wallet Balance, your withdraw page is going to be accessible. To
        activate your Matching Bonus page, refer to the sixth block of Dashboard
        help. So, without further ado, let's dive into the six informational
        blocks that make up the Dashboard page:
        <div className="dashboard-sections">
          <div className="dashboard-sections-header">UPPER TABS</div>
          <ul className="guide-lists" >
            <li>
              <div className="sections-header">Wallet Balance</div>
              <div>
                This represents the total available returns (portfolio +
                affiliate) that you can withdraw. We encourage you to read the
                whitepaper to understand how referral commissions are
                transferred to your wallet balance.
              </div>
            </li>

            <li>
              <div className="sections-header">Portfolio Earnings</div>
              <div>
                This shows the total earnings from the portfolio up until the
                current month.
              </div>
            </li>

            <li>
              <div className="sections-header">Affiliate Earnings</div>
              <div>
                This shows the total earnings you’ve received from both
                Affiliate Multi Level & Matching Bonus Plans up until the
                current month.
              </div>
            </li>

            <li>
              <div className="sections-header">Pending Earnings</div>
              <div>
                This shows the total earnings you’ll receive from both Affiliate
                Multi Level & Matching Bonus Plans at the end of the payment
                month.
              </div>
            </li>

            <li>
              <div className="sections-header">Total Investments</div>
              <div>
                This is the sum of all the money you have put into your
                Portfolio that is still working for you.
              </div>
            </li>
          </ul>
        </div>
        <div className="dashboard-sections">
          <div className="dashboard-sections-header">INVESTMENTS</div>
          <div>
            There are three distinct sections that will provide you with a
            better resolution of your total portfolio value and returns in the
            best possible way:
          </div>
          <ul className="guide-lists">
            <li>
              <div className="sections-header">Total Returns</div>
              <div>
                This represents the total returns at the end of your investment
                lifespan.
              </div>
            </li>

            <li>
              <div className="sections-header">Monthly Profits</div>
              <div>
                This refers to all the profits you have earned from your total
                investments up until today in the current month.
              </div>
            </li>

            <li>
              <div className="sections-header">Monthly Returns</div>
              <div>
                This refers to the total amount of returns you will earn at the
                end of each month.
              </div>
            </li>

            <li>
              <div className="sections-header">Daily Profits</div>
              <div>
                This refers to all the profits you have earned from your total
                investments up until the current time.
              </div>
            </li>

            <li>
              <div className="sections-header">Daily Returns</div>
              <div>
                This refers to the total amount of returns you will earn every
                day.
              </div>
            </li>
          </ul>
        </div>
        <div className="dashboard-sections">
          <div className="dashboard-sections-header">WALLET INPUTS</div>
          <div>
            This is a graphical chart that displays your total earnings from
            your portfolio and affiliate programs monthly. It helps you to track
            your income sources and trends over time. You can use this chart to
            compare your performance with your goals and identify areas for
            improvement.
          </div>
        </div>
        <div className="dashboard-sections">
          <div className="dashboard-sections-header">NEW INVESTORS</div>
          <div>
            This chart displays the growth of your affiliate network by counting
            the new active investors who joined each month. You can see how your
            network expanded over time and which months had the highest or
            lowest numbers of new investors.
          </div>
        </div>
        <div className="dashboard-sections">
          <div className="dashboard-sections-header">EARNINGS</div>
          <div>
            You can see the total amount of money you made from your Portfolio
            and Affiliate programs in this chart.
          </div>
        </div>
        <div className="dashboard-sections">
          <div className="dashboard-sections-header">RECENT ACTIVITIES</div>
          <div>
            This is a summary of the latest five transactions that occurred on
            your account. It includes the date, amount, and description of each
            transaction. You can use this information to monitor your account’s
            activities.
          </div>
        </div>
        <div className="dashboard-sections">
          <div className="dashboard-sections-header">PROFILE</div>
          <ul className="guide-lists">
            <li>
              <div className="sections-header">Posterity Wallet Address</div>
              <div>
                This is the public address of your Posterity Wallet on BEP-20
                blockchain.
              </div>
            </li>

            <li>
              <div className="sections-header">Activation Date</div>
              <div>The time & date, you’ve made your first investment.</div>
            </li>

            <li>
              <div className="sections-header">Your Referral Link</div>
              <div>
                This is your Posterity referral link. You can use it to invite
                your friends, associates or online contacts to join Posterity as
                new clients. When they invest in Posterity, you will earn
                Affiliate Earnings based on the terms and conditions in
                Posterity's Whitepaper. The more new clients you refer, the more
                you can grow your affiliate network and referral commissions.
              </div>
            </li>

            <li>
              <div className="sections-header">Affiliate Multi-Level Plan</div>
              <div>
                It shows the status of your Affiliate Multi-Level Plan.
                Affiliate marketing is a way to earn money by promoting
                Posterity's services. You get a commission when someone buys
                through your affiliate link. To start earning commissions, you
                need to activate your account with a minimum investment of 20
                USD. This will also enable your Affiliate Multi Level Plan,
                which allows you to earn commissions by referring new clients or
                encouraging your existing clients to invest more in their
                accounts.
              </div>
            </li>

            <li>
              <div className="sections-header">Affiliate Matching Bonus Plan</div>
              <div>
                It shows the status of your Affiliate Matching Bonus Plan.
                Posterity offers a new referral plan for eligible investors. To
                qualify, you need to have at least 500 USD in your Portfolio and
                two direct referrals. The new plan gives you a Matching Bonus
                based on your referrals' earnings. To activate it, go to your
                Matching Bonus page and follow the instructions. You can learn
                more about the plan by reading Posterity's Whitepaper, visiting
                the Matching Bonus plan page, or browsing our knowledge base.
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
