import React from "react";

function Portfolio() {
  return (
    <>
    <div className="guide-title">Portfolio</div>
    <div>
      
      More details on your individual investments such as when they are created
      or when they are going to be expired, how many individual investments
      you’ve made and how much you’ll earn at the end or how much you’ve earned
      so far. Here you’ll find more details on your individual investments and
      view your investment history. You can see when each investment was
      created, when it will expire, how much you invested and how much you will
      earn at the end (Totally or individually). You can also see how much you
      have earned so far from your investments and compare your returns with
      other options. This information can help you make better decisions about
      your financial goals and plan for your future.
    
    </div>
    </>
  );
}

export default Portfolio;
