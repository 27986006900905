import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { INotificationRes } from "../../interfaces/notifications/INotificationRes";
import { IValidationErrors } from "../../interfaces";
import { INotificationData } from "../../interfaces/notifications/INotificationData";

export const fetchNotificationAction = createAsyncThunk<
INotificationRes, 
unknown ,
{
    rejectValue: IValidationErrors;
}
>("panel/notification" , async (params , thunkAPI) =>{
    try{
        return await axios.get(`${process.env.REACT_APP_API_ROOT_NEW}/notification/notification` , {params})
        .then((response) =>{
            return response.data.data
        })
         
    } catch(error : any){
        return thunkAPI.rejectWithValue({
            message : error.response.data.message ,
            name : "panel/notification" ,
            code : error.response.data.status
        })
    }
})
