import classNames from "classnames";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { useAppDispatch, useAppSelector } from "../../../../store";
//import { activate2FAAction } from "../../../../features/authentication";
import { actions as authActions } from "../../../../features/authentication";
import { Active2FAAction } from "../../../../features/authentication/slices/Active2FAAction";
import { Button, CustomModal } from "../../../common-components";

import QRCode from "react-qr-code";
import { ajaxSuccessAlert } from "../../../../features/utils";

const validationSchema = Yup.object().shape({
  code: Yup.string()
    .required("You need to enter the 2fa code to activate this feature.")
    .min(6)
    .max(6),
});

interface IActive2FAFormProps {
  isOpen: boolean;
  onClose(): void;
}

export default function Active2FAForm(props: IActive2FAFormProps) {
  const { isOpen, onClose } = props;
  const dispatch = useAppDispatch();
  const { activate2FAStaus } = useAppSelector((state) => state.user);
  const { verify2Fa } = useAppSelector((state) => state.user);
  const { GAData, error } = useAppSelector((state) => state.user);
  const { email } = useAppSelector((state) => state.user.userData.data);

  return (
    <CustomModal isOpen={isOpen} onClose={onClose} className ="Modal">
      <Formik
        initialValues={{ code: "", password: "" }}
        validationSchema={validationSchema}
        onSubmit={async (values, actions) => {
          actions.setSubmitting(true);

          const data = {
            twoFactorToken: values.code,
          };
          await dispatch(Active2FAAction(data)).then((res: any) => {
            res.meta.requestStatus === "fulfilled" &&
              ajaxSuccessAlert(
                "2FA has been activated successfully for your account!"
              ).then((res) => {
                onClose();

                dispatch(authActions.fillTwoFaAuthenticationAt("1"));
              });
          });
          // try {
          // await RootStore.userStore.verifyQRCode(parseInt(values.code));
          // ajaxSuccessAlert(
          //   "2FA has been activated successfully for your account!"
          // ).then((res) => {
          //   if (res) onClose();
          //   dispatch(userProfileAction());
          // });
          // Swal.fire({
          //   icon: 'success',
          //   text: '2FA has been activated successfully for your account!',
          // });
          // } catch (error) {
          //   ajaxErrorAlert("We can't process you request right now!", "Oops!");
          //   // if (error.response.status === 401) {
          //   //   Swal.fire({
          //   //     icon: 'error',
          //   //     text: 'Please enter the right code !',
          //   //   });
          //   // }
          // }
          actions.setSubmitting(true);
        }}
      >
        {({
          values,
          handleReset,
          handleSubmit,
          isSubmitting,
          handleBlur,
          handleChange,
          errors,
        }) => (
          <Form
            onReset={handleReset}
            onSubmit={handleSubmit}
            className="activate2FAForm"
          >
            <h1>Two Factor Authentication</h1>
            <br />
            <p>
              The first step is to download the Google Authenticator app for
              your Android or IOS device. If you need help getting started,
              please see{" "}
              <a href="https://support.google.com/accounts/answer/1066447?hl=en">
                Google's Support Page.
              </a>
              <br />
              If you do not have access to the Play Store or App Store, there
              are other options for getting Google Authenticator:{" "}
              <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2">
                Android Download
              </a>
              ,{" "}
              <a href="https://chrome.google.com/webstore/detail/authenticator/bhghoamapcdpbohphigoooaddinpkbai?hl=en">
                Google Chrome Plugin
              </a>
              ,or
              <a href="https://apps.apple.com/us/app/google-authenticator/id388497605">
                iTunes App Store.
              </a>
            </p>

            <div>
              <div className="QRcode">
                <QRCode
                  // size={256}
                  // style={{ height: "200px", maxWidth: "100%", width: "200px" }}
                  value={GAData.data.secretKey}
                  viewBox={`0 0 256 256`}
                />
              </div>
              <div>
                <label htmlFor="code">
                  <span>
                    Enter 6 digit code from the application <br />
                  </span>
                  <span>
                    After scanning the barcode image, the app will display a
                    six-digit code that you need to enter below
                  </span>
                </label>
                <Field
                  id="code"
                  //type="number"
                  placeholder="Example: 435 837"
                  value={values.code}
                  onChange={handleChange}
                  className={classNames({ inputError: errors.code })}
                  maxLength={6}
                />
                {errors.code && <p className="formError">{errors.code}</p>}
                <div>
                  <Button
                    loading={verify2Fa.status === "pending"}
                    disabled={verify2Fa.status === "pending"}
                    type="submit"
                    className="mr-6"
                  >
                    Activate
                  </Button>
                  <Button type="button" onClick={() => onClose()}>
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </CustomModal>
  );
}
