import { createSlice } from "@reduxjs/toolkit";

import { changeEmailAction } from "./slices/change-email";
import { changePasswordAction } from "./slices/change-password";
import { forgotPasswordAction } from "./slices/forgotPassword-Action";
import { getForgotPasswordAction } from "./slices/getForgotPassword-Action";
import { getReferralNameAction } from "./slices/old-get-referral-name";
// import { loginAction } from "./slices/login";
import { logout, redirectTo } from "../utils";
import { initialData } from "./initialData";
import { Active2FAAction } from "./slices/Active2FAAction";
import { verify2FAAction } from "./slices/Verify2FAAction";
import { changeNicknameAction } from "./slices/change-nickname";
import { googleAuthenticatorAction } from "./slices/googleAuthenticator";
import { registerAction } from "./slices/registerAction";
import { request2FaAction } from "./slices/request-2FA";
import { requestOTPAction } from "./slices/request-otp";
import { signInAction } from "./slices/signin";
import { signoutAction } from "./slices/signout";
import { userProfileAction } from "./slices/userProfile";
import { userProfileUtilsAction } from "./slices/userProfileUtilsAction";
import { stat } from "fs";

export const { actions, reducer } = createSlice({
  name: "user",
  initialState: initialData,
  reducers: {
    setErrors(state, action) {},
    clearErrors(state) {
      state.error = {};
    },
    setRecentActivities(state, action) {
      state.userData.recentActivities = action.payload;
    },
    setUserData(state, action) {
      state.userData.data = action.payload;
    },
    clearRequestPinErrors(state) {
      state.requestPinStatus = "idle";
    },
    clearRequest2FA(state) {
      state.request2FAStatus = "idle";
    },
    fillInitialInvestmentDate(state) {
      state.userProfileDataUtils.data.initialInvestmentAt = "1";
    },
    fillTwoFaAuthenticationAt(state, { payload }) {
      state.userProfileDataUtils.data.twoFaAuthenticationAt = payload;
    },
  },
  extraReducers: (builder) => {
    // LOGIN

    // Sign in
    builder
      .addCase(signInAction.fulfilled, (state, action) => {
        state.signInData.status = "idle";
        if (action.payload) {
          state.signInData.data.hasInvestedAt = action.payload.hasInvestedAt;
          state.dataFetched = true;

          if (action.payload.hasInvestedAt !== null) {
            redirectTo("/panel");
          } else {
            redirectTo("/panel/deposit");
          }
        }
      })
      .addCase(signInAction.pending, (state) => {
        state.signInData.status = "pending";
      })
      .addCase(signInAction.rejected, (state, action) => {
        state.signInData.status = "rejected";
        state.error = action.payload;
      });

    // sign out
    builder
      .addCase(signoutAction.fulfilled, (state, action) => {
        state.signOutData.status = "idle";
        if (action.payload?.statusCode === 200) {
          logout();
        }
      })

      .addCase(signoutAction.pending, (state) => {
        state.signOutData.status = "pending";
      })

      .addCase(signoutAction.rejected, (state, action) => {
        state.signOutData.status = "rejected";
        state.error = action.payload;
      });

    // Forgot Password
    builder
      .addCase(getForgotPasswordAction.fulfilled, (state, action) => {
        state.getForgotPass.status = "idle";
      })
      .addCase(getForgotPasswordAction.pending, (state) => {
        state.getForgotPass.status = "pending";
      })
      .addCase(getForgotPasswordAction.rejected, (state, action) => {
        state.getForgotPass.status = "rejected";
        state.error = action.payload;
      });

    builder
      .addCase(forgotPasswordAction.fulfilled, (state, action) => {
        state.forgotPassword.status = "idle";
      })
      .addCase(forgotPasswordAction.pending, (state) => {
        state.forgotPassword.status = "pending";
      })
      .addCase(forgotPasswordAction.rejected, (state, action) => {
        state.forgotPassword.status = "rejected";
        state.error = action.payload;
      });
    // GA Active
    builder
      .addCase(googleAuthenticatorAction.fulfilled, (state, action) => {
        state.GAData.status = "idle";
        state.dataFetched = true;
        state.GAData.data.secretKey = action.payload?.secretKey;
      })

      .addCase(googleAuthenticatorAction.pending, (state) => {
        state.GAData.status = "pending";
      })

      .addCase(googleAuthenticatorAction.rejected, (state, action) => {
        state.GAData.status = "rejected";
        state.error = action.payload;
      });

    // CHANGE NICKNAME
    builder
      .addCase(changeNicknameAction.fulfilled, (state, action) => {
        state.changingNicknameStatus = "idle";
        state.userData.data.nickname = action.payload;
      })
      .addCase(changeNicknameAction.pending, (state) => {
        state.changingNicknameStatus = "pending";
      })
      .addCase(changeNicknameAction.rejected, (state, action) => {
        state.changingNicknameStatus = "rejected";
        state.error = action.payload;
      });

    // user profile
    builder
      .addCase(userProfileAction.fulfilled, (state, action) => {
        state.userProfileData.status = "idle";
        state.userProfileData.data = action.payload.userProfileData;
      })
      .addCase(userProfileAction.pending, (state) => {
        state.userProfileData.status = "pending";
  
        // state.userProfileData = initialData.userProfileData;
      })
      .addCase(userProfileAction.rejected, (state, { payload }) => {
        state.userProfileData.status = "rejected";
        state.userProfileData.error = payload;
        // logout();
      });

     // user profile utils
     builder.addCase(userProfileUtilsAction.fulfilled , (state , action)=>{
      state.userProfileDataUtils.status = "idle";
      state.userProfileDataUtils.data = action.payload
      state.dataFetched = true;
     })
     .addCase(userProfileUtilsAction.pending , (state) =>{
      state.userProfileDataUtils.status ="pending";
     }) 
     .addCase(userProfileUtilsAction.rejected , (state , action) =>{
      state.userProfileDataUtils.status = "rejected";
      state.userProfileDataUtils.error = action.payload;
     })

    //active 2Fa
    builder
      .addCase(Active2FAAction.fulfilled, (state) => {
        state.verify2Fa.status = "idle";
        //state.verify2Fa = action.payload
      })
      .addCase(Active2FAAction.pending, (state) => {
        state.verify2Fa.status = "pending";
      })
      .addCase(Active2FAAction.rejected, (state) => {
        state.verify2Fa.status = "rejected";
      });

    //verify 2fa
    builder
      .addCase(verify2FAAction.fulfilled, (state) => {
        state.verify2Fa.status = "idle";
        //state.verify2Fa = action.payload
      })
      .addCase(verify2FAAction.pending, (state) => {
        state.verify2Fa.status = "pending";
      })
      .addCase(verify2FAAction.rejected, (state) => {
        state.verify2Fa.status = "rejected";
      });

    // REGISTER
    builder
      .addCase(registerAction.fulfilled, (state, action) => {
        state.userData.status = "idle";
        state.error = {};
      })
      .addCase(registerAction.pending, (state) => {
        state.userData.status = "pending";
      })
      .addCase(registerAction.rejected, (state, action) => {
        state.userData.status = "rejected";
        state.error = action.payload;
      });

    // REQUEST-OTP;
    builder
      .addCase(requestOTPAction.fulfilled, (state, action) => {
        state.OTPstatus = "idle";
      })
      .addCase(requestOTPAction.pending, (state) => {
        state.OTPstatus = "pending";
      })
      .addCase(requestOTPAction.rejected, (state, action) => {
        state.OTPstatus = "rejected";
      });

    // REQUEST 2FA
    builder
      .addCase(request2FaAction.fulfilled, (state, action) => {
        state.request2FAStatus = "idle";
      })
      .addCase(request2FaAction.pending, (state) => {
        state.request2FAStatus = "pending";
      })
      .addCase(request2FaAction.rejected, (state, action) => {
        state.request2FAStatus = "rejected";
      });
    // CHANGE EMAIL
    builder
      .addCase(changeEmailAction.fulfilled, (state) => {
        state.changeEmailData.status = "idle";
      })
      .addCase(changeEmailAction.pending, (state) => {
        state.changeEmailData.status = "pending";
      })
      .addCase(changeEmailAction.rejected, (state, action) => {
        state.error = action.payload;
        state.changeEmailData.status = "rejected";
      });

    // CHANGE PASSWORD
    builder
      .addCase(changePasswordAction.fulfilled, (state, action) => {
        state.changePasswordStatus = "idle";
      })
      .addCase(changePasswordAction.pending, (state) => {
        state.changePasswordStatus = "pending";
      })
      .addCase(changePasswordAction.rejected, (state, action) => {
        state.changePasswordStatus = "rejected";
        state.error = action.payload;
      });

    // GET REFERRAL NAME
    builder
      .addCase(getReferralNameAction.fulfilled, (state, action) => {
        state.userData.status = "idle";
        if (action.payload?.referralName) {
          state.referralName = action.payload.referralName;
        }
      })
      .addCase(getReferralNameAction.pending, (state) => {
        state.userData.status = "pending";
      })
      .addCase(getReferralNameAction.rejected, (state) => {
        state.userData.status = "rejected";
        state.referralName = "";
      });
  },
});

export default reducer;
