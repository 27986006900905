import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import { IRequest2FA, IValidationErrors } from "../../interfaces";

export const request2FaAction = createAsyncThunk<
  void,
  IRequest2FA,
  {
    rejectValue: IValidationErrors;
  }
>("user/request-2FA", async (credential, thunkAPI) => {
  try {
    await axios.post(`${process.env.REACT_APP_API_ROOT}/get-qrcode`, {
      ...credential,
    });
  } catch (error: any) {
    return thunkAPI.rejectWithValue({
      message: "Please active two factor authentication!",
      name: "user/request-2FA",
      code: error.response.data.status,
    });
  }
});
