import classNames from "classnames";
import { upperFirst } from "lodash";
import React, { useState } from "react";
import { CycleIcon, DeactivateIcon, TickIcon } from "../../../../assets/icons";
import { IEarningReport } from "../../../../features/interfaces/payment";
import { currencyFormat, timeFormat } from "../../../../features/utils";
import { AccordionSizes } from "../../../common-components";

interface IEarningCardProps {
  width: number;
  earningReport: IEarningReport;
}

function EarningCard(props: IEarningCardProps) {
  const { width, earningReport } = props;

  let size =
    width <= 1000 && width > 800
      ? AccordionSizes.large
      : width <= 800 && width > 600
      ? AccordionSizes.medium
      : width <= 600
      ? AccordionSizes.small
      : AccordionSizes.normal;
  const [accordionOpen, setAccordionOpen] = useState(false);

  const renderHeader = () => {
    switch (size) {
      case AccordionSizes.normal:
      case AccordionSizes.large:
      case AccordionSizes.medium:
        return (
          <>
            <div className="accordionItem__item">
              <p>Payment Amount</p>
              <p>{currencyFormat(+earningReport.amount)}</p>
            </div>
            <div className="accordionItem__item text-cyan-600">
              <p>Payment For</p>
              <p>{upperFirst(earningReport.paymentFor)}</p>
            </div>
            <div className="accordionItem__item">
              <p>Due Date</p>
              <p className="text-red-600">
                {earningReport.dueDate
                  ? timeFormat(earningReport.dueDate)
                  : "-"}
              </p>
            </div>
            <div className="accordionItem__item">
              <p>Status {earningReport.status}</p>
              {earningReport.status === "failed" && (
                <p className="flex items-center text-gray-600">
                  <DeactivateIcon className="w-4 h-4 mr-2 fill-current" />
                  Failed
                </p>
              )}
              {earningReport.status === "pending" && (
                <p className="flex items-center text-gray-600">
                  <CycleIcon className="w-4 h-4 mr-2 fill-current" />
                  Pending
                </p>
              )}
              {earningReport.status === "paid" && (
                <p className="flex items-center text-green-600">
                  <TickIcon className="w-4 h-4 mr-2 fill-current" />
                  Paid
                </p>
              )}
            </div>
          </>
        );

      case AccordionSizes.small:
        return (
          <>
            <div className="accordionItem__item">
              <p>Payment Amount</p>
              <p>{currencyFormat(+earningReport.amount)}</p>
            </div>
            <div className="accordionItem__item text-cyan-600">
              <p>Payment For</p>
              <p>{upperFirst(earningReport.paymentFor)}</p>
            </div>
            <div className="accordionItem__item">
              <p>Status</p>
              {earningReport.status === "failed" && (
                <p className="flex items-center text-gray-600">
                  <DeactivateIcon className="w-4 h-4 mr-2 fill-current" />
                  Failed
                </p>
              )}
              {earningReport.status === "pending" && (
                <p className="flex items-center text-gray-600">
                  <CycleIcon className="w-4 h-4 mr-2 fill-current" />
                  Pending
                </p>
              )}
              {earningReport.status === "paid" && (
                <p className="flex items-center text-green-600">
                  <TickIcon className="w-4 h-4 mr-2 fill-current" />
                  Paid
                </p>
              )}
            </div>
          </>
        );
    }
  };

  const renderContent = () => {
    switch (size) {
      case AccordionSizes.small:
        return (
          <div className="accordionItem__itemsCol">
            <div className="accordionItem__item">
              <p>Due Date</p>
              <p className="text-red-600">
                {earningReport.dueDate
                  ? timeFormat(earningReport.dueDate)
                  : "-"}
              </p>
            </div>
          </div>
        );

      default:
        break;
    }
  };

  return (
    <div
      className={classNames("accordionItem", {
        accordionItem__opened: accordionOpen,
      })}
    >
      <div
        className="accordionItem__line"
        onClick={() => {
          if (size === AccordionSizes.small) setAccordionOpen(!accordionOpen);
        }}
      >
        <div className="accordionItem__items">{renderHeader()}</div>
        {size === AccordionSizes.small && (
          <span className="accordionItem__icon" />
        )}
      </div>

      <div className="accordionItem__inner">
        <div className="accordionItem__content">
          <div className={classNames("accordionItem__items bordered")}>
            {renderContent()}
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(EarningCard);
