import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Cookie from "js-cookie";

import {
  IAdminWallet,
  ITransactionModel,
  IUserModel,
  IValidationErrors,
} from "../../interfaces";

export const fetchAdminWalletsAndRecentTransactionAction = createAsyncThunk<
  {
    wallets: IAdminWallet[];
    recentTransactions: ITransactionModel[];
    lastTransaction: ITransactionModel | null;
    user: IUserModel;
  },
  void,
  {
    rejectValue: IValidationErrors;
  }
>("user/fetch-admin-wallets-recent-transactions", async (_, thunkAPI) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_ROOT}/get-depositary-wallets-recent-transactions`,
      {
        token: Cookie.get("token"),
      }
    );

    return response.data;
  } catch (error: any) {
    thunkAPI.rejectWithValue({
      message: error.response.data.message,
      code: error.response.status,
      name: "user/fetch-admin-wallets-recent-transactions",
    });
  }
});
