import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Cookie from "js-cookie";

import { IInvestsSummary, IValidationErrors } from "../../interfaces";

// Get Invest Fee By Asset Name
export const fetchInvestsSummaryAction = createAsyncThunk<
  IInvestsSummary | undefined,
  void,
  {
    rejectValue: IValidationErrors;
  }
>("user/get-invests-summary", async (_, thunkAPI) => {
  try {
    let response = await axios.post(
      `${process.env.REACT_APP_API_ROOT}/invest-summary`,
      {
        token: Cookie.get("token"),
      }
    );

    return response.data;
  } catch (error: any) {
    thunkAPI.rejectWithValue({
      message: error.response.data.message,
      code: error.response.status,
      name: "user/get-invests-summary",
    });
  }
});
