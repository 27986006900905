import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Cookie from "js-cookie";

import { IRequestInvestment, IValidationErrors } from "../../interfaces";

// Create a new Invest request
export const newInvestAction = createAsyncThunk<
  { res: boolean } | undefined,
  IRequestInvestment,
  {
    rejectValue: IValidationErrors;
  }
>("user/new-invest", async (investData, thunkAPI) => {
  try {
    await axios.post(`${process.env.REACT_APP_API_ROOT}/new-invest`, {
      ...investData,
      token: Cookie.get("token"),
    });

    return { res: false };
  } catch (error: any) {
    return thunkAPI.rejectWithValue({
      message: error.response.data.message,
      code: error.response.status,
      name: "user/new-invest",
    });
  }
});
