import classNames from "classnames";
import { useField } from "formik";
import { MouseEvent, useEffect, useState } from "react";
import { currencyFormat, getImg, networkedCoins } from "../../../features/utils";
import { useAppSelector } from "../../../store";
import { TCoinNetWorkArr } from "../../../features/interfaces/withdraw/TCoinNetWorkArr";
import { isEqual } from "lodash";
interface Props {
  name: string;
}

export default function CoinsList(props: Props) {
  const { cryptoPairs, constraints } = useAppSelector(
    (state) => state.withdraw.getWithdrawData
  );

  const [_, __, helpers] = useField(props.name);
  const [coinNetWorkArr, setCoinNetWorkArr] = useState<TCoinNetWorkArr>([
    undefined,
    undefined,
  ]);
  const [coinId , setCoinId] = useState(0)

  //fix for values not submiting to formik state
  useEffect(() => {
    helpers.setValue(coinNetWorkArr);
  }, [coinNetWorkArr]);

  return (
    <div className="coinsList">
      <h1>Please choose your desired cryptocurrency:</h1>
      <div className="coinsList__wrapper">
        <div className="coinsList__wrapper_list">
          <ul className="withdraw_coinsList">
            {cryptoPairs.map((localCoin) => {
              const coinData = cryptoPairs.find((cryptoPair) => {
                return (
                  cryptoPair.networkName === localCoin.networkName &&
                  cryptoPair.cryptoSymbol === localCoin.cryptoSymbol
                );
              });
              const isCoinAvailable = !!(
                coinData && coinData.isWithdarwAvailable
              );
              const localCoinNetwork: TCoinNetWorkArr = [
                localCoin.cryptoSymbol,
                localCoin.networkName,
              ];

              return (
                <li
                  key={"key" + localCoin.cryptoSymbol + localCoin.networkName}
                  className={classNames({
                    disableCoinCard: !isCoinAvailable,
                    selectedCoinCard: isEqual(coinNetWorkArr, localCoinNetwork),
                  })}
                  onClick={(event: MouseEvent<HTMLLIElement>) => {
                    event.preventDefault();
                    if (isCoinAvailable) {
                      setCoinNetWorkArr(localCoinNetwork);
                      helpers.setValue(localCoinNetwork);
                      setCoinId(localCoin.id)
                    }
                  }}
                >
                  <img
                    src={getImg(localCoin.cryptoSymbol!, localCoin.networkName!)}
                    alt={`${localCoin.cryptoSymbol} ${localCoin.networkName}`}
                  />
                </li>
              );
            })}
          </ul>
          
             {
              cryptoPairs.map((coin) => {
                return (
                  coin.id === coinId && <label key={coinId} className="text-sm justify-center">{coin.description}</label>
                )
              })
            }
          
        </div>
      </div>
    </div>
  );
}
