import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { IRequestPinData, IValidationErrors } from "../../interfaces";

export const getForgotPasswordAction = createAsyncThunk<
  {} | any,
  IRequestPinData,
  {
    rejectValue: IValidationErrors;
  }
>("auth/getForgotPass", async (credential, thunkAPI) => {
  try {
    return await axios
      .get(`${process.env.REACT_APP_API_ROOT_NEW}/auth/forgot-password`, {
        params: {
          ...credential,
        },
      })
      .then((response) => {
        return response.data;
      });
  } catch (error: any) {
    return thunkAPI.rejectWithValue({
      message: error.message,
      name: "auth/getForgotPass",
      code: error.response.data.status,
    });
  }
});
