import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import { IAdminWalletResponseModel, IValidationErrors } from "../../interfaces";

export const getAdminWalletAction = createAsyncThunk<
  IAdminWalletResponseModel,
  number | void,
  {
    rejectValue: IValidationErrors;
  }
>("user/get-admin-Wallet", async (coinId, { rejectWithValue }) => {
  try {
    return axios
      .get(
        `${process.env.REACT_APP_API_ROOT_NEW}/crypto/network/${coinId}/wallet`
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue({
          message: error.interceptedMessage,
          code: error.response.status,
          name: "user/getWallet",
        });
      });
  } catch (error: any) {
    let message = "";
    // console.error(error);
    return rejectWithValue({
      message: message,
      code: error.response.status,
      name: "user/getWallet",
    });
  }
});
