import { useEffect } from "react";
import { Redirect, Route, RouteProps, useLocation } from "react-router-dom";
import { useAppSelector } from "../store";
interface MaintenanceRouterProps extends RouteProps {}
const pages = [
  { key: "/panel", value: true },
  { key: "/panel/portfolio", value: true },
  { key: "/panel/deposit", value: true },
  { key: "/panel/withdraw", value: true },
  { key: "/panel/reports", value: true },
  { key: "/panel/affiliate-multi-level-plan", value: true },
  { key: "/panel/affiliate-matching-bonus-plan", value: true },
  { key: "/panel/settings", value: true },
  { key: "/panel/notifications", value: true },
];
export const MaintenanceRoute = (props: MaintenanceRouterProps) => {
  const { value } = useAppSelector((state) => state.maintenance.maintenance);
  const location = useLocation();

  const path = location.pathname;

  const find = pages.filter((_) => _.key === path);
  useEffect(() => {
    // console.log("value", value);
  }, [value]);
  const availableState = find[0] ? find[0].value : true;
  return (
    <Route
      render={() =>
        availableState ? (
          <Route {...props} component={props.component} />
        ) : (
          <Redirect to="/panel/maintenance" />
        )
      }
    />
  );
};
