// @ts-nocheck
import * as Yup from "yup";
import { RouteComponentProps, Link } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import classNames from "classnames";
import web3 from "web3";
import ReCAPTCHA from "react-google-recaptcha";
import { Button } from "../common-components";
import { ajaxSuccessAlert, EMAIL_REGEX } from "../../features/utils";
import { useAppDispatch, useAppSelector } from "../../store";
import { requestPinAction } from "../../features/authentication";
import { getForgotPasswordAction } from "../../features/authentication/slices/getForgotPassword-Action";
import { useState } from "react";
const validationSchema = Yup.object().shape({
  username: Yup.string()
  .required("Please enter correct email (wallet) address to continue.")
  .test(
    "test-for-email",
    "Please enter the correct email (wallet) address to continue.",
    function (value) {
      if (value) {
        if (web3.utils.isAddress(value)) {
          return true;
        } else if (value.search(EMAIL_REGEX) !== -1) {
          return true;
        }
      }

      return false;
    }
    ),
});

export default function ForgotPassword(props: RouteComponentProps) {
  const dispatch = useAppDispatch();
  const [recaptcha , setRecaptcha] = useState("")
  const { getForgotPass } = useAppSelector((state) => state.user);

  // useEffect(() => {
  //   let message = error?.message;

  // if (requestPinStatus === 'rejected') {
  //   ajaxErrorAlert('Please enter a valid email or wallet address.');
  //   dispatch(actions.clearRequestPinErrors());
  // }

  // if (message) {
  //   ajaxErrorAlert(message).then((res) => {
  //     dispatch(actions.clearErrors());
  //   });
  // }
  // }, [error, dispatch, requestPinStatus]);

  // useEffect(() => {
  //   if (requestPinStatus === 'rejected') {
  //     ajaxErrorAlert('Please enter a valid email or wallet address.');
  //     dispatch(actions.clearRequestPinErrors());
  //   }
  // }, [dispatch, requestPinStatus]);

  return (
    <Formik
      initialValues={{ username: "" }}
      validationSchema={validationSchema}
      validateOnBlur={false}
      onSubmit={async (values, formikActions) => {
        formikActions.setSubmitting(true);

        // const data = {
        //   email:
        //     values.username.search(EMAIL_REGEX) !== -1 ? values.username : "",
        //   userWalletAddress:
        //     values.username.search(EMAIL_REGEX) !== -1 ? "" : values.username,
        // };

        const data = {
          [values.username.search(EMAIL_REGEX) !== -1
            ? "email"
            : "userWalletAddress"]: values.username,
        };

        await dispatch(getForgotPasswordAction(data)).then((response) => {
          if (response.meta.requestStatus === "fulfilled") {
            ajaxSuccessAlert(response.payload.message).then((res) => {
              if (res.value) {
                localStorage.setItem("username", values.username);
                props.history.push("/auth/change-password");
              }
            });
          }
        });

        formikActions.setSubmitting(false);
      }}
    >
      {({
        values,
        handleReset,
        handleSubmit,
        handleBlur,
        handleChange,
        errors,
        isSubmitting,
      }) => (
        <Form
          onReset={handleReset}
          onSubmit={handleSubmit}
          className="forgotPasswordForm"
        >
          <div>
            <label htmlFor="username">Enter Your Email or Wallet Address</label>
            <Field
              id="username"
              value={values.username}
              onChange={handleChange}
              onBlur={handleBlur}
              type="text"
              placeholder="Enter a valid email or wallet address"
              className={classNames({ inputError: errors.username })}
            />
            {errors.username && <p className="formError">{errors.username}</p>}
          </div>
          <ReCAPTCHA sitekey={process.env.REACT_APP_SITE_KEY} 
              onChange={(e) => {
                // setFieldValue("captcha", true);
                // setTouched({ ...touched, passwordSearch: true });
                setRecaptcha(e)
              }}
              // onExpired={() => {
              //   setFieldValue("captcha", false);
              // }}
              />
          <div className="mt-4">
            <Button
              loading={getForgotPass.status === "pending"}
              disabled={getForgotPass.status === "pending" || values.username === "" || recaptcha === ""}
              type="submit"
            >
              Reset Password
            </Button>
          </div>
          <div>
            <Link to={`/auth`} className="mt-4">
              Back To Sign In
            </Link>
          </div>
        </Form>
      )}
    </Formik>
  );
}
