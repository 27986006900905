import { IStatus, IValidationErrors } from "../interfaces";
import { IResponseModel } from "../interfaces/IResponseModel";

export const initialData: {
  status: IStatus;
  appMaintenance: IResponseModel;
  error: IValidationErrors | undefined;
  isAppUnderMaintenance: boolean;
  maintenance: {
    value: boolean;
  };
} = {
  appMaintenance: {
    statusCode: 0,
  },
  maintenance: {
    value: true,
  },
  status: "idle",
  error: {},
  isAppUnderMaintenance: false,
};
