import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import {
  IRequestSubmitNewInvestment,
  IValidationErrors,
} from "../../interfaces";

// Create a new Invest request
export const submitNewInvestmentAction = createAsyncThunk<
  { newBalance: string },
  IRequestSubmitNewInvestment,
  {
    rejectValue: IValidationErrors;
  }
>("user/submit-new-investment", async (investData, thunkAPI) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_ROOT_NEW}/investment/invest`,
      {
        ...investData,
      }
    );

    return {
      newBalance: response.data.data.walletBalance,
    };
  } catch (error: any) {
    return thunkAPI.rejectWithValue({
      message: error.response.data.message,
      code: error.response.status,
      name: "user/submit-new-investment",
    });
  }
});
