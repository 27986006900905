import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { IChangePasswordData, IValidationErrors } from "../../interfaces";

export const forgotPasswordAction = createAsyncThunk<
  any,
  IChangePasswordData[],
  {
    rejectValue: IValidationErrors;
  }
>("auth/forgotPass", async (credential, thunkAPI) => {
  try {
    return await axios
      .post(`${process.env.REACT_APP_API_ROOT_NEW}/auth/forgot-password`, {
        ...credential,
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue({
          message: error.message,
          name: "auth/forgotPass",
          code: error.response.data.status,
        });
      });
  } catch (error: any) {
    return thunkAPI.rejectWithValue({
      message: error.message,
      name: "auth/forgotPass",
      code: error.response.data.status,
    });
  }
});
