import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import Cookie from 'js-cookie';

import { ITransactionModel, IValidationErrors } from '../../interfaces';

// Get Invest Fee By Asset Name
export const fetchTransactionAction = createAsyncThunk<
  ITransactionModel[],
  undefined,
  {
    rejectValue: IValidationErrors;
  }
>('user/fetch-user-transactions', async (asset, thunkAPI) => {
  try {
    let response = await axios.post(`${process.env.REACT_APP_API_ROOT}/user-transactions`, {
      token: Cookie.get('token'),
    });

    return response.data.userTransactions;
  } catch (error: any) {
    thunkAPI.rejectWithValue({
      message: error.response.data.message,
      code: error.response.status,
      name: 'user/fetch-user-transactions',
    });
  }
});
