import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Get referral name by referral code
export const getReferralNameAction = createAsyncThunk<
  { referralName: string } | undefined,
  { referralCode: string },
  {}
>("user/get-referral-name", async (referralCode, thunkAPI) => {
  try {
    let response = await axios.post(
      `${process.env.REACT_APP_API_ROOT}/get-username-by-referral-code`,
      {
        referralCode: referralCode,
      }
    );

    return { referralName: response.data["referralName"] };
  } catch (error) {
    thunkAPI.rejectWithValue("any");
  }
});
