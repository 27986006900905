import classNames from "classnames";
import { Field, Form, Formik } from "formik";
import { truncate } from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { bool, object, string } from "yup";
import { ICONS } from "../../../../assets/icons-base64";
import { changeNicknameAction } from "../../../../features/authentication/slices/change-nickname";
import {
  CYCLE_DURATION_IN_MILLISECONDS,
  ajaxSuccessAlert,
  useInterval,
} from "../../../../features/utils";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { Button, CopyToClipboard } from "../../../common-components";
import { userProfileAction } from "../../../../features/authentication";
import { userProfileUtilsAction } from "../../../../features/authentication/slices/userProfileUtilsAction";
const validationSchema = object().shape({
  nickname: string()
    .test(
      "min_length",
      "Nicknames should consist of minimum 3 characters.",
      (value) => {
        if (value && value?.length >= 3) return true;
        return false;
      }
    )
    .test(
      "max_length",
      "Nicknames should consist of maximum 16 characters.",
      (value) => {
        if (value && value?.length <= 16) return true;
        return false;
      }
    )
    .matches(
      /^[a-zA-Z0-9]+$/,
      "Please use A-Z English letters for your nickname."
    ),
  enableEditNickname: bool(),
});

export default function AccountDetails() {
  const [changeNickname, setChangeNickname] = useState(false);

  const dispatch : any = useAppDispatch();

  const { data } = useAppSelector((state) => state.user.userData);
  const { data: userProfileData } = useAppSelector(
    (state) => state.user.userProfileData
  );

  const [nickname, setnickname] = useState(userProfileData.nickname);
  useEffect(() => {
    setnickname(userProfileData.nickname);
  }, [userProfileData.nickname]);
  const { changingNicknameStatus, error } = useAppSelector(
    (state) => state.user
  );
  const { userActivatedDate } = useAppSelector(
    (state) => state.user.userData.data
  );

  const changeHandler = (e: any) => {
    setnickname(e.target.value);
  };

  const clickHandler = () => {
    setChangeNickname(true);
  };

  const clickHandlerChange = async () => {
    await dispatch(changeNicknameAction(nickname)).then((res : any) => {
      res.meta.requestStatus === "fulfilled" &&
        ajaxSuccessAlert("Nickname changed successfully");
        dispatch(userProfileUtilsAction());
      res.meta.requestStatus === "rejected" &&
        setnickname(userProfileData.nickname);
    });

    setChangeNickname(false);
  };

  const [diff, setDiff] = useState(() => {
    let duration =
      moment(moment.now()).diff(data.userActivatedTimestamp, "milliseconds") %
      CYCLE_DURATION_IN_MILLISECONDS;

    return CYCLE_DURATION_IN_MILLISECONDS - duration;
  });

  useInterval(() => {
    if (diff < 0) {
    } else {
      setDiff((prevState) => prevState - 60000);
    }
  }, 60000);

  useEffect(() => {
    dispatch(userProfileAction());
    setnickname(userProfileData.nickname);
  }, []);
  useEffect(() => {
    if (changeNickname) document.getElementById("nickname")?.focus();
  }, [changeNickname]);

  return (
    <Formik
      //validationSchema={validationSchema}
      initialValues={{ nickname: nickname }}
      onSubmit={async (values, actions) => {
        actions.setSubmitting(true);

        if (changeNickname) {
          setChangeNickname(false);
        } else {
          setChangeNickname(true);
        }

        actions.setSubmitting(false);
      }}
    >
      {({
        values,
        handleReset,
        setFieldValue,
        setFieldError,
        setValues,
        setErrors,
        handleSubmit,
        handleBlur,
        handleChange,
        errors,
        isSubmitting,
      }) => (
        <Form
          onReset={handleReset}
          onSubmit={handleSubmit}
          className="accountDetails"
        >
          <div>
            <label htmlFor="nickname">
              <img
                src={`data:${ICONS.IDCard.mimeType};base64, ${ICONS.IDCard.imageData}`}
                alt="posterity"
              />
              Nickname
            </label>

            <Field
              id="nickname"
              name="nickname"
              type="text"
              value={nickname}
              onChange={changeHandler}
              onBlur={handleBlur}
              disabled={!changeNickname}
              autoFocus={changeNickname}
              placeholder="Enter your desired nickname"
              className={classNames({ inputError: errors.nickname })}
            />
            {!changeNickname && <Button onClick={clickHandler}>Edit</Button>}

            {changeNickname && (
              <Button
                type="submit"
                loading={changingNicknameStatus === "pending"}
                disabled={changingNicknameStatus === "pending"}
                onClick={clickHandlerChange}
              >
                Change
              </Button>
            )}
            {errors.nickname && <p className="formError">{errors.nickname}</p>}
          </div>

          <div>
            <label htmlFor="email">
              <img
                src={`data:${ICONS.email.mimeType};base64, ${ICONS.email.imageData}`}
                alt="posterity"
              />
              Email Address:
            </label>
            <Field
              value={
                userProfileData.email ??
                "Couldn't get your email address, Please reload the page"
              }
              disabled
            />
          </div>

          <div className="accountDetails_informativeFields">
            <span>
              <img
                src={`data:${ICONS.wallet.mimeType};base64, ${ICONS.wallet.imageData}`}
                alt="posterity"
              />
              Spot Wallet address:
            </span>
            <div className="relative">
              {truncate(
                userProfileData.spotWalletAddress ??
                  "Couldn't get your spot wallet address.",
                {
                  length: 40,
                }
              )}
              <div className="fixedCopyButton">
                <CopyToClipboard
                  valueToCopy={
                    userProfileData.spotWalletAddress ??
                    "Couldn't get your spot wallet address."
                  }
                  fixed
                />
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
