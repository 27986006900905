import React, { FC } from 'react';
import Modal from 'react-modal';

import { CloseIcon } from '../../assets/icons';

interface Props {
  isOpen: boolean;
  onClose(): void;
  closeOnClickOutside?: boolean;
  className ?: string
}

export const CustomModal: FC<Props> = ({
  children,
  isOpen,
  onClose,
  closeOnClickOutside = false,
  className
  
}) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={onClose}
    shouldCloseOnOverlayClick={closeOnClickOutside}
    className= {className}
    // textGuidClass = {textGuidClass}
    closeTimeoutMS={200}
    ariaHideApp={false}
    overlayClassName='Overlay'>
    <div className='h-full'>
      <CloseIcon
        className='Modal_closeButton'
        onClick={(event: React.MouseEvent<SVGElement>) => onClose()}
      />
      {children}
    </div>
  </Modal>
);
