import { createSlice } from "@reduxjs/toolkit";

import { IPaymentModel, IValidationErrors } from "../interfaces";
import { IEarningReport } from "../interfaces/payment";
import { earningReportAction } from "./slices/earningReportAction";
import { fetchPaymentsAction } from "./slices/fetch-payments";
const initialData: {
  status: "idle" | "rejected" | "pending";
  error: IValidationErrors;
  payments: IPaymentModel[];
  earningReport: IEarningReport[];
  dataFetched: boolean;
} = {
  status: "idle",
  error: {},
  payments: [],
  earningReport: [],
  dataFetched: false,
};

export const { reducer, actions } = createSlice({
  name: "payments",
  initialState: initialData,
  reducers: {
    setMinMaxInvest(state) {},
  },
  extraReducers: (builder) => {
    // GET USER SUBMITTED TRANSACTIONS
    builder
      .addCase(fetchPaymentsAction.fulfilled, (state, action) => {
        state.status = "idle";
        state.dataFetched = true;
        if (action.payload) {
          state.payments = action.payload;
        }
      })
      .addCase(fetchPaymentsAction.pending, (state) => {
        state.status = "pending";
        state.dataFetched = false;
      })
      .addCase(fetchPaymentsAction.rejected, (state, action) => {
        state.status = "rejected";
        state.dataFetched = true;

        if (action.payload) state.error = action.payload;
      });

    builder
      .addCase(earningReportAction.fulfilled, (state, action) => {
        state.status = "idle";
        state.dataFetched = true;
        if (action.payload) {
          state.earningReport = action.payload;
        }
      })
      .addCase(earningReportAction.pending, (state) => {
        state.status = "pending";
        state.dataFetched = false;
      })
      .addCase(earningReportAction.rejected, (state, action) => {
        state.status = "rejected";
        state.dataFetched = true;
        if (action.payload) state.error = action.payload;
      });
  },
});

export default reducer;
