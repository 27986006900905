import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import { ICoinsInfoResponseModel, IValidationErrors } from "../../interfaces";

export const getCoinsInfoAction = createAsyncThunk<
  ICoinsInfoResponseModel,
  void,
  {
    rejectValue: IValidationErrors;
  }
>("user/get-coin-info", async (_, thunkAPI) => {
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_API_ROOT_NEW}/crypto/deposit`
    );

    return response.data;
  } catch (error: any) {
    thunkAPI.rejectWithValue({
      message: error.response.data.message,
      code: error.response.status,
      name: "user/get-coin-info",
    });
  }
});
