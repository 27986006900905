import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { IReportWithdraw } from "../../interfaces/withdraw/withdraw-interfaces";
import { IValidationErrors } from "../../interfaces";
export const withdrawReportAction = createAsyncThunk<
  IReportWithdraw[] | any,
  void,
  {
    rejectValue: IValidationErrors;
  }
>("report/withdraw", async (_, thunkAPI) => {
  try {
    return await axios
      .get(`${process.env.REACT_APP_API_ROOT_NEW}/report/withdrawal`)
      .then((res) => {
        return res.data.data;
      })
      .catch((error) => {
        return error;
      });
  } catch (error: any) {
    return thunkAPI.rejectWithValue({
      message: error.response.message,
      code: error.response.status,
      name: "report/withdraw",
    });
  }
});
