import { memo, useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import { ICONS } from "../../../assets/icons-base64";
import { UserDataType } from "../../../types";
import TwoFactorAuthentication from "./2fa";
import AccountDetails from "./account-details";
import ChangeEmail from "./change-email";
import ChangePassword from "./change-password";


interface Props extends RouteComponentProps {
  userData: UserDataType;
}

function Settings(props: Props) { 
  return (
    <div className="settingsWrapper">
      <div className="settingsWrapper__header">
        <img
          src={`data:${ICONS.accountSettings.mimeType};base64, ${ICONS.accountSettings.imageData}`}
          alt="posterity"
        />
        <p>Account Settings</p>
      </div>
      <div className="settingsWrapper__form">
        <AccountDetails />
        <div className="settingsWrapper__cards">
          <ChangeEmail />
          <ChangePassword />
          <TwoFactorAuthentication />
        </div>
      </div>
    </div>
  );
}

export default memo(Settings);
