import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Get referral name by referral code
export const getReferralNameAction = createAsyncThunk<
  any | undefined,
  string,
  {}
>("user/get-referral-name", async (refferalCode, thunkAPI) => {
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_API_ROOT_NEW}/auth/referral`,
      { params: { referralCode: refferalCode } }
    );

    return { referralName: response.data.data["nickname"] };
  } catch (error) {
    thunkAPI.rejectWithValue("any");
  }
});
