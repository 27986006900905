import classNames from "classnames";
import React from "react";
import { Scrollbars } from "react-custom-scrollbars-2";

// import { TickIcon } from '../../../assets/icons';
import { MLMSummaryType } from "../../../features/interfaces";
import { currencyFormat, percentageFormat } from "../../../features/utils";

function Summary({ referralData }: { referralData?: MLMSummaryType }) {
  const FAKE_DATA = [
    {
      level: 1,
      bonus: 5,
      investors: 24,
      earnings: 123,
      pending: 12360,
      investments: 1376,
    },
    {
      level: 2,
      bonus: 2,
      investors: 234,
      earnings: 12341,
      pending: 1526,
      investments: 1376,
    },
    {
      level: 3,
      bonus: 1,
      investors: 224,
      earnings: 12512432,
      pending: 5436423,
      investments: 1376,
    },
    {
      level: 4,
      bonus: 0.5,
      investors: 124,
      earnings: 123875,
      pending: 134665,
      investments: 1376,
    },
    {
      level: 5,
      bonus: 0.5,
      investors: 123,
      earnings: 12345,
      pending: 25365436,
      investments: 1376,
    },
    {
      level: 6,
      bonus: 0.5,
      investors: 8962,
      earnings: 125,
      pending: 234151346,
      investments: 1376,
    },
    {
      level: 7,
      bonus: 0.5,
      investors: 135,
      earnings: 235,
      pending: 514356,
      investments: 1376,
    },
    {
      level: 8,
      bonus: 0.25,
      investors: 96,
      earnings: 436,
      pending: 14325436,
      investments: 1376,
    },
    {
      level: 9,
      bonus: 0.25,
      investors: 976,
      earnings: 6457,
      pending: 13425236,
      investments: 1376,
    },
    {
      level: 10,
      bonus: 0.25,
      investors: 981,
      earnings: 123875,
      pending: 1287543,
      investments: 1376,
    },
    {
      level: 11,
      bonus: 0.25,
      investors: 1728,
      earnings: 182475,
      pending: 1243875,
      investments: 1376,
    },
    {
      level: 12,
      bonus: 0.25,
      investors: 9178,
      earnings: 786512,
      pending: 12378512,
      investments: 1376,
    },
    {
      level: 13,
      bonus: 0.25,
      investors: 12378,
      earnings: 487215,
      pending: 12378642,
      investments: 1376,
    },
    {
      level: 14,
      bonus: 0.25,
      investors: 87523,
      earnings: 312478512,
      pending: 1348976123,
      investments: 1376,
    },
    {
      level: 15,
      bonus: 0.25,
      investors: 89614,
      earnings: 182390467,
      pending: 98267138,
      investments: 1376,
    },
  ];

  return (
    <div
      className={classNames("card", { card__disable: !referralData?.length })}
    >
      <h1 className="card__header">Summary</h1>
      <Scrollbars
        style={{ width: "100%", position: "relative", height: "35em" }}
        className="ampSummary"
        renderTrackHorizontal={(props) => (
          <div
            {...props}
            style={{ display: "none" }}
            className="track-horizontal"
          />
        )}
      >
        <table>
          <thead>
            <tr>
              <th>Level</th>
              <th>Bonus</th>
              <th>Investments</th>
              <th>Investors</th>
              <th>Earnings</th>
              <th>Pending</th>
            </tr>
            {/* <th>Status</th> */}
          </thead>

          <tbody>
            {referralData?.length
              ? referralData.map((fd) => (
                  <tr key={fd.level}>
                    <td>{fd.level}</td>
                    <td> {percentageFormat(+fd.bonus)}</td>
                    <td className="font-bold">
                      {currencyFormat(fd.investments)}
                    </td>
                    <td>{fd.investors}</td>
                    <td>{currencyFormat(fd.earnings)}</td>
                    <td>{currencyFormat(fd.pending)}</td>
                  </tr>
                ))
              : FAKE_DATA.map((fd) => (
                  <tr key={fd.level}>
                    <td>{fd.level}</td>
                    <td> {percentageFormat(+fd.bonus / 100)}</td>
                    <td className="font-bold">
                      {currencyFormat(fd.investments)}
                    </td>
                    <td>{fd.investors}</td>
                    <td>{currencyFormat(fd.earnings)}</td>
                    <td>{currencyFormat(fd.pending)}</td>
                  </tr>
                ))}
          </tbody>
        </table>
      </Scrollbars>
    </div>
  );
}

export default React.memo(Summary);
