import { createSlice } from "@reduxjs/toolkit";
import { INotificationRes } from "../interfaces/notifications/INotificationRes";
import { fetchNotificationAction } from "./slices/fetchNotification-action";
import { INotificationData } from "../interfaces/notifications/INotificationData";

const initialData :{
    notification :{
        data : INotificationRes
        status : "idle" | "rejected" | "pending";
    }
} ={
    notification : {
        status : "idle" ,
        data : {
            notifications : []
        }
    }
}


export const {reducer , actions} = createSlice({
    name : "notification" , 
    initialState: initialData, 
    reducers : {} ,
    extraReducers : (builder) =>{
        builder
        .addCase(fetchNotificationAction.pending , (state) =>{
            state.notification.status = "pending"
        })
        .addCase(fetchNotificationAction.fulfilled , (state , action) =>{
            state.notification.status = "idle"
            state.notification.data = action.payload
        })
        .addCase(fetchNotificationAction.rejected , (state) =>{
            state.notification.status = "rejected"
        })
    }

})

export default reducer;