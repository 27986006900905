import React, { Component } from "react";
import * as d3 from "d3";
import PropTypes from "prop-types";

const dataCategory = [
  "Affiliate Matching Bonus Plan",
  "Affiliate Multi Level",
  "Investment Earnings",
];

interface Props {
  data: any;
  width: number;
  height: number;
}

interface State {}

class RadialBarChart extends Component<Props, State> {
  static propTypes = {
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    data: PropTypes.arrayOf(PropTypes.object),
  };

  state = {
    barContainers: [],
    bars: [],
    colors: ["#A9418C", "#003366", "#5B9FB2"],
  };

  static getDerivedStateFromProps(nextProps: any, prevState: any) {
    const { data } = nextProps;

    const innerRadius = 90,
      outerRadius = 250 - 10,
      min = Math.sqrt(Math.log(0.001 * 1e4)),
      max = Math.sqrt(Math.log(1000 * 1e4)),
      bigAngle = (Math.PI * 2) / (data.length + 1),
      smallAngle = bigAngle / 7;

    if (!data) return;

    const radius = (mic: any) => {
      let a = (outerRadius - innerRadius) / (min - max),
        b = innerRadius - a * max;

      return a * Math.sqrt(Math.log(mic * 1e4)) + b;
    };

    const bgWedge = d3
      .arc()
      .innerRadius(innerRadius)
      .outerRadius(outerRadius)
      .startAngle(0)
      .endAngle(bigAngle);

    const barContainers = data.map((d: any, i: any) => {
      const path = bgWedge(i);

      return { path };
    });

    const arcGen = d3.arc();

    const bars = dataCategory.map((dc, dci) => {
      const totalBar = data.map((d: any, di: number) => {
        const bar = {
          path: arcGen({
            innerRadius: innerRadius,
            outerRadius: radius(d[dc]),
            startAngle: smallAngle * (dci * 2 + 1),
            endAngle: smallAngle * (dci * 2 + 2),
          }),
          className: dc,
        };
        return bar;
      });
      return totalBar;
    });
    return { barContainers, bars };
  }

  render() {
    const { data, width, height } = this.props;

    const margin = { top: 16, right: 16, bottom: 25, left: 16 },
      bigDeg = 360 / (data.length + 1),
      innerRadius = 90,
      outerRadius = 250 - 10,
      min = Math.sqrt(Math.log(0.001 * 1e4)),
      max = Math.sqrt(Math.log(1000 * 1e4));

    const radius = (mic: any) => {
      let a = (outerRadius - innerRadius) / (min - max),
        b = innerRadius - a * max;

      return a * Math.sqrt(Math.log(mic * 1e4)) + b;
    };

    return (
      <svg
        width={width + margin.left + margin.right}
        height={height + margin.top + margin.bottom}
        // transform={`translate(${-margin.left - margin.right}, ${-margin.top})`}
        fill="#ffffff">
        <g transform={`translate(${margin.left + width / 2},${margin.top + height / 2})`}>
          <g className="axis">
            {d3.range(-3, 4).map((d) => (
              <g key={d} className="tick">
                <circle strokeWidth="2" r={radius(Math.pow(10, d))}></circle>
                {/* <text
                  dy='.32em'
                  y={-radius(Math.pow(10, d))}
                  textAnchor='middle'>
                  {d < 3 ? Math.pow(10, d).toFixed(d > 0 ? 0 : -d) : ''}
                </text> */}
              </g>
            ))}
          </g>

          {data.map((d: any, i: number) => (
            <line
              key={i}
              className="separator"
              transform={`rotate(${180 + bigDeg * (i + 0.5)})`}
              y1={innerRadius * 0.84}
              y2={outerRadius * 1.1}></line>
          ))}
          <line
            className="separator"
            transform={`rotate(${180 + bigDeg * (data.length + 0.5)})`}
            y1={innerRadius * 0.84}
            y2={outerRadius * 1.1}></line>

          {data.map((d: any, i: number) => (
            <text
              key={i}
              className="label"
              dy=".32em"
              x={i + 1 > data.length / 2 ? -outerRadius * 1.1 : outerRadius * 1.1}
              textAnchor={i + 1 > data.length / 2 ? "start" : "end"}
              transform={`rotate(${(i + 1 > data.length / 2 ? 90 : -90) + bigDeg * (i + 1)})`}
              y1={innerRadius * 0.84}
              y2={outerRadius * 1.1}>
              {d.name}
            </text>
          ))}

          <g className="legend" transform={`translate(30,0)`}>
            {dataCategory.map((d: any, index: number) => (
              <g key={index} transform={`translate(${-width / 2 + index * 18},${height / 1.8})`}>
                <text
                  className="label"
                  x={index === 0 ? index : index === 2 ? index * 20 : index * 250}>
                  {d}
                </text>
                <circle
                  cx={index === 0 ? -10 : index === 2 ? index * 16 : index * 240}
                  cy="-4"
                  r="5"
                  fill={this.state.colors[index]}></circle>
              </g>
            ))}
          </g>
          {this.state.barContainers.map((d: any, i: number) => (
            <g key={i} fill="none" className="bacteria" transform={`rotate(${bigDeg * (i + 0.5)})`}>
              <path d={d.path} />
              {this.state.bars.map((d: any, idd: number) => (
                <path key={idd} d={d[i].path} fill={this.state.colors[idd]} />
              ))}
            </g>
          ))}
        </g>
      </svg>
    );
  }
}

export { RadialBarChart };
