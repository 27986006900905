import classNames from "classnames";
import _ from "lodash";
import { INotificationProps } from "../../../features/interfaces/notifications/INotificationProps";
import moment from "moment";



export function NotificationTable({notifications} : INotificationProps) {
  return (
    <>
   <div className={"notificationItem"}>
        <div
          className={classNames("notificationItem__line bgGreenMuted")}
        >
  
          <div className="notificationItem__items ">
            <div className="notificationItem__item">
              <p>{notifications.title}</p>
              <p className="text-sm">{notifications.message}</p>
            </div>
          </div>
          <div className=" flex">
          <div className="w-60">
          <div className=" text-blue-900 text-sm font-bold rounded">{moment(notifications.createdAt).format("LLL")}</div>
          </div>
          </div>
          
        </div>
      </div>
   
    </>
  );
}
