import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Cookie from "js-cookie";

import { IValidationErrors, IWalletInfo } from "../../interfaces";

export const fetchUserWalletsAction = createAsyncThunk<
  { userWallets: IWalletInfo[] } | undefined,
  undefined,
  {
    rejectValue: IValidationErrors;
  }
>("user-wallets/fetch-wallets", async (_, thunkAPI) => {
  try {
    let token = Cookie.get("token");
    if (token?.length) {
      const response = await axios.post(
        `${process.env.REACT_APP_API_ROOT}/get-user-wallets`,
        {
          token: token,
        }
      );

      return {
        userWallets: response.data["userWallets"],
      };
    }
  } catch (error: any) {
    return thunkAPI.rejectWithValue({
      message: error.response.data.message,
      code: error.response.status,
      name: "user-wallets/fetch-wallets",
    });
  }
});
