import { reducer } from "./investment";
export { actions } from "./investment";
export { createDepositAction } from "./slices/createDepositAction";
export { fetchAdminWalletsAndRecentTransactionAction } from "./slices/fetch-admin-wallets-and-recent-transactions";
export { fetchInvestsSummaryAction } from "./slices/fetch-invests-summary";
export { getWalletAction } from "./slices/getWalletAction";
export { newInvestAction } from "./slices/new-invest";
export { submitTransactionHashAction } from "./slices/submit-transaction-hash";

export default reducer;
