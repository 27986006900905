import React from 'react';

interface KnowledgeBaseItemProps {
  header: string;
  passage: string;
}

export default function KnowledgeBaseItem(props: KnowledgeBaseItemProps) {
  return (
    <div className='lKnowledgeBase__list_item'>
      <div>
        <svg width={24} height={24} fill={'#ffffff'} viewBox='0 0 24 24'>
          <rect width={24} height={24} transform='rotate(180 12 12)' opacity={0} />
          <path d='M17 9A5 5 0 0 0 7 9a1 1 0 0 0 2 0 3 3 0 1 1 3 3 1 1 0 0 0-1 1v2a1 1 0 0 0 2 0v-1.1A5 5 0 0 0 17 9z' />
          <circle cx={12} cy={19} r={1} fill={'#ffffff'} />
        </svg>
      </div>
      <div>
        <h1>{props.header}</h1>
        <p className='KnowledgeBase-paaragraph' dangerouslySetInnerHTML={{ __html: props.passage }}></p>
      </div>
    </div>
  );
}
