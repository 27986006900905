import { RefObject, useEffect } from 'react';

export interface ResizeObserverEntry {
  target: HTMLElement;
  contentRect: DOMRectReadOnly;
}
export type ObserverCallback = (entry: DOMRectReadOnly) => void;

export const useResizeObserver = (ref: RefObject<HTMLElement>, callback: ObserverCallback) => {
  useEffect(() => {
    const resizeObserver = new (window as any).ResizeObserver((entries: ResizeObserverEntry[]) => {
      callback(entries[0].contentRect);
    });

    resizeObserver.observe(ref.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, [ref, callback]);
};

// Example
// function Dashboard() {
//   const [dimensions, setDimensions] = useState({ width: 200, height: 100 });
//   const parentElement = useRef(null);

//   useResizeObserver(parentElement, entry => {
//     const { width, height } = entry;

//     setDimensions({
//       width,
//       height
//     });
//   });

//   return (
//     <div ref= { parentElement } style = {{ height: "100vh" }
// }>
//   { dimensions.width } x { dimensions.height }
// </div>
//   );
// }
