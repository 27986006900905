import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { IValidationErrors } from "../../interfaces";
import { IUserProfileData } from "../../interfaces/userProfile/IUserProfile";

export const userProfileUtilsAction = createAsyncThunk<
  IUserProfileData,
  undefined,
  {
    rejectValue: IValidationErrors;
  }
>("user/profile-utils", async (_, thunkAPI) => {
  try {
    return await axios
      .get(`${process.env.REACT_APP_API_ROOT_NEW}/utils/profile`)
      .then((response: any) => {
        return response.data.data;
      })
      .catch((error: any) => {
        return thunkAPI.rejectWithValue({
          message: error.response.data.message,
          code: error.response.status,
          name: "user/profile-utils",
        });
      });
  } catch (error: any) {
    console.error("error", error);
    return thunkAPI.rejectWithValue({
      message: error.response.data.message,
      code: error.response.status,
      name: "user/profile-utils",
    });
  }
});
