import React, { useEffect, useState } from 'react';
import * as d3 from 'd3';

interface ILineChartProps {
  width: number;
  height: number;
}

export function LineChart(props: ILineChartProps) {
  const { width, height } = props;

  const [linePath, setLinePath] = useState<string>('');

  let n = 20;

  const xScale = d3
    .scaleLinear()
    .domain([0, n - 1]) // input
    .range([0, width]); // output

  const yScale = d3.scaleLinear().domain([0, 1]).range([height, 0]);

  const line = d3
    .line()
    // @ts-ignore
    .x((d, i) => {
      return xScale(i);
    })
    // @ts-ignore
    .y((d) => {
      // @ts-ignore
      return yScale(d.y);
    })
    .curve(d3.curveLinear);

  const dataset: { y: number }[] = d3.range(n).map(function (d) {
    return { y: d3.randomUniform(1)() };
  });

  useEffect(() => {
    if (!linePath.length) {
      // @ts-ignore
      setLinePath(line(dataset));
    }
  }, [line, linePath, dataset]);

  return (
    <svg width={width} height={height}>
      <g transform={`translate(${0}, ${0})`}>
        <defs>
          <linearGradient id='myGradient' gradientTransform='rotate(90)'>
            <stop offset='3%' stopColor='rgb(232,69,69, 1)' />
            <stop offset='100%' stopColor='rgb(232,69,69, 0.1)' />
          </linearGradient>
        </defs>

        <line x1='0' y1='50%' x2='100%' y2='50%' stroke='#00587a' opacity='0.16' strokeWidth='2' strokeDasharray='5' />

        {/* <path d={this.state.linePath} fill='none' stroke='#00af91' strokeWidth='4' /> */}
        <path
          d={linePath}
          stroke='#ec4646'
          strokeWidth='3'
          fill='none'
          // fill='url(#myGradient)'
        />
      </g>
    </svg>
  );
}
