import React from "react";
import SunburstChart from "./SunburstChart";
import Sunburst from "sunburst-chart";
import data from "./data";

import * as d3 from "d3";
class SunWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.myRef = React.createRef();
    this.state = {
      myChart: this.props.data,
    };
  }

  componentDidMount() {
    const color = d3.scaleOrdinal(d3.schemeAccent);
    Sunburst()
      // .data(data)
      .data(this.state.myChart)
      .label("name")
      .size("size")
      .width(this.props.width)
      .color((d, parent) => color(parent ? parent.data.name : null))
      .tooltipContent((d, node) => `<i></i>`)(this.myRef.current);
  }

  render() {
    return (
      <div>
        <SunburstChart ref={this.myRef} />
      </div>
    );
  }
}
export default SunWrapper;
