import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import { IMatchingData, IValidationErrors } from "../../interfaces";

export const getMatchingBonusData = createAsyncThunk<
  IMatchingData,
  undefined,
  {
    rejectValue: IValidationErrors;
  }
>("user/get-matching-data", async (_, { rejectWithValue }) => {
  try {
    return await axios
      .get(`${process.env.REACT_APP_API_ROOT_NEW}/matching/matching`)
      .then((response) => {
        return response.data.data;
      })
      .catch((e) => {
        let message = "";
        return rejectWithValue({
          message: message,
          code: e.response.status,
          name: "user/get-matching-data",
        });
      });
  } catch (error: any) {
    let message = "";
    console.error(error);
    return rejectWithValue({
      message: message,
      code: error.response.status,
      name: "user/get-matching-data",
    });
  }
});
