import { FC, MouseEvent, memo, useState } from "react";
import { LockIcon } from "../../../assets/icons";
import { currencyFormat } from "../../../features/utils";
import { useAppSelector } from "../../../store";
import { Button } from "../../common-components";
import { useDispatch } from "react-redux";
import { getCoinsInfoAction } from "../../../features/investment/slices/getCoinsInfoAction";
import { getWalletAction } from "../../../features/investment";
const FundInfo: FC<{
  setDepositFormVisible(): void;
  setInvestFormVisible(): void;
  setInvestChoiceFormVisible() : void;
  hasInvestedAt: string | undefined | null;
  balance: number | string;
  transactionInProcess: boolean;
}> = ({
  setDepositFormVisible,
  hasInvestedAt,
  balance,
  transactionInProcess = false,
  setInvestFormVisible,
  setInvestChoiceFormVisible
}) => {
  const [displayDescription, setDisplayDescription] = useState(false);
  const { getWalletData } = useAppSelector(
    (state) => state.investment.getwallet
  );
  const { txidPanelOpen } = useAppSelector(
    (state) => state.investment.patchtxidInfo
  );
  const dispatch = useDispatch();
  
 

  return (
    <div className="fundsInfo">
      {hasInvestedAt === null && Number(getWalletData.balance) === 0 && (
        <h3 className="mb-8">
          To activate your account, you need to deposit more than $20.
        </h3>
      )}
      {Number(getWalletData.balance) > 0 ? (
        <>
          <h3>Available Balance</h3>
          <h1>
            {currencyFormat(Number(getWalletData.balance), undefined, true)}
          </h1>
        </>
      ) : (
        !(hasInvestedAt === null) && (
          <>
            <h3>Your Balance Is Empty!</h3>
            <h1>
              {currencyFormat(Number(getWalletData.balance), undefined, true)}
            </h1>
          </>
        )
      )}
      <div>
        <Button
          disabled={txidPanelOpen}
          locked={txidPanelOpen}
          onClick={(event: MouseEvent<HTMLButtonElement>) => {
            event.preventDefault();

            if (transactionInProcess) return;
            // dispatch get coins info
            dispatch(getCoinsInfoAction());
            setDepositFormVisible();
          }}
        >
          Deposit
        </Button>

        <Button
          locked={
            // hasInvestedAt === null
            hasInvestedAt === null && Number(getWalletData.balance) < 20
              ? true
              : hasInvestedAt === null && Number(getWalletData.balance) < 20
              ? true
              : false
          }
          disabled={
            // hasInvestedAt === null
            hasInvestedAt === null && Number(getWalletData.balance) < 20
              ? true
              : hasInvestedAt === null && Number(getWalletData.balance) < 20
              ? true
              : false
          }
          onClick={(event: MouseEvent<HTMLButtonElement>) => {
            event.preventDefault();

            setInvestChoiceFormVisible();
            dispatch(getWalletAction())
          }}
        >
          Make An Investment
          {hasInvestedAt === null && Number(getWalletData.balance) < 20 ? (
            <LockIcon className="absolute" />
          ) : hasInvestedAt === null && Number(getWalletData.balance) < 20 ? (
            <LockIcon className="absolute" />
          ) : null}
        </Button>
      </div>
    </div>
  );
};

export default memo(FundInfo);
