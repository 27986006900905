import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { IgetWithdrawData } from "../../interfaces/withdraw/withdraw-interfaces";
import { IValidationErrors } from "../../interfaces";

export const getWithdrawAction = createAsyncThunk<
  IgetWithdrawData,
  void,
  {
    rejectValue: IValidationErrors;
  }
>("user/withdraw", async (_, thunkAPI) => {
  try {
    return await axios
      .get(`${process.env.REACT_APP_API_ROOT_NEW}/withdraw/withdraw`)
      .then((response) => {
        const messageInjectedRes = {
          ...response.data.data,
          message: response.data.message,
        };
        return messageInjectedRes;
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue({
          message: error.response.data.message,
          code: error.response.status,
          name: "user/withdraw",
        });
      });
  } catch (error: any) {
    return thunkAPI.rejectWithValue({
      message: error.response.data.message,
      code: error.response.status,
      name: "user/withdraw",
    });
  }
});
