import classNames from "classnames";
import moment from "moment";
import numbro from "numbro";
import React, { useState } from "react";

import { TickIcon } from "../../../assets/icons";

import { AccordionSizes } from "../";

interface Props {
  size?: AccordionSizes;
}

export function Accordion(props: Props) {
  const [accordionOpen, setAccordionOpen] = useState(false);
  const { size } = props;

  const renderHeader = () => {
    switch (size) {
      case AccordionSizes.normal:
        return (
          <>
            <div className="accordionItem__item">
              <p>#</p>
              <p>1</p>
            </div>
            <div className="accordionItem__item">
              <p>Type</p>
              <p>BTC</p>
            </div>
            <div className="accordionItem__item cryptoAmount">
              <p>Amount (Crypto)</p>
              <p>{1.23}</p>
            </div>
            <div className="accordionItem__item usdAmount">
              <p>Amount (USD)</p>
              <p>
                {numbro(1200000)
                  .format({
                    mantissa: 2,
                    average: true,
                    optionalMantissa: true,
                  })
                  .toUpperCase()}{" "}
              </p>
            </div>
            <div className="accordionItem__item transactionFee">
              <p>Transaction Fee:</p>
              <p>
                {0.23}% -{" "}
                {numbro(120)
                  .format({
                    mantissa: 2,
                    average: true,
                  })
                  .toUpperCase()}{" "}
              </p>
            </div>
            <div className="accordionItem__item">
              <p>Requested Date</p>
              <p>{moment().format("YYYY MMMM DD HH:MM:ss")}</p>
            </div>

            <div className="accordionItem__item">
              <p>Wallet Address</p>
              <p>0xc5f1d05d25b1a296d2c545ef98b296b7dc110132</p>
            </div>
            <div className="accordionItem__item status">
              <p>Status</p>
              <p>
                <TickIcon />
                Approved
              </p>
            </div>
          </>
        );

      case AccordionSizes.large:
        return (
          <>
            <div className="accordionItem__item">
              <p>#</p>
              <p>1</p>
            </div>
            <div className="accordionItem__item">
              <p>Type</p>
              <p>BTC</p>
            </div>
            <div className="accordionItem__item cryptoAmount">
              <p>Amount (Crypto)</p>
              <p>{1.23}</p>
            </div>
            <div className="accordionItem__item usdAmount">
              <p>Amount (USD)</p>
              <p>
                {numbro(1200000)
                  .format({
                    mantissa: 2,
                    average: true,
                    optionalMantissa: true,
                  })
                  .toUpperCase()}{" "}
              </p>
            </div>
            <div className="accordionItem__item transactionFee">
              <p>Transaction Fee:</p>
              <p>
                {0.23}% -{" "}
                {numbro(120)
                  .format({
                    mantissa: 2,
                    average: true,
                  })
                  .toUpperCase()}{" "}
              </p>
            </div>
            <div className="accordionItem__item">
              <p>Requested Date</p>
              <p>{moment().format("YYYY MMMM DD HH:MM:ss")}</p>
            </div>
            <div className="accordionItem__item status">
              <p>Status</p>
              <p>
                <TickIcon />
                Approved
              </p>
            </div>
          </>
        );

      case AccordionSizes.medium:
        return (
          <>
            <div className="accordionItem__item">
              <p>#</p>
              <p>1</p>
            </div>
            <div className="accordionItem__item">
              <p>Type</p>
              <p>BTC</p>
            </div>
            <div className="accordionItem__item cryptoAmount">
              <p>Amount (Crypto)</p>
              <p>{1.23}</p>
            </div>
            <div className="accordionItem__item usdAmount">
              <p>Amount (USD)</p>
              <p>
                {numbro(1200000)
                  .format({
                    mantissa: 2,
                    average: true,
                    optionalMantissa: true,
                  })
                  .toUpperCase()}{" "}
              </p>
            </div>
            <div className="accordionItem__item">
              <p>Requested Date</p>
              <p>{moment().format("YYYY MMMM DD HH:MM:ss")}</p>
            </div>
            <div className="accordionItem__item status">
              <p>Status</p>
              <p>
                <TickIcon />
                Approved
              </p>
            </div>
          </>
        );

      case AccordionSizes.small:
        return (
          <>
            <div className="accordionItem__item">
              <p>#</p>
              <p>1</p>
            </div>
            <div className="accordionItem__item">
              <p>Type</p>
              <p>BTC</p>
            </div>
            <div className="accordionItem__item cryptoAmount">
              <p>Amount (Crypto)</p>
              <p>{1.23}</p>
            </div>
            <div className="accordionItem__item usdAmount">
              <p>Amount (USD)</p>
              <p>
                {numbro(1200000)
                  .format({
                    mantissa: 2,
                    average: true,
                    optionalMantissa: true,
                  })
                  .toUpperCase()}{" "}
              </p>
            </div>
            <div className="accordionItem__item status">
              <p>Status</p>
              <p>
                <TickIcon />
                Approved
              </p>
            </div>
          </>
        );
    }
  };

  const renderContent = () => {
    switch (size) {
      case AccordionSizes.normal:
        return (
          <>
            <div className="accordionItem__item">
              <p>Transaction Hash:</p>
              <p>0xc5f1d05d25b1a296d2c545ef98b296b7dc110132</p>
            </div>
            <div className="accordionItem__item">
              <p>Date Approved:</p>
              <p>{moment().format("YYYY MMMM DD HH:MM:ss")}</p>
            </div>
          </>
        );

      case AccordionSizes.large:
        return (
          <>
            <div className="accordionItem__item">
              <p>Transaction Hash:</p>
              <p>0xc5f1d05d25b1a296d2c545ef98b296b7dc110132</p>
            </div>
            <div className="accordionItem__item">
              <p>Date Approved:</p>
              <p>{moment().format("YYYY MMMM DD HH:MM:ss")}</p>
            </div>
            <div className="accordionItem__item">
              <p>Wallet Address</p>
              <p>0xc5f1d05d25b1a296d2c545ef98b296b7dc110132</p>
            </div>
          </>
        );

      case AccordionSizes.medium:
        return (
          <div className="accordionItem__itemsCol">
            <div className="accordionItem__item">
              <p>Transaction Hash:</p>
              <p>0xc5f1d05d25b1a296d2c545ef98b296b7dc110132</p>
            </div>
            <div className="accordionItem__item transactionFee">
              <p>Transaction Fee:</p>
              <p>
                {0.23}% -{" "}
                {numbro(120)
                  .format({
                    mantissa: 2,
                    average: true,
                  })
                  .toUpperCase()}{" "}
              </p>
            </div>
            <div className="accordionItem__item">
              <p>Date Approved:</p>
              <p>{moment().format("YYYY MMMM DD HH:MM:ss")}</p>
            </div>
            <div className="accordionItem__item">
              <p>Wallet Address</p>
              <p>0xc5f1d05d25b1a296d2c545ef98b296b7dc110132</p>
            </div>
          </div>
        );

      case AccordionSizes.small:
        return (
          <div className="accordionItem__itemsCol">
            <div className="accordionItem__item">
              <p>Transaction Hash:</p>
              <p>0xc5f1d05d25b1a296d2c545ef98b296b7dc110132</p>
            </div>
            <div className="accordionItem__item transactionFee">
              <p>Transaction Fee:</p>
              <p>
                {0.23}% -{" "}
                {numbro(120)
                  .format({
                    mantissa: 2,
                    average: true,
                  })
                  .toUpperCase()}{" "}
              </p>
            </div>
            <div className="accordionItem__item">
              <p>Requested Date</p>
              <p>{moment().format("YYYY MMMM DD HH:MM:ss")}</p>
            </div>
            <div className="accordionItem__item">
              <p>Date Approved:</p>
              <p>{moment().format("YYYY MMMM DD HH:MM:ss")}</p>
            </div>
            <div className="accordionItem__item">
              <p>Wallet Address</p>
              <p>0xc5f1d05d25b1a296d2c545ef98b296b7dc110132</p>
            </div>
          </div>
        );
    }
  };

  return (
    <div
      className={classNames("accordionItem", {
        accordionItem__opened: accordionOpen,
      })}
    >
      <div
        className="accordionItem__line"
        onClick={(event: React.MouseEvent) => setAccordionOpen(!accordionOpen)}
      >
        <div className="accordionItem__items">{renderHeader()}</div>
        <span className="accordionItem__icon" />
      </div>

      <div className="accordionItem__inner">
        <div className="accordionItem__content">
          <div className={classNames("accordionItem__items bordered")}>
            {renderContent()}
          </div>
        </div>
      </div>
    </div>
  );
}
