import classNames from "classnames";
import { upperCase, upperFirst } from "lodash";
import { v4 as uuid } from "uuid";
import { ApprovedIcon, ClockIcon, RejectedIcon } from "../../../assets/icons";
import { ITransactionModel } from "../../../features/interfaces";
import { currencyFormat, timeFormat } from "../../../features/utils";
import { useAppSelector } from "../../../store";

export default function TransactionsHistory({
  history,
}: {
  history: ITransactionModel[] | any;
}) {
  const FAKE_DATA = [
    {
      date: "Wed Sep 22 2021 16:34:11",
      asset: "eth",
      amount: 1.07082,
      status: "approved",
    },
    {
      date: "Mon Aug 10 2021 13:30:11",
      asset: "btc",
      amount: 0.082,
      status: "pending",
    },
    {
      date: "Fri Jan 2 2021 10:56:00",
      asset: "xrp",
      amount: 12.02,
      status: "approved",
    },
    {
      date: "Wed Oct 19 2021 10:30:57",
      asset: "dash",
      amount: 70,
      status: "rejected",
    },
    {
      date: "Sat Jul 16 2021 21:08:51",
      asset: "dash",
      amount: 1.07,
      status: "rejected",
    },
  ];
  const {recentDeposits} = useAppSelector(state => state.investment.getwallet.getWalletData)
  console.log("recentDeposits" , recentDeposits , history)
  return (
    <div
      className={classNames("card deposit_recentTransactions", {
        card__disable:
          history === undefined || (history && history.length === 0),
      })}
    >
      <h1 className="card__header">Recent Transactions</h1>
      <table>
        <thead>
          <tr>
            <th>Date</th>
            <th>Amount</th>
            <th>Status</th>
          </tr>
        </thead>

        <tbody>
          {history && history.length > 0
            ? history.map((item: any) => (
                <tr key={uuid()}>
                  {/* <td>{numericTimeFormat(item.createdAt)}</td> */}
                  <td>{timeFormat(item.createdAt)}</td>

                  <td>
                    {item.usdAmount > 0
                      ? `${currencyFormat(item.usdAmount)}`
                      : "-"}
                  </td>
                  <td>
                    <div>
                      {item.status === "success" ? (
                        <ApprovedIcon className="w-4 h-4 ml-1" />
                      ) : item.status === "pending" ? (
                        <ClockIcon className="w-4 h-4 ml-1" />
                      ) : (
                        <RejectedIcon className="w-4 h-4 ml-1" />
                      )}
                      <p
                        className={classNames({
                          "text-green-600": item.status === "success",
                          "text-red-600": item.status === "failed",
                          "text-red-700": item.status === "canceled",
                          "text-gray-600": item.status === "pending",
                        })}
                      >
                        {upperFirst(
                          item.status === "failed"
                            ? "Failed"
                            : item.status === "success"
                            ? "Approved"
                            : item.status === "canceled" ? "Canceled" : "Pending"
                        )}

                        
                      </p>
                    </div>
                  </td>
                </tr>
              ))
            : FAKE_DATA.map((item) => (
                <tr key={uuid()}>
                  <td>
                    <p className="leading-none text-gray-500">
                      {timeFormat(item.date).slice(2)}
                    </p>
                  </td>
                  <td>
                    <p className="leading-none text-gray-500">{`${
                      item.amount
                    } ${item.asset.toUpperCase()}`}</p>
                  </td>
                  <td>
                    <div>
                      {item.status === "approved" ? (
                        <ApprovedIcon className="w-4 h-4 ml-2" />
                      ) : item.status === "pending" ? (
                        <ClockIcon className="w-4 h-4 ml-2" />
                      ) : (
                        <RejectedIcon className="w-4 h-4 ml-2" />
                      )}
                      <p
                        className={classNames({
                          "text-green-600": item.status === "approved",
                          "text-red-600": item.status === "rejected",
                          "text-gray-600": item.status === "pending",
                        })}
                      >
                        {item.status === "rejected"
                          ? "Cancelled"
                          : upperFirst(item.status)}
                      </p>
                    </div>
                  </td>
                </tr>
              ))}
        </tbody>
      </table>
    </div>
  );
}
