import * as React from "react";
import {
  RouteComponentProps,
  Link as RouterLink,
  useHistory,
} from "react-router-dom";
// @ts-ignore
import { Navigation, Route, Screen, Link, glide } from "react-tiger-transition";
import "react-tiger-transition/styles/main.min.css";

import { RightArrow2Icon, LoginIcon, LogoIcon } from "../../assets/icons";
import AboutPosterity from "./AboutPosterity";
import DEFIPlatform from "./DEFIPlatform";
import HomePage from "./HomePage";
import HowPosterityWorks from "./HowPosterityWorks";
import HybridAffiliatePlan from "./HybridAffiliatePlan";
import IntroducingPosterity from "./IntroducingPosterity";
import KnowledgeBase from "./KnowledgeBase";
import MakeMoreMoney from "./MakeMoreMoney";
import Security from "./Security";
import Technology from "./Technology";
import Cookies from "js-cookie";

interface Props extends RouteComponentProps {}

export default function LandingPage(props: Props) {
  const history = useHistory();
  React.useEffect(() => {
    const token = Cookies.get("token");
    if (token) history.push("/panel");
  });
  const styles = {
    paddingRight: 17,
    overflow: "hidden",
    boxSizing: "content-box",
  };

  // inject glide styles
  glide({
    name: "glide-left",
    opacity: 1,
  });

  glide({
    name: "glide-right",
    direction: "right",
    opacity: 1,
  });

  glide({
    name: "glide-top",
    direction: "top",
    opacity: 1,
  });

  glide({
    name: "glide-bottom",
    direction: "bottom",
    opacity: 1,
  });

  return (
    <div className="landingWrapper">
      <Navigation>
        <div className="landingWrapper__links">
          <RouterLink to="/">
            {/* <img src={Logo} alt='posterity' /> */}
            <span className="logoIcon">
              <LogoIcon />
            </span>
          </RouterLink>
          <RouterLink to="/auth">
            <span className="logoIcon">
              <LoginIcon className="-ml-1" />
            </span>
          </RouterLink>
        </div>

        <Route exact path="/">
          <Screen style={{ ...styles }}>
            <HomePage
              {...props}
              buttons={
                <>
                  <Link
                    to="/introducing"
                    className="landingWrapper__link linkRight"
                    transition="glide-left"
                  >
                    Introducing Posterity
                    <RightArrow2Icon />
                  </Link>
                </>
              }
            />
          </Screen>
        </Route>

        <Route exact path="/introducing">
          <Screen style={{ ...styles }}>
            <IntroducingPosterity
              {...props}
              buttons={
                <>
                  <Link
                    to="/"
                    className="landingWrapper__link linkLeft"
                    transition="glide-right"
                  >
                    Home
                    <RightArrow2Icon />
                  </Link>

                  <Link
                    to="/how-posterity-works"
                    className="landingWrapper__link linkRight"
                    transition="glide-left"
                  >
                    How Posterity Works
                    <RightArrow2Icon />
                  </Link>

                  <Link
                    to="/security"
                    className="landingWrapper__link linkBottom"
                    transition="glide-top"
                  >
                    Security
                    <RightArrow2Icon />
                  </Link>
                </>
              }
            />
          </Screen>
        </Route>

        <Route exact path="/security">
          <Screen style={{ ...styles }}>
            <Security
              {...props}
              buttons={
                <>
                  <Link
                    to="/introducing"
                    className="landingWrapper__link linkTop"
                    transition="glide-bottom"
                  >
                    <RightArrow2Icon />
                    Introduction Posterity
                  </Link>

                  <Link
                    to="/technology"
                    className="landingWrapper__link linkBottom"
                    transition="glide-top"
                  >
                    Technology
                    <RightArrow2Icon />
                  </Link>
                </>
              }
            />
          </Screen>
        </Route>

        <Route exact path="/technology">
          <Screen style={{ ...styles }}>
            <Technology
              {...props}
              buttons={
                <>
                  <Link
                    to="/security"
                    className="landingWrapper__link linkTop"
                    transition="glide-bottom"
                  >
                    <RightArrow2Icon />
                    Security
                  </Link>

                  <Link
                    key="linkToDefi_formTechnology"
                    to="/defi-platform"
                    className="landingWrapper__link linkLeft"
                    style={{
                      transform: "translate(9.5em, 150%) rotate(90deg)",
                    }}
                    transition="glide-right"
                  >
                    DeFi Platform
                    <RightArrow2Icon />
                  </Link>

                  <Link
                    to="/about-posterity"
                    className="landingWrapper__link linkBottom"
                    transition="glide-top"
                  >
                    About Posterity
                    <RightArrow2Icon />
                  </Link>
                </>
              }
            />
          </Screen>
        </Route>

        <Route exact path="/defi-platform">
          <Screen style={{ ...styles }}>
            <DEFIPlatform
              {...props}
              buttons={
                <>
                  <Link
                    to="/technology"
                    className="landingWrapper__link linkRight"
                    transition="glide-left"
                  >
                    Technology
                    <RightArrow2Icon />
                  </Link>

                  <Link
                    to="/knowledge-base"
                    className="landingWrapper__link linkBottom"
                    transition="glide-top"
                  >
                    Knowledge Base
                    <RightArrow2Icon />
                  </Link>
                </>
              }
            />
          </Screen>
        </Route>

        <Route exact path="/about-posterity">
          <Screen style={{ ...styles }}>
            <AboutPosterity
              {...props}
              buttons={
                <>
                  <Link
                    to="/technology"
                    className="landingWrapper__link linkTop"
                    transition="glide-bottom"
                  >
                    <RightArrow2Icon />
                    Technology
                  </Link>

                  <Link
                    to="/make-more-money"
                    className="landingWrapper__link linkRight"
                    transition="glide-left"
                  >
                    Make More Money
                    <RightArrow2Icon />
                  </Link>

                  <Link
                    to="/knowledge-base"
                    className="landingWrapper__link linkLeft"
                    transition="glide-right"
                  >
                    Knowledge Base
                    <RightArrow2Icon />
                  </Link>
                </>
              }
            />
          </Screen>
        </Route>

        <Route exact path="/make-more-money">
          <Screen style={{ ...styles }}>
            <MakeMoreMoney
              {...props}
              buttons={
                <>
                  <Link
                    to="/how-posterity-works"
                    className="landingWrapper__link linkTop"
                    transition="glide-bottom"
                  >
                    <RightArrow2Icon />
                    How Posterity Works
                  </Link>

                  <Link
                    to="/about-posterity"
                    className="landingWrapper__link linkLeft"
                    transition="glide-right"
                  >
                    About Posterity
                    <RightArrow2Icon />
                  </Link>

                  <Link
                    to="/plans"
                    className="landingWrapper__link linkRight"
                    transition="glide-left"
                  >
                    Hybrid Affiliate Plan <RightArrow2Icon />
                  </Link>
                </>
              }
            />
          </Screen>
        </Route>

        <Route exact path="/plans">
          <Screen style={{ ...styles }}>
            <HybridAffiliatePlan
              {...props}
              buttons={
                <>
                  <Link
                    to="/make-more-money"
                    className="landingWrapper__link linkLeft"
                    transition="glide-right"
                  >
                    Make More Money
                    <RightArrow2Icon />
                  </Link>
                </>
              }
            />
          </Screen>
        </Route>

        <Route exact path="/how-posterity-works">
          <Screen style={styles}>
            <HowPosterityWorks
              buttons={
                <>
                  <Link
                    to="/introducing"
                    className="landingWrapper__link linkLeft"
                    transition="glide-right"
                  >
                    Introducing Posterity
                    <RightArrow2Icon />
                  </Link>

                  <Link
                    to="/make-more-money"
                    className="landingWrapper__link linkBottom"
                    transition="glide-top"
                  >
                    Make More Money <RightArrow2Icon />
                  </Link>
                </>
              }
            />
          </Screen>
        </Route>

        <Route exact path="/knowledge-base">
          <Screen style={{ ...styles }}>
            <KnowledgeBase
              {...props}
              buttons={
                <>
                  <Link
                    to="/defi-platform"
                    className="landingWrapper__link linkTop"
                    transition="glide-bottom"
                  >
                    <RightArrow2Icon />
                    DeFi Platform
                  </Link>

                  <Link
                    to="/about-posterity"
                    className="landingWrapper__link linkRight"
                    transition="glide-left"
                  >
                    About Posterity
                    <RightArrow2Icon />
                  </Link>
                </>
              }
            />
          </Screen>
        </Route>
      </Navigation>
    </div>
  );
}
