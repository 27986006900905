import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { IValidationErrors } from "../../interfaces";

export const verify2FAAction = createAsyncThunk<
  {} | undefined,
  { code: string },
  {
    rejectValue: IValidationErrors;
  }
>("user/verify2FA", async (code2fa, thunkAPI) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_ROOT_NEW}/user/2fa`,
      code2fa
    );
    console.error("verify2FAAction response", response);
    return response.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue({
      message: error.interceptedMessage,
      code: error.response.status,
      name: "user/verify2FA",
    });
  }
});
