import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import { IValidationErrors, IWalletInfo } from "../../interfaces";

export const addUserWalletAction = createAsyncThunk<
  { userWallets: IWalletInfo[] },
  { newWalletAddress: string; coinType: string },
  {
    rejectValue: IValidationErrors;
  }
>("user-wallets/add-wallet", async (newWalletAddress, thunkAPI) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_ROOT}/add-user-wallet`,
      newWalletAddress
    );

    return { userWallets: response.data["userWallets"] };
  } catch (error: any) {
    let message = "";

    switch (error.response.status) {
      case 400:
        message = "Incorrect wallet format. Please try again.";
        break;
      case 409:
        message = "Please use another wallet address to continue.";
        break;
      case 500:
        message = "Something went wrong on our side, please try again later.";
        break;
    }

    return thunkAPI.rejectWithValue({
      message: message,
      code: error.response.status,
      name: "user-wallets/add-wallet",
    });
  }
});
