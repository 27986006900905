import { createSlice } from "@reduxjs/toolkit";

import { IMLMData, IValidationErrors } from "../interfaces";
import { fetchMultiLevelDataAction } from "./slices/fetch-multi-level-data";

const initialData: {
  status: "idle" | "rejected" | "pending";
  error: IValidationErrors;
  dataFetched: boolean;
  data: IMLMData | null;
} = {
  status: "idle",
  error: {},
  dataFetched: false,
  data: null,
};

export const { reducer, actions } = createSlice({
  name: "multiLevelData",
  initialState: initialData,
  reducers: {},
  extraReducers: (builder) => {
    // FETCH MULTILEVEL DATA
    builder
      .addCase(fetchMultiLevelDataAction.fulfilled, (state, action) => {
        state.status = "idle";
        state.data = action.payload;
        state.data.mlmSummary.sort((a, b) => a.level - b.level);
        state.dataFetched = true;
      })
      .addCase(fetchMultiLevelDataAction.pending, (state) => {
        state.status = "pending";
      })
      .addCase(fetchMultiLevelDataAction.rejected, (state, action) => {
        state.status = "rejected";
        if (action.payload) state.error = action.payload;
      });
  },
});

export default reducer;
