import { Form, Formik, useFormikContext } from "formik";
import { debounce } from "lodash";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { ICONS } from "../../../../assets/icons-base64";
import { requestOTPAction } from "../../../../features/authentication";
import { ajaxErrorAlert, ajaxSuccessAlert } from "../../../../features/utils";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { Button } from "../../../common-components";
import GAModal from "../../../common-components/GAModal";
import PasswordForm from "./PasswordForm";

export default function ChangePassword() {
  const [openPasswordForm, setOpenPasswordForm] = useState(false);
  const [openGAForm, setOpenGAForm] = useState(false);
  const [GAapproved, setGAapproved] = useState(false);
  const dispatch = useAppDispatch();
  const { twoFaAuthenticationAt } = useAppSelector(
    (state) => state.user.userProfileDataUtils.data
  );
  const setOpenPasswordFormDebounced = debounce(setOpenPasswordForm, 400);
  const setOpenGAFormDebounced = debounce(setOpenGAForm, 400);

  const ReSubmitForm = ({ setGAapproved }: any) => {
    // Grab values and submitForm from context

    const { values, submitForm } = useFormikContext();
    useEffect(() => {
      if (GAapproved && twoFaAuthenticationAt) {
        dispatch(requestOTPAction({ type: "password" }))
          .then((res) => {
            res.meta.requestStatus === "fulfilled" &&
              ajaxSuccessAlert(
                "Confirmation code has been sent to your email, use that to change your password."
              ).then((res) => {
                if (res.value) {
                  Swal.close();
                  setOpenPasswordFormDebounced(true);
                }
              });
            res.meta.requestStatus === "rejected" &&
              ajaxErrorAlert(
                "Couldn't send the Confirmation code to your email. Please try again later",
                "Error",
                { timer: 10000 }
              ).then((res) => {
                if (res.value) {
                  Swal.close();
                }
              });
          })
          .catch((err) => console.error(err));
        setGAapproved(false);
      }
    }, [submitForm]);

    return null;
  };

  return (
    <>
      <GAModal
        isOpen={openGAForm}
        onClose={() => setOpenGAForm(false)}
        setGAapproved={setGAapproved}
      />

      <PasswordForm
        isOpen={openPasswordForm}
        onClose={() => setOpenPasswordForm(false)}
      />

      <Formik
        initialValues={{}}
        onSubmit={async (values, actions) => {
          actions.setSubmitting(true);

          if (twoFaAuthenticationAt === null) {
            await dispatch(requestOTPAction({ type: "password" }))
              .then((res) => {
                res.meta.requestStatus === "fulfilled" &&
                  ajaxSuccessAlert(
                    "Confirmation code has been sent to your email, use that to change your password."
                  ).then((res) => {
                    if (res.value) {
                      Swal.close();
                      setOpenPasswordFormDebounced(true);
                    }
                  });
                res.meta.requestStatus === "rejected" &&
                  ajaxErrorAlert(
                    "Couldn't send the Confirmation code to your email. Please try again later",
                    "Error",
                    { timer: 10000 }
                  ).then((res) => {
                    if (res.value) {
                      Swal.close();
                    }
                  });
              })
              .catch((err) => console.error(err));
            actions.setSubmitting(false);
          }

          if (twoFaAuthenticationAt !== null) {
            !GAapproved && setOpenGAFormDebounced(true);
          }
        }}
      >
        {({ handleReset, handleSubmit }) => (
          <Form
            onReset={handleReset}
            onSubmit={handleSubmit}
            className="settingsCard"
          >
            <div>
              <h3>
                <img
                  src={`data:${ICONS.password.mimeType};base64, ${ICONS.password.imageData}`}
                  alt="posterity"
                />
                Change Password
              </h3>

              <span>
                By hitting the following button your will receive a verification
                code in your mailbox, use that pin code to change your password.
              </span>
            </div>
            <Button type="submit">Change My Password</Button>
            {GAapproved && <ReSubmitForm setGAapproved={setGAapproved} />}
          </Form>
        )}
      </Formik>
    </>
  );
}
