import React, { useRef, useEffect } from "react";

interface Props {
  buttons: React.ReactNode;
}

export default function MakeMoreMoney(props: Props) {
  const _canvas = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const canvas = _canvas.current!;
    const context = canvas.getContext("2d")!;
    context.canvas.width = document.body.clientWidth;
    context.canvas.height = document.body.clientHeight;
    context.lineWidth = 6;
    context.strokeStyle = "#ff4040";

    context.beginPath();
    context.moveTo(context.canvas.width / 2 + context.canvas.width / 6, 0);
    context.lineTo(
      context.canvas.width / 2 + context.canvas.width / 6,
      context.canvas.height / 2
    );
    context.stroke();
    context.closePath();

    context.beginPath();
    context.moveTo(context.canvas.width / 2, context.canvas.height / 2);
    context.lineTo(
      context.canvas.width / 2,
      context.canvas.height - context.canvas.height / 8
    );
    context.lineTo(
      context.canvas.width - context.canvas.width / 10,
      context.canvas.height - context.canvas.height / 8
    );
    context.lineTo(
      context.canvas.width - context.canvas.width / 10,
      context.canvas.height - context.canvas.height / 3
    );
    context.lineTo(
      context.canvas.width,
      context.canvas.height - context.canvas.height / 3
    );
    context.stroke();
    context.closePath();

    context.beginPath();
    context.moveTo(0, context.canvas.height - context.canvas.height / 3);
    context.lineTo(
      context.canvas.width / 2,
      context.canvas.height - context.canvas.height / 3
    );
    context.stroke();
    context.closePath();

    return () => canvas.remove();
  }, [_canvas]);

  return (
    <div className="landingWrapper__wrapper lMakeMoreMoney">
      <canvas ref={_canvas} />
      <div>
        <div>
          <h1>Make more money to improve your life</h1>
          <p>
            Posterity is more than just a financial service provider. It is a
            community of like-minded people who want to achieve their financial
            goals and secure their future. When you join Posterity, you’ll
            become a part of our family and we will support you every step of
            the way. We offer you two ways to grow your wealth with Posterity:
            <dl>
              <dt>Compound Investing</dt>
              <dd>
                This is a smart and flexible way to increase your monthly
                returns by reinvesting your profits as you wish. You can choose
                how much and how often you want to compound your returns and
                enjoy the power of compound interest.
              </dd>

              <dt>Affiliate Hybrid Plan</dt>
              <dd>
                This is a unique and generous way to earn extra income by
                referring new clients to the platform. You will receive high
                commissions on both Posterity referral plans, and you can also
                benefit from the referrals of your sub-affiliates. This is a
                win-win situation for you and your network.
              </dd>
            </dl>
            Start earning more with us NOW. Sign up today and become a Posterity
            affiliate.
          </p>
        </div>
      </div>

      {props.buttons}
    </div>
  );
}
