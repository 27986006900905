import { Form, Formik } from "formik";
import { debounce } from "lodash";
import { useState } from "react";
import Swal from "sweetalert2";
import { ICONS } from "../../../../assets/icons-base64";
import { googleAuthenticatorAction } from "../../../../features/authentication";
import { ajaxSuccessAlert } from "../../../../features/utils";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { Button } from "../../../common-components";
import Active2FAForm from "./Active2FAForm";
export default function TwoFactorAuthentication() {
  const [open2FAForm, setOpen2FAForm] = useState(false);
  const dispatch = useAppDispatch();
  const setOpen2FAFormDebounced = debounce(setOpen2FAForm, 400);
  const { GAData } = useAppSelector((state) => state.user);
  const { userProfileDataUtils } = useAppSelector((state) => state.user);

  return (
    <>
      <Active2FAForm
        isOpen={open2FAForm}
        onClose={() => setOpen2FAFormDebounced(false)}
      />
      <Formik
        initialValues={{}}
        onSubmit={async (values, actions) => {
          actions.setSubmitting(true);

          await dispatch(googleAuthenticatorAction());

          ajaxSuccessAlert(
            "Scan Barcode and Active Two Factor Authentication"
          ).then((res) => {
            if (res.value) {
              Swal.close();
              setOpen2FAFormDebounced(true);
            }
          });

          actions.setSubmitting(false);
        }}
      >
        {({
          values,
          handleReset,
          setFieldValue,
          handleSubmit,
          handleBlur,
          handleChange,
          errors,
          isSubmitting,
        }) => (
          <Form
            onReset={handleReset}
            onSubmit={handleSubmit}
            className="settingsCard"
          >
            <div>
              <h3>
                <img
                  src={`data:${ICONS.access.mimeType};base64, ${ICONS.access.imageData}`}
                  alt="posterity"
                />
                Two Factor Authentication
              </h3>
              <span>
                By hitting the "Activate 2FA" you'll be transferred to the "2FA
                Activation" page. Please backup your Authenticator App upon
                activation, so if you can't access your phone, then you'll have
                the option to recover your App. If not, you will LOSE full
                access to your account permanently.
              </span>
            </div>
            <Button
              loading={GAData.status === "pending"}
              disabled={
                userProfileDataUtils.data.twoFaAuthenticationAt ? true : false
              }
              type="submit"
            >
              Activate 2FA
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
}
