import { Field, Formik, Form } from "formik";
import { Button, CustomModal } from "../../common-components";
import React, { MouseEvent, useState } from 'react'
import { ajaxWarningAlert } from "../../../features/utils";
import { useFormik } from "formik";
import { actions as investActions } from "../../../features/investment";
import { actions as setWalletType } from "../../../features/investment/investment";
import { useAppDispatch, useAppSelector } from "../../../store";
import { currencyFormat } from '../../../features/utils';

function InvestChoice({
    isOpen,
    onClose,
    setInvestFormVisible,
    setInvestFormInvisible,
    depositBalance,
    withdrawableBalance
    // hasInvestedAt,
    // maxSupply,
}: {
    isOpen: boolean;
    onClose(): void;
    setInvestFormVisible(): void;
    setInvestFormInvisible(): void;
    depositBalance: number;
    withdrawableBalance: number
    // hasInvestedAt: string | undefined | null;
    // maxSupply: number;
}) {



    const dispatch = useAppDispatch();

    return (
        <Formik
            initialValues={{ investChoice: "" }}
            validateOnChange
            validateOnBlur
            validateOnMount={false}
            //   validationSchema={Yup.object().shape({
            //     investAmount: Yup.number()
            //       .required("You need to enter desired invest amount.")
            //       .min(
            //         hasInvestedAt !== null ? 20 : 20,
            //         hasInvestedAt !== null
            //           ? `Minimum invest must be ${currencyFormat(
            //               hasInvestedAt !== null ? 20 : 20
            //             )}`
            //           : `‏To activate your account, you need to deposit more than $20. The entered amount must be a multiple of $20`
            //       )
            //       .max(50000, `Maximum invest must be ${currencyFormat(50000)}`)
            //       .test(
            //         "user_invest_amount",
            //         "Your available balance is not enough to make such a deposit. Please use “Deposit” on the current page to top up your balance.",
            //         function (value) {
            //           if (Number(value) > maxSupply) return false;
            //           if (Number(value) <= maxSupply) return true;

            //           return false;
            //         }
            //       )
            //       .test(
            //         "user_invest_multiple_20",
            //         "Deposit amount must be a multiple of $20.",
            //         function (value) {
            //           if (Number(value) % 20 === 0) return true;

            //           return false;
            //         }
            //       ),
            //   })}
            onSubmit={async (values, actions) => {
                actions.setSubmitting(true);
                actions.setSubmitting(false);
            }}

        >
            {({
                values,
                handleReset,
                handleSubmit,
                errors,
                handleChange,
                handleBlur,
                isSubmitting,
                setFieldValue
            }) => (
                <CustomModal
                    isOpen={isOpen}
                    closeOnClickOutside={false}
                    className="Modal-inevest-choice"
                    onClose={() => {
                        if (isSubmitting
                            // || status === "pending"
                        ) return;
                        handleReset();
                        onClose();
                    }}
                >
                    <Form>

                        <div role="group" className="w-full flex flex-col justify-center p-10">
                            <span className="ml-2 text-gray-500 mb-10 text-justify">
                            To invest, you must choose one of the available options. If you have funds available from your deposited cryptocurrencies, choose the first option. If you have funds available in your wallet balance (investment returns plus referral funds), choose the second option. Please note that you cannot invest from the sum of these two options. Your investment must be a multiple of $20. If you do not have sufficient funds, the corresponding option is disabled.
                            </span>
                            <label className="inline-flex items-center mb-4">
                                <Field
                                    type="radio"
                                    className="form-radio text-indigo-600 h-5 w-5 input-choice-invest"
                                    value="deposit"
                                    // checked={values.choiceAmount === "15000"}
                                    disabled={depositBalance <= 20 ? true : false}

                                    name="investChoice"
                                    onChange={handleChange}
                                />
                                <span className="ml-2">Make an investment from your deposited cryptocurrencies (Available Funds : <div id="balance-container">{currencyFormat(depositBalance).replace("$", "")} USD</div>)</span>

                            </label>
                            <label className="inline-flex items-center">
                                <Field
                                    type="radio"
                                    className="form-radio text-indigo-600 h-5 w-5 input-choice-invest"
                                    name="investChoice"
                                    value="internal"
                                    disabled={withdrawableBalance <= 20 ? true : false}
                                    // checked={values.choiceAmount === "17000"}
                                    // id="input-choice-invest"

                                    // checked={checked}
                                    onChange={handleChange}

                                />
                                <span className="ml-2">Make an investment from the sum of your investment returns and referral earnings (Available Funds : <div id="balance-container">{currencyFormat(withdrawableBalance).replace("$", "")} USD</div>)</span>
                            </label>

                        </div>

                        <div className="mb-10">
                            <Button

                                loading={isSubmitting ? true : undefined}
                                disabled={values.investChoice === "" ? true : false}
                                type="submit"
                                onClick={(event: MouseEvent<HTMLButtonElement>) => {
                                    event.preventDefault();
                                    setInvestFormVisible();
                                    dispatch(investActions.setWalletType(values.investChoice))
                                    onClose();

                                }}
                            >
                                Continue
                            </Button>
                        </div>

                    </Form>


                </CustomModal>
            )}
        </Formik>
    )
}

export default InvestChoice
