import { createSlice, current } from "@reduxjs/toolkit";
import { differenceWith, isEqual } from "lodash";
import {
  IMatchingBonusCardModel,
  IMatchingBonusModel,
  IMatchingData,
  IMatchingSubsetModel,
  IMatchingTree,
  IReferral,
  IStatus,
  IValidationErrors,
} from "../interfaces";
import { ISetTeamRes } from "../interfaces/matching-bonus";
import { IGetReferral } from "../interfaces/user";
import { getMatchingBonusData } from "./slices/getMatchingBonusData";
import { getMatchingById } from "./slices/getMatchingById";
import { getReferralAction } from "./slices/getReferralsAction";
import { setTeamAction } from "./slices/setTeamAction";

const initialData: {
  status: "idle" | "rejected" | "pending";
  setTeamAStatus: "idle" | "rejected" | "pending";
  setTeamBStatus: "idle" | "rejected" | "pending";
  error: IValidationErrors;
  dataFetched: boolean;
  referrals: {
    data: IReferral[];
    status: "idle" | "rejected" | "pending";
    error: IValidationErrors;
    dataFetched: boolean;
  };
  getReferrals: IGetReferral[];
  matchingTree: IMatchingTree | undefined;
  matchingTeams: {
    team_a: IMatchingSubsetModel | undefined;
    team_b: IMatchingSubsetModel | undefined;
  };
  setTeam: {
    status: IStatus;
  };
  setTeamRes: {
    data: ISetTeamRes;
  };
  treeChart: {
    status: IStatus;
    dataFetched: boolean;
    data: IMatchingData;
  };
  getMatchingById: {
    status: IStatus;
    data: IMatchingBonusCardModel[];
  };
} = {
  status: "idle",
  setTeamAStatus: "idle",
  setTeamBStatus: "idle",
  error: {},
  dataFetched: false,
  referrals: {
    data: [],
    status: "idle",
    error: {},
    dataFetched: false,
  },
  getReferrals: [],
  treeChart: {
    status: "idle",
    dataFetched: false,
    data: {
      matchingTeamInfo: [],
      investorsChart: [],
      totalInvestorsTeamA: 0,
      totalInvestorsTeamB: 0,
      totalDownlineInvestment: 0,
      firstDayLifecycle: null,
      directReferralsTeamA: 0,
      directReferralsTeamB: 0,
      currentLifecycleStartedAt: null,
      currentLifecycleEndsAt: null,
      matchedBonuses: "",
      totalEarnings: 0,
      totalPendings: 0,
      unmatchedInvestmetsA : 0,
      unmatchedInvestmetsB : 0,
      burnedAmount : 0,
      totalAmbi: {
        teamA: 0,
        teamB: 0,
      },
      investors: {
        teamA: 0,
        teamB: 0,
        total: 0,
      },
      directReferrals: {
        teamA: 0,
        teamB: 0,
        total: 0,
      },
    },
  },
  matchingTree: undefined,
  matchingTeams: {
    team_a: undefined,
    team_b: undefined,
  },
  getMatchingById: { status: "idle", data: [] },
  setTeam: {
    status: "idle",
  },
  setTeamRes: {
    data: {
      teamName: null,
      teamMemberId: 0,
      totalDownline: 0,
      totalDownlineInvestments: 0,
      memberTotalInvestments: 0,
      memberMatchingStatus: "",
      nickName: "",
    },
  },
};

export const { reducer, actions } = createSlice({
  name: "matchingBonus",
  initialState: initialData,
  reducers: {
    setReferralsData(state, action) {
      state.referrals.data = action.payload;
    },
    clearErrors(state) {
      state.error = {};
    },
  },

  extraReducers: (builder) => {
    // get referrals
    builder
      .addCase(getReferralAction.fulfilled, (state, action) => {
        state.status = "idle";
        state.dataFetched = true;
        if (action.payload) {
          let refereeId = action.payload.map((e) => e.id);
          let matchingDataId = state.treeChart.data.matchingTeamInfo.map(
            (e) => e.id
          );
          let filterData = refereeId.filter((d) => !matchingDataId.includes(d));
          state.getReferrals = action.payload.filter((e) =>
            filterData.includes(e.id)
          );
        }
      })
      .addCase(getReferralAction.pending, (state) => {
        state.status = "pending";
        state.dataFetched = false;
      })
      .addCase(getReferralAction.rejected, (state, action) => {
        state.status = "rejected";
        state.dataFetched = true;
      });

    // set team
    builder
      .addCase(setTeamAction.fulfilled, (state, action) => {
        state.setTeam.status = "idle";
        state.setTeamRes.data = action.payload;

        let parentNode = state.treeChart.data.matchingTeamInfo.find(
          (e) => e.parentId === null
        );

        if (parentNode !== undefined) {
          let newData = {
            id: action.payload.teamMemberId,
            nickname: action.payload.nickName,
            teamName: action.payload.teamName,
            totalInvestments: action.payload.memberTotalInvestments,
            matchingStatus: action.payload.memberMatchingStatus,
            parentId: parentNode?.id ? parentNode?.id : null,
          };

          state.treeChart.data.matchingTeamInfo.push(newData);

          let motherArr = state.treeChart.data.matchingTeamInfo.filter(
            (node) => !node.teamName
          );
          let childrenArr = state.treeChart.data.matchingTeamInfo.filter(
            (node) => node.teamName
          );
          childrenArr.sort((a: any, b: any) => {
            return a.teamName.localeCompare(b.teamName);
          });

          state.treeChart.data.matchingTeamInfo = [
            ...motherArr,
            ...childrenArr,
          ];
        }
      })
      .addCase(setTeamAction.pending, (state) => {
        state.setTeam.status = "pending";
      })
      .addCase(setTeamAction.rejected, (state, action) => {
        state.setTeam.status = "rejected";
      });

    builder
      .addCase(getMatchingBonusData.fulfilled, (state, { payload }) => {
        state.treeChart.status = "idle";
        state.treeChart.data = payload;

        state.treeChart.data.matchingTeamInfo.sort((a: any, b: any) =>
          a.teamName.localeCompare(b.teamName)
        );

        state.treeChart.dataFetched = true;
      })
      .addCase(getMatchingBonusData.pending, (state) => {
        state.treeChart.status = "pending";
      })
      .addCase(getMatchingBonusData.rejected, (state, action) => {
        state.treeChart.status = "rejected";
        state.treeChart.dataFetched = false;
      });

    // get Matching By Id
    builder
      .addCase(getMatchingById.fulfilled, (state, { payload }) => {
        state.getMatchingById.status = "idle";
        if (!(typeof payload === "string")) {
          state.getMatchingById.data = payload;

          let newNodes = differenceWith(
            payload,
            current(state.treeChart.data.matchingTeamInfo),
            isEqual
          );
          newNodes.length !== 0 &&
            newNodes.map((node) =>
              state.treeChart.data.matchingTeamInfo.push(node)
            );
        }
      })
      .addCase(getMatchingById.pending, (state) => {
        state.getMatchingById.status = "pending";
      })

      .addCase(getMatchingById.rejected, (state, action) => {
        state.getMatchingById.status = "rejected";
      });
  },
});

export default reducer;
