import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
} from "@reduxjs/toolkit";
import { useDispatch, useSelector, TypedUseSelectorHook } from "react-redux";

import userReducer from "./features/authentication";
import investReducer from "./features/investment";
import multiLevelDataReducer from "./features/multi-level-plan";
import userTransactionsReducer from "./features/transactions";
import userWalletsReducer from "./features/user-wallets";
import matchingBonusReducer from "./features/matching-bonus-plan";
import userPaymentReducer from "./features/payments";
import downloadsReducer from "./features/downloads";
import withdrawReducer from "./features/withdraw";
import maintenanceReducer from "./features/maintenance/maintenance";
import dashboardPortfolioReducer from "./features/Dashboard-Portfolio/dashboard-portfolio-reducer";
import notificationReducer from "./features/notification/notification"
import { apiPendingMiddleware } from "./features/apiPendingMiddleware";

const appReducer = combineReducers({
  user: userReducer,
  investment: investReducer,
  multiLevelData: multiLevelDataReducer,
  matchingBonusData: matchingBonusReducer,
  transactions: userTransactionsReducer,
  userWallets: userWalletsReducer,
  payments: userPaymentReducer,
  downloads: downloadsReducer,
  withdraw: withdrawReducer,
  dashboardPortfolio: dashboardPortfolioReducer,
  maintenance: maintenanceReducer,
  notification : notificationReducer
});

//@ts-ignore
const rootReducer = (state, action) => {
  // for clearing state on signout
  if (action.type === "User/signout") {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().prepend(apiPendingMiddleware.middleware),
});

// export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
