// @flow
import classNames from "classnames";
import { redirectTo } from "../../features/utils";
type Props = {
  "aria-label": string;
  isDisable: boolean;
  isActiveNow: boolean;
  setOpenMenu: (value: React.SetStateAction<boolean>) => void;
  redirectToPath: string;
  children: React.ReactNode;
};
export const ReloadingNavLink = (props: Props) => {
  return (
    <a
      aria-label={props["aria-label"]}
      className={classNames("sideBar__link", {
        sideBar__link_disable: props.isDisable,
        sideBar__link_active: props.isActiveNow,
      })}
      onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
        if (props.isDisable) {
          event.preventDefault();
          return;
        } else {
          props.setOpenMenu(false);
          redirectTo(props.redirectToPath);
        }
      }}
    >
      {props.children}
    </a>
  );
};
