import classNames from "classnames";
import React, { useState } from "react";

import {
  Cancel,
  ClockIcon,
  DeactivateIcon,
  TickIcon,
} from "../../../../assets/icons";
import { ITransactionReports } from "../../../../features/interfaces";
import { currencyFormat } from "../../../../features/utils";
import { AccordionSizes } from "../../../common-components";

interface ITransactionCardProps {
  width: number;
  transaction: ITransactionReports;
}

function TransactionCard(props: ITransactionCardProps) {
  const { width, transaction } = props;

  let size =
    width <= 1000 && width > 800
      ? AccordionSizes.large
      : width <= 800 && width > 600
      ? AccordionSizes.medium
      : width <= 600
      ? AccordionSizes.small
      : AccordionSizes.normal;
  const [accordionOpen, setAccordionOpen] = useState(false);

  const renderHeader = () => {
    switch (size) {
      case AccordionSizes.normal:
        return (
          <>
            
            <div className="accordionItem__item">
              <p>Type</p>
              <p>{transaction.coinType}</p>
            </div>
            <div className="accordionItem__item text-cyan-600">
              <p>Amount (Crypto)</p>
              <p>
                {transaction.cryptoAmount === null
                  ? 0
                  : transaction.cryptoAmount}
              </p>
            </div>
            <div className="accordionItem__item">
              <p>Amount (USD)</p>

              <p>
                {transaction.usdAmount === null
                  ? currencyFormat(0)
                  : currencyFormat(transaction.usdAmount)}
              </p>
            </div>
            <div className="accordionItem__item">
              <p>Wallet Address</p>
              <p className="walletAddress_report">
                {transaction.walletAddress}
              </p>
            </div>
            <div className="accordionItem__item">
              <p>Status</p>
              {transaction.status === "Rejected" ? (
                <p className="flex items-center text-red-600">
                  <Cancel className="w-4 h-4 mr-2 fill-current" />
                  Rejected
                </p>
              ) : transaction.status === "Pending" ? (
                <p className="flex items-center text-gray-600">
                  <ClockIcon className="w-4 h-4 mr-2 fill-current" />
                  Pending
                </p>
              ) : (
                <p className="flex items-center text-green-600">
                  <TickIcon className="w-4 h-4 mr-2 fill-current" />
                  Approved
                </p>
              )}
            </div>
          </>
        );

      case AccordionSizes.large:
        return (
          <>
            <div className="accordionItem__item">
              <p>Type</p>
              <p>{transaction.coinType}</p>
            </div>
            <div className="accordionItem__item">
              <p>Amount (Crypto)</p>
              <p className="text-cyan-600">
                {transaction.cryptoAmount === null
                  ? 0
                  : transaction.cryptoAmount}
              </p>
            </div>
            <div className="accordionItem__item">
              <p>Amount (USD)</p>
              <p>
                {transaction.usdAmount === null
                  ? currencyFormat(0)
                  : currencyFormat(transaction.usdAmount)}{" "}
              </p>
            </div>
            <div className="accordionItem__item">
              <p>Status</p>
              {transaction.status === "Rejected" ? (
                <p className="flex items-center text-red-600">
                  <Cancel className="w-4 h-4 mr-2 fill-current" />
                  Rejected
                </p>
              ) : transaction.status === "Pending" ? (
                <p className="flex items-center text-gray-600">
                  <ClockIcon className="w-4 h-4 mr-2 fill-current" />
                  Pending
                </p>
              ) : (
                <p className="flex items-center text-green-600">
                  <TickIcon className="w-4 h-4 mr-2 fill-current" />
                  Approved
                </p>
              )}
            </div>
          </>
        );

      case AccordionSizes.medium:
        return (
          <>
            <div className="accordionItem__item">
              <p>Type</p>
              <p>{transaction.coinType}</p>
            </div>
            <div className="accordionItem__item text-cyan-600">
              <p>Amount (Crypto)</p>
              <p>
                {transaction.cryptoAmount === null
                  ? 0
                  : transaction.cryptoAmount}
              </p>
            </div>
            <div className="accordionItem__item">
              <p>Amount (USD)</p>
              <p>
                {transaction.usdAmount === null
                  ? currencyFormat(0)
                  : currencyFormat(transaction.usdAmount)}
              </p>
            </div>
            <div className="accordionItem__item">
              <p>Status</p>
              {transaction.status === "Rejected" ? (
                <p className="flex items-center text-gray-600">
                  <DeactivateIcon className="w-4 h-4 mr-2 fill-current" />
                  Rejected
                </p>
              ) : transaction.status === "Pending" ? (
                <p className="flex items-center text-gray-600">
                  <ClockIcon className="w-4 h-4 mr-2 fill-current" />
                  Pending
                </p>
              ) : (
                <p className="flex items-center text-green-600">
                  <TickIcon className="w-4 h-4 mr-2 fill-current" />
                  Approved
                </p>
              )}
            </div>
          </>
        );

      case AccordionSizes.small:
        return (
          <>
            <div className="accordionItem__item">
              <p>Type</p>
              <p>{transaction.coinType}</p>
            </div>
            <div className="accordionItem__item text-cyan-600">
              <p>Amount (Crypto)</p>
              <p>
                {transaction.cryptoAmount === null
                  ? 0
                  : transaction.cryptoAmount}
              </p>
              <p>0</p>
            </div>
            <div className="accordionItem__item">
              <p>Amount (USD)</p>
              <p>
                {transaction.usdAmount === null
                  ? currencyFormat(0)
                  : currencyFormat(transaction.usdAmount)}{" "}
              </p>
            </div>
          </>
        );
    }
  };

  const renderContent = () => {
    switch (size) {
      case AccordionSizes.normal:
        return (
          <>
            <div className="accordionItem__item">
              <p>Transaction Hash:</p>
              <p>{transaction.txHash ? transaction.txHash : "-"}</p>
            </div>
          </>
        );

      case AccordionSizes.large:
        return (
          <>
            <div className="accordionItem__item">
              <p>Transaction Hash:</p>
              <p>{transaction.txHash ? transaction.txHash : "-"}</p>
            </div>

            <div className="accordionItem__item">
              <p>Wallet Address</p>
              <p>{transaction.walletAddress}</p>
            </div>
          </>
        );

      case AccordionSizes.medium:
        return (
          <div className="accordionItem__itemsCol">
            <div className="accordionItem__item">
              <p>Transaction Hash:</p>
              <p>{transaction.txHash ? transaction.txHash : "-"}</p>
            </div>

            <div className="accordionItem__item">
              <p>Wallet Address</p>
              <p>{transaction.walletAddress}</p>
            </div>
          </div>
        );

      case AccordionSizes.small:
        return (
          <div className="accordionItem__itemsCol">
            <div className="accordionItem__item">
              <p>Transaction Hash:</p>
              <p className="break-all whitespace-normal">{transaction.txHash ? transaction.txHash : "-"}</p>
            </div>

            <div className="accordionItem__item">
              <p>Wallet Address</p>
              <p>{transaction.walletAddress}</p>
            </div>
            <div className="accordionItem__item">
              <p>Status</p>
              {transaction.status === "Rejected" ? (
                <p className="flex items-center text-gray-600">
                  <DeactivateIcon className="w-4 h-4 mr-2 fill-current" />
                  Rejected
                </p>
              ) : transaction.status === "Pending" ? (
                <p className="flex items-center text-gray-600">
                  <ClockIcon className="w-4 h-4 mr-2 fill-current" />
                  Pending
                </p>
              ) : (
                <p className="flex items-center text-green-600">
                  <TickIcon className="w-4 h-4 mr-2 fill-current" />
                  Approved
                </p>
              )}
            </div>
          </div>
        );
    }
  };

  return (
    <div
      className={classNames("accordionItem", {
        accordionItem__opened: accordionOpen,
      })}
    >
      <div
        className="accordionItem__line"
        onClick={(event: React.MouseEvent) => setAccordionOpen(!accordionOpen)}
      >
        <div className="accordionItem__items">{renderHeader()}</div>
        <span className="accordionItem__icon" />
      </div>

      <div className="accordionItem__inner">
        <div className="accordionItem__content">
          <div className={classNames("accordionItem__items bordered")}>
            {renderContent()}
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(TransactionCard);
