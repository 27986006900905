import { memo } from "react";
import { currencyFormat } from "../../../features/utils";
import { useAppSelector } from "../../../store";
import { SlickSlider } from "../../common-components";

interface IOverviewCardsListProps {}

function OverviewCardsList(props: IOverviewCardsListProps) {
  const { data } = useAppSelector((state) => state.multiLevelData);

  return (
    <div style={{ marginBottom: "1em" }}>
      <SlickSlider
        settings={{
          className: "slider variable-width",
          dots: false,
          infinite: true,
          speed: 500,
          initialSlide: 0,
          slidesToShow: 4,
          slidesToScroll: 0,
          arrows: false,
          centerMode: false,
          autoplay: true,
          autoplaySpeed: 3000,
          pauseOnHover: true,
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                speed: 500,
                initialSlide: 1,
                slidesToShow: 2.3,
                slidesToScroll: 1,
                centerMode: true,
              },
            },
            {
              breakpoint: 840,
              settings: {
                speed: 500,
                initialSlide: 1,
                slidesToShow: 2.1,
                slidesToScroll: 1,
                centerMode: true,
              },
            },
            {
              breakpoint: 760,
              settings: {
                speed: 500,
                initialSlide: 1,
                slidesToShow: 2.2,
                slidesToScroll: 1,
                centerMode: true,
              },
            },
            {
              breakpoint: 680,
              settings: {
                speed: 500,
                initialSlide: 1,
                slidesToShow: 1.5,
                slidesToScroll: 1,
                centerMode: true,
              },
            },
            {
              breakpoint: 600,
              settings: {
                speed: 500,
                initialSlide: 1,
                slidesToShow: 1.5,
                slidesToScroll: 1,
                centerMode: true,
              },
            },
            {
              breakpoint: 450,
              settings: {
                speed: 500,
                initialSlide: 1,
                slidesToShow: 1.1,
                slidesToScroll: 1,
                centerMode: true,
              },
            },
          ],
        }}
      >
        <div className="amp__overviewCard">
          <div>
            <p className="bg-blue-600">
              {currencyFormat(data?.mlmTotal.totalEarned ?? 0)}
            </p>
            <h3>Total Earned</h3>
          </div>
        </div>
        <div className="amp__overviewCard">
          <div>
            <p className="bg-brand">
              {currencyFormat(data?.mlmTotal.pendingEarnings ?? 0)}
            </p>
            <h3>Pending Earnings</h3>
          </div>
        </div>
        <div className="amp__overviewCard">
          <div>
            <p className="bg-green-600">{data?.mlmTotal.totalInvestors ?? 0}</p>
            <h3>Total Investors</h3>
          </div>
        </div>
        <div className="amp__overviewCard">
          <div>
            <p className="bg-pink-700">{data?.mlmTotal.directReferrals ?? 0}</p>
            <h3>Direct Referrals</h3>
          </div>
        </div>
      </SlickSlider>
    </div>
  );
}

export default memo(OverviewCardsList);
