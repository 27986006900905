import React, { useEffect, useState, useCallback, useRef } from "react";
import * as Yup from "yup";
import { Link, NavLink } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import classNames from "classnames";
import web3 from "web3";
import ReCAPTCHA from "react-google-recaptcha";
import { CheckBox, Button } from "../common-components";
import { useAppDispatch, useAppSelector } from "../../store";
import { ajaxErrorAlert, EMAIL_REGEX } from "../../features/utils";
import { signInAction, actions } from "../../features/authentication";

const validationSchema = Yup.object().shape({
  passwordSearch: Yup.string()
    .required("Please enter a correct password!")
    .min(6, "Password must be at least 6 characters.")
    .max(30, "Password can't be more than 30 characters."),
  usernameSearch: Yup.string()
    .required("Please enter correct email (wallet) address to continue.")
    .test(
      "test-for-email",
      "Please enter the correct email (wallet) address or password to continue.",
      function (value) {
        if (value) {
          if (web3.utils.isAddress(value)) {
            return true;
          } else if (value.search(EMAIL_REGEX) !== -1) {
            return true;
          }
        }

        return false;
      }
    ),
  rememberCredentials: Yup.boolean(),
  captcha: Yup.boolean().required("Please answer the captcha."),
}); 
export default function Login() {
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useAppDispatch();
  const { signInData, error } = useAppSelector((state) => state.user);

  useEffect(() => {
    let message = error?.message;

    // if (message) {
    //   ajaxErrorAlert(message).then((res) => {
    //     if (res.value) dispatch(actions.clearErrors());
    //   });
    // }
  }, [error, dispatch]); 

  return (
    <Formik
      initialValues={{
        // added Search at the end for disabling auto complete in safari
        usernameSearch: "",
        passwordSearch: "",
        rememberCredentials: false,
        captcha: false,
      }}
      validateOnBlur={false}
      // validateOnChange={false}
      validationSchema={validationSchema}
      onSubmit={async (values, actions) => {
        actions.setSubmitting(true);
 

        const data = {
          [values.usernameSearch.search(EMAIL_REGEX) !== -1
            ? "email"
            : "walletAddress"]: values.usernameSearch,
          password: values.passwordSearch,
        };

        await dispatch(signInAction(data));

        actions.setSubmitting(false);
      }}
    >
      {({
        values,
        handleReset,
        setFieldValue,
        handleSubmit,
        handleBlur,
        handleChange,
        errors,
        touched,
        isValid,
        setTouched,
      }) => (
        <Form
          onReset={handleReset}
          onSubmit={handleSubmit}
          className="loginForm"
          // for disabling auto complete in firefox
          autoComplete="off"
        >
          <div>
            <label htmlFor="usernameSearch">
              Wallet Address or Email Address
            </label>
            <Field
              id="usernameSearch"
              name="usernameSearch"
              type="text"
              // validateOnBlur={false}
              value={values.usernameSearch}
              onChange={handleChange}
              onBlur={handleBlur}
              //   for disabling auto complete in chrome
              autoComplete="new-password"
              placeholder="Enter your Posterity wallet address or email address"
              className={classNames({
                inputError: errors.usernameSearch && touched.usernameSearch,
              })}
            />
            {errors.usernameSearch && touched.usernameSearch && (
              <p className="formError">{errors.usernameSearch}</p>
            )}
          </div>

          <div className="passwordField">
            <label htmlFor="passwordSearch">Password</label>
            <Field
              id="passwordSearch"
              name="passwordSearch"
              type={showPassword ? "text" : "password"}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="Enter your password"
              //   for disabling auto complete in chrome
              autoComplete="new-password"
              className={classNames({
                inputError: errors.passwordSearch && touched.passwordSearch,
              })}
            />
            <span
              onClick={(event: React.MouseEvent<HTMLSpanElement>) => {
                event.preventDefault();
                setShowPassword(!showPassword);
              }}
            >
              {showPassword ? "Hide" : "Show"}
            </span>
            {errors.passwordSearch && touched.passwordSearch && (
              <p className="formError">{errors.passwordSearch}</p>
            )}
          </div>

          <div>
            <CheckBox
              id="rememberCredentials"
              name="rememberCredentials"
              handleChange={handleChange}
              value={values.rememberCredentials}
              label="Remember Wallet Address & Password for 30 days"
            />
          </div>

          <div>
            <ReCAPTCHA
              //@ts-ignore
              sitekey={process.env.REACT_APP_SITE_KEY}
              onChange={(e) => {
                setFieldValue("captcha", true);
                setTouched({ ...touched, passwordSearch: true });
              }}
              onExpired={() => {
                setFieldValue("captcha", false);
              }}
            />
          </div>

          <div>
            {/* <NavLink> */}
            <Button
              loading={signInData.status === "pending"}
              disabled={
                signInData.status === "pending" ||
                !isValid ||
                values.captcha === false
              }
              type="submit"
            >
              Sign In
            </Button>
            {/* </NavLink> */}
          </div>
          <div className="mt-4 gap-14">
            <Link to={`auth/forgot-password`} className="mr-3">
              Forgot Password?
            </Link>
            <Link to={`auth/register`}>New to Posterity? Join now</Link>
          </div>
        </Form>
      )}
    </Formik>
  );
}
