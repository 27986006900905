import axios from "axios";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { ajaxErrorAlert, logout } from "./utils";

export const useRequestInterceptor = () => {
  const history = useHistory();
  axios.interceptors.response.use(
    (Response) => Response,
    function (error) {
      const errorCode = error.response.status;
      let interceptedMessage = "";
      interceptedMessage =
        error.response.data.error || error.response.data.message;
      switch (errorCode) {
        case 400:
          interceptedMessage = error.response.data.error?.[0] || error.response.data.message;

          break;
        case 404:
          interceptedMessage =
            error.response.data.error?.[0] || error.response.data.message;

          break;
        case 500:
          interceptedMessage =
            "Something went wrong on our side, please try again later.";
          break;

        case 503:
          // console.log(error.response);
          history.push("/panel/maintenance");
          // dispatch(actions.underMaintenance())
          interceptedMessage =
            error.response.data.error?.[0] || error.response.data.message;
          break;

        default:
          interceptedMessage =
            error.response.data.error?.[0] || error.response.data.message;
      }

      error.interceptedMessage = interceptedMessage;

      if (errorCode === 503) Swal.close();

      if (errorCode !== 503) {
        ajaxErrorAlert(interceptedMessage).then(() => {
          if (
            error.request.responseURL.includes("sign-up") ||
            error.request.responseURL.includes("sign-in")
          )
            return;
          errorCode === 401 && logout();
        });
      }
      return Promise.reject(error);
    }
  );
};
