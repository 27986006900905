// @flow
import { memo, useEffect } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import Authentication from "../components/authentication";
import { PrivateRoute } from "../components/common-components";
import LandingPage from "../components/landing-page";
import Cookie from "js-cookie";
import UserPanel from "../components/user-panel";
import Maintenance from "../components/user-panel/maintenance/Maintenance";
import { EStatus } from "../enums/EStatus";
import { generalAppMaintenanceAction } from "../features/maintenance/slices/generalMaintenanceAction";
import { useRequestInterceptor } from "../features/useRequestInterceptor";
import { useAppDispatch, useAppSelector } from "../store";

type Props = {};

const MainRoutes = (props: Props) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  useEffect(() => {
    dispatch(generalAppMaintenanceAction());
  }, []);
  const { initialInvestmentAt } = useAppSelector(
    (state) => state.user.userProfileDataUtils.data
  );
  const token = Cookie.get("token");



 

  // const { initialInvestmentAt } = useAppSelector(
  //   (state) => state.user.userProfileDataUtils.data
  // );


  // useEffect(() => {
  //   (initialInvestmentAt === null && token)  &&  history.push("/panel/deposit")
  // }, [initialInvestmentAt]);



  

  useRequestInterceptor();
  const { isAppUnderMaintenance, status } = useAppSelector(
    (state) => state.maintenance
  );

  const routesMaker = () => {
    if (status === EStatus.pending) return null;
    if (isAppUnderMaintenance) {
      return (
        <Switch>
          <Route path={`/maintenance`} component={Maintenance} />
          <Redirect to="/maintenance" />
        </Switch>
      );
    } else if (!isAppUnderMaintenance) {
      return (
        <Switch>
          <PrivateRoute path={`/panel`} component={UserPanel} />
          <Route path={`/auth`} component={Authentication} />
          <Route path={`/`} component={LandingPage} />
          {/* {token && initialInvestmentAt  ? (
            <Redirect from="*" to="/panel" />
          ) : token && !initialInvestmentAt ?(
            <Redirect from="*" to="/panel/deposit" />
          ) :  <Redirect from="/*" to="/" />} */}



          {token  ? (
            <Redirect from="*" to="/panel" />
          ) : (
            <Redirect from="/*" to="/" />
          )}
          
        </Switch>
      );
    } else {
      return (
        <Switch>
          <Route path={`/maintenance`} component={Maintenance} />
          <Redirect to="/maintenance" />
        </Switch>
      );
    }
  };
  return routesMaker();
};
export default MainRoutes;
