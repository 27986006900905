import classNames from "classnames";
import React, { useState } from "react";

interface ItemProps {
  children: React.ReactNode;
  title: string;
}

export function AccordionMenu(props: ItemProps) {
  const { children, title } = props;
  const [opened, setOpened] = useState(false);

  return (
    <div
      className={classNames("overflow-hidden font-mono px-8 py-8", {
        "accordion-item--opened": opened,
      })}
    >
      <div
        className="flex justify-between items-center cursor-pointer h-12"
        onClick={() => setOpened(!opened)}
      >
        <h3 className="text-xl">{title}</h3>
        <span className="accordion-item__icon w-6 h-6 opacity-75" />
      </div>
      <div className="accordion-item__inner">
        <div className="accordion-item__content pt-8">{children}</div>
      </div>
    </div>
  );
}
