import React from "react";
import { RouteComponentProps } from "react-router-dom";
// @ts-ignore
import Typewriter from "typewriter-effect";
import { Helmet } from "react-helmet";
import {
  LitecoinIcon,
  BitcoinIcon,
  EthereumIcon,
  TetherIcon,
  BNBIcon,
} from "../../assets/icons";

import BG from "../../assets/img/bg.jpg";
import { downloadWhitPaperModal } from "../../features/utils";

interface Props extends RouteComponentProps {
  buttons: React.ReactNode;
}

export default function HomePage(props: Props) {
  return (
    <>
      <Helmet>
        <title>
          Posterity Website | A Trustless Decentralized Financial Instrument
        </title>
        <meta
          name="description"
          content="A Trustless Decentralized Financial Instrument For Everyone. Autonomous trades powered by new proprietary A.I algorithms on a trust-less decentralized platform."
        />
        <link rel="canonical" href="https://www.posterity.biz/" />
        <meta name="robots" content="index, follow, max-image-preview:large, max-video-preview:1, max-snippet:1" />
        <meta name="keywords" content="Posterity Website" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            name: "Posterity",
            alternateName: "Posterity Website",
            url: "https://www.posterity.biz/",
            logo: "https://www.posterity.biz/Logo.png",
            sameAs: [
              "https://www.facebook.com/profile.php?id=61553027982471",
              "https://x.com/posterity_biz",
              "https://www.youtube.com/@Posterity-biz",
            ],
          })}
        </script>
      </Helmet>
      <div className="landingWrapper__wrapper lHomePage">
        <img src={BG} alt="posterity" />

        <div className="lHomePage__content">
          <h1>
            <Typewriter
              options={{
                autoStart: true,
                loop: false,
              }}
              onInit={(typewriter: any) => {
                typewriter
                  .pasteString(
                    "A Trustless Decentralized Financial Instrument for "
                  )
                  .pauseFor(2500)
                  .typeString(`<b>Me</b>`)
                  .pauseFor(2500)
                  .deleteChars(2)
                  .typeString(`<b>You</b>`)
                  .pauseFor(2500)
                  .deleteChars(3)
                  .typeString(`<b>Us</b>`)
                  .pauseFor(2500)
                  .deleteChars(2)
                  .typeString(`<b>Everyone.</b>`)
                  .pauseFor(2500)
                  .start();
              }}
            />
          </h1>
          <p>
            Earn <b>4% Monthly</b> on your digital cash for 8 years.
          </p>

          <div>
            <button
              onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
                props.history.push("/auth/register")
              }
            >
              Start Earning Now
            </button>
            <button
              onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                downloadWhitPaperModal();
              }}
            >
              Learn More
            </button>
          </div>
        </div>

        <div className="lHomePage__coinsList">
          <BitcoinIcon />
          <EthereumIcon />
          <BNBIcon />
          <TetherIcon />
        </div>

        {props.buttons}
      </div>
    </>
  );
}
