import React from "react";

export const Loading = () => (
  <div className="loadingWrapper">
    <div className="lds-ellipsis">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
);
