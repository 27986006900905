import { currencyFormat } from "../../../features/utils";
import { SlickSlider } from "../../common-components";

interface IPortfolioOverviewProps {
  totalInvest?: number;
  totalPaid?: number;
  toBePaid?: number;
  investsCount?: number;
}

export default function PortfolioOverview(props: IPortfolioOverviewProps) {
  return (
    <div style={{ marginBottom: "1em" }}>
      <SlickSlider
        settings={{
          className: "slider variable-width",
          dots: false,
          infinite: true,
          speed: 500,
          initialSlide: 0,
          slidesToShow: 4,
          slidesToScroll: 0,
          arrows: false,
          centerMode: false,
          autoplay: true,
          autoplaySpeed: 3000,
          pauseOnHover: true,
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                speed: 500,
                initialSlide: 1,
                slidesToShow: 2.8,
                slidesToScroll: 1,
                centerMode: true,
              },
            },
            {
              breakpoint: 840,
              settings: {
                speed: 500,
                initialSlide: 1,
                slidesToShow: 2.1,
                slidesToScroll: 1,
                centerMode: true,
              },
            },
            {
              breakpoint: 760,
              settings: {
                speed: 500,
                initialSlide: 1,
                slidesToShow: 2.2,
                slidesToScroll: 1,
                centerMode: true,
              },
            },
            {
              breakpoint: 680,
              settings: {
                speed: 500,
                initialSlide: 1,
                slidesToShow: 1.5,
                slidesToScroll: 1,
                centerMode: true,
              },
            },
            {
              breakpoint: 600,
              settings: {
                speed: 500,
                initialSlide: 1,
                slidesToShow: 1.5,
                slidesToScroll: 1,
                centerMode: true,
              },
            },
            {
              breakpoint: 450,
              settings: {
                speed: 500,
                initialSlide: 1,
                slidesToShow: 1.1,
                slidesToScroll: 1,
                centerMode: true,
              },
            },
          ],
        }}
      >
        <div className="amp__overviewCard">
          <div>
            <p className="bg-blue-600">{currencyFormat(props.totalInvest)}</p>
            <h3>Total Investments</h3>
          </div>
        </div>
        <div className="amp__overviewCard">
          <div>
            <p className="bg-purple-600">{currencyFormat(props.totalPaid)}</p>
            <h3>Total Paid</h3>
          </div>
        </div>
        <div className="amp__overviewCard">
          <div>
            <p className="bg-green-600">{currencyFormat(props.toBePaid)}</p>
            <h3>To Be Paid</h3>
          </div>
        </div>
        <div className="amp__overviewCard">
          <div>
            <p className="bg-pink-600">{props.investsCount}</p>
            <h3>All Investments</h3>
          </div>
        </div>
      </SlickSlider>
    </div>
  );
}
