import React, { useRef, useEffect } from "react";
interface Props {
  buttons: React.ReactNode;
}

export default function AboutPosterity(props: Props) {
  const _canvas = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const canvas = _canvas.current!;
    const context = canvas.getContext("2d")!;
    context.canvas.width = document.body.clientWidth;
    context.canvas.height = document.body.clientHeight;
    context.lineWidth = 6;
    context.strokeStyle = "#ff4040";

    context.beginPath();
    context.moveTo(context.canvas.width / 2 + context.canvas.width / 10, 0);
    context.lineTo(
      context.canvas.width / 2 + context.canvas.width / 10,
      context.canvas.height / 2
    );
    context.stroke();
    context.closePath();

    context.beginPath();
    context.moveTo(
      context.canvas.width / 2 - context.canvas.width / 10,
      context.canvas.height / 3
    );
    context.lineTo(
      context.canvas.width / 2 - context.canvas.width / 10,
      context.canvas.height / 6
    );
    context.lineTo(context.canvas.width / 10, context.canvas.height / 6);
    context.lineTo(
      context.canvas.width / 10,
      context.canvas.height - context.canvas.height / 3
    );
    context.lineTo(0, context.canvas.height - context.canvas.height / 3);
    context.stroke();
    context.closePath();

    context.beginPath();
    context.moveTo(context.canvas.width / 2, context.canvas.height / 2);
    context.lineTo(
      context.canvas.width / 2,
      context.canvas.height - context.canvas.height / 8
    );
    context.lineTo(
      context.canvas.width - context.canvas.width / 10,
      context.canvas.height - context.canvas.height / 8
    );
    context.lineTo(
      context.canvas.width - context.canvas.width / 10,
      context.canvas.height - context.canvas.height / 3
    );
    context.lineTo(
      context.canvas.width,
      context.canvas.height - context.canvas.height / 3
    );
    context.stroke();
    context.closePath();

    return () => canvas.remove();
  }, [_canvas]);

  return (
    <div className="landingWrapper__wrapper lAboutPosterity">
      <canvas ref={_canvas} />
      <div>
        <h1>About Posterity</h1>

        <p>
          Posterity is a revolutionary platform that combines the power of A.I,
          blockchain and smart contracts to offer a decentralized financial
          service that can generate passive income for its clients. Posterity
          was born from the vision of a team of experts in A.I trade, blockchain
          technologies and software development, who wanted to create a service
          that is independent of any external influence and that can provide a
          smooth, hassle-free and consistent return on investment. Posterity
          uses the Binance Smart Chain and Hyper Ledger Fabric to create a
          secure, transparent and scalable network that can handle millions of
          transactions per day. Posterity also uses a sophisticated A.I
          portfolio manager that can optimize the performance of the investments
          and adjust to the market conditions. Posterity's unique smart
          contracts ensure that the clients receive a <b>4% monthly return</b>{" "}
          on their capital for <b>eight years</b>, without any hidden fees or
          charges. Posterity is a service that puts the clients first and
          empowers them to achieve their financial goals with ease and
          confidence.
        </p>
      </div>

      {props.buttons}
    </div>
  );
}
